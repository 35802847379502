import React from "react";
import { useLocation } from "react-router-dom";
import {JAOS} from "../../services/JAOS.service";
import {formatDate} from "../../services/formatDate.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function ShootingPerson() {
    let query = useQuery();
    let jaos = new JAOS();
    
    let person = {
        FBI:(query.get("FBI") != "null" ? query.get("FBI") : ""),
        SBI:(query.get("SBI") != "null" ? query.get("SBI") : ""),
        DOB:query.get("DOB")?.split("T")[0],
        FName:(query.get("FNM") != "null" ? query.get("FNM") : ""),
        MName:(query.get("MNM") != "null" ? query.get("MNM") : ""),
        LName:(query.get("LNM") != "null" ? query.get("LNM") : ""),
    }
    console.log(person)
    if(person)
    {
        let hash = jaos.objToStack(person)
        window.location.href = '/tools/arrest?personList='+hash
    }
    else
    {
        window.location.href = '/tools/arrest'
    }


    return (
        <div className={"bg-gray-900 h-screen w-screen"}>

        </div>
    );
}

export default ShootingPerson;