import React, {useState} from "react";
import {getDropDownQuery} from "../../services/getDropdown.service";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import {RunGetCityList} from "../../services/dashboard.service";
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { breadcrumbsClasses } from "@mui/material";
import { keyframes } from "@mui/styled-engine";

interface DashboardDataGridProps {
    location:string,
    UpdateCity:any,
    UpdateToDate:any,
    UpdateFromDate:any,
    UpdateCounty:any,
    CustomDate:any,
    UpdatedDateTime:any,
    UpdateCityList:any,
}

const DashboardFilters: React.FC<DashboardDataGridProps> = ({location, UpdateCity, UpdateToDate, UpdateFromDate, UpdateCounty, CustomDate, UpdatedDateTime, UpdateCityList}) => {
    const [city, setCity] = React.useState<string>(location)
    const [county, setCounty] = React.useState<string>(location)
    const [cityList, setCityList] = React.useState<any[]>([])
    const [cityHolder, setCityHolder] = React.useState<any[]>([])
    const [countyList, setCountyList] = React.useState<any[]>([])
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false)
    const [dayRange, setDayRange] = React.useState<number>(28)
    let today= new Date();
    let monthAgoDate = new Date();
    monthAgoDate.setDate(monthAgoDate.getDate() - dayRange);
    monthAgoDate.setHours(0)
    monthAgoDate.setMinutes(0)
    monthAgoDate.setSeconds(0)
    const [fromDate, setFromDate] = React.useState<Date>(monthAgoDate)
    const [dateError, setDateError] = React.useState<string>("")

    React.useEffect(() => {
        if(!initialLoad) {
            setInitialLoad(true)
            let temp:any = []
            let county:any = []
            RunGetCityList().then((cList) => {
                temp.push({City:"ALL NJ"})
                temp.push.apply(temp, cList)
                setCityList(temp)
                setCityHolder(temp)
                county.push({County:"ALL NJ", Value:"ALL NJ"})
                for(var i of cList){
                    if(!county.some((county: { County: any, Value:any }) => county.County.toUpperCase() === i.County.replace(/([A-Z])/g, ' $1').trim().toUpperCase())){
                        county.push({County:i.County.replace(/([A-Z])/g, ' $1').trim().toUpperCase(), Value:i.County.replace('County', '')})
                    }
                }
                setCountyList(county.sort((a:any,b:any) => (a.County > b.County) ? 1 : ((b.County > a.County) ? -1 : 0)))
            })
        }
    }, []);

    const handleCityChange = (c:string) => {
        if(c) {
            UpdateCity(c)
            setCity(c)
        } 
    }

    const handleCountyChange = (c:string) => {
        if(c) {
            if(c === "ALL NJ")
            {
                UpdateCounty(c)
                setCityList(cityHolder)
                setCounty(c)
                setCity(c)
                UpdateCityList([])
            }
            else
            {
                setCounty(c)
                UpdateCounty(c)
                let county = c.replace(/([A-Z])/g, ' $1').trim()
                let cList:any = []
                let cityList:any [] = []
                cList.push({City:"WHOLE COUNTY"})
                cList.push.apply(cList, cityHolder.filter(city=>city.County === c+'County'))
                for(var cty of cList)
                {
                    cityList.push(cty.City.replace(/CITY|BORO|TWP/gi, '').trim())
                }
                UpdateCityList(cityList)
                setCity("WHOLE COUNTY")
                setCityList(cList)
            }
        }
        
    }

    const handleRangeChange = (day:any) => {
        let temp = new Date()

        if(day) {
            switch(day){
                case(1):
                    CustomDate(true)
                    temp.setFullYear(today.getFullYear()-day)
                    temp.setHours(0)
                    temp.setMinutes(0)
                    temp.setSeconds(0)
                    setFromDate(temp)
                    UpdateFromDate(temp)
                    
                break;
                case(6):
                    CustomDate(true)
                    temp.setMonth(today.getMonth()-day)
                    temp.setHours(0)
                    temp.setMinutes(0)
                    temp.setSeconds(0)
                    setFromDate(temp)
                    UpdateFromDate(temp)
                    
                break;
                case(28):
                    CustomDate(true)
                    temp.setDate(today.getDate()-day)
                    temp.setHours(0)
                    temp.setMinutes(0)
                    temp.setSeconds(0)
                    setFromDate(temp)
                    UpdateFromDate(temp)  

                break;
                case(7):
                    CustomDate(true)
                    temp.setDate(today.getDate()-day)
                    temp.setHours(0)
                    temp.setMinutes(0)
                    temp.setSeconds(0)
                    setFromDate(temp)
                    UpdateFromDate(temp)

                    
                break;
                case(0):
                    CustomDate(false)
                break;
            }
            setDayRange(day)
        }
    }

    const handleToDateChange = (date:any) => {
        if(date)
        {
            setDateError("")
            setDayRange(0)
            if(date.getDate() === new Date().getDate())
            {
                CustomDate(true)
            }
            else
            {
                CustomDate(false)
            }
            UpdateToDate(date)
        }
        else
        {
            setDateError("Input A Proper Date.")
        }
    }

    const handleFromDateChange = (date:any) => {

        if(date){
            setDateError("")
            setDayRange(0)
            setFromDate(date)
            UpdateFromDate(date)
        }
        else
        {
            setDateError("Input A Proper Date.")
        }
    }
    
    const dayList:any[] = [{Key:"Past Week",Value:7},{Key:"Past 28 Days",Value:28},{Key:"Past 6 Months",Value:6},{Key:"Past Year",Value:1},{Key:"Custom", Value:0}]
    return (
        <div className = "border-gray-900 border-2">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center ">Dashboard Filters</div>
            <div className = "border-gray-900 border-t-2">
                {cityList && cityList.length !== 0 ? 
                    <>
                        <div className = "mt-3 mr-3 ml-3">
                            <ComboBoxComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"200px"} value={city} change={(c) => { handleCityChange(c.value) }} fields={{ text: 'City', value: 'City' }} dataSource={cityList}  placeholder="Select A City" />
                        </div>
                        <div className = "mt-1 mb-3 mr-3 ml-3">
                            <ComboBoxComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"200px"} value={county} change={(c) => { handleCountyChange(c.value) }} fields={{ text: 'County', value: 'Value' }} dataSource={countyList}  placeholder="Select A County" />
                        </div>
                    </>  
                : 
                    <></> 
                }

                <div className = "m-3">
                    <ComboBoxComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"200px"} value={dayRange} change={(day) => { handleRangeChange(day.value) }} fields={{ text: 'Key', value: 'Value' }} dataSource={dayList}  placeholder="Data Range" />
                    <div className = "mt-1"><DateTimePickerComponent  cssClass="e-outline" value={fromDate} onChange={(date:any) => {handleFromDateChange(date.value) }} floatLabelType="Auto" placeholder="From Date"/></div>
                    <div className = "mt-1"><DateTimePickerComponent  cssClass="e-outline" value={UpdatedDateTime} onChange={(date:any) => {handleToDateChange(date.value) }} floatLabelType="Auto" placeholder="To Date"/></div>

                </div>
                {(dateError && dateError.length !== 0) ?  <div className = "text-red-600" style={{textAlign:"center"}}>{dateError}</div> :<></>}
            </div>

        </div>

    );
}

export default DashboardFilters;
