import React from "react";
import logo from '../../../assets/Images/newArrest.png'
import {Container, Box, Card, TextField, ToggleButtonGroup, ToggleButton, Button, Dialog} from '@mui/material'
import { MailOutline, PhoneIphone } from "@mui/icons-material";
import { Redirect } from "react-router-dom";
import {ForgotPasswordRequest, ForgotPasswordResponse} from "../../../interfaces/auth_interface";
import {ForgotPasswordAPI, logoutUser} from "../../../services/auth.service";
import ErrorIcon from '@mui/icons-material/Error';
import {JAOS} from "../../../services/JAOS.service";


function ForgotPassword() {
    let today:string = new Date().toISOString().slice(0, 10);
    const [details, setDetails] = React.useState({ empID: "", nPassword: "", cPassword: "", otp: "", DOB: ""});
    const [sendTo, setSendTo] = React.useState<string | null>('Email');
    const [validation, setValidation] = React.useState(false);
    const [authentication, setAuthentication] = React.useState(false);
    const [error, setError] = React.useState<string | null>("");

    const [empIDError, setEmpIdError] = React.useState<string>("")
    const [nPasswordError, setNPasswordError] = React.useState<string>("")
    const [cPasswordError, setCPasswordError] = React.useState<string>("")
    const [DOBError, setDOBError] = React.useState<string>("")
    const [OTPError, setOTPError] = React.useState<string>("")

    new JAOS().clearStack();


    const handleSubmit = async (e:any) => {
        e.preventDefault();


        if(handleValidation()){
            let credsReq:any = {
                EmpID: details.empID.trim(),
                NewPassword:details.nPassword,
                ConfirmPassword:details.cPassword,
                DOB:details.DOB,
                OTPType:sendTo,
                OTP: details.otp,
                Origination: 2,
            }

            let res:ForgotPasswordResponse = await ForgotPasswordAPI(credsReq)
            await setError("")

            if (res.LoggedUser && res.LoggedUser.UserValidation){
                //setCountdown(result.OTPCountdown)
                setValidation(true)
            }
            else if(res.LoggedUser)
                setError(res.LoggedUser.ErrorMessage)
            else
                setError("An Error Occurred During Authentication")


            if (res.LoggedUser && res.LoggedUser.LoginValidation)
            {
                setAuthentication(true)
            }
        }
    }

    const handleValidation = () => {
        let val = true
        setEmpIdError("")
        setNPasswordError("")
        setCPasswordError("")
        setOTPError("")
        setDOBError("")

        if(details.empID === "")
        {
            setEmpIdError("User ID Can Not Be Blank")
            val = false
        }
        if(details.DOB === "")
        {
            setDOBError("DOB Must Be Filled In")
            val = false
        }
        if(details.nPassword === "" && validation)
        {
            setNPasswordError("NewPassword Can Not Be Blank")
            val = false
        }
        if(details.cPassword === "" && validation)
        {
            setCPasswordError("ConfirmPassword Can Not Be Blank")
            val = false
        }
        if(details.otp === "" && validation)
        {
            setOTPError("PIN Can Not Be Blank")
            val = false
        }
        if( details.nPassword !== details.cPassword){
            setCPasswordError("Passwords Are Not The Same")
            setNPasswordError("Passwords Are Not The Same")
            val = false
        }

        return val
    }


    const handleSendTo = (event: React.MouseEvent<HTMLElement>, sendToLocation: string | null) => {

        if(sendToLocation)
            setSendTo(sendToLocation);
    };



    return (
        (authentication) ?  <Redirect push to="/login" />:
            <div className = "min-w-screen min-h-screen bg-gray-800">
                {/*<div className = "min-w-screen min-h-screen" style ={{"backgroundColor":"#002B7A"}}></div>*/}
                <Container component="main" maxWidth="xs" >
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}

                    >
                        <Card variant = "outlined" style = {{"padding":"15px"}}
                              sx={{
                                  marginTop: 15,
                                  boxShadow: 25,
                              }}
                        >
                            <img src = {logo} height = "150" width="400" alt=""/>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    color = {empIDError.length > 0 ? "error" : "primary"}
                                    focused = {empIDError.length > 0 ? true : undefined}
                                    margin="normal"
                                    fullWidth
                                    label="User ID"
                                    autoFocus
                                    onChange={e => setDetails({...details, empID: e.target.value})}
                                    value={details.empID}
                                />
                                {empIDError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{empIDError}</span> : <></> }
                                <TextField
                                    color = {DOBError.length > 0 ? "error" : "primary"}
                                    focused = {DOBError.length > 0 ? true : undefined}
                                    margin="normal"
                                    fullWidth
                                    label="DOB"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={e => setDetails({...details, DOB: e.target.value})}
                                    value={details.DOB}
                                />
                                {DOBError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{DOBError}</span> : <></> }
                                <ToggleButtonGroup
                                    color="primary"
                                    value={sendTo}
                                    exclusive
                                    size = "medium"
                                    onChange={handleSendTo}
                                    sx = {{mt: 1}}
                                >
                                    <ToggleButton value = ''  disabled sx = {{pr: 3.5, pl:2}} >Send PIN To</ToggleButton>
                                    <ToggleButton value="Email" sx = {{pr: 2, pl:3}} >Email<MailOutline sx = {{ml:1}} fontSize = 'small'/></ToggleButton>
                                    <ToggleButton value="Phone" sx = {{pr: 2, pl:3}} >Phone<PhoneIphone sx = {{ml:1}} fontSize = 'small'/></ToggleButton>
                                </ToggleButtonGroup>
                                {(validation) ?
                                    <span>
                        <TextField
                            color = {OTPError.length > 0 ? "error" : "primary"}
                            focused = {OTPError.length > 0 ? true : undefined}
                            margin="normal"
                            fullWidth
                            label="PIN"
                            type="password"
                            onChange={e => setDetails({...details, otp: e.target.value})}
                            value = {details.otp}
                        />
                                        {OTPError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{OTPError}</span> : <></> }
                                        <TextField
                                            color = {nPasswordError.length > 0 ? "error" : "primary"}
                                            focused = {nPasswordError.length > 0 ? true : undefined}
                                            margin="normal"
                                            fullWidth
                                            label="New Password"
                                            type="password"
                                            onChange={e => setDetails({...details, nPassword: e.target.value})}
                                            value = {details.nPassword}
                                        />
                                        {nPasswordError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{nPasswordError}</span> : <></> }
                                        <TextField
                                            color = {cPasswordError.length > 0 ? "error" : "primary"}
                                            focused = {cPasswordError.length > 0 ? true : undefined}
                                            margin="normal"
                                            fullWidth
                                            label="Confirm Password"
                                            type="password"
                                            onChange={e => setDetails({...details, cPassword: e.target.value})}
                                            value = {details.cPassword}
                                        />
                                        {cPasswordError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{cPasswordError}</span> : <></> }
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                            Reset
                        </Button>
                    </span>
                                    :
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Validate
                                    </Button>
                                }
                                <label style={{ "textAlign": "center", "color": "red", "fontSize": "10pt" }}>{error}</label>
                                <Button
                                    type="button"
                                    fullWidth
                                    style={{ "backgroundColor": "#db4035" }}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => { window.location.href = "/login" }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Card>
                    </Box>
                </Container>
            </div>
    );
}

export default ForgotPassword;