import React from 'react'

import {
    Button,
    Tooltip
} from "@mui/material";

import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";

import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import CodisPerson from './_searchResultsCodisPerson'
import CodisCaseDisplay from './_searchResultsCodisCase'
import { OpenCodisPDF } from "../../../services/detail.service";
import PersonIcon from "../../../assets/Images/Person.png";
import { showCrumbScore } from '../../../services/config.service';
interface SearchResultsProps {
    CodisPerson: PersonInfo[]
}

const SearchResultsPerson: React.FC<SearchResultsProps> = ({ CodisPerson }) => {
    const [typedPerson, setTypedPersons] = React.useState<any>({})


    let grid: Grid | null;
    const IndicatorTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{ "marginRight": "3px" }}> <img src={CODISIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundCODIS} </span> : <span />}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person.FoundReferral > 0) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <img src={ReferralRearrestedIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
        </>);
    }

    const getPersonSummary = (p: any) => {
        return (<div style={{maxWidth:"80%"}}><BTPersonReport person={p} /></div>)
    }

    //const getCODISDetailRow = (codisHit: any) => {
    //    let CodisCaseInfo = CodisCase?.filter((x) => x.HitNo === codisHit.HitNo)
    //    let PersonsInfo = Persons?.filter((x) => x.OpenCaseRole === codisHit.HitNo)
    //    return (
    //        <div>
    //            <CodisCaseDisplay CodisCase={CodisCaseInfo} />
    //            <CodisPerson CodisPerson={PersonsInfo} />
    //        </div>
    //    )
    //}

    const handleDataTyping = () => {
        if (CodisPerson) {
            let tempP: any = [...CodisPerson]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.DTOFUPDATE) {
                    p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                }
            }
            setTypedPersons(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CodisPerson])

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            grid.excelExport();
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    return (
        <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                CODIS Person Information <img className={"inline ml-2"} src={PersonIcon} alt="ai" />
                {(CodisPerson && CodisPerson.length > 0) ? <h4 className={"font-bold pl-5"}> {CodisPerson.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
            </span>
            {CodisPerson && CodisPerson.length > 0 ? <>
            <GridComponent
                dataSource={typedPerson}
                allowPaging={true}
                dataBound={dataBound}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                detailTemplate={getPersonSummary}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['Search']}
                toolbarClick={GridToolsClick}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}
                rowSelecting={(event: any) => { console.log(event) }}
                rowSelected={(event: any) => { expandRow() }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >

                <ColumnsDirective>
                    <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='MName' headerText='MI' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DOB' headerText='DOB' width={115} minWidth={115} maxWidth={115} format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DNANo' headerText='DNA #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText='SCOR Ranking' customAttributes={{ class: ['e-attr'] }}/>:<></>}
                    <ColumnDirective field='FBI' headerText='FBI #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SBINo' headerText='SBI #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SBIState' headerText='SBI State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DLNo' headerText='DL #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DLSt' headerText='DL State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective template={IndicatorTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='STATUTE' headerText='Statute' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description' maxWidth={250} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DTOFARREST' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Date of Arrest' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Dept' headerText='PoliceStop Dept' customAttributes={{ class: ['e-attr'] }} />
                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
            </> : <></>}
        </div>

    )
};

export default SearchResultsPerson
