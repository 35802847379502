import React from 'react'
import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip
} from "@mui/material";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListRounded  from '@mui/icons-material/FilterListRounded';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ColumnMenu, Resize, Reorder, SortSettingsModel, ExcelExportProperties, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { showCrumbScore } from '../../../services/config.service';


interface SearchResultsProps {
    Persons: PersonInfo[]
}

const SearchResults: React.FC<SearchResultsProps> = ({Persons})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    
    const handleDataTyping = () =>
    {
        if(Persons){
            let tempP:any = [...Persons]

            for(let p of tempP)
            {
                if(p.DOB)
                {
                    p.DOB = new Date(p.DOB)
                }
                if(p.DTOFARREST)
                {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if(p.DTOFUPDATE)
                {
                    p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                }
            }
            setTypedPersons(tempP)
        }
        
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])
    
    let grid: Grid | null;
    const gridTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{"marginRight":"3px"}}> <img src={CODISIcon} style={{"display":"inline"}} alt={""}/> {person.FoundCODIS} </span>: <span/>}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }

    const getPersonSummary = (p:any) =>
    {
        return (<BTPersonReport person={p}/>)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'MainDashboard.xlsx';

            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("SSN1") as Column).visible = true;

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("SSN1") as Column).visible = false;
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns(["FName","MName","LName","DOB" ,"SEX" ,"City" ,"State" ,"STATUTE" ,"STATUTEDESCRIPTION" ,"DTOFARREST" ,"DTOFUPDATE" ,"ORIGINATINGORINAME" ,"Dept" ])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length>0? <>
                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting = {(event:any) => {}}    
                    rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='FName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='MName' width={110} minWidth={110} maxWidth={110} headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='LName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DOB' width={115} minWidth={115} maxWidth={115} format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB'
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText='SBINo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN1' width={110} minWidth={110} maxWidth={110} headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SEX' width={110} minWidth={110} maxWidth={110} headerText='Sex' customAttributes={{ class: ['e-attr'] }}/>
                        { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText='SCOR Ranking' customAttributes={{ class: ['e-attr'] }}/>:<></>}
                        <ColumnDirective field='City' width={140} minWidth={140} maxWidth={140} headerText='City' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='State' width={110} minWidth={110} maxWidth={110} headerText='State' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTE' width={140} minWidth={140} maxWidth={140} headerText='Statute' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTEDESCRIPTION' maxWidth={250} headerText='Description' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DTOFARREST' width={140} minWidth={140} maxWidth={140} format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Arrest Date'
                                         customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DTOFUPDATE' width={135} minWidth={135} maxWidth={135} format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Entry Date'
                                         customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ORIGINATINGORINAME' maxWidth={200} headerText='Agency Arrested' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Dept' maxWidth={200} headerText='PoliceStop Dept' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={(e: any) =>  <div>{e?.Plate + " " + e?.PlateState}</div> } field='Plate' width={140} minWidth={140} maxWidth={140} headerText='Plate' customAttributes={{ class: ['e-attr'] }} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>
            
            </>:<></>}
        </div>
    );
};

export default SearchResults;