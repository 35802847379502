import React from 'react'

import {
    Button,
    Tooltip
} from "@mui/material";

import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import CodisPerson from './_searchResultsCodisPerson'
import CodisCaseDisplay from './_searchResultsCodisCase'
import {OpenCodisPDF} from "../../../services/detail.service";
interface SearchResultsProps {
    CodisHit: any[],
    CodisCase: any[],
    Persons: any[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ CodisHit, CodisCase, Persons }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        if(CodisHit) {
            let tempP: any = [...CodisHit]
            for (let p of tempP) {
                if (p.CreateDate) {
                    p.CreateDate = new Date(p.CreateDate)
                }
                if (p.DateTimeIN) {
                    p.DateTimeIN = new Date(p.DateTimeIN)
                }
            }
            setFormatData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CodisHit])

    let grid: Grid | null;
    const IndicatorTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person?.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person?.FoundArrest > 0) ? <img src={ArrestIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person?.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person?.FoundETicket > 0) ? <img src={ETicketIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person?.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person?.FoundALPR > 0) ? <img src={ALPRIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person?.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person?.FoundCODIS > 0) ? <span style={{"marginRight":"3px"}}> <img src={CODISIcon} style={{"display":"inline"}} alt={""}/> {person?.FoundCODIS} </span>: <span/>}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person?.FoundTowing > 0) ? <img src={TowingIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person?.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person?.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person?.FoundNIBIN > 0) ? <img src={NibinIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person?.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person?.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person?.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person?.FoundViolent > 0) ? <img src={ViolentIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person?.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person?.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }

    const getPersonSummary = (p:any) =>
    {
        return (<BTPersonReport person={p}/>)
    }
    
    const getCODISDetailRow = (codisHit:any) =>
    {
        let CodisCaseInfo = CodisCase?.filter((x)=> x.HitNo === codisHit.HitNo)
        let PersonsInfo = Persons?.filter((x)=> x.HitNo === codisHit.HitNo)
        return (
            <div>
                <CodisCaseDisplay CodisCase={CodisCaseInfo} />
                <CodisPerson CodisPerson={PersonsInfo} />
            </div>
        )
    }
    
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'CODIS.xlsx'
            grid.excelExport(excelProp);
        }
    }

    const expandRow = () => {

        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'CreateDate', direction: 'Descending' }
        ] };
    
    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {CodisHit && CodisHit?.length > 0 ? <>
            <GridComponent
                dataSource={FormatData}
                allowPaging={true}
                pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                sortSettings={sortSettings}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                detailTemplate={getCODISDetailRow}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClick}
                ref={g=> grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"}}
                rowSelecting={(event: any) => { console.log(event) }}
                rowSelected={(event: any) => { expandRow() }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='CreateDate' headerText='Entry Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='DateTimeIN' headerText='Report Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='HitNo' headerText='Hit #' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='CODISAgency' headerText='Agency' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='CODISCounty' headerText='County' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='Offense' headerText='Offense' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective template={IndicatorTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective template={(item: any) => (<Button onClick={() => { OpenCodisPDF(item.FileName,'ALL') /**/ }} >{item.FileName} </Button>)} headerText='File Name' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                  </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>
            </> : <></>}
        </div>

    )
};

export default SearchResults
