import React, {useState} from "react";
import { OpenReleaseNotesPDF } from "../../services/detail.service";
import { getUser } from "../../services/auth.service";
import { Account} from "../../interfaces/auth_interface";
import {Grid} from "@mui/material";
import MainLogo from "../../assets/Images/MainLogo.png";
import PoliceStopLogo from "../../assets/Images/PoliceStop.png";
import Logo from "../../assets/Images/newArrest.png";
import {BTVERSION} from "../../services/config.service";

function Footer() {
    const today = new Date();
    const [user, setUser] = React.useState<Account>(getUser())
    
    return (
        <div className={"text-center PoliceStop"}>
            {/*<img src={in_lawenftoolslogo} className={"mx-auto w-3/12"} alt={""} />*/}
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <div className = "flex justify-center items-center h-full" >
                        <img src={MainLogo} style={{'height':'40px', 'width':'124px'}} alt={'Arrest'}/>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <div id="PoliceStop-content">
                        <h5>
                    <span>                        
                        <strong>CanaraLabels Law Enforcement Tools&reg;</strong>                      
                    </span>
                        </h5>
                    </div>
                    <div id="disclaimer-content">
                        <h5>
                    <span>                        
                        <strong>DISCLAIMER:&nbsp;</strong>
                        Client assumes all responsibility for their use of this information.
                        <p><strong>&copy;&nbsp;{today.getFullYear()}&nbsp;Main&nbsp;-&nbsp;Arrest&nbsp;<a className ="text-blue-700" href="#" onClick={() => { OpenReleaseNotesPDF(user.EmpID, user.Dept)}}>(Version&nbsp;{BTVERSION})</a></strong></p>
                    </span>
                        </h5>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className = "flex justify-center items-center h-full inline">
                        <span className = "inline">
                            {/*<img src={PoliceStopLogo} style={{'height':'40px', 'width':'124px'}} alt={'Arrest'}/>*/}
                            <img src={Logo} style={{'height':'50px'}} alt={'Arrest'}/>  
                        </span>
                        
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Footer;