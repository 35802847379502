import React, { useState } from "react";
import { GetDarInfo_Query } from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";
import { RunDarQuery } from "../../services/getDar.service";
import ArrestSearch from "../../components/tools/arrest/_arrestSearch";
import PersonNearbySearchInfo from "../../components/tools/person_nearby/_personNearbySearch";
import SearchResults from "../../components/tools/person_nearby/_searchResultsPersonNearby";

function PersonNearBy() {
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [incident, setIncident] = useState<boolean>(false)
    let [mainPin, setMainPin] = useState<any>({lat:null,lng:null})

    const SubmitSearch = async (params: any, dateType: string, dropDowns: any) => {
        setIncident((dateType === "Incident"))
        setMainPin({ lat: params.Lat, lng: params.Long, tool:"personNearbyPoint" })
        let search_query: GetDarInfo_Query = {
            "QueryType": "2",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Incident") ? 1 : 0,
            "FromDate": params.FromDate,
            "ToDate": params.ToDate,
            "SearchType": "AND",
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "PoliceStopDept": dropDowns.PoliceStopDept ? dropDowns.PoliceStopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "Latitude": params.Lat,
            "Longitude": params.Long,
            "Radius": params.Radius,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "FTSIDs": [],
        }
        let qRes: any = await RunDarQuery(search_query)
        setDarTbl([])
        //console.log(qRes)
        setDarTbl(qRes.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    return (
        <div>
            {isArrestShow()?
                <>
                    <PersonNearbySearchInfo SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} incidentHeader={incident} mainPin={mainPin}/> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default PersonNearBy;