import React, { useEffect, useState } from "react";
import {BallTriangle} from "react-loader-spinner";
import Logo from "../../assets/Images/Arrest-logo-transparent.png";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {OpenUserManual} from "../../services/detail.service";
import VerifyingAuthentication from "../../components/shared/_VerifyingAuthentication";

interface UserManualProps {
}

const UserManual: React.FC<UserManualProps> = () => {
    let [initialRun, setInitialRun] = React.useState<boolean>(false)
    let [user, setUser] = useState<Account>(getUser())
    
    const handleGetUserManual = () => {
        //Depending on User Auth Level we will show a different UserManual
        OpenUserManual(user.EmpID,user.Dept)
    }
    
    useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleGetUserManual()
        }
    },[])

    return (
        <div>
            <VerifyingAuthentication />
        </div>
    );
}

export default UserManual;