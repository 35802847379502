import React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import Slide, { SlideProps } from '@mui/material/Slide';
import ArrestDropDowns from "../tools/arrest/_arrestDropDowns";
import {getCustodyCounty} from "../../services/getDropdown.service";
import {CheckBoxSelection, Inject, MultiSelectComponent} from "@syncfusion/ej2-react-dropdowns";
type TransitionProps = Omit<SlideProps, 'direction'>;

interface GRAILReferralProps {
    SubmitSearch: any
}

interface searchObject {
    option : string
    value : any
    type : string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

const GRAILReferralSearch: React.FC<GRAILReferralProps> = ({ SubmitSearch }) => {
    let today:Date = new Date();
    var toDate = new Date();
    var fromday = new Date("2022-10-01");
    //var fromday = new Date();
    //if (toDate.getDay() === 1) {
    //    prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
    //    fromday = prevDate //Default - Previous weekend
    //}
    //else
    //    fromday = toDate
    const [params, setParams] = React.useState({ Status: "Preview", Adopted:"", FBI: "", SBI: "", eCDR:"", SSN:"", FName:"", MName:"", LName:"", RTCC:"", County:[], DOB:"", toDate: today, fromDate: fromday })
    const [dateType, setDateType] = React.useState<string>("Submit Date");
    const [clear, setClear] = React.useState<number>(0);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [referralOptions, setReferralOptions] = React.useState<string[]>(["Preview", "Complete"])
    const [adoptedOptions, setAdoptedOptions] = React.useState<any[]>([{key:"Yes", value:"Y"}, {key:"No", value:"N"}])
    const [rtccOptions, setRTCCOptions] = React.useState<any[]>([{key:"Central", value:"Central"}, {key:"North", value:"North"}, {key:"South", value:"South"}])
    const [countyList, setCountyList] = React.useState<any>([])
    const [dropDownParams, setDropDownParams] = React.useState<any>({});

    const [options, setOptions] = React.useState<number>(1)
    const [optionList] = React.useState<string[]>(["Date", "eCDR #", "FBI #", "Name & DOB", "SBI #", "Status", "Adopted", "Indicator", "Referral County", "RTCC Location"])
    const [searchOptions, setSearchOptions] = React.useState<searchObject[]>([{option:"Date", value:0, type:"Date"}, {option:"Status", value:"", type:"Status"}])
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)

            try {
                getCustodyCounty("NJ").then(response => {
                    setCountyList(response)
                })
            }catch(e)
            {
                console.log(e)
            }
            
            handleSearch()
        }
    }, [])
    
    
    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const handleClear = async () => {
        await setSearchOptions([])
        setParams({ ...params, Status: "Preview", Adopted:"", FBI: "", eCDR:"", SBI: "", SSN:"", FName:"", MName:"", LName:"", RTCC:"", County:[], DOB:"", toDate: today, fromDate: fromday })
        setDateType("Submit Date")
        setClear(clear + 1)
        setSearchOptions([{option:"Date", value:0, type:"Date"}, {option:"Status", value:"", type:"Status"}])
        setOptions(options +1)
    }

    const handleSearch = () => {
        if(!searchOptions.some(e => e.option == "")) {
            SubmitSearch(params, dateType, searchOptions.some(e => e.option == "Date"), dropDownParams)
        }
        else {
            setAlertOpen(true)
        }
    }

    const HandleDropDowns = (dropDowns:any) => {
        setDropDownParams(dropDowns)
    }

    const removeOption = (indx:number) => {
        let temp = searchOptions
        let t = params
        if(temp[indx]?.type == 'Name')
        {
            t.FName = ""
            t.MName = ""
            t.LName = ""
            t.DOB = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'Status')
        {
            t.Status = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'Adopted')
        {
            t.Adopted = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'RTCC')
        {
            t.RTCC = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'County')
        {
            t.County = []
            setParams(t)
        }
        
        handleValueChange(temp[indx]?.option, "", indx)
        let del = temp.splice(indx, 1)
        if(del && del[0]?.option == 'Indicator')
        {
            setDropDownParams({})
        }
        setSearchOptions(temp)
        setOptions(options +1)
    }

    const handleOptions = () => {
        let temp = searchOptions
        temp.push({option:"", value:"", type:""})
        setSearchOptions(temp)
        setOptions(options +1)
    }

    const changeOption = (selected:string, indx:number) => {
        let temp = searchOptions
        let t = params
        if(temp[indx]?.type == 'Name')
        {
            t.FName = ""
            t.MName = ""
            t.LName = ""
            t.DOB = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'Status')
        {
            t.Status = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'Adopted')
        {
            t.Adopted = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'RTCC')
        {
            t.RTCC = ""
            setParams(t)
        }
        else if(temp[indx]?.type == 'County')
        {
            t.County = []
            setParams(t)
        }
        handleValueChange(temp[indx]?.option, "", indx)
        temp[indx] = {option:selected, value:"", type:selected == "Date" ? "Date" : selected == "Name & DOB" ? "Name" : selected == 'Status' ? 'Status' : selected == 'Adopted' ? 'Adopted' : selected == 'RTCC Location' ? 'RTCC' : selected == 'Referral County' ? 'County' : selected == 'Indicator' ? 'Indicator' : "String"}
        setSearchOptions(temp)
        setOptions(options + 1)
    }

    const handleValueChange = (type:string, value:string, indx:number) =>{
        let t:string = type.split("#")[0]
        let temp = searchOptions
        temp[indx] = {option:temp[indx].option, value:value, type:temp[indx].type}
        switch(t.trim()){
            case("FBI"):
                setParams({...params, FBI: value})
                break
            case("SBI"):
                setParams({...params, SBI: value})
                break
            case("eCDR"):
                setParams({...params, eCDR: value})
                break
        }
        setSearchOptions(temp)
        setOptions(options +1)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <Snackbar open={alertOpen} TransitionComponent={TransitionDown} anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={"error"} sx={{ width: '100%' }}>
                    {"One or More Search Condition is Invalid"}
                </Alert>
            </Snackbar>
            <div className = "flex">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{ mt: 1, mr: 2 }}
                >
                    <ToggleButton value="Submit Date" sx={{ pr: 2, pl: 2 }} >Submit Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                {/*<ToggleButtonGroup
                    color="primary"
                    value={vinType}
                    exclusive
                    size="small"
                    onChange={handleVinType}
                    sx={{ mt: 1}}
                >
                    <ToggleButton value="VIN" sx={{ pr: 2, pl: 2 }} >VIN</ToggleButton>
                    <ToggleButton value="No VIN" sx={{ pr: 2, pl: 2 }} >No VIN</ToggleButton>
                </ToggleButtonGroup>*/}
                <div className = "mt-2">
                    <IconButton size={"large"} onClick={handleOptions} color ={"inherit"} title={"Add Search Condition"}> <AddIcon/> </IconButton>
                </div>
            </div>
            {searchOptions.length > 0 ?
                ((searchOptions) ? searchOptions.map((obj, indx) => (
                    <div className="mt-5 flex">
                        <FormControl sx={{minWidth: 300, marginRight: 2}} size="small">
                            <InputLabel id="search-option">Search Option</InputLabel>
                            <Select
                                labelId="search-option"
                                value={obj.option}
                                label="Search Option"
                                onChange={(e) => changeOption(e.target.value, indx)}
                            >
                                {optionList.map((option,idx) => (
                                    <MenuItem value={option} disabled={searchOptions.some(e => e.option == option)} key={indx+'MNU'+idx}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {obj.option && obj.option.length > 0 ?
                            (obj.type == "Date" ?
                                    <span>
                                <span className="mr-4">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="From Date"
                                            value={params.fromDate}
                                            onChange={(e: any) => {
                                                setParams({...params, fromDate: e})
                                            }}
                                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB" InputLabelProps={{shrink: true}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </span>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="To Date"
                                        value={params.toDate}
                                        onChange={(e: any) => {
                                            setParams({...params, toDate: e})
                                        }}
                                        renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB" InputLabelProps={{shrink: true}} {...params} />}
                                    />
                                </LocalizationProvider>
                            </span>
                                    :
                                    obj.type == 'Name' ?
                                        <span>
                                       <TextField
                                           sx={{minWidth: 100, marginRight: 2}}
                                           className="mx-2"
                                           label={'First Name'}
                                           value={params?.FName}
                                           onChange={(e: any) => {
                                               setParams({...params, FName: e.target.value})
                                           }}
                                           size="small"
                                       /> 
                                        <TextField
                                            sx={{minWidth: 100, marginRight: 2}}
                                            className="mx-2"
                                            label={"Middle Name"}
                                            value={params?.MName}
                                            onChange={(e: any) => {
                                                setParams({...params, MName: e.target.value})
                                            }}
                                            size="small"
                                        />
                                        <TextField
                                            sx={{minWidth: 100, marginRight: 2}}
                                            className="mx-2"
                                            label={"Last Name"}
                                            value={params?.LName}
                                            onChange={(e: any) => {
                                                setParams({...params, LName: e.target.value})
                                            }}
                                            size="small"
                                        />
                                        <TextField
                                            margin='none'
                                            size={"small"}
                                            type="date"
                                            label="DOB"
                                            InputLabelProps={{ shrink: true }}
                                            value = {params.DOB}
                                            onChange={(e:any) => {setParams({...params, DOB: e.target.value})}}  />
                                    </span>
                                        :
                                        obj.type == 'Status' ?
                                            <span>
                                                <div>
                                                    <FormControl size = "small" >
                                                        <InputLabel id="Status-Label">Status</InputLabel>
                                                        <Select
                                                            labelId="Status-Label"
                                                            sx={{minWidth:510}}
                                                            className = "font-bold"
                                                            id="demo-simple-select"
                                                            value={params.Status}
                                                            label={"Status"}
                                                            onChange={(e: any) => setParams({...params, Status: e.target.value})}
                                                        >
                                                            {referralOptions.map((option, idxx) => (
                                                                <MenuItem value={option} key={indx+'status'+idxx}>{option}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </span>
                                        :
                                        obj.type == 'Adopted' ?
                                            <span>
                                                <div>
                                                    <FormControl size = "small" >
                                                        <InputLabel id="Adopted-Label">Adopted</InputLabel>
                                                        <Select
                                                            labelId="Adopted-Label"
                                                            sx={{minWidth:510}}
                                                            className = "font-bold"
                                                            id="demo-simple-select"
                                                            value={params.Adopted}
                                                            label={"Adopted"}
                                                            onChange={(e: any) => setParams({...params, Adopted: e.target.value})}
                                                        >
                                                            {adoptedOptions.map((option, idxx) => (
                                                                <MenuItem value={option.value} key={indx+'adopted'+idxx}>{option.key}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </span>
                                            :
                                            obj.type == 'RTCC' ?
                                                <span>
                                                <div>
                                                    <FormControl size = "small" >
                                                        <InputLabel id="RTCC-Label">RTCC Location</InputLabel>
                                                        <Select
                                                            labelId="RTCC-Label"
                                                            sx={{minWidth:510}}
                                                            className = "font-bold"
                                                            id="demo-simple-select"
                                                            value={params.RTCC}
                                                            label="RTCC Location"
                                                            onChange={(e: any) => setParams({...params, RTCC: e.target.value})}
                                                        >
                                                            {rtccOptions.map((option, idxx) => (
                                                                <MenuItem value={option.value} key={indx+'RTCC'+idxx}>{option.key}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </span> 
                                            :
                                            obj.type == 'County' ?
                                            <span>
                                                <div>
                                                    <FormControl size = "small" sx={{minWidth:510, maxWidth: 510}}>
                                                        <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={params.County} change={(e: any) => setParams({...params, County: e.value})} fields={{text: 'Value', value: 'Key'}} dataSource={countyList} placeholder="Referral County" mode="CheckBox">
                                                           <Inject services={[CheckBoxSelection]} />
                                                       </MultiSelectComponent>
                                                    </FormControl>
                                                </div>
                                            </span>
                                        :
                                        obj.type == "Indicator" ?
                                            <span className="inline">
                                                <ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"GVRTF"}/>
                                            </span>    
                                        :
                                        <TextField
                                            sx={{minWidth: 510}}
                                            className="mx-2"
                                            label={obj.option}
                                            value={obj.value}
                                            type={obj.type}
                                            onChange={(e) => {
                                                handleValueChange(obj.option, e.target.value, indx)
                                            }}
                                            size="small"
                                        />
                            )
                            :
                            <></>
                        }
                        <span className="ml-10 inline">
                            <IconButton aria-label="delete" size="small" onClick={() => removeOption(indx)} color={"error"}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </div>

                )) : <></>)
                :
                <div className = "text-lg text-gray-500 mt-5" style={{"display":"flex", "justifyContent":"center"}}>Please add Search Condtions</div>
            }
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch} disabled={searchOptions.length == 0}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}


export default GRAILReferralSearch;