import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {getToken, logoutUser, RefreshUserToken} from "./auth.service";
import NProgress from 'nprogress'
import process from "process";
import { API_URL } from "./config.service";

const AuthenticatedAPIReq:AxiosInstance = axios.create();



export let RequestsCounter:number = 0

AuthenticatedAPIReq.interceptors.request.use((config: AxiosRequestConfig) => {
    RequestsCounter++;
    NProgress.start()
    
    config.baseURL = API_URL
    config.withCredentials = false
    config.headers = {
        authToken: getToken(),
    }
    return config
}, (error: AxiosError):Promise<AxiosError>=>{
    if (--RequestsCounter == 0) {
        NProgress.done()
    }
    
    console.error(`[HTTP CLIENT Error (Req)] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
})

AuthenticatedAPIReq.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
    if (--RequestsCounter == 0) {
        NProgress.done()
    }
    
    RefreshUserToken() //Don't need await this have it run in the background
    
    return response;
    
}, (error: AxiosError): Promise<AxiosError> =>{
    if (--RequestsCounter == 0) {
        NProgress.done()
    }
    if(error.response?.status === 401){
        logoutUser();
        window.location.href="/login"
        return Promise.reject(error)
    }else{
        console.error(error);
        return Promise.reject(error)
    }
})

const calculatePercentage = (loaded:number, total:number) => (Math.floor(loaded) / total)
const setupUpdateProgress = () => {
    const update = (e:any) => NProgress.inc(calculatePercentage(e.loaded, e.total))
    AuthenticatedAPIReq.defaults.onDownloadProgress = update
    AuthenticatedAPIReq.defaults.onUploadProgress = update
}
setupUpdateProgress();

export default AuthenticatedAPIReq