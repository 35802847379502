import React, { useState, useEffect } from "react";
import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

interface PhoneSearchProps {
    SubmitSearch: any
}

const PhoneSearchInfo: React.FC<PhoneSearchProps> = ({ SubmitSearch }) => {
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", Address: "", City: "", State: "", Zip: "", Phone: ""})
    const [clear, setClear] = React.useState<number>(0);



    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", Address: "", City: "", State: "", Zip: "", Phone: "" })
        setClear(clear + 1)
    }

    const handleSearch = () => {
        SubmitSearch(params)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="mb-3">
                <div><TextField margin='none' size={"small"} label="Phone"  value={params.Phone} onChange={e => setParams({ ...params, Phone: e.target.value })} /></div>
            </div>
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2 mb-3" >
                <div><TextField margin='none' size={"small"} label="First Name"  value={params.FirstName} onChange={e => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="Middle Initial"  value={params.MI} onChange={e => setParams({ ...params, MI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="Last Name"  value={params.LastName} onChange={e => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="Address"  value={params.Address} onChange={e => setParams({ ...params, Address: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="City"  value={params.City} onChange={e => setParams({ ...params, City: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="State"  value={params.State} onChange={e => setParams({ ...params, State: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="Zip"  value={params.Zip} onChange={e => setParams({ ...params, Zip: e.target.value })} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default PhoneSearchInfo;