import React, {useRef ,useState} from "react";
import {Account} from "../../interfaces/auth_interface";
import LinkedAnalysisGraph from "../../components/LinkAnalysis/_linkAnalysis";
import {RunDarQuery} from "../../services/getDar.service";
import {
    GetAssociatedPeopleRun,
    GetLinkAnalysisData,
    GetPersonImage
} from "../../services/linkanalysis.service";
import {formatDT} from "../../services/formatDate.service";
import {useLocation} from "react-router-dom";
import {isArrestShow} from "../../services/auth.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LinkAnalysis() {
    const [callData, setCallData] = React.useState<boolean>(false)
    const [flag, setFlag] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>({type: 'LinkChart', items: []})
    const [linkColor, setLinkColor] = React.useState<string>('#566573')
    const [associatedPeople, setAssociatedPeople] = React.useState<any[]>([])
    const [fullData, setFullData] = React.useState<any>(data)
    let query = useQuery();

    React.useEffect(() => {
        if(!callData)
        {
            setCallData(true)
            loadChartData()
        }
    }, []);

    const getPersonImage = async (person:any) => {
        let APIKEY: string | null = query.get("APIKEY")
        let AUTHOTP: string | null = query.get("AUTHOTP")
        let pic: any = await GetPersonImage({
            FirstName: person?.d?.data?.FName ? person?.d?.data?.FName : null,
            MiddleName: person?.d?.data?.MName ? person?.d?.data?.MName : null,
            LastName: person?.d?.data?.LName ? person?.d?.data?.LName : null,
            DOB: person?.d?.data?.DOB ? person?.d?.data?.DOB : null,
            SBINo: person?.d?.data?.SBINo ? person?.d?.data?.SBINo : null,
            FBI: person?.d?.data?.FBI ? person?.d?.data?.FBI : null,
        }, APIKEY, AUTHOTP)
        return pic
    }

    const loadChartData = () => {
        let search_query: any =query.get("ReportID") //http://localhost:3000/link_analysis?ReportID=7B5430AA-B351-48CF-BA99-3D0BCDEE9C2B
        let APIKEY: string | null = query.get("APIKEY")
        let AUTHOTP: string | null = query.get("AUTHOTP")
        GetLinkAnalysisData(search_query,APIKEY,AUTHOTP).then(response => {
                if(response.length > 0) {
                    makeReport(response[0])
                }
            }
        )
    }

    //Creating data for Chart via JSON file
    const makeReport = async (report:any) => {
        let tempData = data
        //var node = { type: 'node', id: 'n1', c: 'blue', t: 'Hello World!' }
        tempData.items.push({
            type: 'node',
            id: 'R' + report.ID,
            u: 'icons/weapon.png',
            t:  (report.AssociatedEvidences.AssociatedWeapon.length > 0
                ? report.Lab + " " +  report.ReferenceNumber + " (" + (report.NIBINHit ? "HIT" : "POTENTIAL") + ")" + '\n RECOVERED WEAPON'
                : report.Lab + " " +  report.ReferenceNumber + " (" + (report.NIBINHit ? "HIT" : "POTENTIAL") + ")" + ' \n UNRECOVERED WEAPON'),
            d:{
                type:'report',
                data:report
            },
            ha0: {
                c: ( report.AssociatedEvidences.AssociatedWeapon.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)'),
                r: 30,
                w: 8
            }
        })
        for(var incident of report.Incidents) {
            //---------------INCIDENTS-------------
            tempData.items.push(
                {
                    type: 'node',
                    id: 'I' + incident.ID,
                    t:  incident.Dept.replace(/\s+/g, '').trim() + ' ' + incident.CaseNumber + '\n' + ( incident?.Shooting_DATETIME ?  formatDT(incident?.Shooting_DATETIME) : formatDT(incident.OccurrenceDate)) + '\n' + incident.Inc_Type,
                    u: 'icons/legal-docs.png',
                    d:{
                        type:'incident',
                        data:incident
                    }
                })
            tempData.items.push(
                {
                    type: 'link',
                    id: 'L-I' + incident.ID,
                    id1: 'I' + incident.ID,
                    id2: 'R' + report.ID,
                    a1:true,
                    w: 4,
                    c: linkColor,
                })
            //----------------------------------
            //---------------PEOPLE-------------
            if (incident.Persons.length !== 0)
            {
                for(var person of incident.Persons) {

                    if(person?.Role?.toUpperCase() !== ('INVESTIGATOR')){

                        tempData.items.push(
                            {
                                type: 'node',
                                id: 'P' + person.ID,
                                t: (person.FName ? person.FName : "") + ' ' + (person.LName ? person.LName : ""),
                                u: 'icons/cbimage.png',
                                d: {
                                    type: 'person',
                                    data: person,
                                    pic: false
                                }
                            })
                    }
                    tempData.items.push(
                        {
                            type: 'link',
                            id: 'L-P' + person.ID,
                            id1: 'P' + person.ID,
                            id2: 'I' + incident.ID,
                            a1: true,
                            w: 4,
                            c: linkColor,
                            t1: person.Role,
                            fb:true
                        })
                }
            }
            //---------------------------------------
            //-----------------WEAPON----------------
            if (incident.Weapon.length !== 0)
            {
                for(var weapon of incident.Weapon) {

                    tempData.items.push(
                        {
                            type: 'node',
                            id: 'W' + weapon.ID,
                            t: 'Recovered ' + (weapon.MAKE ? weapon.MAKE : "") + ' ' + (weapon.MODEL ? weapon.MODEL : ""),
                            u: 'icons/weapon.png',
                            d:{
                                type:'weapon',
                                data:weapon
                            },
                            ha0: {
                                c: ( report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.ID === weapon.ID) ? 'rgb(0, 160, 0)' : 'rgb(255,255,255)'),
                                r: 30,
                                w: 8
                            },
                        })
                    tempData.items.push(
                        {
                            type: 'link',
                            id: 'L-W' + weapon.ID,
                            id1: 'W' + weapon.ID,
                            id2: 'I' + incident.ID,
                            a1: true,
                            w: 4,
                            c: linkColor,
                            t1: weapon.SERIAL_NUMBER,
                            fb:true
                        })
                }
            }
            //---------------------------------------
            //---------------BALISTICS---------------
            if (incident.Ballistics.length !== 0)
            {
                for(var ballistic of incident.Ballistics) {

                    tempData.items.push(
                        {
                            type: 'node',
                            id: 'B' + ballistic.ID,
                            t:  (ballistic.Caliber_Type?.length > 0 ? ballistic.Caliber_Type + ' ' : "")  + (ballistic.VGID_CPart?.length > 0 ? ballistic.VGID_CPart : ""),
                            u: 'icons/shell.png',
                            d:{
                                type:'ballistic',
                                data:ballistic
                            },
                            ha0: {
                                c: ( report.AssociatedEvidences.AssociatedBallistic.some((aBallistic:any) => aBallistic.ID === ballistic.ID) ?
                                    (report.AssociatedEvidences.AssociatedWeapon.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)') : 'rgb(255,255,255)'),
                                r: 30,
                                w: 8
                            }
                        })
                    tempData.items.push(
                        {
                            type: 'link',
                            id: 'L-B' + ballistic.ID,
                            id1: 'B' + ballistic.ID,
                            id2: 'I' + incident.ID,
                            a1: true,
                            w: 4,
                            c: linkColor,
                            t1: ballistic.Caliber_Type,
                            fb:true
                        })
                }
            }
            //---------------------------------------
        }

        setData(tempData)
        setFullData(tempData)
        setFlag(true)
    }

    const GetAssociatedPeople = (node:any) => {
        let aQuery:any ={}
        if(node.d.type === 'person') {
            aQuery = {
                FirstName: (node?.d?.data?.FName ? node.d.data.FName : ""),
                MiddleName: (node?.d?.data?.MName ? node.d.data.MName : ""),
                LastName: (node?.d?.data?.LName ? node.d.data.LName : ""),
                DOB: (node?.d?.data?.DOB ? node.d.data.DOB : ""),
                FBI: (node?.d?.data?.FBI ? node.d.data.FBI : ""),
                SBINo: (node?.d?.data?.SBINo ? node.d.data.SBINo : ""),
            }
        }
        else
        {
            aQuery = node.d.data
        }
        GetAssociatedPeopleRun(aQuery).then((response:any) => {
            makeAssociatedPersonNode(node,response)
        })
    }

    const makeAssociatedPersonNode = async (node:any, associatedObject:any) => {
        let fullD = data
        setAssociatedPeople([])
        let temp:any = []
        for(var key of Object.keys(associatedObject?.AnyData)) {
            if(associatedObject.AnyData[key].length > 0){
                for(var person of associatedObject.AnyData[key])
                {
                    if(checkNode(person)) {
                        temp.push(
                            {
                                type: 'node',
                                id: 'P' + person.PersonID,
                                t: person.FName + ' ' + person.LName,
                                u: ('icons/cbimage.png'),
                                d: {
                                    type: 'associated_person',
                                    data: person,
                                    pic: false
                                }
                            })
                    }
                    if(checkLink(person)) {
                        temp.push(
                            {
                                type: 'link',
                                id: 'L-P' + person.PersonID,
                                id1: 'P' + person.PersonID,
                                id2: node.id,
                                a1: true,
                                w: 4,
                                c: linkColor,
                                t1: person.AType,
                                fb: true
                            })
                    }
                }
            }
        }
        let newList = fullD.items.concat(temp)
        fullD.items = newList
        setAssociatedPeople(temp)
        setFullData(fullD)
    }

    const checkLink = (person:any) => {
        for (var i = 0; i < fullData.items.length; i++) {
            if (fullData.items[i].type == "link") {
                if(fullData.items[i].id === 'L-P' + person.PersonID)
                {
                    return false
                }
            }
        }
        return true;
    }

    const checkNode = (person:any) => {
        for (var i = 0; i < fullData.items.length; i++) {
            if (fullData.items[i].type == "node" && fullData.items[i].d.type === 'person') {
                var data = fullData?.items[i]?.d?.data
                //SBI Check
                if(person?.SBINo === data?.SBINo)
                {
                    return false
                }
                //FBI Check
                if(person?.FBI === data?.FBI)
                {
                    return false
                }
                //NAME + DOB Check
                var pFName = person?.FName ? person?.FName : ""
                var pLName = person?.LName ? person?.LName : ""
                var dFName = data?.FName ? data?.FName : ""
                var dLName = data?.LName ? data?.LName : ""
                if((pFName === dFName) && (pLName === dLName) && (person?.DOB === data?.DOB))
                {
                    return false
                }
                //PERSONID Check
                if(('P'+person?.PersonID) === fullData?.items[i].id)
                {
                    return false
                }
            }
        }
        return true;
    }


    return (
        <>
            {isArrestShow()?
                <>
                    {flag ?
                        <div>
                            <LinkedAnalysisGraph data={data} GetAssociatedPeople={GetAssociatedPeople} associatedPeopleList={associatedPeople} getPersonImage={getPersonImage} />
                        </div>
                        :
                        <div></div>}
                </>
                :<></>}
        </>
    );
}

export default LinkAnalysis;