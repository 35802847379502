import React, { useState, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton, ButtonGroup, Box, InputLabel, Select, MenuItem, FormControl, AlertColor, Alert, Snackbar
} from "@mui/material";
import {formatDate, formatDT} from "../../services/formatDate.service"
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "./style.css";
import noImage from "../../assets/Images/cbimage.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {JAOS} from "../../services/JAOS.service";
import {RunGetPOI} from "../../services/account.service";
import {getCustodyCounty, getDropDownQuery, getRTCCArea} from "../../services/getDropdown.service";
import {RunGetGRAILReferral, RunSetGRAILReferral} from "../../services/getDar.service";
import {GRAILReferral} from "../../interfaces/GRAILReferral.interface";
import PersonImageInfo from "../../interfaces/PersonImageInfo.interface";

interface DetailProps {
    Hash?:string | null
}

const GVRTFInputForm: React.FC<DetailProps> = ({Hash}) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [listOptions, setListOptions] = React.useState<any[]>([{Key:'Yes', Value:"Y"}, {Key:'No', Value:"N"}])
    const [referralOptions, setReferralOptions] = React.useState<string[]>(["Preview", "Complete"])
    const [formOptions, setFormOptions] = React.useState<string[]>(["Pending"])
    const [custodyCountyList, setCustodyCountyList] = React.useState<any>([])
    const [resCountyList, setResCountyList] = React.useState<any>([])
    const [rtccList, setRTCCList] = React.useState<any>([]) 
    let today = new Date()
    const [params, setParams] = React.useState<any>({
        submitDate:"", fName:"", mName:"", lName:"", dob:"",sbi:"", fbi:"", eCDR:"", city:"", resCounty:"",
        custodyStatus:"N", custodyCounty:"", tArrests:0, gunArrests:0,
        felConvict:0, victimNum:0, suspectNum:0, ShootingStatus:"N", Shooting:"", gangStatus:"N", gang:"",
        paroleStatus:"N", paroleStart:"", paroleEnd:"", codisStatus:"N", codis:"", pendingCharges:0, pendingGunCharges:0, pic:"",
        referralStatus:"Preview", adoptedStatus:"N", referralCounty:"", prosecutor:"", judge:"", prosecution:"", rtcc:"",
        nibinStatus:"N", nibin:"", caseExplorer:"", caseExplorerStatus:"N", infoShareStatus:"N", infoShare:"", analyticNotes:"", comments:"",
        editStatus:"Add", reportID:"", personID:"", murderStatus:'N', murder:"", murderNum:0
    })

    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")
    
    const LoadData = () => {
        try {
            let obj = new JAOS()
            let res = obj.objFromStack(Hash ? Hash : "")
            
            try {
                getCustodyCounty("NJ").then(response => {
                    setCustodyCountyList(response)
                })
            }catch(e)
            {
                console.log(e)
            }
            
            try{
                getRTCCArea("NJ").then(response => {
                    setRTCCList(response)
                })
            }catch(e)
            {
                console.log(e) 
            }
            
            try{
                getDropDownQuery("ResidentCounty").then(response => {
                    setResCountyList(response)
                })
            }catch(e)
            {
                console.log(e)
            }

            let temp = params
            
            let today = new Date()
            let dateString = today.getFullYear() + '-' + ((today.getMonth() + 1).toString()).padStart(2,'0')  + '-' + today.getDate().toString().padStart(2,'0')

            
            if(res?.EditStatus == 'Edit' && res?.ID != null)
            {
                temp.personID = res?.PersonID
                temp.reportID = res.ID
                temp.submitDate = dateString
                temp.fName = res.FIRSTNAME ? res.FIRSTNAME : ""
                temp.mName = res.MI ? res.MI : ""
                temp.lName = res.LASTNAME ? res.LASTNAME : ""
                if(res.DOB) {
                    let dob = new Date(res.DOB)
                    let dobString = dob.getFullYear() + '-' + ((dob.getMonth() + 1).toString()).padStart(2, '0') + '-' + dob.getDate().toString().padStart(2, '0')
                    temp.dob = dobString
                }
                temp.sbi = res.SBINo ? res.SBINo : ""
                temp.fbi = res.FBI ? res.FBI : ""
                temp.eCDR = res.eCDR ? res.eCDR : ""
                temp.city = res.City ? res.City : ""
                temp.resCounty = res.Resident_County ? res.Resident_County : ""
                temp.custodyStatus = res.Custody_Status ? res.Custody_Status : ""
                temp.custodyCounty = res.Custody_County ? res.Custody_County : ""
                temp.tArrests = res.Total_Arrests ? res.Total_Arrests : 0
                temp.gunArrests = res.Gun_Arrests ? res.Gun_Arrests : 0
                temp.felConvict = res.Felony_Convictions ? res.Felony_Convictions : 0
                temp.victimNum = res.Shooting_Victim ? res.Shooting_Victim : 0
                temp.suspectNum = res.Shooting_Suspect ? res.Shooting_Suspect : 0
                temp.ShootingStatus = res.isShooting ? res.isShooting : ""
                temp.Shooting = res.Shooting ? res.Shooting : ""
                temp.gangStatus = res.isGang ? res.isGang : ""
                temp.gang = res.Gang ? res.Gang : ""
                temp.paroleStatus = res.isParole ? res.isParole : ""
                if(res.isParole == 'Y' && res.Parole && res.Parole.length > 0) {
                    let parole = res.Parole.split('-')
                    let pStartDate = new Date(parole[0])
                    let paroleStart = pStartDate.getFullYear() + '-' + ((pStartDate.getMonth() + 1).toString()).padStart(2, '0') + '-' + pStartDate.getDate().toString().padStart(2, '0')
                    temp.paroleStart = paroleStart
                    
                    let pEndDate = new Date(parole[1])
                    let paroleEnd = pEndDate.getFullYear() + '-' + ((pEndDate.getMonth() + 1).toString()).padStart(2, '0') + '-' + pEndDate.getDate().toString().padStart(2, '0')
                    temp.paroleEnd = paroleEnd
                }
                temp.codisStatus = res.isCODIS ? res.isCODIS : ""
                temp.codis = res.CODIS ? res.CODIS : ""
                temp.pendingCharges = res.Pending_Charges_Count ? res.Pending_Charges_Count : 0
                temp.pendingGunCharges = res.Pending_GunCharge_Count ? res.Pending_GunCharge_Count : 0
                temp.pic = res.Photos && res.Photos.length > 0 ? res.Photos[0].Image : ""
                temp.referralStatus = res.Status ? res.Status : ""
                temp.adoptedStatus = res.Adopted ? res.Adopted : ""
                temp.referralCounty = res.Referral_County ? res.Referral_County : ""
                temp.prosecutor = res.Prosecutor ? res.Prosecutor : ""
                temp.judge = res.Judge ? res.Judge : ""
                temp.prosecution = res.Prosecution_Outcome ? res.Prosecution_Outcome : ""
                temp.rtcc = res.RTCC ? res.RTCC : ""
                temp.nibinStatus = res.isNIBIN ? res.isNIBIN : ""
                temp.nibin = res.NIBIN ? res.NIBIN : ""
                temp.caseExplorer = res.CaseExplorer ? res.CaseExplorer : ""
                temp.caseExplorerStatus = res.isCaseExplorer ? res.isCaseExplorer : ""
                temp.infoShareStatus = res.isInfoShare ? res.isInfoShare : ""
                temp.infoShare = res.InfoShare ? res.InfoShare : ""
                temp.analyticNotes = res.Analytic_Notes ? res.Analytic_Notes : ""
                temp.comments = res.Comments ? res.Comments : ""
                temp.editStatus = res.EditStatus ? res.EditStatus : ""
            }
            else {
                if (res?.PersonInfo != null) {
                    
                    temp.personID = res?.PersonInfo?.PersonID
                    temp.submitDate = dateString
                    temp.fName = res.PersonInfo?.FName ? res.PersonInfo.FName : ""
                    temp.mName = res.PersonInfo?.MName ? res.PersonInfo.MName : ""
                    temp.lName = res.PersonInfo?.LName ? res.PersonInfo.LName : ""
                    if (res.PersonInfo?.DOB != null) {
                        let dob = new Date(res.PersonInfo.DOB)
                        let dobString = dob.getFullYear() + '-' + ((dob.getMonth() + 1).toString()).padStart(2, '0') + '-' + dob.getDate().toString().padStart(2, '0')
                        temp.dob = dobString
                    }
                    temp.sbi = res.PersonInfo?.SBINo ? res.PersonInfo?.SBINo : ""
                    temp.fbi = res.PersonInfo?.FBI ? res.PersonInfo?.FBI : ""
                }
                if (res.ArrestInfo != null && res.ArrestInfo.length > 0) {
                    temp.city = res.ArrestInfo[0]?.ResidentCity ? res.ArrestInfo[0]?.ResidentCity : ""
                    temp.resCounty = res.ArrestInfo[0]?.ResidentCounty ? res.ArrestInfo[0]?.ResidentCounty : ""
                }
                if (res.ImageInfo && res.ImageInfo.length > 0) {
                    temp.pic = res.ImageInfo[0].Image
                }
                if (res.SCORInfo != null) {
                    temp.tArrests = res.SCORInfo?.Arrest ? res.SCORInfo?.Arrest : 0
                    temp.gunArrests = res.SCORInfo?.Arrest_Gun ? res.SCORInfo?.Arrest_Gun / 5 : 0
                    temp.victimNum = res.SCORInfo?.ShootingVictim ? res.SCORInfo?.ShootingVictim / 3 : 0
                    temp.suspectNum = res.SCORInfo?.ShootingSuspect ? res.SCORInfo?.ShootingSuspect / 7 : 0

                }
                if (res?.ShootingInfo != null && res?.ShootingInfo.length > 0) {
                    temp.ShootingStatus = "Y"
                    for (var pop of res?.ShootingInfo) {
                        temp.Shooting += (pop?.RoleDesc ? pop?.RoleDesc + " in " : "") + (pop?.City ? pop?.City + ' ' : "") + (pop?.IncidentType ? pop?.IncidentType : "") + (pop?.DateTimeIn ? ' on ' + formatDate(pop?.DateTimeIn) : "") + (pop?.IncidentID ? " (" + pop?.IncidentID + ') ' : "") + '\n'
                    }
                }
                if (res?.ParoleInfo != null && res?.ParoleInfo.length > 0) {
                    if (res?.PersonInfo?.FoundPrisonParole == 2) {
                        temp.paroleStatus = 'Y'
                        let pStart = new Date(res?.ParoleInfo[0]?.Start_Date)
                        let pStartString = pStart.getFullYear() + '-' + ((pStart.getMonth() + 1).toString()).padStart(2, '0') + '-' + pStart.getDate().toString().padStart(2, '0')
                        temp.paroleStart = pStartString
                        let pEnd = new Date(res?.ParoleInfo[0]?.End_Date)
                        let pEndString = pEnd.getFullYear() + '-' + ((pEnd.getMonth() + 1).toString()).padStart(2, '0') + '-' + pEnd.getDate().toString().padStart(2, '0')
                        temp.paroleEnd = pEndString
                    }
                }
                if(res?.NIBINInfo != null && res?.NIBINInfo.length > 0)
                {
                    temp.nibinStatus = 'Y'
                    for (var n of res?.NIBINInfo) {
                        temp.nibin += (n?.Offense ? n?.Offense + (n?.HitNumber ? ' ' + n?.HitNumber + ' ' : "") +  " in " : "") +  (n?.City ? n?.City : "") + (n?.OffenseDate ? ' on ' + formatDate(n?.OffenseDate) : "") + (n?.CaseNumber ? " (" + n?.CaseNumber + ') ' : "") + '\n'
                    }
                    
                }
                if(res?.DNAInfo != null && res?.DNAInfo.length > 0)
                {
                    temp.codisStatus = 'Y'
                    for (var d of res?.DNAInfo) {
                        temp.codis += (d?.Offense ? d?.Offense +  " in " : "") +  (d?.County ? d?.County + ' County' : "") + (d?.OffenseDate ? ' on ' + formatDate(d?.OffenseDate) : "") + (d?.DNANo ? " (" + d?.DNANo + ') ' : "") + '\n'
                    }

                }
            }
            setParams(temp)
        }
    catch(e)
        {
            console.log(e)
        }
    }

    useEffect(() => {
        if(!DataLoaded) {
            setDataLoaded(true)
            LoadData()
        }
    }, [Hash]);

    const handlePersonPhoto = (target: any) => {
        try {
            var file = target?.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                setParams({...params, pic: reader.result})
            }
            reader.readAsDataURL(file);
        }
        catch (e) {
            console.log(e)
        }
    };
    
    const handleSubmit = async () => {
        if(handleValidation())
        {
            let GRAIL:GRAILReferral =
            {
                "PersonID": params?.personID,
                "ID": params?.reportID,
                "SubmitDate": params?.submitDate,
                "FIRSTNAME": params?.fName,
                "MI": params?.mName,
                "LASTNAME": params?.lName,
                "DOB": params?.dob,
                "SSN": null,
                "FBI": params?.fbi,
                "eCDR": params?.eCDR,
                "SBINo": params?.sbi,
                "SBIState": "NJ",
                "DLNo": null,
                "DLState": null,
                "City": params?.city,
                "Resident_County": params?.resCounty,
                "Custody_Status": params?.custodyStatus,
                "Custody_County": params?.custodyCounty,
                "Total_Arrests": parseInt(params?.tArrests),
                "Gun_Arrests": parseInt(params?.gunArrests),
                "Felony_Convictions": params?.felConvict,
                "Shooting_Victim": params?.victimNum,
                "Shooting_Suspect": params?.suspectNum,
                "Gang": params?.gang,
                "isShooting": params?.ShootingStatus,
                "isParole": params?.paroleStatus,
                "isCODIS": params?.codisStatus,
                "isNIBIN": params?.nibinStatus,
                "isCaseExplorer": params?.caseExplorerStatus,
                "isInfoShare": params?.infoShareStatus,
                "isGang": params?.gangStatus,
                "Arrest": null,
                "Shooting": params?.Shooting,
                "Parole": formatDate(params?.paroleStart) + ' - ' + formatDate(params?.paroleEnd),
                "CODIS": params?.codis,
                "NIBIN": params?.nibin,
                "CaseExplorer": params?.caseExplorer,
                "InfoShare": params?.infoShare,
                "Pending_Charges_Count": params?.pendingCharges,
                "Pending_GunCharge_Count": params?.pendingGunCharges,
                "Pending_Charges": null,
                "Pending_GunCharge": null,
                "Analytic_Notes": params?.analyticNotes,
                "Adopted": params?.adoptedStatus,
                "Prosecutor": params?.prosecutor,
                "Judge": params?.judge,
                "Prosecution_Outcome": params?.prosecution,
                "Referral_County": params?.referralCounty,
                "Comments": params?.comments,
                "RTCC": params?.rtcc,
                "Status": params?.referralStatus,
                "EditStatus": params?.editStatus,
                "CreateUID": null,
                "CreateDate": null,
                "UpdUID":  null,
                "UpdDate": null,
                "Photos": [{"Image": (params?.pic!= null && params?.pic.length > 0) ? params?.pic.split(',')[1] : ""}]
            }
            let response = await RunGetGRAILReferral(GRAIL)
            let grail = response?.AnyTable
            let noRecord = true
            let today = new Date()
            for (var g of grail) {
                if ((g?.Status == "Preview" || g.CreateDate == today) && (params?.editStatus != 'Edit' && params?.editStatus != 'Delete')) {
                    noRecord = false;
                }
            }
            if(noRecord) {
                let res = await RunSetGRAILReferral(GRAIL)
                if(res != null)
                {
                    setAlertType("success")
                    setAlertText(("Referral: '" + (params?.reportID ? params?.reportID : "") + "' has been Saved"))
                    setAlertOpen(true)
                }
                else {
                    setAlertType("error")
                    setAlertText(("An Error has occured while attempting to Save that record. "))
                    setAlertOpen(true)
                }
            }
            else
            {
                setAlertType("error")
                setAlertText(("An Incomplete Referral has already been created for ") + params?.fName + ' ' + params?.lName + " Please Edit through referral search.")
                setAlertOpen(true)
            }
            
        }
    }
    
    const handleValidation = () => {
        let ret = false
        
        if(params?.fName?.length > 0 && params?.mName?.length > 0 && params?.lName?.length > 0 && params?.DOB?.length > 0)
        {
            ret = true
        }
        if(params?.fbi?.length > 0)
        {
            ret = true
        }
        if(params?.sbi?.length > 0)
        {
            ret = true
        }
        
        return ret
    }
    
    
    return (
        <div className="">
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <div className = "sm:w-screen lg:w-9/12 m-auto border-2 border-gray-400 mb-5">
                <h1 className="pb-2 pt-2 font-bold text-center text-4xl text-white" style={{backgroundColor:"#1E3A8A"}}>ENTER A NEW REFERRAL</h1>
                <div className = "text-center text-sm">UNCLASSIFIED//LAW ENFORCEMENT SENSITIVE</div>
                <div className = "flex flex-row">
                    <div className ="basis-5/12">
                        {/*<div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Record ID:
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }}/> </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Date Submitted
                                <div className = "ml-2 w-44">
                                     <TextField fullWidth margin='none' type={"date"} size={"small"} inputProps={{ style: { height: 10} }} value={params?.submitDate} onChange={(e: any) => setParams({ ...params, submitDate: e.target.value })}/>
                                </div>
                            </span>
                        </div>
                        {/*<div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="Submit Date" type={"date"} value = {params.submitDate} InputLabelProps={{ shrink: true }} onChange={(e:any) => setParams({...params, submitDate: e.target.value})}  />
                                </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                FName
                                <div className = "ml-2 w-44">
                                    <TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.fName} onChange={(e: any) => setParams({ ...params, fName: e.target.value })}/>
                                </div>
                            </span>
                        </div>
                       {/* <div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="First Name" value = {params.fName} onChange={(e:any) => setParams({...params, fName: e.target.value})}  />
                                </div>
                            </span>
                        </div>
                        */}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                               MName
                                <div className = "ml-2 w-10"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.mName} onChange={(e: any) => setParams({ ...params, mName: e.target.value })}/> </div>
                            </span>
                        </div>
                        {/*<div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="Middle Name" value = {params.mName} onChange={(e:any) => setParams({...params, mName: e.target.value})}  />
                                </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                LName
                                <div className = "ml-2 w-44"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.lName} onChange={(e: any) => setParams({ ...params, lName: e.target.value })}/> </div>
                            </span>
                        </div>
                        {/*<div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="Last Name" value = {params.lName} onChange={(e:any) => setParams({...params, lName: e.target.value})}  />
                                </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                DOB
                                <div className = "ml-2 w-44">
                                     <TextField fullWidth margin='none' type={"date"} size={"small"} inputProps={{ style: { height: 10} }} value={params?.dob} onChange={(e: any) => setParams({ ...params, dob: e.target.value })}/>
                                </div>
                            </span>
                        </div>
                       {/* <div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="DOB" type={"date"} value = {params.dob} InputLabelProps={{ shrink: true }} onChange={(e:any) => setParams({...params, dob: e.target.value})}  />
                                </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                SBI
                                <div className = "ml-2 w-44"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.sbi} onChange={(e: any) => setParams({ ...params, sbi: e.target.value })}  /> </div>
                            </span>
                        </div>
                        {/*<div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="SBI" value = {params.sbi} onChange={(e:any) => setParams({...params, sbi: e.target.value})}  />
                                </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                FBI
                                <div className = "ml-2 w-44"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.fbi} onChange={(e: any) => setParams({ ...params, fbi: e.target.value })}  /> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                eCDR
                                <div className = "ml-2 w-44"><TextField fullWidth  margin='none' size={"small"} inputProps={{ style: { height: 10}, maxLength: 50 }} value={params?.eCDR} onChange={(e: any) => setParams({ ...params, eCDR: e.target.value })}  /> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                City
                                <div className = "ml-2 w-44"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.city} onChange={(e: any) => setParams({ ...params, city: e.target.value })} /> </div>
                            </span>
                        </div>
                        {/*<div className = "mb-2">
                            <span className="align-middle flex inline">
                                <div className = "ml-1">
                                    <TextField fullWidth margin='none' size={"small"} label="City" value = {params.city} onChange={(e:any) => setParams({...params, city: e.target.value})}  />
                                </div>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                County {/*Dropdown*/}
                                <div className = "ml-2 w-44">
                                    <FormControl className={"w-52"}>
                                      <Select
                                          sx={{height:28}}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={params.resCounty}
                                          onChange={(e: any) => setParams({ ...params, resCounty: e.target.value })}
                                      >
                                        {resCountyList.map((option:any, idx:any) => (
                                            <MenuItem value={option?.Value} key={'f'+idx}>{option?.Key}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </span>
                        </div>
                        {/*<div className = "ml-1 mb-2">
                            <span className="align-middle flex inline">
                                <FormControl className={"w-52"}>
                                  <InputLabel id="demo-simple-select-label">Resident County</InputLabel>
                                  <Select
                                      size={"small"}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Resident County"
                                      value={params.resCounty}
                                      onChange={(e: any) => setParams({ ...params, resCounty: e.target.value })}
                                  >
                                    {resCountyList.map((option:any) => (
                                        <MenuItem value={option?.Value}>{option?.Key}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className = "mr-2">Custody Status</span>{/* 2Dropdowns*/}
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.custodyStatus}
                                        onChange={(e: any) => setParams({ ...params, custodyStatus: e.target.value })}
                                    >
                                        {listOptions.map((option, idx) => (
                                            <MenuItem value={option.Value} key={'CSKEY'+idx}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {params?.custodyStatus == 'Y' ? 
                                    <div className = "ml-2 w-44">
                                        <FormControl size = "small" >
                                            <InputLabel id="search-option">Custody County</InputLabel>
                                            <Select
                                                sx={{height:28, minWidth:160}}
                                                className = "font-bold"
                                                id="demo-simple-select"
                                                label = "Custody County"
                                                value={params.custodyCounty}
                                                onChange={(e: any) => setParams({ ...params, custodyCounty: e.target.value })}
                                            >
                                                {custodyCountyList.map((option:any, idx:any) => (
                                                    <MenuItem value={option?.Key} key={'CCKEY'+idx}>{option?.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                : <></>}
                            </span>
                        </div>
                        {/*<div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <FormControl size = "small" >
                                    <InputLabel id="demo-simple-select-label">Custody Status</InputLabel>
                                    <Select
                                        sx={{minWidth:130}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        label = "Custody Status"
                                        value={params.custodyStatus}
                                        onChange={(e: any) => setParams({ ...params, custodyStatus: e.target.value })}
                                    >
                                        {listOptions.map((option) => (
                                            <MenuItem value={option.Value}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {params?.custodyStatus == 'Y' ?
                                    <div className = "ml-2 w-44">
                                        <FormControl size = "small" >
                                            <InputLabel id="search-option">Custody County</InputLabel>
                                            <Select
                                                sx={{minWidth:160}}
                                                className = "font-bold"
                                                id="demo-simple-select"
                                                label = "Custody County"
                                                value={params.custodyCounty}
                                                onChange={(e: any) => setParams({ ...params, custodyCounty: e.target.value })}
                                            >
                                                {custodyCountyList.map((option:any) => (
                                                    <MenuItem value={option?.Key}>{option?.Value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    : <></>}
                            </span>
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Total Arrests
                                <div className = "ml-2 w-14 mr-2"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.tArrests} onChange={(e: any) => setParams({ ...params, tArrests: e.target.value })}/> </div>
                                Gun Arrests
                                <div className = "ml-2 w-14 mr-2"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.gunArrests} onChange={(e: any) => setParams({ ...params, gunArrests: e.target.value })}/> </div>
                                Felony Convictions
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.felConvict} onChange={(e: any) => setParams({ ...params, felConvict: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                # of Times<span className = "font-bold">&nbsp;Victim&nbsp;</span>in Shooting
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.victimNum} onChange={(e: any) => setParams({ ...params, victimNum: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                # of Times<span className = "font-bold">&nbsp;Suspect&nbsp;</span>in Shooting
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.suspectNum} onChange={(e: any) => setParams({ ...params, suspectNum: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className = "mr-2">NJ POP</span>
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.ShootingStatus}
                                        onChange={(e: any) => setParams({ ...params, ShootingStatus: e.target.value })}
                                    >
                                        {listOptions.map((option, idx) => (
                                            <MenuItem value={option.Value} key={'ShootingKEY'+idx}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {params?.ShootingStatus == 'Y' ? 
                                <div className = "ml-2 w-80"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14} }} value={params?.Shooting} onChange={(e: any) => setParams({ ...params, Shooting: e.target.value })}/> </div>
                                 : <></>}
                            </span>
                        </div>
                        {/*<div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className = "mr-2">Murder Charge</span>
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.murderStatus}
                                        onChange={(e: any) => setParams({ ...params, murderStatus: e.target.value })}
                                    >
                                        {listOptions.map((option) => (
                                            <MenuItem value={option.Value}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.murderNum} onChange={(e: any) => setParams({ ...params, murderNum: e.target.value })}/> </div>
                            </span>
                            {params?.murderStatus == 'Y' ?
                                <div className = "ml-36 mt-2 w-80"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14} }} value={params?.murder} onChange={(e: any) => setParams({ ...params, murder: e.target.value })}/> </div>
                                : <></>}
                        </div>*/}
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className ="mr-4">Gang</span>
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.gangStatus}
                                        onChange={(e: any) => setParams({ ...params, gangStatus: e.target.value })}
                                    >
                                        {listOptions.map((option,idx) => (
                                            <MenuItem value={option.Value} key={'GNGKEY'+idx}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {params?.gangStatus == 'Y' ?
                                    <div className = "ml-2 w-80"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14} }} value={params?.gang} onChange={(e: any) => setParams({ ...params, gang: e.target.value })}/> </div>
                                : <></> }
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className ="mr-2">Parole</span>
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.paroleStatus}
                                        onChange={(e: any) => setParams({ ...params, paroleStatus: e.target.value })}
                                    >
                                        {listOptions.map((option, idx) => (
                                            <MenuItem value={option.Value} key={'PRLKEY'+idx}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {params?.paroleStatus == 'Y' ?
                                    <span className ="flex inline">
                                        <span className = "ml-2 w-44"><TextField fullWidth margin='none' label={"Start Date"} type={"date"} size={"small"} inputProps={{ style: { height: 10} }} InputLabelProps={{ shrink: true }} value={params?.paroleStart} onChange={(e: any) => setParams({ ...params, paroleStart: e.target.value })}/> </span>
                                        <span className = "ml-2 w-44"><TextField fullWidth margin='none' label={"End Date"} type={"date"} size={"small"} inputProps={{ style: { height: 10} }} InputLabelProps={{ shrink: true }} value={params?.paroleEnd} onChange={(e: any) => setParams({ ...params, paroleEnd: e.target.value })}/> </span>
                                    </span>
                                :<></>}
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className = "mr-2">CODIS</span>
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.codisStatus}
                                        onChange={(e: any) => setParams({ ...params, codisStatus: e.target.value })}
                                    >
                                        {listOptions.map((option,idx) => (
                                            <MenuItem value={option.Value} key={'CODISKEY'+idx}>{option.Key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {params?.codisStatus == 'Y' ?
                                    <div className = "ml-2 w-80"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14} }} value={params?.codis} onChange={(e: any) => setParams({ ...params, codis: e.target.value })}/> </div>
                                    : <></>}
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Pending Charges Prior to Last Arrest
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.pendingCharges} onChange={(e: any) => setParams({ ...params, pendingCharges: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Pending <span className = "font-bold">&nbsp;Gun Charges&nbsp;</span> Prior to Last Arrest
                                <div className = "ml-2 w-14"><TextField type={"number"} fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.pendingGunCharges} onChange={(e: any) => setParams({ ...params, pendingGunCharges: e.target.value })}/> </div>
                            </span>
                        </div>
                    </div>
                    
                    <div  className ="w-4/12">
                        <div className = "mt-2 ml-2">
                            <span className="align-middle flex inline">
                                <span className ="mt-1">Referral Status</span>
                                <div className = "ml-2 w-44 mr-5">
                                    <FormControl size = "small" >
                                        <Select
                                            sx={{height:28, minWidth:140}}
                                            className = "font-bold"
                                            id="demo-simple-select"
                                            value={params.referralStatus}
                                            onChange={(e: any) => setParams({ ...params, referralStatus: e.target.value })}
                                        >
                                            {referralOptions.map((option, idx) => (
                                                <MenuItem value={option} key={'REFSTATKEY'+idx}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </span>
                        </div>
                        <div className = "text-center text-gray-600 text-lg mt-5">Click the paperclip to insert the mug shot</div>
                        <div className = "flex mb-7 ">
                            <div className = "mt-3 ml-20 inline max-h-56" style={{ maxWidth: 200 }}>
                                <img src={params?.pic ? params?.pic : noImage} style={{height:200, width:200}} />
                            </div>
                            <span className="inline mt-1">
                                    <input
                                        id="icon-button-photo"
                                        style={{display:"none"}}
                                        onChange={e => {handlePersonPhoto(e.target)}}
                                        type="file"
                                    />
                                    <label htmlFor="icon-button-photo" className = "">
                                        <IconButton color="primary" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Federal Adoption
                                <div className = "ml-2 w-60">
                                    <FormControl size = "small" >
                                        <Select
                                            sx={{height:28, minWidth:140}}
                                            className = "font-bold"
                                            id="demo-simple-select"
                                            value={params.adoptedStatus}
                                            onChange={(e: any) => setParams({ ...params, adoptedStatus: e.target.value })}
                                        >
                                            {listOptions.map((option, idx) => (
                                                <MenuItem value={option.Value} key={'FAKEY'+idx}>{option.Key}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className ="mr-2"> Referral County </span>
                                <FormControl size = "small" >
                                    <Select
                                        sx={{height:28, minWidth:160}}
                                        className = "font-bold"
                                        id="demo-simple-select"
                                        value={params.referralCounty}
                                        onChange={(e: any) => setParams({ ...params, referralCounty: e.target.value })}
                                    >
                                        {resCountyList.map((option:any, idx:any) => (
                                            <MenuItem value={option?.Key} key={'REFCNTYKEY'+idx}>{option?.Value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Prosecutor
                                <div className = "ml-2 w-60"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.prosecutor} onChange={(e: any) => setParams({ ...params, prosecutor: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                Judge
                                <div className = "ml-2 w-60"><TextField fullWidth margin='none' size={"small"} inputProps={{ style: { height: 10} }} value={params?.judge} onChange={(e: any) => setParams({ ...params, judge: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                <span className ="mt-1">Prosecution<br/>Outcome</span>
                                <div className = "ml-2 w-60"><TextField multiline={true} minRows={2} maxRows={2} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 15} }} value={params?.prosecution} onChange={(e: any) => setParams({ ...params, prosecution: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-2">
                            <span className="align-middle flex inline">
                                RTCC {/*Dropdown*/}
                                <div className = "ml-2 w-20">
                                    <FormControl size = "small" >
                                        <Select
                                            sx={{height:28, minWidth:160}}
                                            className = "font-bold"
                                            id="demo-simple-select"
                                            value={params.rtcc}
                                            onChange={(e: any) => setParams({ ...params, rtcc: e.target.value })}
                                        >
                                            {rtccList.map((option:any, idx:any) => (
                                                <MenuItem value={option?.Key} key={'PROSOUTCOMEKEY'+idx}>{option?.Value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </span>
                        </div>
                        <span className = "flex inline">
                            <div className = "ml-2 mb-2">
                                <span className="align-middle flex inline">
                                    <span className = "mr-2">NIBIN</span>
                                    <FormControl size = "small" >
                                        <Select
                                            sx={{height:28, minWidth:160}}
                                            className = "font-bold"
                                            id="demo-simple-select"
                                            value={params.nibinStatus}
                                            onChange={(e: any) => setParams({ ...params, nibinStatus: e.target.value })}
                                        >
                                            {listOptions.map((option, idx) => (
                                                <MenuItem value={option.Value} key={'NIBINKEY'+idx}>{option.Key}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </span>
                                {params?.nibinStatus == 'Y' ?
                                    <div className = "mt-2 w-64"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 13} }} value={params?.nibin} onChange={(e: any) => setParams({ ...params, nibin: e.target.value })}/> </div>
                                    : <></>}
                            </div>
                            <div className = "ml-2 mb-2">
                                <span className="align-middle flex inline">
                                    <span className = "mr-2">CaseExplorer</span>
                                    <FormControl size = "small" >
                                        <Select
                                            sx={{height:28, minWidth:160}}
                                            className = "font-bold"
                                            id="demo-simple-select"
                                            value={params.caseExplorerStatus}
                                            onChange={(e: any) => setParams({ ...params, caseExplorerStatus: e.target.value })}
                                        >
                                            {listOptions.map((option,idx) => (
                                                <MenuItem value={option.Value} key={'CEKEY'+idx}>{option.Key}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </span>
                                {params?.caseExplorerStatus == 'Y' ?
                                    <div className = "mt-2 w-64"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14} }} value={params?.caseExplorer} onChange={(e: any) => setParams({ ...params, caseExplorer: e.target.value })}/> </div>
                                    : <></>}
                            </div>
                        </span>
                    </div>
                    
                    <div  className ="" style={{width:"30%"}}>
                        <div className = "ml-2 mb-2 mt-2">
                            <span className={"ml-2 text-sm text-gray-600"}>Analytic Notes</span>
                            <span className="align-middle flex inline mt-2">
                                <div className = "w-96"><TextField multiline={true} minRows={9} maxRows={9} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} value={params?.analyticNotes} onChange={(e: any) => setParams({ ...params, analyticNotes: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-2 mb-4 mt-2">
                            <span className={"ml-2 text-sm text-gray-600"}>Comments</span>
                            <span className="align-middle flex inline mt-2">
                                <div className = "w-96"><TextField multiline={true} minRows={8} maxRows={8} fullWidth margin='none' size={"small"} inputProps={{style: {fontSize: 14}}} value={params?.comments} onChange={(e: any) => setParams({ ...params, comments: e.target.value })}/> </div>
                            </span>
                        </div>
                        <div className = "ml-16" style={{marginTop:68}}>
                                <span className="align-middle flex inline">
                                    <span className = "mr-2">InfoShare</span>
                                    <FormControl size = "small" >
                                        <Select
                                            sx={{height:28, minWidth:160}}
                                            className = "font-bold"
                                            id="demo-simple-select"
                                            value={params.infoShareStatus}
                                            onChange={(e: any) => setParams({ ...params, infoShareStatus: e.target.value })}
                                        >
                                            {listOptions.map((option, idx) => (
                                                <MenuItem value={option.Value} key={'ISKEY'+idx}>{option.Key}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </span>
                            {params?.infoShareStatus == 'Y' ?
                                <div className = "mt-2 w-64"><TextField multiline={true} minRows={2} maxRows={6} fullWidth margin='none' size={"small"} inputProps={{ style: { fontSize: 14} }} value={params?.infoShare} onChange={(e: any) => setParams({ ...params, infoShare: e.target.value })}/> </div>
                                : <></>}
                        </div>
                        <div className={"mt-10 mb-5 mr-5"} style={{"display":"flex", "justifyContent":"end"}}>
                            <ButtonGroup size="large" color="inherit" >
                                <Button  type="submit" onClick={()=>{handleSubmit()}}>Save</Button>
                                <Button onClick={()=>{window.close()}}>Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div className = "text-sm w-10/12" style={{backgroundColor:"#919292"}}>
                        (U) INFORMATION NOTICE: This product contains unclassified information that is law enforcement sensitive. (U/LES) Recipients should not release any portion of this product to the media,the public, or other personnel who do not have a valid need-to-know.
                    </div>
                </div>
                <div className = "text-center text-sm mb-6">UNCLASSIFIED//LAW ENFORCEMENT SENSITIVE</div>
            </div>
        </div>
    );
}

export default GVRTFInputForm;