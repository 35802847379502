import { AxiosResponse } from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import ArrestDetail from "../interfaces/Detail/ArrestDetail.interface";
import PoliceStopDetail from "../interfaces/Detail/PoliceStopDetail.interface";
import PoliceStopImageDetail from "../interfaces/Detail/PoliceStopImageDetail.interface";
import ETicketDetail from "../interfaces/Detail/ETicketDetail.interface";
import ALPRDetail from "../interfaces/Detail/ALPRDetail.interface";
import CadRmsDetail from "../interfaces/Detail/CadRmsDetail.interface";
import ETraceDetail from "../interfaces/Detail/ETraceDetail.interface";
import NJTraceDetail from "../interfaces/Detail/NJTraceDetail.interface";
import ParkMobileDetail from "../interfaces/Detail/ParkMobileDetail.interface";
import PrisonParoleDetail from "../interfaces/Detail/PrisonParoleDetail.interface";
import PrisonReleaseDetail from "../interfaces/Detail/PrisonReleaseDetail.interface";
import ProfessionDetail from "../interfaces/Detail/ProfessionDetail.interface";
import ViolentDetail from "../interfaces/Detail/ViolentDetail.interface";
import {LINKANALYSIS, Shooting} from "./config.service";
import {Account} from "../interfaces/auth_interface";
import {getUser} from "./auth.service";

export async function RunArrestDetailQuery(ID:string | null, Dept:string | null) : Promise<ArrestDetail> {
    let res:AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Arrest/?ID='+ID + '&Dept=' +Dept)
    return(res.data.AnyData)
}

export async function RunPoliceStopDetailQuery(ID: string | null, Dept: string | null): Promise<PoliceStopDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/PoliceStop/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunPoliceStopImageDetailQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/PoliceStop_Image/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunETicketDetailQuery(ID: string | null, Dept: string | null): Promise<ETicketDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ETicket/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunShotSpotterQuery(ID: string | null, Dept: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ShotSpotter/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunALPRDetailQuery(ID: string | null, Dept: string | null): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunALPRDetailNo_IDQuery(ID: string | null, Dept: string | null): Promise<ALPRDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ALPR_NoID?ALPRItem=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunCadRmsDetailQuery(ID: string | null, Dept: string | null): Promise<CadRmsDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/CadRms/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunETraceDetailQuery(ID: string | null, Dept: string | null): Promise<ETraceDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ETrace/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunNJTraceDetailQuery(ID: string | null, Dept: string | null): Promise<NJTraceDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/NJTrace/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunParkMobileDetailQuery(ID: string | null, Dept: string | null): Promise<ParkMobileDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ParkMobile/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunPrisonParoleDetailQuery(ID: string | null, Dept: string | null): Promise<PrisonParoleDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/PrisonParole/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunPrisonReleaseDetailQuery(ID: string | null, Dept: string | null): Promise<PrisonReleaseDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/PrisonRelease/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunProfessionDetailQuery(ID: string | null, Dept: string | null): Promise<ProfessionDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Profession/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunViolentDetailQuery(ID: string | null, Dept: string | null): Promise<ViolentDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Violent/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function RunCodisDNADetailQuery(ID: string | null, Dept: string | null): Promise<ETicketDetail> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Codis/?ID=' + ID + '&Dept=' + Dept)
    return (res.data.AnyData)
}

export async function base64_to_blob(base64Data:any, contentType:any){
    const base64Response = await fetch(`data:${contentType};base64,${base64Data}`);
    return await base64Response.blob();

}

export async function OpenCodisPDF(ID: string | null, Dept: string | null) {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/CodisDNA/?ID=' + ID + '&Dept=' + Dept)
        if(res.data.AnyData != null){
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r=>{
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl,'_blank')
            })
        }        
    }catch (e) {
        console.error(e)
    }
}

export async function OpenSCORPDF(ID: any | null, Dept: string | null) {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/CrumbScorePDF/?ID=' + ID + '&Dept=' + Dept)
        if(res.data.AnyData != null){
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r=>{
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl,'_blank')
            })
        }
    }catch (e) {
        console.error(e)
    }
}

export async function OpenReleaseNotesPDF(ID: any | null, Dept: string | null) {
    try {
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/ReleaseNotesPDF/?ID=' + ID + '&Dept=' + Dept)
        if (res.data.AnyData != null) {
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    } catch (e) {
        console.error(e)
    }
}


export function OpenShootingPDF(Shooting_IncidentID:any){
    let user:Account = getUser();
    window.open(Shooting + "/auth?UserName="+user.EmpID + "&Dept=" + user.Dept + '&OTP='+user.eOTP+ '&vShooting='+Shooting_IncidentID, '_blank')
}
export function OpenNIBINPDF(NIBIN_IncidentID:any){
    let user:Account = getUser();
    window.open("/link_analysis?ReportID=" +NIBIN_IncidentID)
    window.open(Shooting + "/auth?UserName="+user.EmpID + "&Dept=" + user.Dept + '&OTP='+user.eOTP+ '&vNIBIN='+NIBIN_IncidentID)
}

export async function OpenUserManual(ID: any | null, Dept: string | null) {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/UserManualPDF/?ID=' + ID + '&Dept=' + Dept)
        if(res.data.AnyData != null){
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r=>{
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, "_self")
            })
        }
    }catch (e) {
        console.error(e)
    }
}


//export async function RunShootingDetailQuery(ID: string | null, Dept: string | null): Promise<ShootingDetail> {
//    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/Shooting/?ID=' + ID + '&Dept=' + Dept)
//    return (res.data.AnyData)
//}

//export async function RunNIBINDetailQuery(ID: string | null, Dept: string | null): Promise<NIBINDetail> {
//    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Detail/NIBIN/?ID=' + ID + '&Dept=' + Dept)
//    return (res.data.AnyData)
//}