import { GetDarInfo_Query, getDarInfoResponse, PersonInfo, getGraphResponse, ChartData, getAuditLogInfoResponse } from "../interfaces/getDarInfo_interface";
import axios, {AxiosResponse} from "axios";
import {SearchDL} from "../interfaces/searchDL.interface";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { ALPRTimeout_Sec } from "./config.service";
import PlateSearch from "../interfaces/PlateSearch.interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import {GRAILReferral} from "../interfaces/GRAILReferral.interface";

export async function RunDarQuery(params:GetDarInfo_Query) : Promise<getDarInfoResponse> {
    let res:AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo',params)
    return(res.data)
}

export async function RunAuditLogQuery(params: GetDarInfo_Query): Promise<getAuditLogInfoResponse> {
    let res: AxiosResponse<getAuditLogInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

export async function RunGraphQuery(params: GetDarInfo_Query): Promise<getGraphResponse> {
    let res: AxiosResponse<getGraphResponse> = await AuthenticatedAPIReqSplash.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

//export async function RunSummaryInfoForPersonQuery(params:SearchDL) : Promise<SummaryInfo> {
//    let res:AxiosResponse<SummaryInfo> = await AuthenticatedAPIReq.post('/api/Person/GetSummaryInfoForPerson',params)
//    return(res.data)
//}

export async function RunPerson_ImageQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Image', params)
    return (res.data)
}

export async function RunPerson_ArrestQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Arrest', params)
    return (res.data)
}

export async function RunPerson_ShootingQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Shooting', params)
    return (res.data)
}

export async function RunPerson_CodisDNAQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_CodisDNA', params)
    return (res.data)
}

export async function RunPerson_PoliceStopQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_PoliceStop', params)
    return (res.data)
}

export async function RunPerson_ETicketQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ETicket', params)
    return (res.data)
}

export async function RunPerson_CADRMSQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_CADRMS', params)
    return (res.data)
}

export async function RunPerson_ParkMobileQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ParkMobile', params)
    return (res.data)
}

export async function RunPerson_ETraceQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ETrace', params)
    return (res.data)
}

export async function RunPerson_NJTraceQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_NJTrace', params)
    return (res.data)
}

export async function RunPerson_NIBINQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_NIBIN', params)
    return (res.data)
}

export async function RunPerson_PrisonQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Prison', params)
    return (res.data)
}

export async function RunPerson_ParoleQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Parole', params)
    return (res.data)
}

export async function RunPerson_ViolentQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Violent', params)
    return (res.data)
}

export async function RunPerson_ProfessionQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Profession', params)
    return (res.data)
}

export async function RunPerson_ALPRQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ALPR', params, { timeout: ALPRTimeout_Sec * 1000 } )
    return (res.data)
}

export async function RunPerson_DLQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_DL', params)
    return (res.data)
}

export async function RunPerson_HumanTrafficQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_HumanTraffic', params)
    return (res.data)
}

export async function RunPerson_Plate(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Plate', params)
    return (res.data)
}

export async function RunPerson_AddressPhoneQuery(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_AddressPhone', params)
    return (res.data)
}

export async function RunMultipleOccurrence(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/Search/MultipleOccurrence', params)
    return (res.data)
}

export async function RunVINQuery(params: PlateSearch): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Vin/Search', params)
    return (res.data)
}

export async function RunPerson_CrumbScore(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_CrumbScore', params)
    return (res.data)
}

export async function RunPerson_Duplicate(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Duplicate', params)
    return (res.data)
}

export async function RunSetGRAILReferral(params: GRAILReferral): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/setGRAILRefferal', params)
    return (res.data)
}

export async function RunGetGRAILReferral(params: GRAILReferral): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.post('/api/GRAIL/getGRAILRefferal', params)
    return (res.data)
}

export async function RunPerson_Referral(params: SearchDL): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_Referral', params)
    return (res.data)
}

export { }