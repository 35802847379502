import 'keylines';
import React, {useRef} from "react";
import {Chart} from "../../react-keylines";
import {Button, Card, CardContent} from "@mui/material";
import {formatDate, formatDT} from "../../services/formatDate.service";
import {useLocation} from "react-router-dom";
//import PDFDocument from "../../../../keylines-6.8.1-78597/pdf/pdfkit.standalone";

interface LinkAnalysisProps {
    data:any,
    GetAssociatedPeople:any,
    associatedPeopleList: any,
    getPersonImage : any
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LinkedAnalysisGraph: React.FC<LinkAnalysisProps> = ({data, GetAssociatedPeople, associatedPeopleList, getPersonImage}) => {
    // chartLoc is used OUTSIDE (aka custom) of a Keylines function. chartRef is used INSIDE keylines functions.
    var chartLoc: any = null
    const [chartRef, setChartRef] = React.useState<any>(null)

    const myContainer = React.useRef<any>(null)

    const [toolTip, setToolTip] = React.useState<any>()
    const [toolTipStyle, setToolTipStyle] = React.useState<any>({})
    const [chartOrientation, setChartOrientation] = React.useState<any>("down")
    const [combine, setCombine] = React.useState<any>("Combine")
    const [ready, setReady] = React.useState<any>(false)

    let query = useQuery();

    React.useEffect(() => {
        if(chartRef)
        {
            getAssociatedPeopleImages()
        }
    }, [associatedPeopleList]);

    const getAssociatedPeopleImages = async () => {
        if (associatedPeopleList.length > 0) {
            await chartRef.merge(associatedPeopleList)
            await chartRef.layout('sequential', { orientation: chartOrientation, tightness: 3 })
            getPersonImages()
        }
    }

    React.useEffect(() => {
        if (ready) {
            getPersonImages()
        }
    }, [ready]);

    const loadedChart = (chart:any) => {
        chart.layout('sequential', {orientation: 'down', tightness: 3 })
        chart.on('hover', createTooltip);
        let { width, height } = chart.viewOptions();
        chart.on('view-change', (item:any) => {
            const { width: newWidth, height: newHeight } = chart.viewOptions();
            if (width !== newWidth || height !== newHeight) {
                closeTooltip();
                width = newWidth;
                height = newHeight;
            }
            updateTooltipPosition(item)
        });
        chart.options({ watermark: { a: 'bottom', fb: true, fs: 14, fc: '#d3d3d3', t: 'Provided By Arrest Reports https://Arrestreports.com' } })
        chart.on('drag-move', updateTooltipPosition);
        chart.on('double-click', doubleClickHandler);
        chartLoc = chart;
        setChartRef(chart)
        setReady(true)
    }

    const getPersonImages = async () => {
        chartRef.each({ type: 'node' }, async (item: any) => {
            if ((item.d.type === 'person' || item.d.type == 'associated_person') && !item.d.pic) {
                let picture = await getPersonImage(item)
                if (picture && picture[0]) {
                    await chartRef.setProperties({ id: item.id, u: picture[0].Image, d: { ...item.d, pic: true } })
                }
                else {
                    await chartRef.setProperties({ id: item.id, d: { ...item.d, pic: true } })
                }
            }
        });
    }

    const closeTooltip = () => {
        setToolTip(<></>)
    }

    const updateTooltipPosition = (item:any) => {
        if(item && item.id) {
            const node = chartLoc?.getItem(item.id);
            if (node && node.x) {
                const coordinates = chartLoc.viewCoordinates(node.x, node.y);
                setToolTipStyle({
                    transform: `scale(${Math.max(0.75, Math.min(2, chartLoc.viewOptions().zoom))}`,
                    left: coordinates.x + 40,
                    top: coordinates.y - (myContainer?.current?.clientHeight / 2),
                    position: 'absolute',
                    zIndex: 1000
                })
            }
        }
    }

    const createTooltip = ( item:any ) => {
        const node = chartLoc?.getItem(item.id);
        if(node)
        {
            updateTooltipPosition(item)
            if(node?.d?.type) {
                switch (node.d.type) {
                    case("report"):
                        setToolTip(
                            <div>
                                {/*<Card className="border-2 border-gray-700" sx={{maxWidth: 300, m: 'auto', mb: 1}}>*/}
                                {/*    <CardContent>*/}
                                {/*        <div></div>*/}
                                {/*    </CardContent>*/}
                                {/*</Card>*/}
                            </div>
                        )
                        break;
                    case("incident"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        {node.d.data.Inc_Location &&
                                            <div><span className="font-bold">Address:</span> {node.d.data.Inc_Location}</div>}
                                        {node.d.data.OccurrenceDate &&
                                            <div><span className="font-bold">Date-Time:</span> {formatDT(node.d.data.OccurrenceDate)}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("person"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Name:</span> {node.t}</div>
                                        {node.d.data.DOB &&
                                            <div><span className="font-bold">DOB:</span> {formatDate(node.d.data.DOB)}</div>}
                                        {node.d.data.SBINo &&
                                            <div><span className="font-bold">SBI #:</span> {node.d.data.SBINo}</div>}
                                        {node.d.data.FBI &&
                                            <div><span className="font-bold">FBI #:</span> {node.d.data.FBI}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("associated_person"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Name:</span> {node.t}</div>
                                        {node.d.data.DOB &&
                                            <div><span className="font-bold">DOB:</span> {formatDate(node.d.data.DOB)}</div>}
                                        {node.d.data.SBINo &&
                                            <div><span className="font-bold">SBI #:</span> {node.d.data.SBINo}</div>}
                                        {node.d.data.FBI &&
                                            <div><span className="font-bold">FBI #:</span> {node.d.data.FBI}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("weapon"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        {node.d.data.CALIBER &&
                                            <div><span className="font-bold">Caliber:</span> {node.d.data.CALIBER}</div>}
                                        {node.d.data.MAKE &&
                                            <div><span className="font-bold">Make:</span> {node.d.data.MAKE}</div>}
                                        {node.d.data.W_CLASS &&
                                            <div><span className="font-bold">Class:</span> {node.d.data.W_CLASS}</div>}
                                        {node.d.data.MODEL &&
                                            <div><span className="font-bold">Model:</span> {node.d.data.MODEL}</div>}
                                        {node.d.data.SERIAL_NUMBER &&
                                            <div><span className="font-bold">Serial Number:</span> {node.d.data.SERIAL_NUMBER}</div>}
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;
                    case("ballistic"):
                        setToolTip(
                            <div>
                                <Card className="border-2 border-gray-700" sx={{maxWidth: 400, m: 'auto', mb: 1}}>
                                    <CardContent>
                                        <div><span className="font-bold">Ballistic: </span> ({node?.d?.data?.Item_Quantity}) {node.t}</div>
                                    </CardContent>
                                </Card>
                            </div>
                        )
                        break;


                }
            }

        }
        else
        {
            setToolTip(<></>)
        }
    }

    const exportChart = async () => {

        if(chartRef) {
            chartRef.toDataURL(null,null,{fit:'chart', noScale:true, watermark:true,}).then((dataURL:any) => {
                fetch(dataURL)
                    .then(res => res.blob())
                    .then((blob:any) => {
                        var blobUrl = URL.createObjectURL(blob);
                        downloadPdf(blobUrl)
                    })
            });
        }
    }

    const downloadPdf = (image:any) => {
        const imageLink = document.createElement('a');
        imageLink.download = data && data.items ? data?.items[0]?.d?.data?.ReferenceNumber : query.get("ReportID") + '.png'
        imageLink.href = image;
        imageLink.click();

        URL.revokeObjectURL(image);
    }

    const changeOrientation = () => {
        if(chartOrientation === 'down')
        {
            setChartOrientation('right')
            chartRef.layout('sequential', {orientation: 'right', tightness: 3 })
        }
        else
        {
            setChartOrientation('down')
            chartRef.layout('sequential', {orientation: 'down', tightness: 3 })
        }

    }
    const clickHandler = (item:any) => {

        // console.log(item)
        // if(item.id && chartRef.getItem(item.id).type === 'node')
        // {
        //     var node = chartRef.getItem(item.id)
        //     console.log(node)
        //     if(node.d.type ==='person')
        //     {
        //
        //         console.log(node.t)
        //     }
        // }
    }

    const doubleClickHandler = (item:any) => {
        if(item && item.id && chartLoc.getItem(item.id).type === 'node')
        {
            var node = chartLoc.getItem(item.id)
            if(node.d.type ==='person' || node.d.type==='associated_person')
            {
                GetAssociatedPeople(node)
            }
        }
    }



    // const combineNodes = () => {
    //     console.log(chartRef)
    //     var selected = chartRef.selection().filter((id:any) => chartRef.getItem(id).type === 'node')
    //     console.log(selected)
    // }

    return (
        <div className ="static">
            <div ref ={myContainer} style={toolTipStyle} >{toolTip}</div>
            <div style={{position:"absolute", top:5, right:10}}><Button variant="outlined" onClick={exportChart}>Export</Button></div>
            <div style={{position:"absolute", top:5, left:100}}><Button variant="outlined" onClick={changeOrientation}>Orientation</Button></div>
            <Chart
                click={clickHandler}
                data={data}
                ready={loadedChart}
                containerClassName="chart-container"

            />
        </div>
    );
}

export default LinkedAnalysisGraph