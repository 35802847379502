import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import {Button, FormControlLabel} from '@mui/material';
import {Google_API_Key, showCrumbScore} from "../../../services/config.service";
import Pin from "../../../interfaces/Pin.interface";
import icon_gun from "../../../assets/Images/Gun.png"
import icon_gunRed from "../../../assets/Images/gun_red.png"
import icon_alpr from "../../../assets/Images/FixedALPRCamera.png"
import icon_alprInactive from "../../../assets/Images/FixedALPRCameraInactive.png"
import icon_cctv from "../../../assets/Images/FixedCCTVCamera.png"
import icon_person from "../../../assets/Images/Person.png"
import icon_car from "../../../assets/Images/Vehicle.png"
import icon_stolenCar from "../../../assets/Images/StolenVehicle.png"
import icon_arrest from "../../../assets/Images/ai1.png"
import icon_home from "../../../assets/Images/address.png"
import icon_Shooting from "../../../assets/Images/Shooting.png"
import icon_PoliceStop from "../../../assets/Images/ic1.png"
import icon_eticket from "../../../assets/Images/et1.png"
import icon_cadrms from "../../../assets/Images/Opencase.png"
import icon_njtrace from "../../../assets/Images/NjGunTrace1.png"
import icon_nibin from "../../../assets/Images/nibin1.png"
import icon_etrace from "../../../assets/Images/WeaponTrace.png"
import icon_parkmobile from "../../../assets/Images/pm1.png"
import icon_alprIndicator from "../../../assets/Images/alpr1.png"
import icon_shotSpotter from "../../../assets/Images/shotSpotter.png"
import { getALPRCameras, getCCTVCameras } from "../../../services/getDropdown.service";
import icon_openCase from "../../../assets/Images/Opencase.png"
import Checkbox from '@mui/material/Checkbox';
import DownloadIcon from '@mui/icons-material/Download';
import {JAOS} from "../../../services/JAOS.service";
import {
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow, ExcelExport, ExcelExportProperties,
    Filter, Grid, GridComponent,
    Inject,
    Page, PdfExport, Reorder, Resize, Search,
    Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import { isPointInPolygon } from '../../../services/dashboard.service';

interface GeoCodeProps {
    HandleGeoCodeClose: any,
    show: boolean,
    pins: Pin[]
    mapVersion: string
    exportData?: any | null
}

const GeoCode: React.FC<GeoCodeProps> = ({ HandleGeoCodeClose, show, pins, mapVersion, exportData = null}) => {
    const [open, setOpen] = React.useState<boolean>(show)
    const [loadALPR, setLoadALPR] = React.useState<boolean>(false)
    const [alprInfo, setAlprInfo] = React.useState<any[]>([])
    const [showALPR, setShowALPR] = React.useState<boolean>(false)
    const [loadCCTV, setLoadCCTV] = React.useState<boolean>(false)
    const [CCTVInfo, setCCTVInfo] = React.useState<any[]>([])
    const [showCCTV, setShowCCTV] = React.useState<boolean>(false)
    const [showStolen, setShowStolen] = React.useState<boolean>(false)

    const NJBoundry = [
        [41.362453762936575, -74.6918236783217],
        [40.990338834229426, -73.91728763616442],
        [40.6952910099279, -74.01067141429685],
        [40.486723153439925, -74.26885009501595],
        [40.49090088462017, -74.01067141429685],
        [40.06763774018179, -73.8568628385493],
        [39.48504098031486, -74.21391846082041],
        [38.89324697993387, -74.93901603220169],
        [39.47232109991295, -75.55425033519187],
        [39.62903700481567, -75.55974349861143],
        [39.852904834638665, -75.40593492286388],
        [40.23558358525728, -74.79619378329326],
        [40.557709209883015, -75.20818103975989],
        [40.98204561057492, -75.15324940556434]
    ]

    //
    //let bPins = []
    //for (let pin of CCTVInfo) {
        
    //    if (!isPointInPolygon(parseFloat(pin.Latitude), parseFloat(pin.Longitude), NJBoundry)) {
    //        bPins.push(pin)
    //    }
    //}
    //console.log(bPins)

    React.useEffect(() => {
        if(!loadALPR && mapVersion !== 'alprCamera'){
            setLoadALPR(true)
            loadALPRCameras()
        }
        if(!loadCCTV && mapVersion !== 'alprCamera')
        {
            setLoadCCTV(true)
            loadCCTVCameras()
        }
    }, [show]);
    
    const loadALPRCameras = async () => {
        let r:any = await getALPRCameras()
        setAlprInfo(r)
    }

    const loadCCTVCameras = async () => {
        let r:any = await getCCTVCameras()
        setCCTVInfo(r)
    }
    
    const handleOpen = () => {
        setOpen(!open)
    }
    /////////////////////////////////////////////////////
    const containerStyle = {
        width: '1200px',
        height: '800px'
    };

    const handleCenter = ():Pin => {
        var centerLat = 0
        var centerLng = 0

        for(var i = 0; i < pins.length; i++)
        {
            centerLat += parseFloat(pins[i].lat)
            centerLng += parseFloat(pins[i].lng)
        }

        centerLat = centerLat/pins.length
        centerLng = centerLng/pins.length

        return {lat:centerLat, lng:centerLng}
    }

    const center = {
        lat: handleCenter().lat || 40.844600,
        lng: handleCenter().lng || -74.108270
    };
    
    
    const getPinIcon = (pin:Pin):string  => {
        if(pin.tool && pin.tool.length > 0){
            switch(pin.tool)
            {
                case ('statute'):
                case ('personNearbyInc'):
                    return "https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_" + (getStatuteColor(pin.statute ? pin.statute : "")) + ".png";
                case('frequent'):
                    return "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red" + pin.arrestCount + '.png'
                case('crumb'):
                    return "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red" + pin.arrestCount + '.png'
                case('shooting'):
                    if(pin.inc_type === 'Murder')
                        return icon_gunRed
                    else 
                        return icon_gun
                case('person'):
                    return icon_person
                case('car'):
                    return icon_car
                case ('stolenCar'):
                    return icon_stolenCar
                case ("home"):
                case ("personNearbyRes"):
                    return icon_home
                case("arrest"):
                    return icon_arrest
                case("Shooting"):
                    return icon_Shooting
                case("PoliceStop"):
                    return icon_PoliceStop
                case("eticket"):
                    return icon_eticket
                case("cadrms"):
                case("case"):
                    return icon_cadrms
                case("etrace"):
                    return icon_etrace
                case("parkmobile"):
                    return icon_parkmobile
                case("alprCamera"):
                    return icon_alpr
                case("alpr"):
                    return icon_alprIndicator
                case ("shotSpotter"):
                    return icon_shotSpotter
                case ("personNearbyPoint"):
                    return "https://maps.google.com/mapfiles/kml/pal2/icon13.png"
                case ("openCase"):
                    return icon_openCase
                    
            }
        }
        return ""

    }

    const getStatuteColor = (statute:string):string  =>{
        var grp = statute.substring(0, 5);
        if (grp === '9:6-1' || grp === '26:2C' || grp === '53:1-' || grp === '2C:7-' || grp === '20:2-' ||
            grp === '12:1E' || grp === '26:2D' || grp === '2C:38' || grp === '2C:21' || grp === '02C:2') {
            return 'gray' //rgba(255, 0, 0, 0.5)
        }
        else if (grp === '2A:11' || grp === '2A:4A' || grp === '12:1E' || grp === '32:1-' || grp === '58:10' ||
            grp === '17:15' || grp === '2C:14' || grp === '2C:16' || grp === '54A:8' || grp === '2C:45') {
            return 'green'
        }
        else if (grp === '5:12-' || grp === '2C:5-' || grp === '2C:10' || grp === '2C:24' || grp === '13:1E' ||
            grp === '0012C' || grp === '23:4-' || grp === '33:1-' || grp === '2C:13' || grp === '2C:46') {
            return 'orange'
        }
        else if (grp === '9:6-8' || grp === '19:34' || grp === '2C:27' || grp === '2C:36' || grp === '2C:29' ||
            grp === '2C:34' || grp === '2C:40' || grp === '2C:41' || grp === '6:1-1' || grp === '21:1A') {
            return 'purple'
        }
        else if (grp === '38A:1' || grp === '2C:39' || grp === '2C:20' || grp === '52:17' || grp === '11A:1' ||
            grp === '24:21' || grp === '2C:37' || grp === '2A:10' || grp === '56:8-' || grp === '2C:58') {
            return 'red'
        }
        else if (grp === '19:63' || grp === '34:15' || grp === '39:2-' || grp === '2C:30' || grp === '30:41' ||
            grp === '45:19' || grp === '11A:2' || grp === '52:14' || grp === '2A:17' || grp === '2A:15') {
            return 'white'
        }
        else if (grp === '4:22-' || grp === '54:40' || grp === '39:4-' || grp === '39:3-' || grp === '30:4D' ||
            grp === '2C:18' || grp === '2C:43' || grp === '39:10' || grp === '2C:2-' || grp === '13:9-') {
            return 'yellow'
        }
        else if (grp === '4-8.2' || grp === '54:52' || grp === '2C:62' || grp === '12:7-' || grp === '23:7-' ||
            grp === '2C:22' || grp === '2C:35' || grp === '2C: 3' || grp === '2C:15' || grp === '18A:3') {
            return 'black'
        }
        else if (grp === '21:3-' || grp === '2C:17' || grp === '12:3-' || grp === '52:34' || grp === '2C:11' ||
            grp === '2C:25' || grp === '21:2-' || grp === '49:3-' || grp === '30:40' || grp === '55:13') {
            return 'blue'
        }
        else if (grp === '30:4-' || grp === '2C:12' || grp === '2C:28' || grp === '54:50' || grp === '2A:85' ||
            grp === '9:6-3' || grp === '2A:16' || grp === '2A:14' || grp === '2A:94' || grp === '2C:33') {
            return 'brown'
        }
        else if (grp === '99:10') {
            return 'shadow'
        }
        else {
            return 'red'
        }
    };

    const searchPeople = () =>  {
        let obj = {FName:"", MName:"",LName:"",DOB:""}
        if(exportData)
        {
            for (let p of exportData){
                if(p.DOB && p.FName && p.LName)
                {
                    obj.FName += p.FName + ",";
                    obj.MName += p.MName + ",";
                    obj.LName += p.LName + ",";
                    obj.DOB += p.DOB.substring(5, 2 + 5) + "/" + p.DOB.substring(8, 2 + 8) + "/" + p.DOB.substring(0, 4) + ",";
                }
            }
        }
        if(obj.FName.length !== 0) {
            let l = new JAOS()
            let hashID = l.objToStack(obj)
            window.open('/tools/arrest?personList=' + hashID, '_blank')
        }
    }
    
    const searchPlates = () =>  {
        if(exportData)
        {
            let obj = {Plates:""}
            if(exportData)
            {
                for (let p of exportData.filter((plate: any) => showStolen ? (plate?.POIReason?.toLowerCase() == 'stolen vehicle' || plate?.POIReason?.toLowerCase() == 'stolen plate') : plate)){
                    if(p.red_vrm)
                    {
                        obj.Plates += p.red_vrm + ",";
                    }
                }
            }
            
            if(obj.Plates.length !== 0) {
                let l = new JAOS()
                let hashID = l.objToStack(obj)
                window.open('/tools/arrest?personList=' + hashID, '_blank')
            }
        }
    }
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key
    })

    const [map, setMap] = React.useState(null)

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    ////////////////////////////////////////////

    const getMarkerTitle = (pin:Pin):string => {
        
        let title = ""

        if(pin.tool && pin.tool.length > 0) {
            switch (pin.tool) {
                case('statute'):
                    title += 'Name: ' + pin.name + '\n'
                    title += 'Statute: ' + pin.statute + '\n'
                    title += 'Offense Date: ' + pin.offense + '\n'
                    title += 'City: ' + pin.city + '\n'
                    break;
                case('frequent'):
                    title += 'Name: ' + pin.name + '\n'
                    title += 'Address: ' + pin.address?.toUpperCase() + '\n\t' + pin.city?.toUpperCase() + ' ' + pin.state?.toUpperCase() + ' ' + pin.zip?.toUpperCase() +  '\n'
                    break;
                case('crumb'):
                    title += 'Name: ' + pin.name + '\n'
                    title += 'Crumb Score Date: ' + pin.crumbScoreDate + '\n'
                    title += 'Crumb Percentile: ' + pin.crumbPercentile + '\n'
                    title += 'Crumb Score: ' + pin.crumbScore + '\n'
                    break;
                case ('shooting'):
                case ('shotSpotter'):
                    title += 'Date: ' + pin.date + '\n'
                    if(pin.dept)
                        title += 'Dept: ' + pin.dept + '\n'
                    if(pin.victims)
                        title += 'Victims: ' + pin.victims + '\n'
                    if(pin.suspect)
                        title += 'Suspect: ' + pin.suspect + '\n'
                    break;
                case('person'):
                    title += 'Name: ' + pin.name + '\n'
                    if(pin.date)
                        title += 'Arrest Date: ' + pin.date + '\n'
                    if(pin.statute)
                        title += 'Statute: ' + pin.statute + '\n'
                    break;
                case('car'):
                    if(pin.date)
                    title += 'Date: ' + pin.date + '\n'
                    if(pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    if(pin.alprSource)
                        title += 'ALPR Source: ' + pin.alprSource + '\n'
                    break;
                case ('stolenCar'):
                    title += 'STOLEN VEHICLE' + '\n' 
                    if (pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    if (pin.alprSource)
                        title += 'ALPR Source: ' + pin.alprSource + '\n'
                    break;
                case('home'):
                    if(pin.address)
                        title += 'Address: ' + pin.address + '\n'
                    if(pin.city)
                        title += 'City: ' + pin.city + '\n'
                    if(pin.zip)
                        title += 'Zip: ' + pin.zip + '\n'
                    break;
                case('arrest'):
                    if(pin.date)
                        title += 'Arrest Date: ' + pin.date + '\n'
                    if(pin.statute)
                        title += 'Statute: ' + pin.statute + '\n'
                    if(pin.arrestedBy)
                        title += 'PD: ' + pin.arrestedBy + '\n'
                    break;
                case('Shooting'):
                    if(pin.date)
                        title += 'Incident Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Incident #: ' + pin.offense + '\n'
                    if(pin.weapon)
                        title += 'Weapon: ' + pin.weapon + '\n'
                    break;
                case('PoliceStop'):
                    if(pin.date)
                        title += 'Interaction Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Req ID: ' + pin.offense + '\n'
                    if(pin.arrestedBy)
                        title += 'PD: ' + pin.arrestedBy + '\n'
                    break;
                case('eticket'):
                    if(pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if(pin.dept)
                        title += 'Source: ' + pin.dept + '\n'
                    if(pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case('cadrms'):
                    if(pin.date)
                        title += 'Incident Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    if(pin.role)
                        title += 'Role: ' + pin.role + '\n'
                    break;
                case('etrace'):
                    if(pin.date)
                        title += 'Recovery Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'ATF #: ' + pin.offense + '\n'
                    if(pin.weapon)
                        title += 'Weapon: ' + pin.weapon + '\n'
                    break;
                case('case'):
                    if(pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    break;
                case('openCase'):
                    if(pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if(pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    if(pin.inc_type)
                        title += 'Incident Type: ' + pin.inc_type + '\n'
                    break;
                case('alprCamera'):
                    if(pin.name)
                        title += 'Name: ' + pin.name + '\n'
                    if(pin.status)
                        title += 'Status: ' + pin.status + '\n'
                    if(pin.alprSource)      
                        title += 'Source: ' + pin.alprSource + '\n'
                    break;
                case('alpr'):
                    if(pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if(pin.name)
                        title += 'Camera: ' + pin.name + '\n'
                    if(pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case ('parkmobile'):
                    if (pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if (pin.dept)
                        title += 'Zone: ' + pin.city + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case ('personNearbyInc'):
                case ('personNearbyRes'):
                    if (pin.arrestCount)
                        title += 'Distance (in miles): ' + pin.arrestCount + '\n'
                    if (pin.name)
                        title += 'Name: ' + pin.name + '\n'
                    if (pin.address)
                        title += 'Address: ' + pin.address?.toUpperCase().trim() + ' ' + pin.city?.toUpperCase().trim() + ' ' + pin.state?.toUpperCase().trim() + ' ' + pin.zip?.toUpperCase().trim() + '\n'
                    if (pin.statute && pin.offense)
                        title += 'Offense: ' + pin.offense.trim() + ' - ' + pin.statute.trim()
                    break;
            }
        }

        return title
    }

    React.useEffect(() => {
        if(!open)
            HandleGeoCodeClose()
    },[open])

    const handleALPRShow = (e:any) =>
    {
        setShowALPR(!showALPR)
    }

    const handleCCTVShow = (e:any) =>
    {
        setShowCCTV(!showCCTV)
    }

    const handleStolenOnly = (e: any) => {
        setShowStolen(!showStolen)
    }

    const getALPRTitle = (pin:any) => {
        let title = ''
        
        if(pin.Name)
            title += 'Name: ' + pin.Name + '\n'
        if(pin.Status)
            title += 'Status: ' + pin.Status + '\n'
        if(pin.Server)
            title += 'Server: ' + pin.Server + '\n'
            
        return title    
    }

    const getALPRIcon = (pin: any) => {
        console.log(pin);
        if (pin.Status && pin.Status == "Active")
            return icon_alpr
        else
            return icon_alprInactive
    }

    //----------------------------- EXPORT --------------------
    let grid: Grid | null;
    
    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const excelExportProperties: ExcelExportProperties = {
        includeHiddenColumn: true,
        fileName: 'VehicleList.xlsx'
    };
    
    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            
            grid.excelExport(excelExportProperties);

        }
    }
    
    const exportVehicles = () => {
        if (grid) {

            grid.excelExport(excelExportProperties);

        }
    }
    //----------------------------- EXPORT END --------------------
    
    const list = (anchor: 'right') => (
        
        //There are 3 Map Versions
        //1 - No Buttons only key
        <Box
            sx={{ width: 1200 }}
            role="presentation"
        >
            {(isLoaded) ? (
                <>
                    <Button onClick={handleOpen}>X</Button>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={(mapVersion === 'personNearbyInc' || mapVersion === 'personNearbyRes') ? 14 : 8}
                        onUnmount={onUnmount}
                        ref={(m: any) => setMap(m)}
                        options={{ styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }], }}
                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        <>
                            {mapVersion === 'shooting' || mapVersion === 'car' || mapVersion === 'alprCamera' || mapVersion === 'openCase' || mapVersion == 'shotSpotter'?
                                <>
                                {showALPR ?
                                    alprInfo.map((pin) =>
                                        <Marker
                                            position={{ lat: parseFloat(pin.Latitude), lng: parseFloat(pin.Longitude) }}
                                            icon={getALPRIcon(pin)}
                                            title={getALPRTitle(pin)}
                                        />
                                    )
                                : <></>}
                                {showCCTV ?
                                    CCTVInfo.map((pin) =>
                                        <Marker
                                            position={{ lat: parseFloat(pin.Latitude), lng: parseFloat(pin.Longitude) }}
                                            icon ={icon_cctv}
                                            title ={getALPRTitle(pin)}
                                        />
                                    )
                                    : <></>}
                                </>
                            :<></>}
                            {(pins && pins.length > 0) ?
                                pins.filter((pin) => showStolen ? pin.tool !== 'car' : pin).map((pin) =>
                                    <Marker
                                        position={{ lat: parseFloat(pin.lat), lng: parseFloat(pin.lng) }}
                                        icon={getPinIcon(pin)}
                                        title={getMarkerTitle(pin)}
                                    />
                                )
                            : <> </>}
                        </>
                    </GoogleMap>
                    
                    <span className = "pl-2 w-full pt-2 " style ={{display:"flex", justifyContent:"center"}}>
                        {mapVersion === 'car' ?
                            <span className="mr-2">
                                <Button variant="outlined" color="inherit" onClick={exportVehicles}>Export</Button>
                            </span>
                            :<></>}
                        {mapVersion === 'shooting' || mapVersion === 'car' || mapVersion === 'shotSpotter' || mapVersion === 'openCase' ?
                            <span className="inline">
                                <FormControlLabel control={<Checkbox checked={showALPR} onChange={handleALPRShow} checkedIcon={<img src={icon_alpr} />} />} label="Show ALPR Fixed Camera" />
                                <FormControlLabel control={<Checkbox checked={showCCTV} checkedIcon={<img src={icon_cctv} />} onChange={handleCCTVShow} />} label="Show CCTV" />
                                {mapVersion === 'car' ? <FormControlLabel control={<Checkbox checked={showStolen} checkedIcon={<img src={icon_stolenCar} />} onChange={handleStolenOnly} />} label="Stolen Vehicle Only" /> : <></>}
                            </span>
                        :<></>}
                        {/*{mapVersion === 'person' || mapVersion === 'car'?
                            <span className=""><Button variant="outlined" color="inherit" ><DownloadIcon/>Export</Button></span>
                            : <></>}*/}
                        {mapVersion === 'car' ?
                            <span className="mr-4"><Button variant="outlined" size={"small"} color="inherit" onClick={searchPlates}><img src={icon_arrest}/>Vehicle Connection</Button></span>
                            : <></>}
                        {mapVersion === 'person' ?
                            <span className="mr-2"><Button variant="outlined" size={"small"} color="inherit" onClick={searchPeople}><img src={icon_arrest}/>Person Connection</Button></span>
                            : <></>}
                        {mapVersion === 'shooting' || mapVersion === 'person' || mapVersion === 'car' ?
                            <FormControlLabel control={<img src={icon_gun} />} label="Shooting" />
                            : <></>}
                        {mapVersion === 'shotSpotter' ?
                            <FormControlLabel control={<img src={icon_shotSpotter} />} label="Shot Spotter" />
                            : <></>}
                        {mapVersion === 'car' ?
                            <FormControlLabel control={<img src={icon_car} />} label="Vehicle" />
                            : <></>}
                        {/*{mapVersion === 'car' ?*/}
                        {/*    <FormControlLabel control={<img src={icon_stolenCar} />} label="Stolen Vehicle" />*/}
                        {/*    : <></>}*/}
                        {(mapVersion === 'alprCamera') ?
                            <FormControlLabel control={<img src={icon_alpr} />} label="ALPR Camera" />
                        : <></>}
                        {/*{(mapVersion === 'shooting' || mapVersion === 'shotSpotter' || mapVersion === 'openCase') ?*/}
                        {/*    <FormControlLabel control={<img src={icon_cctv} />} label="CCTV Camera" />*/}
                        {/*    : <></>}*/}
                        {mapVersion === 'person' ?
                            <FormControlLabel control={<img src={icon_person} />} label="Arrested Person" />
                            
                            : <></>}
                        {mapVersion === 'personNearbyInc' || mapVersion === 'personNearbyRes' ?
                            <FormControlLabel control={<img src={"https://maps.google.com/mapfiles/kml/pal2/icon13.png"} />} label="Search Location" />
                            : <></>}
                        {mapVersion === 'personNearbyRes' || mapVersion === 'PersonSummary'?
                            <FormControlLabel control={<img src={icon_home} />} label="Residency Address" />
                            : <></>}
                        {mapVersion === 'personNearbyInc' ?
                            <FormControlLabel control={<img src={"https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_red.png"} />} label="Incident Address" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_arrest} />} label="Arrest" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_Shooting} />} label="Shooting" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_PoliceStop} />} label="CanaraLabels" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_eticket} />} label="E-Ticket" />
                            : <></>}

                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_cadrms} />} label="CAD/RMS" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_etrace} />} label="E-Trace" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_njtrace} />} label="NJ GunTrace" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_nibin} />} label="NIBIN" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_parkmobile} />} label="Parking" />
                            : <></>}
                        {mapVersion === 'PersonSummary' ?
                            <FormControlLabel control={<img src={icon_alprIndicator} />} label="ALPR" />
                            : <></>}
                    </span>
                    <div style={{display:"none"}}>
                        <GridComponent
                            dataSource={exportData}
                            dataBound={dataBound}
                            allowPaging={true}
                            pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                            allowSorting={true}
                            allowMultiSorting={true}
                            allowFiltering={true}
                            filterSettings={{type: 'CheckBox'}}
                            allowTextWrap={true}
                            textWrapSettings={{ wrapMode: 'Both' }}
                            allowExcelExport={true}
                            allowPdfExport={true}
                            toolbar={['ExcelExport']}
                            toolbarClick={GridToolsClick}
                            ref={g=> grid = g}
                            rowHeight={24}
                        >
                            <ColumnsDirective>
                                <ColumnDirective field='red_vrm' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Plate' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='red_TimeStamp' visible={true} width={140} minWidth={140} maxWidth={140} headerText='TimeStamp' customAttributes={{ class: ['e-attr'] }} />
                                <ColumnDirective field='POIReasonType' visible={true} width={140} minWidth={140} maxWidth={140} headerText='POI Reason Type' customAttributes={{ class: ['e-attr'] }} />
                                <ColumnDirective field='POIReason' visible={true} width={140} minWidth={140} maxWidth={140} headerText='POI Reason' customAttributes={{ class: ['e-attr'] }} />
                                <ColumnDirective field='Distance' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Distance' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='IncidentName' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Incident Number' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='Latitude' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Latitude' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='Longitude' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Longitude' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='LprID' visible={true} width={140} minWidth={140} maxWidth={140} headerText='LprID' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='Occurrence' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Occurrence' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='Red_ID' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Red_ID' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='ServerDB' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Server DB' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='Src_IPV4Address' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Src_IPV4 Address' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='ViolationType' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Violation Type' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='src_Description' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Description' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='src_Name' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Src Name' customAttributes={{ class: ['e-attr']}}/>
                                <ColumnDirective field='ste_Name' visible={true} width={140} minWidth={140} maxWidth={140} headerText='Dept' customAttributes={{ class: ['e-attr']}}/>
                            </ColumnsDirective>
                            <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar,ExcelExport]}/>
                        </GridComponent>
                    </div>
                </>
            ) : <></> }
        </Box>
    );

    return (
        <div>
            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={open}
                    onClose={handleOpen}
                    variant="persistent"
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default GeoCode