//SET to 'false' if deploying to Production without the quotes
export const ISDEV:boolean = false
export const BTVERSION : string = '7.0.0'
export const API_URL: string = ISDEV ? "https://localhost:44365" : "https://api.test.canaralabels.com" //"https://localhost:44365" "https://demoapi.canaralabels.com"
export const Google_API_Key: string = "AIzaSyBRnGulaLFJWacrgj1hpgHiO1HEoEyUuDA"
export const ALPRTimeout_Sec: number = 300 //(300 * 1000) 5 Minute ALPR timeout for any server fetch
export const Shooting: string = ISDEV ? "https://demoShooting.canaralabels.com" : "https://shoothit.test.canaralabels.com" //"https://localhost:44366" "https://demoShooting.canaralabels.com"
export const NIBIN_URL: string = ISDEV ? "https://demoNIBIN.canaralabels.com" : "https://nibin.test.canaralabels.com" // "https://localhost:44367" : "https://demoNIBIN.canaralabels.com"
export const HMNTRFC_URL: string = ISDEV ? "https://demoHumanTraffic.canaralabels.com" : "https://humantraffic.test.canaralabels.com"  // "https://localhost:44368" : "https://demoHumanTraffic.canaralabels.com"
export const LINKANALYSIS: string = ISDEV ? "https://demolinkanalysis.canaralabels.com" : "https://linkanalysis.test.canaralabels.com"
export const THREAT_URL: string = ISDEV ? "https://demoThreatAssessment.canaralabels.com" : "https://ThreatAssessment.test.canaralabels.com"
export const THREAT_API_URL: string = ISDEV ? 'https://demoThreatAssessment.canaralabels.com' : "https://ThreatAssessment.test.canaralabels.com" //"https://demoThreatAssessment.canaralabels.com"
export const EOS_URL: string = ISDEV ? 'https://demoPoliceDirectContact.com' : 'https://PoliceDirectContact.test.canaralabels.com';
export const DEVICELOCATION_URL: string = ISDEV ? 'https://localhost:44365' : "https://DeviceLocation.test.canaralabels.com" //"https://localhost:8080" //"https://demoDeviceLocation.canaralabels.com"
export const DashboardTimer: number = 600000   //10 minutes refresh of Dashboard
export const DashboardRefresh: number = 3000000 //50 minutes Dashboard Token Refresh
export const DashboardMarqueeTimer: number = 600000 //10 minutes refresh of Dashboard Shooting Marquee
export const showCrumbScore: boolean = true; //true if want to show in graphs
export const Main_URL: string = ISDEV ? "https://localhost:44365" : "https://test.canaralabels.com" //"https://localhost:3000" "https://demo.canaralabels.com"