import React from "react";
import AlprMultipleOccurrenceSearch
    from "../../components/tools/alpr_multiple_occurrence/_alprMultipleOccurrenceSearch";
import {ALPRMultipleOccurenceQuery, ALPRMultipleOccurenceResult} from "../../interfaces/getDarInfo_interface";
import {RunMultipleOccurrence} from "../../services/getDar.service";
import ALPRMultipleOccurrenceResults
    from "../../components/tools/alpr_multiple_occurrence/_alprMultipleOccurrenceResults";
import {useLocation} from "react-router-dom";
import {isArrestShow, isCODISShow} from "../../services/auth.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ALPRMultipleOccurrence() {
    const [ALPRMultiData, setALPRMultiData] = React.useState<ALPRMultipleOccurenceResult>({})
    let [noData, setNoData] = React.useState<boolean>(false)

    const SubmitSearch = async (SearchQuery:any) => {
        //console.log(SearchQuery)
        let res = await RunMultipleOccurrence(SearchQuery)
        
        //console.log(JSON.parse(res?.JsonObject))
        setALPRMultiData({})
        setALPRMultiData(JSON.parse(res?.JsonObject))
        let obj = JSON.parse(res?.JsonObject)
        if (obj?.MultipleOccurrenceList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    let query = useQuery();
    
    
    return (
        <div>
            {isCODISShow()?
                <>
                    {query.get("ALPRList") ?
                        <AlprMultipleOccurrenceSearch SubmitSearch={SubmitSearch} data={query.get("ALPRList")}/>
                        :
                        <AlprMultipleOccurrenceSearch SubmitSearch={SubmitSearch} />
                    }
                    {!noData ? <ALPRMultipleOccurrenceResults ALPRData={ALPRMultiData} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
        
    );
}

export default ALPRMultipleOccurrence;