import React, { useState } from "react";
import { GetDarInfo_Query } from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";
import { RunDarQuery } from "../../services/getDar.service";
import StolenVehicleSearch from "../../components/tools/stolenVehicle/_stolenVehicleSearch";
import SearchResults from "../../components/tools/stolenVehicle/_searchResultsStolenVehicle";

function StolenVehicle() {
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [graphData, setGraphData] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, vinType: string, dropDowns: any, dateRange:boolean) => {
        console.log(dropDowns)
        let search_query: GetDarInfo_Query = {
            "QueryType": "L",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": dateRange,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": params.fromDate.toLocaleString(),
            "ToDate": params.toDate.toLocaleString(),
            "SearchType": "AND",
            "IndicatorOn": true,
            "TopArrestNumber": (vinType === "No VIN") ? 0 : 1,
            "FNames":  [],
            "MNames":  [],
            "LNames": [],
            "DOBs": [],
            "SEX": dropDowns.CDD.VehicleMake ? dropDowns.CDD.VehicleMake : [],
            "Address": "",
            "Cities": dropDowns.CDD.VehicleYear ? dropDowns.CDD.VehicleYear: [],
            "States":  dropDowns.DD.State ? dropDowns.DD.State : [],
            "Zip": "",
            "Statutes": dropDowns.SS.StolenState ? dropDowns.SS.StolenState : [],
            "Gang": dropDowns.CDD.VehicleModel ? dropDowns.CDD.VehicleModel : [],
            "FBIs":  [],
            "SBIs":  [],
            "DLNos": [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": params.VIN ? params.VIN.split(',') : [],
            "AgencyArrested":  [],
            "PoliceStopDept": dropDowns.DD.PoliceStopDept ? dropDowns.DD.PoliceStopDept : [],
            "Indicators": dropDowns.DD.Indicator ? dropDowns.DD.Indicator : [],
            "Latitude": null,
            "Longitude":null,
            "Radius": 0,
            "ResidentCounty": dropDowns.CDD.VehicleColor ? dropDowns.CDD.VehicleColor: [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [""],
            "FTSIDs": [],
        }
        let qRes: any = await RunDarQuery(search_query)
        setDarTbl([])
        setGraphData([])
        //console.log(qRes)
        setDarTbl(qRes.AnyTable)
        setGraphData(qRes.SecondaryTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    return (
        <div>
            {isArrestShow()?
                <>
                    <StolenVehicleSearch SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} Graph={graphData} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
                :<></>}
        </div>
    );
}

export default StolenVehicle;