import React from 'react'
import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip,
    Switch,
    FormControlLabel,
    FormGroup,
    FormControl, ButtonGroup, Icon, Dialog, Alert, Snackbar
} from "@mui/material";
import { formatDate, formatDT } from '../../services/formatDate.service';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateUser from "./UpdateUser";
import {RunSetDept, RunSetUser, RunUserList} from "../../services/account.service";
import UpdateDepts from "./UpdateDepartment";
import UserSearchResults from './_searchResultsUsers';
import {
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow, ExcelExport, ExcelExportProperties,
    Filter, Grid, GridComponent,
    Inject,
    Page, PdfExport, Reorder, Resize, Search,
    Sort, SortSettingsModel, Toolbar
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import BTPersonReport from "../shared/_BTPersonReport";

interface SearchResultsProps {
    Data: any[]
    UserList: any[]
    GetUserList: any
    GetDeptList: any
}

const  SearchResults: React.FC<SearchResultsProps> = ({Data, UserList, GetUserList, GetDeptList})=> {
    const [typedData, setTypedData] = React.useState(Data);
    const [user, setUser] = React.useState<boolean>(false)
    const [userID, setUserID] = React.useState<string>("0")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [people, setPeople] = React.useState<any>([])
    let grid: Grid | null;
    
    const CloseBox = () => {
        setUser(false)
        GetDeptList()
    }

    const editDept = (id:string) =>
    {
        setUserID(id)
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=" + id)
    }

    const deleteUser = async (person:any) => {
        let p = person
        if (window.confirm("Remove This User Permanently?")){
            person.EditStatus = "Delete"
            let r = await RunSetUser(person)
            //console.log(r)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                setAlertOpen(true)
                setAlertText("Delete Successful")
            }
            else
            {
                alert(r.ErrorMessage)
            }
        }
    }

    const resendUser = async (person:any) => {
        let p = person
        if (window.confirm("Are You Sure Want To Resend The Login Link To Selected User?")){
            person.EditStatus = "Resend"
            let r = await RunSetUser(person)
            //console.log(r)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                setAlertOpen(true)
                setAlertText("Resend Successful")
            }
            else
            {
                alert(r.ErrorMessage)
            }
        }
    }

    const ActivateDeactivateDept = async (person: any) => {
        let temp = [...typedData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))]
        p.EditStatus = p.Active === "Active" ? "Inactive": "Active"
        let r = await RunSetDept(p)
        if (r.AnyData?.IsValid) {
            GetUserList()
            setAlertOpen(true)
            p.Active = p.Active === "Active" ? "Inactive": "Active"
            temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))] = p
            setTypedData([])
            setTypedData(temp)
            grid?.refresh()
        }
    }

    const statusTemplate = (dept: any): any => {
        let deactivationSoon = false;
        const then = new Date(dept?.DateTerminated);
        const now = new Date();
        
        const msBetweenDates = Math.abs(then.getTime() - now.getTime());

        // 👇️ convert ms to days                 hour   min  sec   ms
        const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

        if (daysBetweenDates < 90) {
            deactivationSoon = true
        }
        
        return (
            <span>
               <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch onChange={() => ActivateDeactivateDept(dept)} color={deactivationSoon ? "warning" : "primary"} checked = {dept.Active === "Active"}/>
                            }
                            label= {dept.Active === "Active" ? "Active" : "Inactive" }
                        />
                    </FormGroup>
                </FormControl>
            </span>);
    }

    const actionTemplate = (dept: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Tooltip title="Edit" placement="top" arrow>
                            <IconButton onClick={() => editDept(dept.UniqueID)}><EditIcon/></IconButton>
                        </Tooltip>
                    {/*<Tooltip title="Delete" placement="top" arrow>
                            <IconButton onClick={() => deleteDept(person)}><DeleteIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title="Resend Department Link" placement="top" arrow>
                            <IconButton onClick={() => resendDept(person.UniqueID)}><EmailIcon/></IconButton>
                        </Tooltip>*/}
                </ButtonGroup>
            </span>);
    }

    const numberTemplate = (dept: any): any => {
        return (
            <span>
                {dept.ActiveUsers + ' / ' + dept.NumberOfUsers}
            </span>);
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Data])
    
    const handleDataTyping = () =>
    {
        if (Data) {
            setTypedData([])
            let tempD: any = [...Data]

            for (let p of tempD) {
                if (p.DateTerminated) {
                    p.DateTerminated = new Date(p.DateTerminated)
                }
                if (p.DeptName) {
                    p.DeptName = p.DeptName.toUpperCase()
                }
                if (p.DeptGroup) {
                    p.DeptGroup = p.DeptGroup.toUpperCase()
                }
                if (p.DeptAccountTypeMain) {
                    p.DeptAccountTypeMain = p.DeptAccountTypeMain.toUpperCase()
                }
                if (p.State) {
                    p.State = p.State.toUpperCase()
                }
                if (p.County) {
                    p.County = p.County.toUpperCase()
                }
                if (p.ContractName) {
                    p.ContractName = p.ContractName.toUpperCase()
                }
                if (p.ContractEmail) {
                    p.ContractEmail = p.ContractEmail.toUpperCase()
                }
            }
            setTypedData(tempD)
        }

    }
    
    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            // let excelProp:ExcelExportProperties = {}
            // excelProp.fileName = 'ArrestExport.xlsx'
            //grid.excelExport(excelProp);
            (grid.columns[0] as Column).visible = false;
            (grid.columns[1] as Column).visible = false;

            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'Departments.xlsx'
            grid.excelExport(excelProp);

            //grid.excelExport();

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;
            (grid.columns[1] as Column).visible = true;
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
            {field: 'Active', direction: 'Ascending' },
            {field: 'DateTerminated', direction: 'Ascending' }
            
    ] };

    const getPersonSummary = (dept:any) =>
    {
        let users:any[] = UserList.filter(person => person?.DeptCode === dept?.DeptCode)
        return(
            users?.length > 0 ? 
            <div className={"mt-3 pl-5 pb-5 "}>
                 <UserSearchResults Persons={users} GetUserList={GetUserList}/>
            </div>
            : <div className={"m-3 font-bold text-lg"} >No Records Found</div>
        )
    }
    
    return (
        <div className={" pr-10"}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={ () => { setAlertOpen(false)}} severity="success" sx={{ width: '100%' }}>
                    {setAlertText}
                </Alert>
            </Snackbar>
            {typedData && typedData.length>0? <>
                <h4 className={"font-bold pl-5"}> {typedData?.length} Records Found</h4>
                <GridComponent
                    dataSource={typedData}
                    //dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    sortSettings={sortSettings}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    excelExportComplete={excelExportComplete}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={actionTemplate} headerText='Action' width={100} minWidth={100} maxWidth={100} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={statusTemplate} field="Active" headerText='Status' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DeptName' headerText='Dept Name' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='DeptGroup' headerText='Dept Group' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='DeptAccountTypeMain' headerText='Acc Type' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='DateTerminated' format={{ type: 'date', format: 'MM/dd/yyyy' }}  width={170} minWidth={170} maxWidth={170} headerText='Deactivation Date'
                                         customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='State' headerText='State' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='County' headerText='County' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective template={numberTemplate} field="ActiveUsers" headerText='Number of Users' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr']}}/>
                        {/*<ColumnDirective field='ContractName' headerText='Contact Name' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='ContractEmail' headerText='Contact Email' customAttributes={{ class: ['e-attr']}}/>*/}
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>
            </>:<></>}
            <Dialog
                open={user}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdateDepts ID={userID} CloseBox={CloseBox}/>
            </Dialog>
        </div>

    )
};


export default SearchResults