import React, {memo, useState} from 'react'
import {
Tooltip
} from "@mui/material";
import PersonPhotoIcon from "../../assets/Images/photo1.png";
import ArrestIcon from "../../assets/Images/ai1.png";
import PoliceStopIcon from "../../assets/Images/ic1.png";
import ETicketIcon from "../../assets/Images/et1.png";
import ParkMobileIcon from "../../assets/Images/pm1.png";
import ALPRIcon from "../../assets/Images/alpr1.png";
import CADRMSIcon from "../../assets/Images/Opencase1.png";
import CODISIcon from "../../assets/Images/DNA1.png";
import TowingIcon from "../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../assets/Images/jail1.png";
import GunTraceIcon from "../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../assets/Images/parole1.png";
import ViolentIcon from "../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../assets/Images/profession1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import PriorReferralGrid from "./_resultsPriorReferralsGrid";
import GVRTFOutputForm from "./GVRTF_OutputForm";
import ReferralIcon from "../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../shared/_BTPersonReport";

interface SearchResultsProps {
    Persons: any[]
    UpdateReferralList? : any
}

const ReferralGrid1: React.FC<SearchResultsProps> = ({Persons, UpdateReferralList})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])

    const handleDataTyping = () =>
    {
        if(Persons) {
            if (Persons) {
                let tempP: any = [...Persons]

                for (let p of tempP) {
                    if (p.DOB) {
                        p.DOB = new Date(p.DOB)
                    }
                    if (p.DTOFARREST) {
                        p.DTOFARREST = new Date(p.DTOFARREST)
                    }
                    if (p.DTOFUPDATE) {
                        p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                    }
                }
                setTypedPersons(tempP)
            }
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])

    let grid: Grid | null;

    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Referrals.xlsx';

            (grid.getColumnByField("Referral_County") as Column).visible = true;
            (grid.getColumnByField("RTCC") as Column).visible = true;
            (grid.getColumnByField("Indicator") as Column).visible = false;

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("Referral_County") as Column).visible = false;
            (grid.getColumnByField("RTCC") as Column).visible = false;
            (grid.getColumnByField("Indicator") as Column).visible = true;
        }
    }

    const excelQueryCellInfo = (args: { column: { customExcelTemplate: (arg0: any) => any; }; value: string; data: any; }) => {
        console.log(args)
        /*if (has(args.column, "customExcelTemplate")) {
            args.value = toString(args.column.customExcelTemplate(args.data));
        }*/
    };

    const gridTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{"marginRight":"3px"}}> <img src={CODISIcon} style={{"display":"inline"}} alt={""}/> {person.FoundCODIS} </span>: <span/>}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    const getPersonSummary = (p:any) =>
    {
        return (<div><BTPersonReport person={p}/></div>)
    }
    
    /*const showAllReferrals = (p:any) => {
        return (<div><PriorReferralGrid Person={p} CurList = {[...Persons]}/></div>)
    }*/
    
    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length>0? <>
                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                <GridComponent
                    dataSource={typedPersons}
                    //dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: false,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting = {(event:any) => {console.log()}}
                    rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='FName' headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='MName' headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='LName' headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DOB'   format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI'   headerText='FBI' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='SBINo' headerText='SBINo' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Status'  headerText='Status' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Referral_County'  headerText='Referral County' visible={false} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='RTCC'  headerText='RTCC Location' visible={false} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' field={"Indicator"} customAttributes={{ class: ['e-attr'] }}/>
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>
            </>:<></>}
        </div>
    );
};

export default memo(ReferralGrid1);