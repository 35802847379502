import {Button, ButtonGroup, Checkbox, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import React from "react";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface FrequentOffenderSearchProps {
    SubmitSearch: any
}

const FrequentOffenderSearch: React.FC<FrequentOffenderSearchProps> = ({SubmitSearch}) => {
    let today:any = new Date()
    let date:any = new Date()
    date.setFullYear(date.getFullYear() - 1)
    const [params, setParams] = React.useState({toDate:today, fromDate:date})
    const [top, setTop] = React.useState<number>(50)
    const [dateType, setDateType] = React.useState<string>("Arrest Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleSearch()
        }
    }, [])
    
    
    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams, top)
    }

    const handleClear = () => {
        setParams({...params, toDate:today, fromDate:date.toISOString().slice(0,10)})
        setTop(50)
        setDateType("Arrested Date")
        setAndOrType("AND")
        setClear(clear+1)
    }

    const handleParamChange = (e:any) => {
        setTop(e.target.value)
    }

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if(dateType){
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns:any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if(andOrType)
            setAndOrType(andOrType);
    };


    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{mr: 2 }}
                >
                    <ToggleButton value="Offense Date" sx={{ pr: 2, pl: 2 }} >Offense Date</ToggleButton>
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                </ToggleButtonGroup>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            value={params.fromDate}
                            onChange={(e:any) => {
                                setParams({...params, fromDate: e})
                            }}
                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={params.toDate}
                                onChange={(e:any) => {
                                    setParams({...params, toDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                            />
                    </LocalizationProvider>
                </div>
                <div><TextField margin='none' size={"small"} label="Top #"   type="number" value = {top} onChange={handleParamChange}  /></div>
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop":"25px"}}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"frequent"}/></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
        );
}

export default FrequentOffenderSearch