import React, {useState} from 'react'
import {
    Alert,
    AlertColor,
    Button, ButtonGroup, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip
} from "@mui/material";
import PersonPhotoIcon from "../../assets/Images/photo1.png";
import ArrestIcon from "../../assets/Images/ai1.png";
import PoliceStopIcon from "../../assets/Images/ic1.png";
import ETicketIcon from "../../assets/Images/et1.png";
import ParkMobileIcon from "../../assets/Images/pm1.png";
import ALPRIcon from "../../assets/Images/alpr1.png";
import CADRMSIcon from "../../assets/Images/Opencase1.png";
import CODISIcon from "../../assets/Images/DNA1.png";
import TowingIcon from "../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../assets/Images/jail1.png";
import GunTraceIcon from "../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../assets/Images/parole1.png";
import ViolentIcon from "../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../assets/Images/profession1.png";
//import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {GetDarInfo_Query, getDarInfoResponse, PersonInfo} from "../../interfaces/getDarInfo_interface";
import noImage from "../../assets/Images/cbimage.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {formatDate, formatDT} from '../../services/formatDate.service';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp';
import {GRAILReferral} from "../../interfaces/GRAILReferral.interface";
import {JAOS} from "../../services/JAOS.service";
import {RunDarQuery, RunPerson_Referral, RunSetGRAILReferral} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import GVRTFOutputForm from "./GVRTF_OutputForm";
import {SearchDL} from "../../interfaces/searchDL.interface";
import ReferralGrid1 from "./_resultsReferralGrid1";

interface SearchResultsProps {
    Persons: any[]
    GetReferrals? : any
}

const SearchResults: React.FC<SearchResultsProps> = ({Persons})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [referralList, setReferralList] = React.useState<any[]>([])
    
    const handleDataTyping = () =>
    {
        if(Persons) {
            if (Persons) {
                let tempP: any = [...Persons]

                for (let p of tempP) {
                    if (p.DOB) {
                        p.DOB = new Date(p.DOB)
                    }
                    if (p.DTOFARREST) {
                        p.DTOFARREST = new Date(p.DTOFARREST)
                    }
                    if (p.DTOFUPDATE) {
                        p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                    }
                }
                setTypedPersons(tempP)
            }
        }

    }
    
    const UpdateReferralList = (newList :any[]) => {
        setReferralList(newList)

    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])

    return (
        <div className={"pl-10 pr-10 mt-5"}>
                <div>
                    <GVRTFOutputForm Persons={typedPersons} Additions={referralList}/>
                </div>
                <ReferralGrid1 Persons={typedPersons}/>
        </div>
    );
};

export default SearchResults;