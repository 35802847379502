import React, {useState} from "react";
import SearchResults from "../../components/tools/duplicate_person/_searchResultsDuplicatePerson";
import {GetDarInfo_Query, PersonInfo, getDarInfoResponse} from "../../interfaces/getDarInfo_interface";
import {RunDarQuery} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";
import DuplicatePersonSearch from "../../components/tools/duplicate_person/_duplicatePersonSearch";

function DuplicatePerson() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params:any, dateType:string,  dateRange:boolean) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "J",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": dateRange,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": params?.fromDate?.toLocaleString(),
            "ToDate": params?.toDate?.toLocaleString(),
            "SearchType": 'AND',
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FName ? params.FName.split(',') : [],
            "MNames": params.MName ? params.MName.split(',') : [],
            "LNames": params.LName ? params.LName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX":[],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": [],
            "PoliceStopDept": [],
            "Indicators": [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "SSN": params.SSN ? params.SSN.split(',') : [],
            "FTSIDs": [],
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        setDarTbl([])
        setDarTbl(qRes.AnyTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    return (
        <div>
            {isArrestShow()?
                <>
                    <DuplicatePersonSearch SubmitSearch={SubmitSearch}/>
                    {!noData ? <SearchResults Persons={darTbl} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
                :<></>}
        </div>
    );
}

export default DuplicatePerson;