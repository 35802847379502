import React from 'react'
import {Tooltip} from "@mui/material";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { showCrumbScore } from '../../../services/config.service';


interface SearchResultsProps {
    Persons: PersonInfo[]
}

const SearchResults: React.FC<SearchResultsProps> = ({Persons})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])

    const handleDataTyping = () =>
    {
        if(Persons) {
            let tempP: any = [...Persons]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.DTOFUPDATE) {
                    p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])

    let grid: Grid | null;
    const gridTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{"marginRight":"3px"}}> <img src={CODISIcon} style={{"display":"inline"}} alt={""}/> {person.FoundCODIS} </span>: <span/>}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Phone.xlsx';

            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("SSN1") as Column).visible = true;

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("SSN1") as Column).visible = false;
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns(["FName","MName","LName","DOB" ,"SEX" ,"City" ,"State" ,"Zip", "AddressType","Phone","PhoneType","PhoneProvider","DTOFARREST","ORIGINATINGORINAME","Dept"])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };
    
    return (
        <div className={"pl-5 pr-5 mt-5"}>
            {typedPersons && typedPersons?.length>0? <>
                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g=> grid = g}
                    dataBound={dataBound}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective >
                        <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='MName' headerText='MI' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText='SBINo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN1' width={110} minWidth={110} maxWidth={110} headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SEX' headerText='Sex' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }}/>
                        { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText='SCOR Ranking' customAttributes={{ class: ['e-attr'] }}/>:<></>}
                        <ColumnDirective field='Address' headerText='Address' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='City' headerText='City' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='State' headerText='State' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Zip' headerText='Zip' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='AddressType' headerText='Address Type' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Phone' headerText='Phone' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PhoneType' headerText='Phone Type' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PhoneProvider' headerText='Phone Provider' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={gridTemplate} headerText='Indicator' maxWidth={300} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DTOFARREST' format = {{type:'date', format:'MM/dd/yyyy'}} headerText='Date Arrested' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' maxWidth={200} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Dept' headerText='PoliceStop Dept' maxWidth={200} customAttributes={{ class: ['e-attr'] }}/>
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default SearchResults;