import axios, {AxiosResponse} from "axios";
import {
    Account,
    AccountTools,
    LoginRequest,
    LoginResponse,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    Expiry
} from "../interfaces/auth_interface";
import { API_URL, EOS_URL, HMNTRFC_URL, ISDEV, NIBIN_URL, Shooting, THREAT_URL, DEVICELOCATION_URL } from "./config.service";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";


export async function  LoginAPI(creds:LoginRequest) : Promise<LoginResponse> {
    let res:AxiosResponse<LoginResponse> = await axios.post('/api/Account/Login',creds, {
        baseURL: API_URL,
        withCredentials: false,
    })
    if(res.status<300)
        return res.data
    else
        return {AuthToken: null,LoggedUser:null}
         
}

export async function  AcceptedSystemNotice(creds:any) : Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/SystemNotice', creds)
    return (res.data)

}

export async function  AcceptedAgreement(creds:any) : Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/Agreement', creds)
    return (res.data)
}

export async function  ForgotPasswordAPI(creds:ForgotPasswordRequest) : Promise<ForgotPasswordResponse> {
    let res:AxiosResponse<ForgotPasswordResponse> = await axios.post('/api/Account/ResetPassword',creds, {
        baseURL: API_URL,
        withCredentials: false,
    })
    if(res.status<300)
        return res.data
    else
        return {AuthToken: null,LoggedUser:null}
         
}

export function getUser():Account{
    let user : string | null = localStorage.getItem("user")
    if(user){
        let usrObj:Account = JSON.parse(atob(user.split('.')[1]))
        return usrObj;
    }
    return {AccountType: null,DeptAccountType: null, AuthLevel: null, ClientIP: null, Dept: null, DeptORI: null, Firstname: null, Lastname: null, EmpID: null, ClientADUser: null, ClientBrowser: null, eOTP: null, exp: null};
      
}

export function getExpiry():string | null{
    let ex : string | null = localStorage.getItem("expiry")
    return ex
}


export function getToken():string{
    let user : string | null = localStorage.getItem("user")
    if(user){
        return user
    }
    return ""
}

export function getDashboardToken():string{
    let dashboard : string | null = localStorage.getItem("dashboard")
    if(dashboard){
        return dashboard
    }
    return ""
}

export async function RefreshUserToken (force:boolean = false){
    try{
        let user = getUser();
        let now =  new Date();
        if(user.exp){
            //On Last API Call within 40 Minutes of Exp get new token
            if ((((user.exp * 1000) - now.getTime())<(2400000) && ((user.exp * 1000) - now.getTime())>0) || force) {
                console.log("Refresh Token")
                let res:AxiosResponse<LoginResponse> = await axios.get('/api/Account/RefreshToken', {
                    baseURL: API_URL,
                    withCredentials: false,
                    headers:{
                        AuthToken: getToken()
                    }
                })
                //console.log(res)
                if(res.data.AuthToken)
                    localStorage.setItem('user', res.data.AuthToken)
            }
        }
    }catch (e) {
        console.log("Unable to get new authentication token.")
    }
}

export function isUserLoggedIn():boolean{
    let user:Account = getUser();
    return !!(user.exp && user.eOTP && user.EmpID && user.Dept);
}

export function logoutUser() {
        localStorage.removeItem("user");
        localStorage.removeItem("expiry");
        localStorage.removeItem("TA");
        //localStorage.clear();
}

export function getUserTools():AccountTools[]{
    let user:Account = getUser();
    let tools:AccountTools[] = [];
    
    //tools.push({href: "", name: ""})
    let showArrest:boolean = isArrestShow()
    let showCODIS:boolean = isCODISShow()
    
    if(showArrest)
        tools.push({ href: "/tools/arrest", name: "Arrest" })
    if (showArrest)
        tools.push({ href: "/tools/arrest_analysis", name: "Arrest Analysis" })
    if (showCODIS)
        tools.push({ href: "/tools/codis_notification", name: "CODIS Notification" })
    if (isNJSP())
        tools.push({ href: DEVICELOCATION_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "Device IDs", type: 'A' })
    if (isNJSP())
        tools.push({ href: "/tools/duplicate_person", name: "Duplicate Person" })
    if (isEyeOnScene())
        tools.push({ href: EOS_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "EyeOnScene", type: 'A' })
    if(showArrest)
        tools.push({ href: "/tools/frequent_offender", name: "Frequent Offender" })
    if (showArrest)
        tools.push({ href: "/tools/GVRTF_Referral", name: "GVRTF Referral" })
    if (isHumanTrafficShow())
        tools.push({ href: HMNTRFC_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "Human Traffic", type: 'A' })    
    if (showCODIS)
        tools.push({ href: "/tools/alpr_multiple_occurrence", name: "LPR Multiple Occurrence" })
    if (showCODIS)
        tools.push({ href: "/tools/alpr_vin_search", name: "LPR VIN Search" })
    if (isNCICShow())
        tools.push({ href: "/ncic?Hash", name: "NCIC Search" })
    if (isNIBINShow())
        tools.push({ href: NIBIN_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "NIBIN", type: 'A' })
    if (showArrest)
        tools.push({ href: Shooting + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&BTAUTH=' + getToken(), name: "NJ POP", type: 'A' })
    if (showArrest)
        tools.push({ href: "/tools/open_case_incident", name: "Open Case Incident" })
    if (showArrest)
        tools.push({ href: "/tools/parole", name: "Parole" })
    if(showArrest)
        tools.push({ href: "/tools/person_nearby", name: "Person Nearby" })
    if (showArrest)
        tools.push({ href: "/tools/phone_search", name: "Phone Search" })
    if (showArrest)
        tools.push({ href: "/tools/prisoner_information", name: "Prisoner Information" })
    if (showArrest)
        tools.push({ href: "/tools/professional_license", name: "Professional License" })
    if (isNJSP())
        tools.push({ href: "/tools/profile_report", name: "Profile Report" })
    if (showArrest)
        tools.push({ href: "/tools/scor", name: "SCOR Search" })
    if (showArrest)
        tools.push({ href: "/tools/shooting_notification", name: "Shooting Notification" })
    if (showArrest)
        tools.push({ href: "/tools/shot_spotter", name: "Shot Spotter" })
    if (showArrest)
        tools.push({ href: "/tools/statute_mapping", name: "Statute Mapping" })
    if (showArrest)
        tools.push({ href: "/tools/stolen_vehicle", name: "Stolen Vehicle" })
    if (isThreatAssessment())
        tools.push({ href: THREAT_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP, name: "Threat Assessment", type: 'A' })
    if(showArrest)
        tools.push({ href: "/tools/towed_vehicle", name: "Towed Vehicle" })
    if (showArrest)
        tools.push({ href: "/tools/violent_offender", name: "Violent Offender" })






    





    return tools
}

export function getAuditLog(): Account {
    let auditlog: string | null = localStorage.getItem("AuditLog")
    if (auditlog) {
        let auditlogObj: Account = JSON.parse(atob(auditlog.split('.')[1]))
        return auditlogObj;
    }
    return { AccountType: null, DeptAccountType: null, AuthLevel: null, ClientIP: null, Dept: null, DeptORI: null, Firstname: null, Lastname: null, EmpID: null, ClientADUser: null, ClientBrowser: null, eOTP: null, exp: null };

}

export function getUserProfile() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let profilePage:AccountTools[] = [];

    //User Profile
    profilePage.push({ href: "/users", name: "User Profile" })
    profilePage.push({ href: "/change_password", name: "Change Password" })
    //Audit Log
    if(showSearchHistory())
    profilePage.push({ href: "/SearchHistory", name: "Search History" })

    if (showDepartments()) {
        profilePage.push({ href: "/departments", name: "Departments" })
    }
    if (showDeptUnitLevel())
        profilePage.push({ href: "/deptUnit", name: "Dept Unit Level" })

    return profilePage
}

export function getDashboards() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let dashboardPage:AccountTools[] = [];
    
    dashboardPage.push({ href: "/dashboard", name: "Shooting DASHBOARD", type:'A' })
    dashboardPage.push({ href: Shooting + "/auth?UserName=" + user.EmpID + "&vNIBINDash=Y&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&BTAUTH=' + getToken(), name: "CRIME GUN DASHBOARD", type: 'A' })
    if(isNCICShow()) {
        dashboardPage.push({href: Shooting + "/auth?UserName=" + user.EmpID + "&vGRAILDash=Y&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&BTAUTH=' + getToken(), name: "GVRTF DASHBOARD", type: 'A'
        })
    }

    return dashboardPage
}

export function getSupportTabs() {
    let user: Account = getUser();
    //let auditlog: Account = getAuditLog();
    let supportPage:AccountTools[] = [];

    supportPage.push({ href: "/support/FAQ", name: "FAQs"})
    /*supportPage.push({ href: "/support/UserManual", name: "User Manual", type: "A"})*/
    /*supportPage.push({ href: "/support/Contact", name: "Contact Main"})*/
    /*supportPage.push({ href: "https://www.CanaraLabels.com/trainingcalendar", name: "Request Training", type:"A"})*/


    return supportPage
}


export function showSearchHistory(){
    let user: Account = getUser()
    if(user.DeptAccountType === 'ACCOUNTING')
        return false;
    else
        return true;
}

export function showDepartments(){
    let user: Account = getUser()
    if(user.DeptAccountType === 'ACCOUNTING' || user.AuthLevel === 'S')
        return true;
    else
        return false;
}

export function showDeptUnitLevel(){
    let user: Account = getUser()
    if(user.DeptAccountType === 'NIBIN')
        return false
    else if(user.DeptAccountType === 'ACCOUNTING')
        return false
    else if( user.AuthLevel === 'S' || user.AuthLevel === 'G' || (user.AuthLevel === 'M'))
        return true;
    else
        return false;
}


export function authHeader() {
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
        user = JSON.parse(userStr);

    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}

export function isPhoneShow() {
    let user: Account = getUser()
    if (user.DeptAccountType === 'NIBIN')
        return false;  //Even NIBIN Managers cannot see Arrest records
    else if (user.DeptAccountType === 'CODIS')
        return false; //Even CODIS Managers cannot see Arrest records
    else
        return (user.AuthLevel === 'S' || user.AuthLevel === 'M' || user.AuthLevel === 'G' || user.AuthLevel === 'A' || user.AuthLevel === 'I');
}

export function isArrestShow() {
    let user:Account = getUser()
    if (user.DeptAccountType === 'NIBIN')
        return false;  //Even NIBIN Managers cannot see Arrest records
    else if (user.DeptAccountType === 'CODIS')
        return false; //Even CODIS Managers cannot see Arrest records
    else if (user.DeptAccountType === 'ACCOUNTING')
        return false; //Even ACCOUNTING Managers cannot see Arrest records
    else 
        return !(user.AuthLevel === 'C' || user.AuthLevel === 'D' || user.AuthLevel === 'H' || user.AuthLevel === 'N');
}

export function isAnalysisShow() {
    return false;
}


export function isPOIShow() {
    let user:Account = getUser()
    if (user.DeptAccountType === 'NIBIN')
        return false;  //Even NIBIN Managers cannot see POI records
    else if (user.DeptAccountType === 'ACCOUNTING') 
        return false;  //Even ACCOUNTING Managers cannot see POI records
    else if (user.DeptAccountType === 'CODIS')
        return (user.AuthLevel === 'M' || user.AuthLevel === 'G' || user.AuthLevel === 'D');
    else 
        return !(user.AuthLevel === 'P' || user.AuthLevel === 'H' || user.AuthLevel === 'N');
}

export function isCODISShow() {
    let user:Account = getUser()
    if (user.DeptAccountType === 'NIBIN')
        return false;  //Even NIBIN Managers cannot see Arrest records
    else if (user.DeptAccountType === 'CODIS')
        return (user.AuthLevel === 'M' || user.AuthLevel === 'G' || user.AuthLevel === 'D');
    else 
        return !(user.AuthLevel === 'H' || user.AuthLevel === 'N' || user.AuthLevel === 'C');
}

export function isHumanTrafficShow() {
    let user:Account = getUser()
    if (user.DeptAccountType === 'NIBIN')
        return false;  //Even NIBIN Managers cannot see Arrest records
    else if (user.DeptAccountType === 'CODIS')
        return false; //Even CODIS Managers cannot see Arrest records
    else 
        return !(user.AuthLevel === 'P' || user.AuthLevel === 'C' || user.AuthLevel === 'D' || user.AuthLevel === 'N');
}

export function isEyeOnScene() {
    let user:Account = getUser()
    if(user.AuthLevel === 'C' || user.AuthLevel === 'D' || user.AuthLevel === 'N')
        return false;
    return ((user.DeptAccountType?.toUpperCase() === 'INTERNAL' || user.Dept?.toUpperCase() === 'NJSP' || user.Dept?.toUpperCase()==='NEWARK' || user.Dept?.toUpperCase()==='RUTHERFORD'));
}

export function isThreatAssessment() {
    //TBD need to change the function to handle authorization security
    let user: Account = getUser()
    let TA = localStorage.getItem('TA')
    if (user.DeptAccountType !== "NIBIN" && TA === 'true')
    {
        return true
    }
}

export function showTools() {
    let user:Account = getUser()
    if (user.DeptAccountType === 'ACCOUNTING')
        return false
    else 
        return true
}

export function isNIBINShow() {
    let user:Account = getUser()
    return user.DeptAccountType === 'NIBIN';
}

export function isNJSP() {
    let user:Account = getUser()
    return (user.DeptAccountType === 'INTERNAL' || user.Dept === 'NJSP');
}

export function isLogoShow () {
    let user:Account = getUser()
    return user.Dept === 'NJSP' || user.Dept === 'CENTRAL_TEST1';
}

export function isNCICShow () {
    let user: Account = getUser()
    if (user.DeptAccountType === 'NIBIN')
        return false;  //Even NIBIN Managers cannot see Arrest records
    else if (user.DeptAccountType === 'CODIS')
        return false; //Even CODIS Managers cannot see Arrest records
    else
        return (user.Dept === 'NJSP' || user.Dept === 'CENTRAL_TEST1' || user.Dept === 'CENTRAL') && (user.AuthLevel === 'S' || user.AuthLevel === 'M' || user.AuthLevel === 'G' || user.AuthLevel === 'A' || user.AuthLevel === 'I');
}

export { }