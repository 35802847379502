import React, {useState, useEffect} from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    ButtonGroup,
    FormControl,
    InputLabel, Select, MenuItem
} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface SCORSearchProps {
    SubmitSearch: any
}

const SCORSearch: React.FC<SCORSearchProps> = ({SubmitSearch}) => {
    let today:Date = new Date();
    const [params, setParams] = React.useState({FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", CrumbScore: 0, CrumbPercentile: 20, toDate:today, fromDate:today})
    const [dateType, setDateType] = React.useState<string>("Percentile Date");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [customRank, setCustomRank] = React.useState<boolean>(false);
    const [rank, setRank] = React.useState<string>("20");
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleSearch()
        }
    }, [])
    
    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if(dateType){
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns:any) => {
        setDropDownParams(dropDowns)
    }

    const handleClear = () => {
        setParams({...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", CrumbScore: 0, CrumbPercentile: 20, toDate:today, fromDate:today})
        setRank("20")
        setDateType("Percentile Date")
        setClear(clear+1)
        setCustomRank(false)
    }

    const handleDropDownChange = (e:any) => {
        let p = {...params}
        setRank(e.target.value)
        switch(e.target.value)
        {
            case('5'):
                p.CrumbPercentile = 5
                setCustomRank(false)
            break;
            case('10'):
                p.CrumbPercentile = 10
                setCustomRank(false)
            break;
            case('20'):
                p.CrumbPercentile = 20
                setCustomRank(false)
            break;
            case('40'):
                p.CrumbPercentile = 40
                setCustomRank(false)
            break;
            case('50'):
                p.CrumbPercentile = 50
                setCustomRank(false)
            break;
            case('Custom'):
                setCustomRank(true)
            break;
        }
        setParams(p)
    }

    const handleSearch = () => {
        SubmitSearch(params, dateType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{mr: 2 }}
                >
                    <ToggleButton value="Percentile Date" sx={{ pr: 2, pl: 2 }} >Percentile Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>                     
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            value={params.fromDate}
                            onChange={(e:any) => {
                                setParams({...params, fromDate: e})
                            }}
                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={params.toDate}
                                onChange={(e:any) => {
                                    setParams({...params, toDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                            />
                    </LocalizationProvider>
                </div>
                <div><TextField margin='none' size={"small"} label="First Name"   value = {params.FirstName} onChange={(e:any) => setParams({...params, FirstName: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="Middle Initial"  value = {params.MI} onChange={(e:any) => setParams({...params, MI: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="Last Name"  value = {params.LastName} onChange={(e:any) => setParams({...params, LastName: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} value = {params.DOB} onChange={(e:any) => setParams({...params, DOB: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="FBI #"  value = {params.FBI} onChange={(e:any) => setParams({...params, FBI: e.target.value})}   /></div>
                <div><TextField margin='none' size={"small"} label="SBI#"   value = {params.SBI} onChange={(e:any) => setParams({...params, SBI: e.target.value})} /></div>
                <div><TextField margin='none' size={"small"} label="SCOR" type="number"  value = {params.CrumbScore} onChange={(e:any) => setParams({...params, CrumbScore: e.target.value})} /></div>
                <div className="mr-5">
                    <FormControl fullWidth>
                        <InputLabel id="timeFrame">SCOR Ranking</InputLabel>
                        <Select
                            labelId="timeFrame"
                            value={rank}
                            label="Time Frame"
                            onChange={handleDropDownChange}
                            size={'small'}
                        >
                            <MenuItem value={"5"}>Top 5</MenuItem>
                            <MenuItem value={"10"}>Top 10</MenuItem>
                            <MenuItem value={"20"}>Top 20</MenuItem>
                            <MenuItem value={"40"}>Top 40</MenuItem>
                            <MenuItem value={"50"}>Top 50</MenuItem>
                            <MenuItem value={"Custom"}>Custom Rank</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {customRank ?
                    <div>
                        <TextField margin='none' size={"small"} label="Custom SCOR Ranking " type="number"
                                   value={params.CrumbPercentile}
                                   onChange={(e: any) => setParams({...params, CrumbPercentile: e.target.value})}/>
                    </div>
                : <div></div> }
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop":"25px"}}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"crumb"}/></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default SCORSearch;