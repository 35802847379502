import React, { useState, useEffect } from "react";
import {
    Tooltip
} from "@mui/material";

import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

interface AuditLogSearchResultsProps {
    AuditLogResults: any[]
}

const AuditLogSearchResults: React.FC<AuditLogSearchResultsProps> = ({ AuditLogResults }) => {
    const [typedPersons, setTypedAuditLogResults] = useState<any[]>([])

    const handleDataTyping = () => {
        if (AuditLogResults) {
            if (AuditLogResults) {
                let tempP: any = [...AuditLogResults]

                for (let p of tempP) {
                    if (p.LOG_DATETIME) {
                        p.LOG_DATETIME = new Date(p.LOG_DATETIME)
                    }
                }
                setTypedAuditLogResults(tempP)
            }
        }
    }

    useEffect(() => { handleDataTyping() }, [AuditLogResults])
    let grid: Grid | null;
    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'ArrestAuditLog.xlsx';

            grid.excelExport(excelProp);
        }
    }
    const excelExportComplete = () => {
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns(["MESSAGE"])
        }
    }

    let sortSettings: SortSettingsModel = { columns: [{ field: 'LOG_DATETIME', direction: 'Descending' }] };

    return (
        <div className={"pl-10 pr-10 mt-5 mb-5"}>
            {typedPersons && typedPersons.length > 0 ? <>
                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        mode: "Row"
                    }}
                    rowSelecting={(event: any) => { console.log(event) }}
                    allowSelection={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='ACTION' headerText='Action' width={200} minWidth={200} maxWidth={200} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='MESSAGE' headerText='Message' maxWidth={1340} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LOG_DATETIME' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }}
                            headerText='Date' width={200} minWidth={200} maxWidth={200} customAttributes={{ class: ['e-attr'] }} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
            </> : <></>}
        </div>
    );
};
export default AuditLogSearchResults;

//<ColumnDirective field='EventType' headerText='EventType' customAttributes={{ class: ['e-attr'] }} />