import React from "react";
import {Redirect} from "react-router-dom";
import {logoutUser} from "../../../services/auth.service";
import {JAOS} from "../../../services/JAOS.service";

function LogOff() {
    new JAOS().clearStack();
    logoutUser();
    
    return (
        <Redirect to={'/login'} />
    );
}

export default LogOff;