import React from "react";
import { useLocation } from "react-router-dom";
import PoliceStopImageDetailInfo from "../../components/Detail/_PoliceStopImageDetailInfo";
import PoliceStopDetailInfo from "../../components/Detail/_PoliceStopDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

  
function PoliceStopDetail() {
    let query = useQuery();

    return (
        <div>
            {/*return tbody(*/}
            {/*for (var i = 0; i < numrows; i++) {*/}
            {/*    ObjectRow()*/}
            {/*}*/}
            <PoliceStopImageDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
            <PoliceStopDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
        </div>
    );
}

export default PoliceStopDetail;