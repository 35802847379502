import React from "react";
import Footer from "../../components/NavBar/footer";
import "./TwoPanelLayout.css"
import BTlogo from '../../assets/Images/Arrest-logo-transparent.png'
import SideBar from "../../components/NavBar/TwoPanel/SideBar";
interface TwoPanelLayoutState {}
interface TwoPanelLayoutProps{}

export class TwoPanelLayout extends React.Component<TwoPanelLayoutProps, TwoPanelLayoutState> {
    constructor(props:TwoPanelLayoutProps) {
        super(props);
        this.state={}
    }
    
    render () {
        return (
                <main className="flex w-full min-h-screen">
                    <SideBar />
                    <section className="w-full p-4">
                        {this.props.children}
                        <Footer />
                    </section>

                </main>
                
        );
    }
}
