import React, { useState, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Dialog, Tooltip
} from "@mui/material";
import ParkMobileDetail from "../../interfaces/Detail/ParkMobileDetail.interface"
import { RunParkMobileDetailQuery } from "../../services/detail.service";
import { formatDate, formatDT } from "../../services/formatDate.service"
import { TransitionProps } from '@mui/material/transitions';

import './Detail.css'
import DetailMap from "./_DetailMap";
import Slide from "@mui/material/Slide";

interface DetailProps {
    ID: string | null
    department: string | null
}


const ParkMobileDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<ParkMobileDetail>({})
    const [pin, setPin] = React.useState<any>({})
    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)

    const loadData = async () => {
        //console.log(ID, " ", department)
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunParkMobileDetailQuery(ID, department);
            setData(res)
            createPin(res)
        }
    }

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data: any) => {
        let tPin: any = {}
        if (Data && Data?.Latitude && Data?.Longitude) {
            tPin = ({
                lat: Data?.Latitude,
                lng: Data?.Longitude,
                date: Data?.Startdatetime,
                city: Data?.Zoneid,
                plate: Data?.Plate + " " + Data?.Platestate && Data?.Platestate?.length > 0 ? Data?.Platestate : "",
                detail: "parkmobile"
            })
        }
        setPin(tPin)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            {enlarged ?
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    onClose={()=>{setEnlarged(null)}}
                    open={open}
                    TransitionComponent={Transition}

                >
                    <Tooltip title={"Click to Shrink"}><img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/></Tooltip>
                </Dialog>
                :
                <></>
            }
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Details</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td><Tooltip title={"Click to Enlarge"}><img src={Data?.PlateEntryImage} width="200" height="250" onClick={()=>{setEnlarged(Data?.PlateEntryImage); setOpen(true)}}/></Tooltip></td>
                                <td><Tooltip title={"Click to Enlarge"}><img src={Data?.PlateExitImage} width="200" height="250" onClick={()=>{setEnlarged(Data?.PlateExitImage); setOpen(true)}}/></Tooltip></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data?.Id}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read ID</span><span className="DetailTableData">{Data?.Mobileparkid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate #</span><span className="DetailTableData">{Data?.Plate}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate State</span><span className="DetailTableData">{Data?.Platestate}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Start DateTime</span><span className="DetailTableData">{formatDT(Data?.Startdatetime)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">End DateTime</span><span className="DetailTableData">{formatDT(Data?.Enddatetime)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Permit</span><span className="DetailTableData">{Data?.Permit}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Latitude</span><span className="DetailTableData">{Data?.Latitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Longitude</span><span className="DetailTableData">{Data?.Longitude}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="parkmobile" /> </div> : <></>}
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Parking Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Zone ID</span><span className="DetailTableData">{Data?.Zoneid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Zone Signage</span><span className="DetailTableData">{Data?.Zonesignage}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Space Number</span><span className="DetailTableData">{Data?.Spacenumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Agency ID</span><span className="DetailTableData">{Data?.Agencyid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Agency Name</span><span className="DetailTableData">{Data?.Agencyshortname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Import Datetime</span><span className="DetailTableData">{formatDT(Data?.Importdatetime)}</span></div>
                </CardContent>
            </Card>
        </div>
    );
}

export default ParkMobileDetailInfo;