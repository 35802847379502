import React, { useState } from "react";
import AuditLogSearch from "../../components/Admin/AuditLogSearch";
import AuditLogSearchResults from "../../components/Admin/_searchResultsAuditLog";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import { getUser } from "../../services/auth.service";

function AuditLog() {
    let [AuditLog, setAuditLog] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "K",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 0,
            "FromDate": params?.FromDate?.toLocaleString(),
            "ToDate": params?.ToDate?.toLocaleString(),
            "SearchType": 'AND',
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": params.Message ? params.Message.split(',') : [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": [],
            "PoliceStopDept": [],
            "Indicators": [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": params?.Action ? params.Action : [],
            "CaseNumbers": [],
            "SSN": [],
            "PlaceOfBirth": [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setAuditLog([])
        setAuditLog(qRes.AnyTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    return (
        <div id="AdminAuditLog">
            <>
                <AuditLogSearch SubmitSearch={SubmitSearch} />
                {!noData ? <AuditLogSearchResults AuditLogResults={AuditLog} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}

            </>
        </div>
    );
}

export default AuditLog;