import {
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {DateToString, formatDate} from "../../services/formatDate.service";
import POIAttributes from "../../interfaces/POI.interface";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import {RunGetNCIC, RunGetPOI, RunSetPOI, RunSetUser} from "../../services/account.service";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {JAOS} from "../../services/JAOS.service";
import ErrorIcon from "@mui/icons-material/Error";



interface UpdatePOIProps {
    ID:string|null
    CloseBox:any
    Hash?:string | null
}

const UpdatePOI: React.FC<UpdatePOIProps> = ({ID, CloseBox, Hash=null}) =>{
    let [user, setUser] = useState<Account>(getUser())
    const [params, setParams] = React.useState<POIAttributes>({})
    const [submitError, setSubmitError] = React.useState<string>("")
    const [supervisorError, setSupervisorError] = React.useState<string>("")

    const LoadUserData = async (ID:string) => {
        if(Hash){
            try {
                let obj = new JAOS()
                let res = obj.objFromStack(Hash ? Hash : "")
                //console.log(res)
                let r = await RunGetPOI(res)
                await setParams(r.AnyData)
                
            }
            catch(e)
            {
                console.log(e)
            }
        }
        else {
            let query = {
                "uniqueID": ID,
            }
            let r = await RunGetPOI(query)
            await setParams(r.AnyData)
        }
    }

    const checkValidation = () => {
        setSupervisorError("")
        

        var response = true
        if(params.AuthorizedBy === null || params.AuthorizedBy && params.AuthorizedBy?.length === 0)
        {
            setSupervisorError("The Supervisor field is required.")
            response = false;
        }
        
        return response

    }

    const submitPOI = async () => {
        setSubmitError("")
        if(checkValidation()) {
            let param = params
            if(param.ExpiryDate) {
                let d = new Date(param.ExpiryDate)
                d.setHours(23)
                d.setMinutes(59)
                d.setSeconds(59)
                param.ExpiryDate = DateToString(d)
            }
            if(param.StartDate)
                param.StartDate = DateToString(new Date(param.StartDate))
            let r = await RunSetPOI(param)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                CloseBox()
            }
            else
            {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
            }
        }
    }
    
    useEffect(() => {
        if(ID !== null)
            LoadUserData(ID)
    }, [ID]);


    const handleExpireTime = (reasonType:string) => {
        let p = params
        let expDate = new Date()
        p.ReasonType = reasonType
        //console.log(reasonType)
        switch(reasonType){
            case("NCIC"):
                expDate.setDate(expDate.getDate() + 2);
                p.ExpiryDate = expDate.toISOString().substring(0,10)
                break;
            case("COVERT"):
                expDate.setMonth(expDate.getMonth()+1)
                p.ExpiryDate = expDate.toISOString().substring(0,10)
                break;
            case("NON-NCIC"):
                expDate.setMonth(expDate.getMonth()+1)
                p.ExpiryDate = expDate.toISOString().substring(0,10)
                break;
        }
        //console.log(p)
        setParams(p)
    }

    return (
        <div className = "mx-auto">
            {(params && params?.ReasonTypeList && params?.ReasonTypeList.length > 0 ?
                <Card sx={{ maxWidth: 900 }}  className = "mx-auto">
                    <CardContent>
                        <span className = "text-blue-400 font-bold text-xl " >
                        {params?.UniqueID === 0 ? <span>Add New POI</span> :<span>Edit POI Details</span>}
                        </span>
                        <hr/>
                        <Disclosure defaultOpen={true}>
                            {({ open }) => (
                            <>
                                <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                    <CardContent>
                                        <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Issuance Type </span>
                                                </span>
                                            </span>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 p-4"> 
                                            <div className = "grid grid-cols-2 mt-5 gap-y-5">
                                                <div className="w-full pr-5">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="ReasonTypeID">Reason Type</InputLabel>
                                                        <Select
                                                            size="small"
                                                            labelId="ReasonTypeID"
                                                            label="Reason Type"
                                                            className="w-full"
                                                            value = {params?.ReasonType}
                                                            defaultValue = {params?.ReasonType}
                                                            onChange={e => {handleExpireTime(e.target.value); setParams({...params, ReasonType: e.target.value})}}
                                                        >
                                                            {params.ReasonTypeList && params?.ReasonTypeList.map((reason) => (
                                                                <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {params && params?.ReasonType === 'NON-NCIC' ?
                                                    <div className="w-full pl-5">
                                                        <FormControl fullWidth>
                                                            <InputLabel id="ReasonID">Reason</InputLabel>
                                                            <Select
                                                                size="small"
                                                                labelId="ReasonID"
                                                                label="Reason"
                                                                className="w-full"
                                                                value = {params?.ReasonNonNCIC}
                                                                onChange={e => setParams({ ...params, ReasonNonNCIC: e.target.value })}
                                                            >
                                                                {params.ReasonNonNCICList && params?.ReasonNonNCICList.map((reason) => (
                                                                    <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                : params && params?.ReasonType === 'COVERT' ?
                                                    <div className="w-full pl-5">
                                                        <FormControl fullWidth>
                                                            <InputLabel id="ReasonID">Reason</InputLabel>
                                                            <Select
                                                                size="small"
                                                                labelId="ReasonID"
                                                                label="Reason"
                                                                className="w-full"
                                                                value = {params?.ReasonCovert}
                                                                onChange={e => setParams({ ...params, ReasonCovert: e.target.value })}
                                                            >
                                                                {params.ReasonCovertList && params?.ReasonCovertList.map((reason) => (
                                                                    <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                : params && params?.ReasonType === 'NCIC' ?
                                                    <div className="w-full pl-5">
                                                        <FormControl fullWidth>
                                                            <InputLabel id="ReasonID">Reason</InputLabel>
                                                            <Select
                                                                size="small"
                                                                labelId="ReasonID"
                                                                label="Reason"
                                                                className="w-full"
                                                                value = {params?.ReasonNCIC}
                                                                onChange={e => setParams({ ...params, ReasonNCIC: e.target.value })}
                                                            >
                                                                {params.ReasonNCICList && params?.ReasonNCICList.map((reason) => (
                                                                    <MenuItem value={reason.Key}>{reason.Value}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>        
                                                :<></> }
                                                <div className="w-full pr-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="Start Date"
                                                        className="w-full"
                                                        type="date"
                                                        value={params?.StartDate?.substring(0,10)}
                                                        onChange={e => setParams({ ...params, StartDate: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pl-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="Expiry Date"
                                                        className="w-full"
                                                        type="date"
                                                        value={params?.ExpiryDate?.substring(0,10)}
                                                        onChange={e => setParams({ ...params, ExpiryDate: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pr-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="NIC #"
                                                        className="w-full"
                                                        value={params?.NICNum}
                                                        onChange={e => setParams({ ...params, NICNum: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full ml-5">
                                                    <FormControlLabel
                                                        control={<Checkbox checked={params?.ConfidentialPOI} onChange = {e => setParams({ ...params, ConfidentialPOI: !params?.ConfidentialPOI })} sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}/>}
                                                        label="Confidential POI"
                                                    />
                                                </div>
                                            </div>
                                        </Disclosure.Panel>
                                    </CardContent>
                                </Card>
                                </>
                            )}
                            </Disclosure>
                            <Disclosure defaultOpen={true}>
                                {({ open }) => (
                                    <>
                                <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                    <CardContent>
                                        <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Vehicle Information </span>
                                                </span>
                                            </span>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 p-4">
                                            <div className = "grid grid-cols-2 mt-5 gap-y-5">
                                                <div className="w-full pr-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="Plate #1"
                                                        className="w-full"
                                                        value={params?.PlateNumber}
                                                        onChange={e => setParams({ ...params, PlateNumber: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pl-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="State #1"
                                                        inputProps={{ maxLength: 2 }}
                                                        placeholder = "NJ, PA, CA"
                                                        className="w-full"
                                                        value={params?.PlateSt}
                                                        onChange={e => setParams({ ...params, PlateSt: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pr-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="Plate #2"
                                                        className="w-full"
                                                        value={params?.PlateNumber2}
                                                        onChange={e => setParams({ ...params, PlateNumber2: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pl-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="State #2"
                                                        inputProps={{ maxLength: 2 }}
                                                        placeholder = "NJ, PA, CA"
                                                        className="w-full"
                                                        value={params?.PlateSt2}
                                                        onChange={e => setParams({ ...params, PlateSt2: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pr-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="Plate #3"
                                                        className="w-full"
                                                        value={params?.PlateNumber3}
                                                        onChange={e => setParams({ ...params, PlateNumber3: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full pl-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="State #3"
                                                        inputProps={{ maxLength: 2 }}
                                                        placeholder = "NJ, PA, CA"
                                                        className="w-full"
                                                        value={params?.PlateSt3}
                                                        onChange={e => setParams({ ...params, PlateSt3: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className = "grid grid-cols-1 mt-5 gap-y-5">
                                                <div className="w-full">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="VIN"
                                                        className="w-full"
                                                        value={params?.VIN}
                                                        onChange={e => setParams({ ...params, VIN: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </Disclosure.Panel>
                                    </CardContent>
                                </Card>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure defaultOpen={true}>
                            {({ open }) => (
                                <>
                                <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                    <CardContent>
                                        <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Suspect Information </span>
                                                </span>
                                            </span>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="text-gray-500 p-4">
                                        <div className = "grid grid-cols-3 mt-5 gap-y-5">
                                            <div className="w-full pr-5">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="First Name"
                                                    className="w-full"
                                                    value={params?.FName}
                                                    onChange={e => setParams({ ...params, FName: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="Middle Initial"
                                                    className="w-full"
                                                    value={params?.MName}
                                                    onChange={e => setParams({ ...params, MName: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="Last Name"
                                                    className="w-full"
                                                    value={params?.LName}
                                                    onChange={e => setParams({ ...params, LName: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="DOB"
                                                    className="w-full"
                                                    type={"date"}
                                                    value={params?.DOB?.substring(0,10)}
                                                    onChange={e => setParams({ ...params, DOB: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="DL #"
                                                    className="w-full"
                                                    value={params?.DLNo}
                                                    onChange={e => setParams({ ...params, DLNo: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="DL State"
                                                    className="w-full"
                                                    value={params?.DLSt}
                                                    onChange={e => setParams({ ...params, DLSt: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="SSN #"
                                                    className="w-full"
                                                    value={params?.SSN}
                                                    onChange={e => setParams({ ...params, SSN: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full pr-5">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="FBI #"
                                                    className="w-full"
                                                    value={params?.FBINo}
                                                    onChange={e => setParams({ ...params, FBINo: e.target.value })}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <TextField
                                                    size="small"
                                                    InputLabelProps={{'shrink':true}}
                                                    label="SBI #"
                                                    className="w-full"
                                                    value={params?.SBINo}
                                                    onChange={e => setParams({ ...params, SBINo: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                    </CardContent>
                                    </Card>
                                </>
                            )}
                            </Disclosure>
                            <Disclosure defaultOpen={true}>
                            {({ open }) => (
                                <>
                                    <Card  sx={{ maxWidth: 900 , marginTop: 2 }}>
                                        <CardContent>
                                            <Disclosure.Button className="w-auto w-full text-left">
                                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <span>
                                                    <KeyboardArrowDownIcon
                                                        className={`${open ? "transform rotate-180" : ""}`}
                                                        style = {{fontSize : "2em"}}
                                                    />
                                                    <span className = "text-blue-400 font-bold text-xl " > Notes </span>
                                                </span>
                                            </span>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="text-gray-500 p-4">
                                            <div className = "grid grid-cols-1 mt-5 gap-y-5">
                                                <div className="w-full">
                                                    <TextField
                                                        size="small"
                                                        multiline
                                                        minRows={4}
                                                        InputLabelProps={{'shrink':true}}
                                                        className="w-full"
                                                        label="Comments"
                                                        value={params?.SearchNotes}
                                                        onChange={e => setParams({ ...params, SearchNotes: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className = "grid grid-cols-3 mt-5 gap-y-5">
                                                <div className="w-full pr-5">
                                                    <FormControl fullWidth color = {supervisorError.length > 0 ? "error" : "primary"} focused = {supervisorError.length > 0 ? true : undefined}>
                                                        {supervisorError.length > 0 ? <InputLabel id="AccountTypeLevel">{supervisorError}</InputLabel> : <InputLabel id="SupervisorID">Supervisor</InputLabel>}
                                                        <Select
                                                            size="small"
                                                            labelId="SupervisorID"
                                                            label={supervisorError.length > 0 ? supervisorError :"Supervisor"}
                                                            className="w-full"
                                                            value = {params?.AuthorizedBy}
                                                            onChange={e => setParams({ ...params, AuthorizedBy: e.target.value })}
                                                        >
                                                            {params.AuthorizedByList && params?.AuthorizedByList.map((supervisor) => (
                                                                <MenuItem value={supervisor.Key}>{supervisor.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="w-full pr-5">
                                                    <TextField
                                                        size="small"
                                                        InputLabelProps={{'shrink':true}}
                                                        label="Case #"
                                                        className="w-full"
                                                        value={params?.CaseNo}
                                                        onChange={e => setParams({ ...params, CaseNo: e.target.value })}
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="AlertOptionID">Alert Option</InputLabel>
                                                        <Select
                                                            size="small"
                                                            labelId="AlertOptionID"
                                                            label="Alert Option"
                                                            className="w-full"
                                                            value = {params?.AlertOption}
                                                            onChange={e => setParams({ ...params, AlertOption: e.target.value })}
                                                        >
                                                            {params.AlertOptionList && params?.AlertOptionList.map((alert) => (
                                                                <MenuItem value={alert.Key}>{alert.Value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className = "grid grid-cols-1 mt-5 gap-y-5">
                                                <div className="w-full">
                                                    <TextField
                                                        size="small"
                                                        multiline
                                                        className="w-full"
                                                        label="Notification Emails"
                                                        value={params?.AdditionalEmail}
                                                        onChange={e => setParams({ ...params, AdditionalEmail: e.target.value })}
                                                    />
                                                </div>
                                            </div>    
                                            </Disclosure.Panel>
                                        </CardContent>
                                    </Card>
                                </>
                        )}
                        </Disclosure>
                        <Card  sx={{ maxWidth: 900, marginTop: 2 }}>
                            <CardContent>
                                <span className = "text-blue-400 font-bold text-xl" >Acceptance Agreement</span>
                                <h1 className = "mt-2 font-normal text-3xl">Criteria for and Examples of Legitimate BOLO Situations</h1>
                                <div className = "mt-2">
                                    A license plate number or partial license plate number shall not be included in an ALPR initial BOLO list unless there is a legitimate and specific law enforcement reason to identify or locate that particular vehicle, or any person or persons who are reasonably believed to be associated with that vehicle. A license plate or partial license plate number shall not be included in a Post-Scan BOLO list unless there is a legitimate and specific law enforcement reason to ascertain the past locations(s) of that particular vehicle, or of any person or persons who are reasonably believed to be associated with that vehicle.
                                    <br />
                                    <br />
                                    Examples of legitimate and specific reasons include, but are not limited to: persons who are subject to an outstanding arrest warrant; missing persons; AMBER Alerts; stolen vehicles; vehicles that are reasonably believed to be involved in the commission of a crime or disorderly persons offense; vehicles that are registered to or are reasonably believed to be operated by persons who do not have a valid operator’s license or who are on the revoked or suspended list; vehicles with expired registrations or other Title 39 violations; persons who are subject to a restraining order or curfew issued by a court or by the Parole Board, or who are subject to any other duly issued order restricting their movements; persons wanted by a law enforcement agency who are of interest in a specific investigation, whether or not such persons are themselves suspected of criminal activity; and persons who are on any watch list issued by a State or federal agency responsible for homeland security
                                </div>
                            </CardContent>
                        </Card>
                        {submitError && submitError?.length > 0 ?
                            <span style={{ color: "red", fontSize:14}}>
                           {submitError.split(",").map((error)=> <div> <ErrorIcon style={{color:"red"}} fontSize = 'small' />{error}</div>)}
                        </span>
                            : <></>}
                        <div className="mt-8 flex justify-center">
                            <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                                <Button onClick = {submitPOI}>Save</Button>
                                <Button onClick = {CloseBox}>CANCEL</Button>
                            </ButtonGroup>
                        </div>
                    </CardContent>
                </Card>
                : <></>)}
        </div>
    );
}

export default UpdatePOI;

/*<div >
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Reason Type		    " variant="standard" value={params.ReasonType} onChange={e => setParams({ ...params, ReasonType: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Reason              " variant="standard" value={params.ReasonNCIC} onChange={e => setParams({ ...params, ReasonNCIC: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Reason              " variant="standard" value={params.ReasonNonNCIC} onChange={e => setParams({ ...params, ReasonNonNCIC: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Reason              " variant="standard" value={params.ReasonCovert} onChange={e => setParams({ ...params, ReasonCovert: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="NIC #               " variant="standard" value={params.NICNum} onChange={e => setParams({ ...params, NICNum: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Start Date          " variant="standard" value={params.StartDate} onChange={e => setParams({ ...params, StartDate: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Expiry Date         " variant="standard" value={params.ExpiryDate} onChange={e => setParams({ ...params, ExpiryDate: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Confidential POI"     variant="standard" value={params.ConfidentialPOI} onChange={e => setParams({ ...params, ConfidentialPOI: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Local POI           " variant="standard" value={params.LocalPOI} onChange={e => setParams({ ...params, LocalPOI: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate #1, State     " variant="standard" value={params.PlateNumber} onChange={e => setParams({ ...params, PlateNumber: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="State               " variant="standard" value={params.PlateSt} onChange={e => setParams({ ...params, PlateSt: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate #2, State     " variant="standard" value={params.PlateNumber2} onChange={e => setParams({ ...params, PlateNumber2: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="State               " variant="standard" value={params.PlateSt2} onChange={e => setParams({ ...params, PlateSt2: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate #3, State     " variant="standard" value={params.PlateNumber3} onChange={e => setParams({ ...params, PlateNumber3: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Plate State         " variant="standard" value={params.PlateSt3} onChange={e => setParams({ ...params, PlateSt3: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="VIN                 " variant="standard" value={params.VIN} onChange={e => setParams({ ...params, VIN: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="First Name          " variant="standard" value={params.FName} onChange={e => setParams({ ...params, FName: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Middle Initial      " variant="standard" value={params.MName} onChange={e => setParams({ ...params, MName: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Last Name           " variant="standard" value={params.LName} onChange={e => setParams({ ...params, LName: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="DOB                 " variant="standard" value={params.DOB} onChange={e => setParams({ ...params, DOB: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="DL #                " variant="standard" value={params.DLNo} onChange={e => setParams({ ...params, DLNo: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="DL State            " variant="standard" value={params.DLSt} onChange={e => setParams({ ...params, DLSt: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="SSN #               " variant="standard" value={params.SSN} onChange={e => setParams({ ...params, SSN: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="FBI #               " variant="standard" value={params.FBINo} onChange={e => setParams({ ...params, FBINo: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="SBI #               " variant="standard" value={params.SBINo} onChange={e => setParams({ ...params, SBINo: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Comments            " variant="standard" value={params.SearchNotes} onChange={e => setParams({ ...params, SearchNotes: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Alert Option        " variant="standard" value={params.AlertOption} onChange={e => setParams({ ...params, AlertOption: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Employee ID         " variant="standard" value={params.EmpID} onChange={e => setParams({ ...params, EmpID: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Department          " variant="standard" value={params.DeptCode} onChange={e => setParams({ ...params, DeptCode: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Supervisor          " variant="standard" value={params.AuthorizedBy} onChange={e => setParams({ ...params, AuthorizedBy: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Case #              " variant="standard" value={params.CaseNo} onChange={e => setParams({ ...params, CaseNo: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Created User        " variant="standard" value={params.CreateUID} onChange={e => setParams({ ...params, CreateUID: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Created Date        " variant="standard" value={params.CreateDate} onChange={e => setParams({ ...params, CreateDate: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Updated User        " variant="standard" value={params.UpdUID} onChange={e => setParams({ ...params, UpdUID: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Updated Date        " variant="standard" value={params.UpdDate} onChange={e => setParams({ ...params, UpdDate: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Notification Emails " variant="standard" value={params.AdditionalEmail} onChange={e => setParams({ ...params, AdditionalEmail: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Active			    " variant="standard" value={params.Active} onChange={e => setParams({ ...params, Active: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="EditStatus		    " variant="standard" value={params.EditStatus} onChange={e => setParams({ ...params, EditStatus: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Reason              " variant="standard" value={params.Reason} onChange={e => setParams({ ...params, Reason: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="ID                  " variant="standard" value={params.UniqueID} onChange={e => setParams({ ...params, UniqueID: e.target.value })} /></div>
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="IsValid             " variant="standard" value={params.IsValid} onChange={e => setParams({ ...params, IsValid: e.target.value })} /></div>

            </div>
            <div className="mt-8 flex justify-center">
                <Button style={{"marginRight":"30px"}} variant="outlined" size={"large"} type="submit" onClick={handleSearch}>Search</Button>
                <Button variant="outlined" size={"large"} color={"error"} onClick={e => setParams({...params, FirstName: "", MI: "", LastName: "", Email: "", UserID: "", DeptName: "", AccountType: "",DeptGroup: "", })}>CLEAR</Button>
            </div>
*/