import React, {useState} from 'react' 
import {Card, CardActions, CardContent, Typography, Button, TextField} from "@mui/material"; 
import {getUser} from "../../../services/auth.service";
import {Account} from "../../../interfaces/auth_interface";

interface AgreementProps {
    AcceptAgreement:any
}
//@ts-ignore
const Agreement: React.FC<AgreementProps> = ({AcceptAgreement}) => {
    const [initials, setInitials] = useState("")
    const [initError, setInitError] = useState<string>("")
    let [user, setUser] = useState<Account>(getUser())

    const DeclineLogout = () => {
        window.location.reload()
    }

    const submitNotice = (e:any) => {
        e.preventDefault();
        setInitError("")
        if(initials.length <= 0)
        {
            setInitError("The Initials Field Is Required.")
        }
        else {
            AcceptAgreement(initials) 
        }
        
    }

    return (
        <div className="body-content">
            <form onSubmit={submitNotice}>
            <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 30, fontWeight:"bold" }} gutterBottom className={"text-center w-min-screen"}>
                            USER AGREEMENT
                        </Typography>
                        <Typography sx={{ fontSize: 14, color:'red', textAlign:'center' }}>
                            In order to maintain the highest standards concerning information collection, stored data, and public safety, any authorized law enforcement officer or authorized civilian crime analyst [User] accessing or using ALPRs, ALPR data, ALPR databases or any database which incorporates ALPR data owned or administered by New Jersey Law Enforcement agencies , must complete this User Agreement.
                        </Typography>
                        <Typography sx={{ fontSize: 18, fontWeight:"bold", mt:2}}>
                            Use your initials to indicate you {user.Firstname} {user.Lastname} have read and fully understand each requirement listed below.
                        </Typography>
                        <ul style={{textAlign: 'left', listStyleType:"circle"}} className={"p-5"}>
                            <li>
                                I have read New Jersey Attorney General Law Enforcement Directive 2010-5 and I will act in accordance with this policy or its most current revision should this policy be updated.
                            </li>
                            <li>
                                I have completed 28 CFR Part 23 criminal intelligence training, such as US Department of Justice, Bureau of Justice Assistance, <a href="https://www.ncirc.gov/28CFR/" style={{ color: 'blue' }} target="_blank">28 CFR Part 23 site</a> or comparable training.
                            </li>
                            <li>
                                I will not share my account/login and will only use my own account/login to access ALPR data.
                            </li>
                            <li>
                                Download and dissemination of ALPR data must follow New Jersey Attorney General Law Enforcement Directive 2010-5.
                            </li>
                            <li>
                                I will only access, use, or share ALPR data for legitimate, specific, and documented law enforcement purposes, the same as I would for Criminal Justice Information Services (CJIS) data.
                            </li>
                            <li>
                                I will not use ALPR data to disclose personal identifying information (PII) about an individual, such as a name, address, or date of birth, unless there is a legitimate, specific, and documented law enforcement purpose for disclosing the PII to a law enforcement officer or civilian crime analyst.
                            </li>
                            <li>
                                I will not use, retain, or store ALPR data greater than five (5) years old from the time of capture.
                            </li>
                            <li>
                                BOLO lists, hot lists, or other compilation of license plates associated with specific vehicles or persons must have a legitimate, specific, and documented law enforcement purpose.
                            </li>
                        </ul>
                        <Typography sx={{ fontSize: 12, fontWeight:"bold"}}>
                            By signing this User Agreement, I verify and affirm: I have read and fully understand each of the above requirements.  I understand the sensitivity associated with ALPR data.  I will comply with New Jersey Attorney General Law Enforcement Directive 2010-5 and any other policy prescribed by my employer governing the use of ALPR data. Failure to comply may result in the termination of access, recommendation for disciplinary action, and/or criminal charges.
                        </Typography>
                        <TextField
                            margin="normal"
                            label={initError.length > 0 ? initError:"Initials"}
                            type="text"
                            onChange={e => setInitials(e.target.value)}
                            value = {initials}
                            color = {initError.length > 0 ? "error": "primary"}
                            focused = {initError.length > 0 ? true : undefined}
                        />
                    </CardContent>
                    <CardActions>
                        <Button style={{"margin":"5px"}} variant={"outlined"} color={"success"} type="submit" >
                            Accept
                        </Button>
                        <Button style={{ "margin": "5px" }} variant={"outlined"} color={"error"} type="button" onClick={DeclineLogout}>
                           Decline
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </div>
    )
}

export default Agreement