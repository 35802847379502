import React, { useState } from "react";
import PhoneSearchInfo from "../../components/tools/phone/_phoneSearch";
import SearchResults from "../../components/tools/phone/_searchResultsPhone";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunMultipleOccurrence } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow, isPhoneShow} from "../../services/auth.service";

function PhoneSearch() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any) => {
        let search_query: any = {
            "incidents": [
                {
                    "date": "",
                    "threshold": 0,
                    "radius": 0,
                    "location": {
                        "address": params.Address ? params.Address : "",
                        "city": params.City ? params.City : "",
                        "state": params.State ? params.State : "",
                        "zip": params.Zip ? params.Zip : "",
                        "latitude": 0,
                        "longitude": 0,
                        "addressType": ""
                    },
                    "showEdit": true,
                    "alprServerDB": "",
                    "alprServerName": ""
                }
            ],
            "minOccurrence": 0,
            "lookFor": params.MI ? params.MI : "",
            "plate": {
                "plateNumber": params.Phone ? params.Phone : "",
                "plateSt": params.FirstName ? params.FirstName : ""
            },
            "queryType": "P",
            "alprReason": params.LastName ? params.LastName : "",
            "alprCaseNumber": "",
            "alprReasonList": [
                {
                    //"key": "string",
                    //"value": "string"
                }
            ]
        }
        let qRes: any = await RunMultipleOccurrence(search_query)
        setDarTbl([])
        setDarTbl(qRes.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    return (
        <div id="ToolsArrest">
            {isPhoneShow()?
                <>
                    <PhoneSearchInfo SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default PhoneSearch;