import React, { useState, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup
} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import { formatDate } from "../../../services/formatDate.service";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface StatuteMappingSearchProps {
    SubmitSearch: any
}

const StatuteMappingSearch: React.FC<StatuteMappingSearchProps> = ({ SubmitSearch }) => {
    let today: any = new Date()
    let date:any = new Date()
    date.setDate(date.getDate() - 1)
    const [params, setParams] = React.useState({toDate: today, fromDate:date})
    const [dateType, setDateType] = React.useState<string>("Arrest Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [customDate, setCustomDate] = React.useState<boolean>(false);
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [time, setTime] = React.useState<string>("Yesterday");
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleSearch()
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, toDate: today, fromDate:date.toISOString().slice(0,10)})
        setDateType("Arrest Date")
        setTime("Yesterday")
        setAndOrType("AND")
        setClear(clear + 1)
    }

    const handleDropDownChange = (e:any) => {
       setTime(e.target.value)
       setCustomDate(false)
       date = new Date()

       switch(e.target.value)
       {
           case 'Yesterday':
               date.setDate(date.getDate() - 1)
               break
           case '3 Days':
                date.setDate(date.getDate() - 3)
               break
           case '1 Week':
                date.setDate(date.getDate() - 7)
               break
           case '2 Weeks':
                date.setDate(date.getDate() - 14)
               break
           case '1 Month':
                date.setMonth(date.getMonth() - 1)
               break
           case '3 Months':
                date.setMonth(date.getMonth() - 3)
               break
           case '6 Months':
                date.setMonth(date.getMonth() - 6)
               break
           case '12 Months':
                date.setFullYear(date.getFullYear() - 1)
               break
           case 'Custom Range':
                setCustomDate(true)
               break
        
       }

       setParams({ ...params, fromDate: date })
    }

    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{ mr: 2 }}
                >
                    <ToggleButton value="Offense Date" sx={{ pr: 2, pl: 2 }} >Offense Date</ToggleButton>
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                </ToggleButtonGroup>
                <div className="mr-5">
                    <FormControl fullWidth>
                    <InputLabel id="timeFrame">Time Frame</InputLabel>
                        <Select
                        labelId="timeFrame"
                        value={time}
                        label="Time Frame"
                        onChange={handleDropDownChange}
                        >
                        <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
                        <MenuItem value={"3 Days"}>3 Days</MenuItem>
                        <MenuItem value={"1 Week"}>1 Week</MenuItem>
                        <MenuItem value={"2 Weeks"}>2 Weeks</MenuItem>
                        <MenuItem value={"1 Month"}>1 Month</MenuItem>
                        <MenuItem value={"3 Months"}>3 Months</MenuItem>
                        <MenuItem value={"6 Months"}>6 Months</MenuItem>
                        <MenuItem value={"12 Months"}>12 Months</MenuItem>
                        <MenuItem value={"Custom Range"}>Custom Range</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {customDate ? 
                < > 
                    <div >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From Date"
                                value={params.fromDate}
                                onChange={(e:any) => {
                                    setParams({...params, fromDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To Date"
                                    value={params.toDate}
                                    onChange={(e:any) => {
                                        setParams({...params, toDate: e})
                                    }}
                                    renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                />
                        </LocalizationProvider>
                    </div>
                </>
                :<></>}
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"statute"} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <label><b>{dateType} {formatDate(params.fromDate.toLocaleString())} - {formatDate(params.toDate.toLocaleString())}</b></label>
        </div>
    );
}

export default StatuteMappingSearch;