import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ArrestInfo} from "../../../interfaces/ArrestInfo.interface";
import {TablePagination, CircularProgress, Button} from "@mui/material";
import { Link } from "react-router-dom";
import icon_arrest from "../../../assets/Images/ai1.png"
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {ref} from "pdfkit";
import RTCC from "../../../assets/Images/RTCC.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import {JAOS} from "../../../services/JAOS.service";

interface PanelReferralsProps {
    person: PersonInfo,
    Referrals: ArrestInfo[],
    isLoading:boolean
}


const PanelReferral: React.FC<PanelReferralsProps> = ({person, Referrals, isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...Referrals]
        for (let d of tempP) {
            if (d?.SubmitDate) {
                d.SubmitDate = new Date(d.SubmitDate)
            }
            if(d?.Adopted)
            {
                d.Adopted = (d?.Adopted == 'Y' ? "Yes" : "No")
            }
            if(d?.Custody_Status)
            {
                d.Custody_Status = (d?.Custody_Status == 'Y' ? "Yes" : "No")
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Referrals])

    const handleReferralView = (p:any) => {
        let temp = {...p}
        if(temp?.Adopted)
        {
            temp.Adopted = (temp?.Adopted == "Yes" ? 'Y' : 'N')
        }
        if(temp?.Custody_Status)
        {
            temp.Custody_Status = (temp?.Custody_Status == "Yes" ? 'Y' : 'N')
        }
        let l = new JAOS()
        let hashID = l.objToStack([temp])
        window.open('/detail/ReferralDetail?Hash='+hashID,'_blank')
    }
    
    return(
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">                    
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Referral <img src={ReferralIcon} alt={''} style={{ display: 'inline',height:32, width:32, }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {Referrals?.length}</span>
                           </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='ID'  headerText='Referral ID' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='SubmitDate'   format={{ type: 'date', format: 'MM/dd/yyyy' }} width={160} minWidth={160} maxWidth={160} headerText='Submit Date' customAttributes={{ class: ['e-attr'] }} />
                                    <ColumnDirective field='Status'  headerText='Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='Adopted'  headerText='Adopted' customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='Custody_Status'  headerText='Custody Status' customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='Custody_County'  headerText='Custody County' customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective field='Prosecution_Outcome'  headerText='Prosecution Outcome' customAttributes={{ class: ['e-attr'] }}/>
                                    <ColumnDirective template={(item : any ) => (<Button onClick={ (e:any) => {handleReferralView(item)} } >View</Button>)}  headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelReferral;