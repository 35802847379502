import React from 'react'

import {
    Button,
    Tooltip
} from "@mui/material";

import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import OpencaseIcon from "../../../assets/Images/Opencase.png";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

interface SearchResultsProps {
    CodisCase: any[],
}

const CodisCaseDisplay: React.FC<SearchResultsProps> = ({ CodisCase }) => {
    const [typedCase,setTypedCases] = React.useState<any>({})
    let grid: Grid | null;

    const handleDataTyping = () => {
        if (CodisCase) {
            let tempP: any = [...CodisCase]

            for (let p of tempP) {
                if (p.OffenseDate) {
                    p.OffenseDate = new Date(p.OffenseDate)
                }
            }
            setTypedCases(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [CodisCase])

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            grid.excelExport();
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
        {field: 'OffenseDate', direction: 'Descending' }
    ] };

    return (
        <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                CODIS Case Information <img className={"inline ml-2"} src={OpencaseIcon} alt="ai" />
                {(CodisCase && CodisCase.length > 0) ? <h4 className={"font-bold pl-5"}> {CodisCase.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
            </span>
            {CodisCase && CodisCase?.length > 0 ? <>    
            <GridComponent
                dataSource={typedCase}
                allowPaging={true}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                sortSettings={sortSettings}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['Search']}
                toolbarClick={GridToolsClick}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}
               // dataBound={dataBound}
                rowSelecting={(event: any) => { console.log(event) }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='OffenseDate' headerText='Offense Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Offense' headerText='Offense' customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='LabSpecimenID' headerText='Lab Specimen ID' customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CODISAgency' headerText='Agency'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='AgencyCaseNo' headerText='Case #'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CaseType' headerText='Case Type'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='Profile' headerText='Profile'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CODISCounty' headerText='County'  customAttributes={{ class: ['e-attr'] }} />
                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
            </GridComponent>
            </>
            : <></>}
        </div>

    )
};

export default CodisCaseDisplay


////import React from 'react'

////import {
////    Button, Collapse, IconButton,
////    Paper,
////    Table,
////    TableBody,
////    TableCell,
////    TableContainer,
////    TableHead,
////    TablePagination,
////    TableRow, Tooltip
////} from "@mui/material";
////import { CodisCase } from "../../../interfaces/Codis.interface";
////import PersonIcon from "../../../assets/Images/Person.png";
////import OpencaseIcon from "../../../assets/Images/Opencase.png";
////import {PersonInfo} from "../../../interfaces/getDarInfo_interface";

////interface SearchResultsProps {
////    CodisCase: CodisCase[]
////}

////const SearchResultsCase: React.FC<SearchResultsProps> = ({ CodisCase }) => {
////    //For Pagination
////    const [page, setPage] = React.useState(0);
////    const [rowsPerPage, setRowsPerPage] = React.useState(10);

////    const handleChangePage = (event: unknown, newPage: number) => { setPage(newPage); };

////    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
////        setRowsPerPage(+event.target.value);
////        setPage(0);
////    };

////    return (
////        <div className={"pl-10 pr-10 mt-5"}>            
////            CODIS Case Information <img src={OpencaseIcon} alt="ai" />  {(CodisCase && CodisCase.length > 0) ? <h4 className={"font-bold pl-5"}> {CodisCase.length} Records Found</h4> : <></>}
////            <Paper sx={{ width: '100%' }}>
////                <TableContainer component={Paper}>
////                    <Table aria-label="collapsible table" size="small" style={{ "border": "1px solid black" }}>
////                        <TableHead className={"bg-blue-100"}>
////                            <TableRow>                                
////                                <TableCell style={{ "border": "1px solid gray" }}>Offense Date</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>Offense</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>Lab Specimen ID</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>Agency</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>Case #</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>Case Type</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>Profile</TableCell>
////                                <TableCell style={{ "border": "1px solid gray" }}>County</TableCell>
////                            </TableRow>
////                        </TableHead>
////                        <TableBody>
////                            {
////                                CodisCase.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
////                                    .map((CodisCase, idx) => (
////                                        <CodisCaseDisplayRow index={idx} codisCase={CodisCase} />
////                                    ))
////                            }
////                        </TableBody>
////                    </Table>
////                </TableContainer>
////                {(CodisCase.length > 10) ?
////                    <TablePagination
////                        rowsPerPageOptions={[10, 25, 50, 100]}
////                        component="div"
////                        count={CodisCase.length}
////                        rowsPerPage={rowsPerPage}
////                        page={page}
////                        onPageChange={handleChangePage}
////                        onRowsPerPageChange={handleChangeRowsPerPage}
////                        className={"bg-blue-100"}
////                    />
////                    : <></>}
////            </Paper>
////        </div>
////    )
////};

////interface CodisCaseDisplayRow {
////    index: number
////    codisCase: CodisCase
////}

////const CodisCaseDisplayRow: React.FC<CodisCaseDisplayRow> = ({ index, codisCase }) => {
////    const [open, setOpen] = React.useState(false);
////    return (
////        <React.Fragment>
////            <TableRow key={"pdr" + index} onClick={() => setOpen(!open)}>                
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.OffenseDate}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.Offense}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.LabSpecimenID}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.CODISAgency}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.AgencyCaseNo}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.CaseType}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.Profile}</TableCell>
////                <TableCell style={{ "border": "1px solid gray" }}>{codisCase.CODISCounty}</TableCell>
////            </TableRow>            
////        </React.Fragment>
////    )
////};

////export default SearchResultsCase
