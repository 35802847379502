import React, {useState, useEffect} from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    FormControl,
    InputLabel,
    Select, MenuItem, ButtonGroup
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {ALPRIncidents, ALPRMultipleOccurenceQuery, ALPRPlate} from "../../../interfaces/getDarInfo_interface";
import {NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import GeoCode_Position from "../../shared/GeoCodes/_GeoCode_GetPos";
import {JAOS} from "../../../services/JAOS.service";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import MapIcon from "@mui/icons-material/Map";
import ErrorIcon from "@mui/icons-material/Error";
import {DateToString} from "../../../services/formatDate.service";
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import { getDropDownALPRCamera } from "../../../services/getDropdown.service";

interface alprMultipleOccurrenceSearchProps {
    SubmitSearch: any
    data?: any | null
}


const AlprMultipleOccurrenceSearch: React.FC<alprMultipleOccurrenceSearchProps> = ({SubmitSearch, data=null}) => {
    let today:any = new Date()
    const [ALPRListItems, setALPRListItems] = React.useState<ALPRIncidents[]>([])
    const[ALPRQuery, setALPRQuery] = React.useState<ALPRMultipleOccurenceQuery>({minOccurrence:1})
    const[geoCodeIDX, setGeoCodeIDX] = React.useState<number>(0)
    const [showGeoCode, setShowGeoCode] = React.useState<boolean>(false)
    const [showAlprMap, setShowAlprMap] = React.useState<boolean>(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    const [clear, setClear] = React.useState<number>(0);
    const [loadedData, setLoadedData] = React.useState<boolean>(false)
    const [alprReasonList,setALPRReasonList] = React.useState<string[]>(["Arson","Assault","Audit","BOLO Post Scan Query",
                                                                                    "Burglary", "Confidential Investigation", "Crime Scene Query",
                                                                                    "DB Investigation", "Delayed Felony/Stolen MV's",
                                                                                    "Homicide", "MV Violation", "Robbery", "Sexual Crimes", 
                                                                                    "Suspicious Incident", "Test", "Theft", "Training", 
                                                                                    "Trend Analysis", "Wanted Person Search"])
    
    const [reasonError, setReasonError] = React.useState<string>("")
    const [mapVersion, setMapVersion] = useState<string>("alprCamera")


    useEffect(() => {
        if(data !== null)
            handleDataMake()
        else{
            addALPRItem()
        }
    }, [data]);
    
    const handleDataMake = () => {
        let l = new JAOS()
        let alprList = l.objFromStack(data)

        let t:ALPRIncidents[] = [...ALPRListItems]
        
        for (let alpr of alprList)
        {
            t.push({
                alprServerDB: "",
                alprServerName: "",
                date: new Date(alpr.DateTimeIN.toLocaleString()).toLocaleString(),
                location: {
                    address:alpr.Address ,
                    city:alpr.City,
                    state:alpr.State,
                    zip:alpr.Zip,
                    latitude:alpr.Incident_Lat,
                    longitude:alpr.Incident_Lon,
                    addressType:""
                },
                radius: 1,
                showEdit: false,
                threshold: 10
            })
        }
        
        setALPRListItems(t)
    }
    
    const handleClear = () => {
        setALPRListItems([{
            alprServerDB: "",
            alprServerName: "",
            date: new Date().toLocaleString(),
            location: {
                address:"",
                city:"",
                state:"",
                zip:"",
                latitude:0,
                longitude:0,
                addressType:""
            },
            radius: 1,
            showEdit: false,
            threshold: 5
        }])
        setALPRQuery({minOccurrence:1,alprReason:"",alprCaseNumber:"", plate:{plateNumber:"",plateSt:""}})
        setReasonError("")
        setClear(clear+1)
    }
    
    const handleValidation = () => {
        setReasonError("")
        let error = ""
        let val = true
        let p = ALPRQuery
        if(p) {
           /* if (!p.alprReason || p.alprReason?.length === 0) {
                error += "Please Select a Reason.\n" 
                val = false
            }*/
            if (!p.alprCaseNumber || p.alprCaseNumber?.length === 0) {
                error += "Please Enter a Case Number.\n"
                val = false
            }
        }
        setReasonError(error)
        return val
    }
    
    const handleSearch = () => {
        if(handleValidation()) {
            let SearchQuery: ALPRMultipleOccurenceQuery = {...ALPRQuery}
            let tempI = [...ALPRListItems]
            SearchQuery.incidents = [...tempI]
            SearchQuery.queryType = 'M'
            SearchQuery.UTCDate = false
            SubmitSearch(SearchQuery)
        }
    }

    const addALPRItem = () => {
        let t:ALPRIncidents[] = [...ALPRListItems]
        t.push({
            alprServerDB: "",
            alprServerName: "",
            date: new Date().toLocaleString(),
            location: {
                address:"",
                city:"",
                state:"",
                zip:"",
                latitude:0,
                longitude:0,
                addressType:""
            },
            radius: 1,
            showEdit: false,
            threshold: 5
        })
        setALPRListItems(t)
    }

    const openGeoCode = async () => {
        setMapVersion("alprCamera")
        setAllPins([])
        let pins: any[] = []

        let data = await getDropDownALPRCamera({ ServerList: [] })  //getDropDownALPRCamera returns 2 sets of data Distinct CameraList, Camera with Lat&Long (used in GeoCode)

        for (let p of data?.AnyTable)
        {
            if (p.Latitude && p.Longitude) {
                pins.push({
                    lat: p.Latitude,
                    lng: p.Longitude,
                    date: p.DateTimeIN,
                    alprSource: p.Source,
                    name: p.Name,
                    status: p.Status,
                    tool: "alprCamera"
                })
            }
        }
        setAllPins(pins);
        setShowAlprMap(true)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            {(showAlprMap) ? <div><GeoCode HandleGeoCodeClose={() => { setShowAlprMap(!showAlprMap) }} show={showAlprMap} pins={allPins} mapVersion={mapVersion} /></div> : <></>}
            {showGeoCode && ALPRListItems?.length > 0 ?
                <GeoCode_Position
                    SetLatLng={(lat:any,lng:any)=>{
                        let t: ALPRIncidents[] = [...ALPRListItems]
                        t[geoCodeIDX].location.latitude = lat
                        t[geoCodeIDX].location.longitude = lng
                        setALPRListItems(t)
                    }
                    }
                    HandleGeoCodeClose={()=>{setShowGeoCode(!showGeoCode)}}
                    show={showGeoCode}
                    pin={{lat:ALPRListItems[geoCodeIDX].location.latitude, lng:ALPRListItems[geoCodeIDX].location.longitude, address: ALPRListItems[geoCodeIDX].location.address, city:ALPRListItems[geoCodeIDX].location.city, state:ALPRListItems[geoCodeIDX].location.state, zip:ALPRListItems[geoCodeIDX].location.zip}}
                /> :<></>}
           
            
                <table style={{width: '100%', border:'1px solid'}}>
                    <thead style={{backgroundColor:'lightskyblue', border:'1px solid'}}>
                        <tr>
                            <th>#</th>
                            <th>Incident Date</th>
                            <th>Threshold (in Minutes)</th>
                            <th>Incident Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Map</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>Radius (in Miles)</th>
                            <th><DeleteIcon /></th>
                        </tr>
                    </thead>
                    <tbody>
                    {ALPRListItems.map((alpr:ALPRIncidents, idx:number)=> {
                        return(
                            <>
                                <tr style={{border: '1px solid'}}>
                                    <td style={{border: '1px solid lightgray'}}>{idx + 1}</td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <DateTimePickerComponent                                            
                                            value={new Date(alpr.date)}
                                            strictMode={true}
                                            onChange={
                                                (e: { target: { value: any; }; }) => {
                                                    let t: ALPRIncidents[] = [...ALPRListItems]
                                                    t[idx].date = (e.target.value).toLocaleString();
                                                    setALPRListItems(t)
                                                }
                                            }
                                            format='MM/dd/yyyy HH:mm'                                            
                                            placeholder='Enter date'
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <NumericTextBoxComponent
                                            value={alpr.threshold}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].threshold = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="Threshold"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <TextBoxComponent
                                            value={alpr.location.address}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].location.address = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="Address"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <TextBoxComponent
                                            value={alpr.location.city}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].location.city = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="City"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <TextBoxComponent
                                            value={alpr.location.state}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].location.state = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="State"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <TextBoxComponent
                                            value={alpr.location.zip}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].location.zip = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="Zip"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <Button 
                                            variant="outlined" 
                                            color='inherit'
                                            size={"large"}
                                            onClick={()=>{
                                                setGeoCodeIDX(idx)
                                                setShowGeoCode(!showGeoCode)
                                            }}
                                        >
                                            <span><MapIcon color="success" style={{fontSize:"20"}} className="mr-1"/>GeoCode</span>
                                        </Button>
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <NumericTextBoxComponent
                                            value={alpr.location.latitude}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].location.latitude = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="Latitude"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <NumericTextBoxComponent
                                            value={alpr.location.longitude}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].location.longitude = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="Longitude"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <NumericTextBoxComponent
                                            value={alpr.radius}
                                            onChange={(e: any) => {
                                                let t: ALPRIncidents[] = [...ALPRListItems]
                                                t[idx].radius = e.value
                                                setALPRListItems(t)
                                            }
                                            }
                                            placeholder="Radius"
                                            floatLabelType="Auto"
                                        />
                                    </td>
                                    <td style={{border: '1px solid lightgray'}}>
                                        <Button onClick={() => {
                                            let t: ALPRIncidents[] = [...ALPRListItems]
                                            t.splice(idx, 1)
                                            setALPRListItems(t)
                                        }}>
                                            <span className={"text-red-500"}><DeleteIcon /></span>
                                        </Button>
                                    </td>
                                </tr>
                            </>
                            
                        )
                        
                    })}
                    </tbody>
                </table>

            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2" >
                <div className ="mt-2"><Button variant="outlined" color="inherit" size={"large"} type="submit" onClick={addALPRItem}>ADD INCIDENT</Button></div>
                <DropDownListComponent id="ddlelement" value={ALPRQuery?.alprReason} dataSource={alprReasonList} onChange={(e:any) => setALPRQuery({...ALPRQuery, alprReason: e.target.value})} placeholder="Reason" />
                <TextBoxComponent
                    value={ALPRQuery?.alprCaseNumber}
                    onChange={(e:any)=>{
                        setALPRQuery({...ALPRQuery, alprCaseNumber:e.value})
                    }}
                    placeholder="Case Number"
                    floatLabelType="Auto"
                />
                <TextBoxComponent
                    value={ALPRQuery?.plate?.plateNumber}
                    onChange={(e:any)=>{
                        setALPRQuery({...ALPRQuery, plate:{plateNumber:e.target.value,plateSt:""}})
                    }}
                    placeholder="Plate"
                    floatLabelType="Auto"
                />

                <NumericTextBoxComponent
                    value={ALPRQuery?.minOccurrence}
                    onChange={(e:any)=>{
                        setALPRQuery({...ALPRQuery, minOccurrence:e.value})
                    }}
                    placeholder="Minimum Occurrence"
                    floatLabelType="Auto"
                />
                <ButtonGroup size="medium" color="inherit" >
                    <Button variant="outlined" onClick={() => { openGeoCode() }} title="Maps ALPR Cameras">
                        <MapIcon color="success" className="mr-2" /><b>ALPR Camera</b>
                    </Button>
                </ButtonGroup>
            </div>

            
            <div style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <span style={{ "display": "flex", "justifyContent": "end" }} className="col-span-4">{reasonError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{reasonError}</span> : <></>}</span>

        </div>
    );
}

export default AlprMultipleOccurrenceSearch;