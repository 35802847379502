import React, { useState } from "react";
import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import { RunUserList, RunDepartmentList }  from "../../services/account.service";
import Users from "../../components/Admin/Users";
import DepartmentsList from "../../components/Admin/Departments";
import SearchResults from "../../components/Admin/_searchResultsDepartment";
import Departments from "../../components/Admin/Departments";

function classNames(...classes:string[]) {
    return classes.filter(Boolean).join(' ')
}

function ManageDept() {
    let [dptTbl, setDptTbl] = useState<any[]>([])
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [storedParams, setStoredParams] = useState<any>({})
    let initialRun = false

    const SubmitSearch = async (params: any) => {
        if(!initialRun)
        {
            initialRun = true
            UserList()
        }

        if(!params)
        {
            params = storedParams
        }
        else
        {
            setStoredParams(params)
        }
        
        let search_query: any = {
            "PlateNumber":"",
            "PlateSt":"",
            "FName":"",
            "MName":"",
            "LName":"",
            "DOB":"",
            "SEX":"",
            "City":"",
            "DLNo":"",
            "DLSt": params.Status ? params.Status :"",
            "VIN":"",
            "FBI":"",
            "SSN": params.numUsers ? params.numUsers :"",
            "DTOFARREST":"",
            "STATUTE":"",
            "STATUTEDESCRIPTION":"",
            "PoliceStopIPAddress":"",
            "PoliceStopReqID": "",
            "PoliceStopDeviceORI": params.DeptName ? params.DeptName :"",
            "DeptAccountType": params.AccountType ? params.AccountType :"",
            "DeptGroup": params.DeptGroup ? params.DeptGroup :""
        }
        let qResD: any = await RunDepartmentList(search_query)
        setDptTbl([])
        setDptTbl(qResD?.AnyData)

        let qRes: any = await RunUserList(search_query)
        setDarTbl([])
        setDarTbl(qRes?.AnyData)
    }

    const UserList = async () => {
        let search_query: any = {
            "PlateNumber":"",
            "PlateSt":"",
            "FName":"",
            "MName":"",
            "LName":"",
            "DOB":"",
            "SEX":"",
            "City":"",
            "DLNo":"",
            "DLSt": "",
            "VIN":"",
            "FBI":"",
            "SSN": "",
            "DTOFARREST":"",
            "STATUTE":"",
            "STATUTEDESCRIPTION":"",
            "PoliceStopIPAddress":"",
            "PoliceStopReqID": "",
            "PoliceStopDeviceORI": "",
            "DeptAccountType": "",
            "DeptGroup":""
        }

        let qRes: any = await RunUserList(search_query)
        setDarTbl([])
        setDarTbl(qRes?.AnyData)
    }

    return (
        <div className="w-full">
            <Departments SubmitSearch={SubmitSearch}/>
            <div className = "pl-10 mt-3">
                <SearchResults Data = {dptTbl} UserList = {darTbl} GetUserList = {UserList} GetDeptList = {SubmitSearch}/>
            </div>
        </div>
    );
}

export default ManageDept;