import React, {useState} from "react";
import ReactECharts from "echarts-for-react";

interface ShootingsDashboardProps {
    location:string,
    dbData:any,
}


const ShootingAgeDashboardGraph: React.FC<ShootingsDashboardProps> = ({location, dbData}) => {
    var option;

    const handleSeries = () => {
        let alive:any = []
        let dead:any = []
        let series:any = []

        if(dbData.AgeData)
        {
            for(let age of dbData.AgeData)
            {
                if(age.Alive === 'Alive')
                {
                    alive.push(age.Age0t17)
                    alive.push(age.Age18t24)
                    alive.push(age.Age25t29)
                    alive.push(age.Age30t39)
                    alive.push(age.Age40t49)
                    alive.push(age.Age50UP)
                }
                else{
                    dead.push(age.Age0t17)
                    dead.push(age.Age18t24)
                    dead.push(age.Age25t29)
                    dead.push(age.Age30t39)
                    dead.push(age.Age40t49)
                    dead.push(age.Age50UP)
                }
                
            }
            series.push({
                name: 'Non-Fatal',
                type: 'bar',
                stack: 'total',
                color:'#1e3a8a',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: alive
            },)
            series.push({
                name: 'Fatal',
                type: 'bar',
                stack: 'total',
                color:'#8E1600',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: dead
            },)
        }
        return series
    }
    
    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        height:'200',
        width:'425',
        toolbox: {
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['0-17', '18-24', '25-29', '30-39', '40-49', '50+']
        },
        yAxis: {
            type: 'value'
        },
        series: handleSeries()
    };

    return (
        <div className = "border-gray-900 border-2 h-72">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center h-8 text-lg">Shooting Victims By Age</div>
            {dbData && dbData.AgeData?.length !== 0 ?
                <div className = "border-gray-900 border-t-2">
                    <ReactECharts option={option} />
                </div>
                :
                <div>No Data</div>
            }
        </div>
    );
}

export default ShootingAgeDashboardGraph;