import React, {memo, useEffect, useState} from "react";
import {formatDate} from "../../services/formatDate.service";
import noImage from "../../assets/Images/cbimage.png";
import {Alert, AlertColor, Button, ButtonGroup, Snackbar, TextField} from "@mui/material";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import {GRAILReferral} from "../../interfaces/GRAILReferral.interface";
import {JAOS} from "../../services/JAOS.service";
import {RunSetGRAILReferral} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isNCICShow} from "../../services/auth.service";

interface DetailProps {
    Persons: any[]
    Additions?: any[]
}

const GVRTFOutputForm: React.FC<DetailProps> = ({Persons, Additions}) => {
    const [Referrals, setReferrals] = React.useState<any[]>([])
    const [pos, setPos] = React.useState<number>(0)
    const [b1color, setB1Color] = React.useState<string>('#FFFFFF')
    const [b2color, setB2Color] = React.useState<string>('#FFFFFF')
    const [b3color, setB3Color] = React.useState<string>('#FFFFFF')
    const [b4color, setB4Color] = React.useState<string>('#FFFFFF')

    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const [count, setCount] = React.useState<number>(0)
    
    const handleDataTyping = () =>
    {
        if (Persons) {
            setPos(0)
            let tempP: any = [...Persons]
            for (var i of tempP)
            {
                if(i.DOB)
                {
                    i.DOB = new Date(i.DOB)
                }
            }
            setReferrals(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])
    
    /*useEffect(() => {
        function checkReferralData() {
        function checkReferralData() {
            const item = sessionStorage.getItem("referral")
            if (item) {
                setReferrals([])
                //setReferrals(JSON.parse(item))
            }
            sessionStorage.removeItem("referral")
        }
        window.addEventListener('storage', checkReferralData)

        return () => {
            window.removeEventListener('storage', checkReferralData)
        };
    }, []);*/
    
    const handleEdit = () => {
        let GRAIL:GRAILReferral =
            {
                "PersonID": Referrals[pos]?.PersonID,
                "ID": Referrals[pos]?.ID,
                "SubmitDate": Referrals[pos]?.SubmitDate,
                "FIRSTNAME": Referrals[pos]?.FName,
                "MI": Referrals[pos]?.MName,
                "LASTNAME": Referrals[pos]?.LName,
                "DOB": Referrals[pos]?.DOB,
                "SSN": null,
                "FBI": Referrals[pos]?.FBI,
                "eCDR": Referrals[pos]?.eCDR,
                "SBINo": Referrals[pos]?.SBINo,
                "SBIState": "NJ",
                "DLNo": null,
                "DLState": null,
                "City": Referrals[pos]?.City,
                "Resident_County": Referrals[pos]?.Resident_County,
                "Custody_Status": Referrals[pos]?.Custody_Status,
                "Custody_County": Referrals[pos]?.Custody_County,
                "Total_Arrests": parseInt(Referrals[pos]?.Total_Arrests),
                "Gun_Arrests": parseInt(Referrals[pos]?.Gun_Arrests),
                "Felony_Convictions": Referrals[pos]?.Felony_Convictions,
                "Shooting_Victim": Referrals[pos]?.Shooting_Victim,
                "Shooting_Suspect": Referrals[pos]?.Shooting_Suspect,
                "Gang": Referrals[pos]?.Gang,
                "isShooting": Referrals[pos]?.isShooting,
                "isParole": Referrals[pos]?.isParole,
                "isCODIS": Referrals[pos]?.isCODIS,
                "isNIBIN": Referrals[pos]?.isNIBIN,
                "isCaseExplorer": Referrals[pos]?.isCaseExplorer,
                "isInfoShare": Referrals[pos]?.isInfoShare,
                "isGang": Referrals[pos]?.isGang,
                "Arrest": null,
                "Shooting": Referrals[pos]?.Shooting,
                "Parole": Referrals[pos]?.Parole,
                "CODIS": Referrals[pos]?.CODIS,
                "NIBIN": Referrals[pos]?.NIBIN,
                "CaseExplorer": Referrals[pos]?.CaseExplorer,
                "InfoShare": Referrals[pos]?.InfoShare,
                "Pending_Charges_Count": Referrals[pos]?.Pending_Charges_Count,
                "Pending_GunCharge_Count": Referrals[pos]?.Pending_GunCharge_County,
                "Pending_Charges": null,
                "Pending_GunCharge": null,
                "Analytic_Notes": Referrals[pos]?.Analytic_Notes,
                "Adopted": Referrals[pos]?.Adopted,
                "Prosecutor": Referrals[pos]?.Prosecutor,
                "Judge": Referrals[pos]?.Judge,
                "Prosecution_Outcome": Referrals[pos]?.Prosecution_Outcome,
                "Referral_County": Referrals[pos]?.Referral_County,
                "Comments": Referrals[pos]?.Comments,
                "RTCC": Referrals[pos]?.RTCC,
                "Status": Referrals[pos]?.Status,
                "EditStatus": "Edit",
                "CreateUID": null,
                "CreateDate": null,
                "UpdUID":  null,
                "UpdDate": null,
                "Photos": [{"Image": (Referrals[pos]?.Pic!= null && Referrals[pos]?.Pic.length > 0) ? ("data:image/jpeg;base64," + Referrals[pos]?.Pic) : ""}]
            }
        let l = new JAOS()
        let hashID = l.objToStack(GRAIL)
        window.open('/GRAIL?Hash='+hashID,'_blank')
    }

    const handleDelete = async () => {
        try {
            if (window.confirm("Remove This Specific Record Permanently? Record ID = " + Referrals[pos]?.ID)) {
                let GRAIL: GRAILReferral =
                    {
                        "PersonID": Referrals[pos]?.PersonID, 
                        "ID": Referrals[pos]?.ID,
                        "SubmitDate": Referrals[pos]?.SubmitDate,
                        "FIRSTNAME": Referrals[pos]?.FName,
                        "MI": Referrals[pos]?.MName,
                        "LASTNAME": Referrals[pos]?.LName,
                        "DOB": Referrals[pos]?.DOB,
                        "SSN": null,
                        "FBI": Referrals[pos]?.FBI,
                        "eCDR": Referrals[pos]?.eCDR,
                        "SBINo": Referrals[pos]?.SBINo,
                        "SBIState": "NJ",
                        "DLNo": null,
                        "DLState": null,
                        "City": Referrals[pos]?.City,
                        "Resident_County": Referrals[pos]?.Resident_County,
                        "Custody_Status": Referrals[pos]?.Custody_Status,
                        "Custody_County": Referrals[pos]?.Custody_County,
                        "Total_Arrests": parseInt(Referrals[pos]?.Total_Arrests),
                        "Gun_Arrests": parseInt(Referrals[pos]?.Gun_Arrests),
                        "Felony_Convictions": Referrals[pos]?.Felony_Convictions,
                        "Shooting_Victim": Referrals[pos]?.Shooting_Victim,
                        "Shooting_Suspect": Referrals[pos]?.Shooting_Suspect,
                        "Gang": Referrals[pos]?.Gang,
                        "isShooting": Referrals[pos]?.isShooting,
                        "isParole": Referrals[pos]?.isParole,
                        "isCODIS": Referrals[pos]?.isCODIS,
                        "isNIBIN": Referrals[pos]?.isNIBIN,
                        "isCaseExplorer": Referrals[pos]?.isCaseExplorer,
                        "isInfoShare": Referrals[pos]?.isInfoShare,
                        "isGang": Referrals[pos]?.isGang,
                        "Arrest": null,
                        "Shooting": Referrals[pos]?.Shooting,
                        "Parole": Referrals[pos]?.Parole,
                        "CODIS": Referrals[pos]?.CODIS,
                        "NIBIN": Referrals[pos]?.NIBIN,
                        "CaseExplorer": Referrals[pos]?.CaseExplorer,
                        "InfoShare": Referrals[pos]?.InfoShare,
                        "Pending_Charges_Count": Referrals[pos]?.Pending_Charges_Count,
                        "Pending_GunCharge_Count": Referrals[pos]?.Pending_GunCharge_County,
                        "Pending_Charges": null,
                        "Pending_GunCharge": null,
                        "Analytic_Notes": Referrals[pos]?.Analytic_Notes,
                        "Adopted": Referrals[pos]?.Adopted,
                        "Prosecutor": Referrals[pos]?.Prosecutor,
                        "Judge": Referrals[pos]?.Judge,
                        "Prosecution_Outcome": Referrals[pos]?.Prosecution_Outcome,
                        "Referral_County": Referrals[pos]?.Referral_County,
                        "Comments": Referrals[pos]?.Comments,
                        "RTCC": Referrals[pos]?.RTCC,
                        "Status": Referrals[pos]?.Status,
                        "EditStatus": "Delete",
                        "CreateUID": null,
                        "CreateDate": null,
                        "UpdUID": null,
                        "UpdDate": null,
                        "Photos": [{"Image": (Referrals[pos]?.Pic != null && Referrals[pos]?.Pic.length > 0) ? ("data:image/jpeg;base64," + Referrals[pos]?.Pic) : ""}]
                    }
                let ID = await RunSetGRAILReferral(GRAIL)
                if( ID )
                {
                    setAlertType("success")
                    setAlertText(("Referral: '" + Referrals[pos]?.ID + "'" + " has been Deleted. Changes will reflect after Search click."))
                    setAlertOpen(true)
                }
                else {
                    setAlertType("error")
                    setAlertText(("An Error has occured while attempting to Delete that record. '" + Referrals[pos]?.ID + "'"))
                    setAlertOpen(true)
                }
            }
        }
        catch(e)
        {
            console.log(e)
        }
    }

    const handleFirstRecord = () => {
        setPos(0)
        setCount(count+1)
    }

    const handlePriorRecord = () => {
        if(pos != 0)
        {
            setPos(pos-1)
            setCount(count+1)
        }
    }

    const handleNextRecord = () => {
        if(pos < Referrals.length-1 && (pos + 1 < Referrals.length))
        {
            setPos(pos+1)
            setCount(count+1)
        }
    }

    const handleLastRecord = () => {
        setPos(Referrals.length-1)
        setCount(count+1)
    }
    
    
    return (
        (Referrals && Referrals.length > 0 ?
        <div>
            <div className="">
                <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <div className = "w-10/12 m-auto border-2 border-gray-400 mb-5 bg-black">
                    <h1 className="pb-2 pt-2 font-bold text-center text-4xl text-white" style={{backgroundColor:"#1E3A8A"}}>{Referrals[pos]?.FName} {Referrals[pos]?.MName} {Referrals[pos]?.LName}</h1>
                    <div className = " mt-1 text-center text-sm text-red-500">UNCLASSIFIED//LAW ENFORCEMENT SENSITIVE</div>
                    <div className = "grid grid-cols-3">
                        <div className ="">
                            <div className = "ml-2">
                                    <span className="align-middle flex inline text-white">
                                        {/*Record ID:
                                        <div className = "ml-2 w-14 text-gray-300 border-gray-300 border text-center">{Referrals[pos]?.ID}</div>*/}
                                        <div className = "ml-2">{pos+1} of {Referrals?.length}</div>
                                    </span>
                            </div>
                            <div className = "mt-8 ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        Date Submitted
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{formatDate(Referrals[pos]?.SubmitDate)}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        DOB
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{ Referrals[pos] && Referrals[pos]?.DOB ? ((Referrals[pos]?.DOB.getMonth() + 1).toString()).padStart(2, '0') + '/' + Referrals[pos]?.DOB.getDate().toString().padStart(2, '0') + '/'+ Referrals[pos]?.DOB.getFullYear()  : ""}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        SBI
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.SBINo}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        FBI
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.FBI}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        eCDR
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.eCDR}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        City
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.City}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        County
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Resident_County}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        Custody Status
                                        <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">{(Referrals[pos]?.Custody_Status?.length > 0 ? Referrals[pos]?.Custody_Status == 'N' ? "No" : "Yes" : "")} {Referrals[pos]?.Custody_County}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        Total Arrests
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4  mr-2">{Referrals[pos]?.Total_Arrests != null ? Referrals[pos]?.Total_Arrests : 0 } </div>
                                        Gun Arrests
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4  mr-2">{Referrals[pos]?.Gun_Arrests != null ? Referrals[pos]?.Gun_Arrests : 0 } </div>
                                        Felony Convictions
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Felony_Convictions != null ? Referrals[pos]?.Felony_Convictions : 0 } </div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        <span className={"mr-2"}># of Times<span className = "font-bold">&nbsp;Victim&nbsp;</span>in Shooting</span>
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4  ml-2">{Referrals[pos]?.Shooting_Victim != null ? Referrals[pos]?.Shooting_Victim : 0}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline text-white">
                                        # of Times<span className = "font-bold">&nbsp;Suspect&nbsp;</span>in Shooting
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Shooting_Suspect != null ? Referrals[pos]?.Shooting_Suspect : 0}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline">
                                        <span className = "mr-2 text-white">NJ POP</span>
                                        { Referrals[pos]?.isShooting == 'Y' ?
                                            <div className = ""><textarea readOnly={true} rows={6} className = "w-96 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.Shooting && Referrals[pos]?.Shooting.length > 0 ? Referrals[pos]?.Shooting : "Yes"}></textarea></div>
                                            :
                                            <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">No</div>
                                        }
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline">
                                        <span className = "mr-5 text-white">Gang</span>
                                        { Referrals[pos]?.isGang == 'Y' ?
                                            <div className = ""><textarea readOnly={true} rows={2} className = "w-96 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.Gang && Referrals[pos]?.Gang.length > 0 ? Referrals[pos]?.Gang : "Yes"}></textarea></div>
                                            :
                                            <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">No</div>
                                        }
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline">
                                        <span className = "mr-3 text-white">Parole</span>
                                        { Referrals[pos]?.isParole == 'Y' ?
                                            <div className = "ml-2 w-56 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos].Parole && Referrals[pos]?.Parole.length > 1 ? Referrals[pos].Parole : "Yes"}</div>
                                            :
                                            <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">No</div>
                                        }
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline">
                                        <span className = "mr-2 text-white">CODIS</span>
                                        { Referrals[pos]?.isCODIS == 'Y' ?
                                            <div className = ""><textarea readOnly={true} rows={4} className = "w-96 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.Codis && Referrals[pos]?.Codis.length > 0 ? Referrals[pos]?.Codis : "Yes"}></textarea></div>
                                            :
                                            <div className = "ml-2 w-44 text-gray-300 border-gray-300 border pr-4 pl-4 ">No</div>
                                        }
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline">
                                        <span className = "mr-3 text-white">Pending Charges Prior to Last Arrest</span>
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Pending_Charges_Count != null ? Referrals[pos]?.Pending_Charges_Count : 0}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mb-2">
                                    <span className="align-middle flex inline">
                                        <span className = "mr-3 text-white">Pending <span className = "font-bold">&nbsp;Gun Charges&nbsp;</span> Prior to Last Arrest</span>
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Pending_GunCharge_Count != null ? Referrals[pos]?.Pending_GunCharge_Count : 0}</div>
                                    </span>
                            </div>
                        </div>
                        <div className ="">
                            <div className = "mt-2 ml-2">
                                    <span className="flex justify-center">
                                        <span className ="mt-1 text-white">Referral Status</span>
                                        <div className = "ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Status}</div>
                                    </span>
                            </div>
                            <div className = "mt-10 m-auto max-h-56 flex justify-center" style={{ maxWidth: 200 }}>
                                <img src={Referrals[pos]?.Pic ? ("data:image/jpeg;base64,"+Referrals[pos]?.Pic) : noImage} style={{height:200, width:200}} />
                            </div>
                            <div className = "mt-10 ml-2">
                                    <span className="flex">
                                        <span className ="mt-1 text-white">Federal Adoption</span>
                                        <div className = "ml-2 w-56 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Adopted && Referrals[pos]?.Adopted.length > 0 ? Referrals[pos]?.Adopted == 'Y' ? "Yes" : "No" :""}</div>
                                    </span>
                            </div>
                            <div className = "mt-2 ml-2">
                                    <span className="flex">
                                        <span className ="mt-1 text-white">Referral County</span>
                                        <div className = "ml-2 w-60 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Referral_County}</div>
                                    </span>
                            </div>
                            <div className = "mt-2 ml-2">
                                    <span className="flex">
                                        <span className ="mt-1 text-white mr-2">Prosecutor</span>
                                        <div className = "ml-2 w-60 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Prosecutor}</div>
                                    </span>
                            </div>
                            <div className = "mt-2 ml-2">
                                    <span className="flex">
                                        <span className ="mt-1 text-white">Prosecution<br/>Outcome</span>
                                        <div className = "ml-2 w-60 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.Prosecutor}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mt-2">
                                    <span className={"flex"}>
                                        <span className="align-middle flex inline text-white">RTCC</span>
                                        <div className = "ml-2 w-32 text-gray-300 border-gray-300 border pr-4 pl-4 ">{Referrals[pos]?.RTCC}</div>
                                    </span>
                            </div>
                            <div className = "ml-2 mt-8">
                                    <span className="align-middle flex inline">
                                        <span className="align-middle flex inline mr-4">
                                            <span className = "mr-2 text-white">NIBIN</span>
                                            { Referrals[pos]?.isNIBIN == 'Y' ?
                                                <div className = ""><textarea readOnly={true} rows={3} className = "w-52 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.NIBIN && Referrals[pos]?.NIBIN.length > 0 ? Referrals[pos]?.NIBIN : "Yes"}></textarea></div>
                                                :
                                                <div className = "ml-2 mb-16 text-gray-300 border-gray-300 border pr-4 pl-4 h-7">No</div>
                                            }
                                        </span>
                                        <span className="align-middle flex inline mr-4">
                                            <span className = "mr-2 text-white">CaseExplorer</span>
                                            { Referrals[pos]?.isCaseExplorer == 'Y' ?
                                                <div className = ""><textarea readOnly={true} rows={3} className = "w-52 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.CaseExplorer && Referrals[pos]?.CaseExplorer.length > 0 ? Referrals[pos]?.CaseExplorer : "Yes"}></textarea></div>
                                                :
                                                <div className = "ml-2 mb-16 text-gray-300 border-gray-300 border pr-4 pl-4 h-7">No</div>
                                            }
                                        </span>
                                        <span className="align-middle flex inline">
                                            <span className = "mr-2 text-white">InfoShare</span>
                                            { Referrals[pos]?.isInfoShare == 'Y' ?
                                                <div className = ""><textarea readOnly={true} rows={3} className = "w-52 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.InfoShare && Referrals[pos]?.InfoShare.length > 0 ? Referrals[pos]?.InfoShare : "Yes"}></textarea></div>
                                                :
                                                <div className = "ml-2 mb-16 text-gray-300 border-gray-300 border pr-4 pl-4 h-7">No</div>
                                            }
                                        </span>
                                    </span>
                            </div>
                            <div className={" mt-8 mr-5"} style={{"display":"flex", top:"100%"}}>
                                <span className={"mr-6"} onMouseEnter={()=>{setB1Color("#0DA2EF")}} onMouseLeave={()=>{setB1Color("#FFFFFF")}}><Button color={"primary"} style={{color:b1color, border:"1px solid", borderColor:b1color, width:160}} onClick={handleFirstRecord}><DoubleArrowSharpIcon className="transform rotate-180"/>First Record</Button></span>
                                <span className={"mr-6"} onMouseEnter={()=>{setB2Color("#0DA2EF")}} onMouseLeave={()=>{setB2Color("#FFFFFF")}}><Button color={"primary"} style={{color:b2color, border:"1px solid", borderColor:b2color, width:160}} onClick={handlePriorRecord}><KeyboardArrowLeftSharpIcon/>Prior Record</Button></span>
                                <span className={"mr-6"} onMouseEnter={()=>{setB3Color("#0DA2EF")}} onMouseLeave={()=>{setB3Color("#FFFFFF")}}><Button color={"primary"} style={{color:b3color, border:"1px solid", borderColor:b3color, width:160}} onClick={handleNextRecord}>Next Record<KeyboardArrowRightSharpIcon/></Button></span>
                                <span className={"mr-6"} onMouseEnter={()=>{setB4Color("#0DA2EF")}} onMouseLeave={()=>{setB4Color("#FFFFFF")}}><Button color={"primary"} style={{color:b4color, border:"1px solid", borderColor:b4color, width:160}} onClick={handleLastRecord}>Last Record<DoubleArrowSharpIcon/></Button></span>
                            </div>
                        </div>
                        <div className ="">
                            <div className={" mr-5 mb-2"} style={{"display":"flex", "justifyContent":"end"}}>
                                    {
                                        isNCICShow() ?
                                            <ButtonGroup size="small" color="inherit" variant="contained">
                                                <Button style={{ color: '#FFFFFF' }} color={"primary"} type="submit" onClick={handleEdit}>EDIT</Button>
                                                <Button style={{ color: '#FFFFFF' }} color="error" onClick={handleDelete}>DELETE</Button>
                                            </ButtonGroup> : <></>
                                    }
                                
                            </div>
                            <div className = "ml-2 mb- mt-2">
                                <span className={"ml-2 text-sm text-white"}>Analytic Notes</span>
                                <span className="align-middle flex inline mt-2">
                                    <div ><textarea readOnly={true} rows={9} className = "w-96 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value={Referrals[pos]?.Analytic_Notes && Referrals[pos]?.Analytic_Notes.length > 0 ? Referrals[pos]?.Analytic_Notes : "" }></textarea></div>
                                </span>
                            </div>
                            <div className = "ml-2 mb-4 mt-2">
                                <span className={"ml-2 text-sm text-white"}>Comments</span>
                                <span className="align-middle flex inline mt-2">
                                    <div ><textarea readOnly={true} rows={8} className = "w-96 ml-2 text-gray-300 border-gray-300 border pr-4 pl-4 bg-black" value ={Referrals[pos]?.Comments && Referrals[pos]?.Comments.length > 0 ? Referrals[pos]?.Comments : "" }></textarea></div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className = "text-sm w-11/12 mt-5 text-white" style={{backgroundColor:"#919292"}}>
                            (U) INFORMATION NOTICE: This product contains unclassified information that is law enforcement sensitive. (U/LES) Recipients should not release any portion of this product to the media,the public, or other personnel who do not have a valid need-to-know.
                        </div>
                    </div>
                    <div className = "mt-1 text-center text-sm text-red-500 mb-4">UNCLASSIFIED//LAW ENFORCEMENT SENSITIVE</div>
                </div>
            </div>
        </div>
            : <></>)
    );
}

export default GVRTFOutputForm;