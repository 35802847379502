import React, {useState, useEffect} from "react";

import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface ShotSpotterSearchProps {
    SubmitSearch: any
}

const ShotSpotterSearch: React.FC<ShotSpotterSearchProps> = ({ SubmitSearch }) => {
    let todayDate = new Date();
    let monthAgoDate = new Date();
    monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
    monthAgoDate.setDate(monthAgoDate.getDate() + 1);
    const [params, setParams] = React.useState({ toDate: todayDate, fromDate: monthAgoDate, showDate: false})
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleSearch()
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleClear = () => {
        setParams({ ...params, toDate: todayDate, fromDate: monthAgoDate, showDate: false})
        setDateType("Entry Date")
        setAndOrType("AND")
        setClear(clear + 1)
    }

    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-9 gap-x-4 gap-y-2" >
                <div className="col-span-1">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        onChange={handleDateType}
                    >
                        <ToggleButton value="Incident Date" sx={{ pr: 2, pl: 2 }} >Incident Date</ToggleButton>
                        <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            value={params.fromDate}
                            onChange={(e:any) => {
                                setParams({...params, fromDate: e})
                            }}
                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={params.toDate}
                                onChange={(e:any) => {
                                    setParams({...params, toDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                            />
                    </LocalizationProvider>
                </div>                
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"shotSpotter"} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default ShotSpotterSearch;
