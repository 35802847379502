import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import UpdatePOI from "../../components/Admin/UpdatePOI";
import BTPersonReport from "../../components/shared/_BTPersonReport";
import {JAOS} from "../../services/JAOS.service";
import {PersonInfo} from "../../interfaces/getDarInfo_interface";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function PersonSummaryPDF() {
    let l = new JAOS();
    let query = useQuery();
    let ps = query.get("PersonSummary")
    let p = query.get("Person")
    
    let [personID,setPersonID] = React.useState<string>("0")
    let [person, setPerson] = React.useState<any>(l.objFromStack(p?p:""));
    let [personSummary, setPersonSummary] = React.useState<any>( l.objFromStack(ps?ps:""));
    
    //console.log({...person})
    //console.log({...personSummary})
    return (
        <div className="w-full">
            {(person && personSummary)?
                Object.keys(personSummary).length === 0? 
                    <BTPersonReport person={{...person}} />  : 
                    <BTPersonReport person={{...person}}  PersonSummaryInfos={{...personSummary}} PrintMode={true}/> 
                : <></>}
        </div>
    );
}

export default PersonSummaryPDF;