import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip,
    Switch,
    FormControlLabel,
    FormGroup,
    FormControl, Snackbar, Alert, TextField, ButtonGroup, Dialog, InputLabel, Select, MenuItem
} from "@mui/material";
import { formatDT } from '../../services/formatDate.service';
import { Person, PersonSearchRounded } from '@mui/icons-material';
import {RunSetDept, RunSetUser} from "../../services/account.service";
import React, {useEffect, useState} from "react";
import {CheckBoxSelection, Inject, MultiSelectComponent} from "@syncfusion/ej2-react-dropdowns";
import UpdateUser from "./UpdateUser";
import UpdateDepartment from "./UpdateDepartment";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {getDeptAccountType, getDeptGroup, } from "../../services/getDropdown.service";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';


interface UserSearchProps {
    SubmitSearch: any,
}


const Departments: React.FC<UserSearchProps> = ({SubmitSearch}) => {
    let [creds, setCreds] = useState<Account>(getUser())
    const [params, setParams] = React.useState({
        AccountType:"",
        DeptGroup:"",
        DeptName:"",
        numUsers:"",
        Status:"",
    })
    const [user, setUser] = React.useState<boolean>(false)
    const [dept, setDept] = React.useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [accountType, setAccountType] = React.useState<any>([])
    const [deptGroupList, setDepGroupList] = React.useState<any>([])


    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            getDeptAccountType().then((res) => {
                setAccountType(res)
            })
            getDeptGroup().then((res) => {
                setDepGroupList(res)
            })
            handleSearch()
        }
    });

    const handleSearch = () => {
        SubmitSearch(params)
    }

    const createUser = () => {
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=0")
    }

    const createDept = () => {
        setDept(true)
    }

    const CloseBox = () => {
        setUser(false)
        setDept(false)
        SubmitSearch(params)
    }

    const changeUserActivation = async (person: any) => {
        let p = person
        if (window.confirm("Are You Sure Want To Resend The Login Link To Selected User?")) {
            person.EditStatus = "Resend"
            let r = await RunSetUser(person)
            //console.log(r)
            if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0) {
                setAlertOpen(true)
                setAlertText("Resend Successful")
            } else {
                alert(r.ErrorMessage)
            }
        }
    }

    const handleClear = () => {
        setParams({
            ...params,
            AccountType:"",
            DeptGroup:"",
            DeptName:"",
            numUsers:"",
            Status:"",
        })
    }

    return (
        <div className="ml-5 pl-5 pr-5 pt-5">
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => {
                setAlertOpen(false)
            }}>
                <Alert onClose={() => {
                    setAlertOpen(false)
                }} severity="success" sx={{width: '100%'}}>
                    {setAlertText}
                </Alert>
            </Snackbar>
            <div>
                {creds.AuthLevel === 'S' || creds.DeptAccountType === "ACCOUNTING" ?
                    <div className="inline">
                        <div className="inline mr-5">
                            <FormControl sx={{minWidth: 210, maxWidth: 210, minHeight: 80}}>
                                <InputLabel id="labelDG">Dept Group</InputLabel>
                                <Select
                                    size = "small"
                                    labelId="labelDG"
                                    value={params.DeptGroup}
                                    label="Dept Group"
                                    onChange={(e) => {setParams({...params, DeptGroup: e.target.value})}}
                                >
                                    {deptGroupList.map((item:any) => (
                                        <MenuItem value={item.Key}>{item.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="inline mr-5">
                            <TextField margin='none' size={"small"} label="Department Name"
                                       value={params.DeptName} onChange={e => setParams({...params, DeptName: e.target.value})}/>
                        </div>
                        <div className="inline mr-5">
                            <FormControl sx={{minWidth: 210, maxWidth: 210, minHeight: 80}}>
                                <InputLabel id="labelAT">Account Type</InputLabel>
                                <Select
                                    size = "small"
                                    labelId="labelAC"
                                    value={params.AccountType}
                                    label="Account Type"
                                    onChange={(e) => {setParams({...params, AccountType: e.target.value})}}
                                >
                                    {accountType.map((item:any) => (
                                        <MenuItem value={item.Key}>{item.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="inline mr-5">
                            <TextField margin='none' size={"small"} label="Number of Users" type={'number'}
                                       value={params.numUsers} onChange={e => setParams({...params, numUsers: e.target.value})}/>
                        </div>
                        <div className = "inline mr-5">
                            <FormControl sx={{minWidth: 210, maxWidth: 210, minHeight:80}}>
                                <InputLabel id="labelS">Status</InputLabel>
                                <Select
                                    size = "small"
                                    labelId="labelS"
                                    value={params.Status}
                                    label="Status"
                                    onChange={(e) => {setParams({...params, Status: e.target.value})}}
                                >
                                    <MenuItem value={'Active'}>{'Active'}</MenuItem>
                                    <MenuItem value={'Inactive'}>{'Inactive'}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    : <></>}
                
            </div>
            <div className="mt-8 mb-4 flex justify-center">
                <ButtonGroup variant="outlined" size={"large"} color="inherit">
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <div className={"mb-3"}>
                {creds.AuthLevel === 'S' || ( creds.DeptAccountType === 'ACCOUNTING' && creds.AuthLevel === 'M') ?
                    <span><Button variant="outlined" size={"small"} type="submit" color="inherit" onClick={createDept}>Add Dept</Button></span>
                    : <></>}
            </div>
            <Dialog
                open={dept}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdateDepartment ID={"0"} CloseBox={CloseBox}/>
            </Dialog>
        </div>
    );
}

export default Departments;



/*  
    //For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [deptHolder, setDeptHolder] = React.useState(Departments)

    const handleChangePage = (event: unknown, newPage: number) => {setPage(newPage);};

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        setDeptHolder(Departments)
    },[ Departments
    ])

    const editUser = (id:string) =>
    {
        //setUserID(id)
        //setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=" + id)
    }
    
    const ActivateDeactivateDept = async (dept: any) => {
        dept.EditStatus = dept.Active ? "Deactivate" : "Activate"
        let r = await RunSetDept(dept)
        //console.log(r)
        if (r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0) {
            //setAlertOpen(true)
            var temp = [...deptHolder]
            var p = temp.indexOf(dept)
            temp[p].Active = !!!dept.Active
            setDeptHolder(temp)
            //setAlertText("Process Successful")
        }
    }

    return (
        <div className={"pl-5 mb-10"}>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small" style={{"border":"1px solid black"}}>
                        <TableBody>
                            {
                                deptHolder?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((dept,idx)=>(
                                        <TableRow>
                                            <TableCell style={{"border":"1px solid gray"}}>
                                                <FormControl component="fieldset" variant="standard">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch onChange={() => ActivateDeactivateDept(dept)} checked = {JSON.parse(dept.Active)}/>
                                                            }
                                                            label= {JSON.parse(dept.Active) ? "Active" : "Disabled" }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                                    <div>(Users: {dept.ActiveUsers}/{dept.NumberOfUsers})</div>
                                                    <div>{dept.DeptName}</div>
                                            </TableCell>
                                        </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {(deptHolder?.length>15)?
                    <TablePagination
                        rowsPerPageOptions={[15, 25, 50, 100]}
                        component="div"
                        count={deptHolder.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={"bg-blue-100"}
                    />
                    : <></>}
                
            </Paper>

        </div>
    );
}*/