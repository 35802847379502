import React from "react";
import Logo from "../../assets/Images/Arrest-logo-transparent.png";
import {BallTriangle} from "react-loader-spinner";

const VerifyingAuthentication: React.FC = () => {

    return (
        <div className ="flex flex-col items-center justify-center h-screen">
            <div className = "mb-2 mt-2"><img src={Logo} style={{'height':'80px'}} alt={'Arrest'}/></div>
            <div className = "border-2 borde-gray-500 rounded-5 shadow-lg m-auto w-2/5 flex flex-col justify-center items-center text-center p-20">
                <div><BallTriangle color="#2E3182" height={80} width={80} /></div>
                <div className = "text-3xl mt-5">Verifying Authentication </div>
            </div>
        </div>
    );

}

export default VerifyingAuthentication;