import React from 'react'
import GeoCode from '../../shared/GeoCodes/_GeoCode_Display';
import {
    Button
} from "@mui/material";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { showCrumbScore } from '../../../services/config.service';

interface SearchResultsProps {
    Persons: any[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ Persons }) => {
    const [showStatute, setShowStatute] = React.useState<boolean>(false);
    const switchStatute = () => { setShowStatute(!showStatute) };
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])

    const handleDataTyping = () =>
    {
        if(Persons) {
            let tempP: any = [...Persons]

            for (let p of tempP) {
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])
    
    const openGeoCode = () => {
        let pins:any[] = []
        for (let p of Persons)
        {
            if(p.Incident_Latitude && p.Incident_Longitude)
            {
                let pName = p.FName?.toUpperCase() + " " + p.MName?.toUpperCase() + " " + p.LName?.toUpperCase()
                pins.push({lat:p.Incident_Latitude, lng:p.Incident_Longitude, statute:p.STATUTE, name:pName, offense:p.DTOFARREST, city:p.City, tool:"statute"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const getPersonSummary = (p:any) =>
    {
        return (<BTPersonReport person={p}/>)
    }
    let grid: Grid | null;
    
    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'StatuteMapping.xlsx';

            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("SSN1") as Column).visible = true;

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("SSN1") as Column).visible = false;
        }
    }

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
            {field: 'City', direction: 'Ascending' }
        ] };
    
    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons?.length>0? <>
                <h4 className={"font-bold pl-5"}> {typedPersons?.length} Records Found</h4>
                <Button variant="outlined" color="success" size={"large"} onClick={openGeoCode}>
                    <span className={"text-green-500"}>Map</span>
                </Button>
                {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={'1'} /></div>:<></>}
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting={(event: any) => { console.log(event) }}
                    rowSelected={(event: any) => { expandRow() }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='DTOFARREST' headerText='Offense Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={160} minWidth={160} maxWidth={160}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='STATUTE' width={140} minWidth={140} maxWidth={140} headerText='Statute' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTEDESCRIPTION' maxWidth={250} headerText='Description' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText='SBINo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN1' width={110} minWidth={110} maxWidth={110} headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='DOB' headerText='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='SEX' headerText='Sex' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr']}}/>
                        { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText='SCOR Ranking' customAttributes={{ class: ['e-attr'] }}/>:<></>}
                        <ColumnDirective field='City' headerText='Incident City' width={200} minWidth={200} maxWidth={200}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='State' headerText='Incident State' width={200} minWidth={200} maxWidth={200}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='Incident_Latitude' headerText='Incident Latitude' width={200} minWidth={200} maxWidth={200}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='Incident_Longitude' headerText='Incident Longitude' width={200} minWidth={200} maxWidth={200}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={180} minWidth={180} maxWidth={180}  customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='Dept' headerText='PoliceStop Dept' width={160} minWidth={160} maxWidth={160}  customAttributes={{ class: ['e-attr']}}/>
                       </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default SearchResults