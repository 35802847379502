import {
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    FormControl, FormControlLabel, InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {RunDeptUnits, RunEmpAuthLevels, RunGetUser, RunMangers, RunSetUser} from "../../services/account.service";
import UserAttributes from "../../interfaces/UserAttributes.interface";
import {DateToString, formatDate, formatDT, formatPhone} from "../../services/formatDate.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isNJSP} from "../../services/auth.service";
import ErrorIcon from "@mui/icons-material/Error";

interface UpdateUserProps {
    ID:string|null
    CloseBox:any
    EditStatus?:string|null
}

const UpdateUser: React.FC<UpdateUserProps> = ({ID, CloseBox, EditStatus = "Add"}) =>{
    const [auth, setAuth] = React.useState<string>('')
    let [user, setUser] = useState<Account>(getUser())
    const [params, setParams] = React.useState<UserAttributes>({})
    const [notesError, setNotesError] = React.useState<string>("")
    const [empIDError, setEmpIDError] = React.useState<string>("")
    const [fnError, setFNError] = React.useState<string>("")
    const [lnError, setLNError] = React.useState<string>("")
    const [emailError, setEmailError] = React.useState<string>("")
    const [phoneError, setPhoneError] = React.useState<string>("")
    const [submitError, setSubmitError] = React.useState<string>("")
    const [DateTermination, setDateTermination] = React.useState<any>()

    const LoadUserData = async (ID:string) => {
        let query = {
            "uniqueID": ID,
        }
        let r = await RunGetUser(query)
        //console.log(r?.AnyData);
        if(r?.AnyData)
            if(r?.AnyData?.DeptCode){
                let res = await RunMangers(r?.AnyData?.DeptCode, null)
                let tempParam:UserAttributes = r?.AnyData
                tempParam.ManagerList = res?.AnyData
                let response = await RunDeptUnits(r?.AnyData?.DeptCode, "")
                tempParam.DeptUnitList = response?.AnyData
                tempParam.DeptUnitID = tempParam?.UserDeptUnitList && tempParam?.UserDeptUnitList.length > 0 ? tempParam?.UserDeptUnitList[0] : ""
                await setParams(tempParam)
            }
       
    }

    useEffect(() => {
        if(ID !== null)
            LoadUserData(ID)
    }, [ID]);
    
    const checkValidation = () => {
        setEmpIDError("")
        setNotesError("")
        setFNError("")
        setLNError("")
        setEmailError("")
        setPhoneError("")
        
        var response = true
        if(EditStatus !== "PartialEdit") {
            if (params.EmpID === null || (params.EmpID && params.EmpID.length <= 0)) {
                setEmpIDError("The User ID Field is required.")
                response = false
            }
            if (params.FirstName === null || (params.FirstName && params.FirstName.length <= 0)) {
                setFNError("The User First Name Field is required.")
                response = false
            }
            if (params.LastName === null || (params.LastName && params.LastName.length <= 0)) {
                setLNError("The User Last Name Field is required.")
                response = false
            }
            if ((params.CellPhone === null || params.CellPhone?.length === 0) && (params.EmailID === null || (params.EmailID && params.EmailID.length <= 0))) {
                setEmailError("The EMail Address Field is required.")
                response = false
            }
            if ((params.EmailID === null || params.EmailID?.length === 0) && (params.CellPhone === null || (params.CellPhone && params.CellPhone.length <= 0))) {
                setPhoneError("The Cell Phone # Field is required.")
                response = false
            }
            if (params.Notes === null || (params.Notes && params.Notes.trim().length <= 0)) {
                setNotesError("The Notes Field is required.")
                response = false
            }
        }
        return response
        
    }
    
    const submitUser = async () => {
        setSubmitError("")
        if (checkValidation()) {
            if (params?.UserDeptUnitList && params?.UserDeptUnitList?.length > 0)
                params.UserDeptUnitList[0] = params?.DeptUnitID
            else
                params.UserDeptUnitList = params?.DeptUnitID && params?.DeptUnitID?.length > 0 ? [params?.DeptUnitID] : []
            let r = await RunSetUser(params)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                CloseBox()
            }
            else
            {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
            }
        }
    }
    
    const handleDeptChange = async (e:any) => {
        let value = e.target.value
        let obj = params?.DeptList?.filter((x) => x.Key === value)
        if( obj && obj?.length > 0 && params?.EmpAuthLevel) {
            let r = await RunMangers(obj[0].Key, null)
            let res = await RunEmpAuthLevels(obj[0].Key, 'M')
            let response = await RunDeptUnits(obj[0].Key, "")
            await setParams({...params, ManagerList: r.AnyData, DeptCode: obj[0].Key, DeptName: obj[0].Value, EmpAuthLevel: 'M', DeptAccountType: res.AnyData[0].Value, AuthLevelList:res?.AnyData.slice(1), DeptUnitList: response?.AnyData})
        }
    }

    const handleTFOChange = async (e: any) => {
        let obj = { ...params};
        obj.TFO = !obj.TFO;
        if (obj.TFO) {
            var todayDate = new Date(); //todayDate.AddMonths(3)
            todayDate = new Date(todayDate.setMonth(todayDate.getMonth() + 3));
            setDateTermination(obj.DateTerminated); //store Old Termination date incase user changes mind to not to set TFO
            obj.DateTerminated = DateToString(todayDate); // formatDate(todayDate.toDateString());  //   Add 3 Months
        }
        else {
            obj.DateTerminated = DateTermination;
        }
        await setParams(obj);
    }
    
    const showManager = ():boolean => {
        return params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'H'
    }
    
    const handleAuthChange = (e:any) => {
        let auth = e.target.value
        //console.log(e)
        switch(auth){
            case('P'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"Patrol"})
                break;
            case('I'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"Detective"})
                break;
            case('C'):
                setParams({ ...params, EmpAuthLevel: auth, EmpAuthLevelDesc: "Civilian", ShootingModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false})
                break;
            case('D'):
                setParams({ ...params, EmpAuthLevel: auth, EmpAuthLevelDesc: "CODIS NJSNAP", ShootingModify: false, HTModify: false, NIBINModify: false, SearchAnySource: false, TFO: false})
                break;
            case('H'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"HumanTraffic User"})
                break;
            case('A'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"Supervisor"})
                break;
            case('G'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"Manager & Supervisor"})
                break;
            case('S'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"Super Admin"})
                break;
            case('M'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"Manager"})
             break;
            case('N'):
                setParams({...params, EmpAuthLevel:auth,EmpAuthLevelDesc:"NIBIN Lab User"})
                break;
        }
    }
    //{userIDError.length > 0 ? <span style={{ color: "red", fontSize:14 }}><ErrorIcon style={{color:"red"}} fontSize = 'small' />{userIDError}</span> : <></> }

    return (
        <div className = "mx-auto">
            {(params && params?.DeptList && params?.DeptList.length > 0 ?
            
            <Card sx={{ maxWidth: 900}} className = "mx-auto" >
                <CardContent>
                {EditStatus !== "PartialEdit" ?
                    <>
                    <span className = "text-blue-400 font-bold text-xl " >
                        {params?.UniqueID === 0 ? <span>Add New User</span> :<span>Edit Account Details </span>}
                    </span>
                    <hr/>
                    <div className = "grid grid-cols-2 mt-5 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                disabled={EditStatus !== 'Add'}
                                variant={EditStatus !== 'Add' ? "filled" : "outlined"}
                                placeholder={"USER AGENCY EMAIL ADDRESS"}
                                label={empIDError.length > 0 ? empIDError:"User ID"}
                                value={params?.EmpID}
                                onChange={e => setParams({ ...params, EmpID: e.target.value })} 
                                color = {empIDError.length > 0 ? "error" : "primary"}
                                focused = {empIDError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full pl-5">
                            {user.AuthLevel === 'S' ? 
                            <FormControl fullWidth>
                                <InputLabel id="DeptLabel">Department</InputLabel>
                                <Select
                                    size = "small"
                                    labelId="DeptLabel"
                                    label="Department"
                                    value = {params?.DeptCode}
                                    onChange = {handleDeptChange}
                                >   
                                    {params.DeptList && params?.DeptList.map((dept) => (
                                        <MenuItem value={dept.Key}>{dept.Value}</MenuItem>
                                    ))} 
                                </Select>
                            </FormControl>
                                :
                                <TextField size = "small" InputLabelProps={{'shrink': true}} disabled margin='none' className="w-full" variant="filled" label="Department" value={params?.DeptCode}/>
                            }
                        </div>
                    </div>
                                <div className={params?.DeptAccountType !== 'NIBIN' && (params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'H') ? "grid grid-cols-3 mt-5 gap-y-5" : "grid grid-cols-2 mt-5 gap-y-5"}>
                        <div className="w-full pr-5">
                            <FormControl fullWidth>
                                <InputLabel id="AuthLevel">Authorization</InputLabel>
                                <Select
                                    size = "small"
                                    labelId="AuthLabel"
                                    label="Authorization"
                                    value = {params?.EmpAuthLevel}
                                    onChange = {handleAuthChange}
                                >
                                    {params.AuthLevelList && params?.AuthLevelList.map((auth) => (
                                        <MenuItem value={auth.Key}>{auth.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="pl-5 w-full">
                            <FormControl fullWidth>
                                <InputLabel id="DeptUnit">Dept Unit</InputLabel>
                                <Select
                                    disabled={params?.DeptAccountType === "NIBIN" || (user.AuthLevel !== 'M' && user.AuthLevel !== 'G' && user.AuthLevel !== 'S')}
                                    size="small"
                                    labelId="DeptUnit"
                                    label="Dept Unit"
                                    value={params?.DeptUnitID}//put something here
                                    onChange={e => setParams({ ...params, DeptUnitID: e.target.value })}
                                >
                                    {params?.DeptUnitList && params?.DeptUnitList.map((dUnit) => (
                                        <MenuItem value={dUnit.Key}>{dUnit.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {params?.DeptAccountType !== 'NIBIN' && (params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D' || params?.EmpAuthLevel === 'H') ?
                        <div className="w-full pl-5 pr-5">
                            {params && params?.ManagerList && params?.ManagerList.length !== 0 && (user.AuthLevel === 'M' || user.AuthLevel === 'S' || user.AuthLevel === 'G') ?
                                <FormControl fullWidth>
                                    <InputLabel id="Manager ID">Manager ID</InputLabel>
                                    <Select
                                        size = "small"
                                        labelId="Manager ID"
                                        label="Manager ID"
                                        value = {params?.ManagerEmpID}
                                        onChange = {e => setParams({...params, ManagerEmpID:e.target.value})}
                                    >
                                        {params?.ManagerList && params?.ManagerList.map((manager) => (
                                            <MenuItem value={manager.Key}>{manager.Value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                :
                                <FormControl fullWidth>
                                    <InputLabel id="Manager ID">Manager ID</InputLabel>
                                    <Select
                                        disabled
                                        size = "small"
                                        labelId="Manager ID"
                                        label="Manager ID"
                                        value = {params?.ManagerEmpID}
                                        onChange = {e => setParams({...params, ManagerEmpID:e.target.value})}
                                    >
                                        {params?.ManagerList && params?.ManagerList.map((manager) => (
                                            <MenuItem value={manager.Key}>{manager.Value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}
                        </div>
                        : <></>} 
                    </div>
                        {params?.DeptAccountType === "NIBIN" || params?.DeptAccountType === "CODIS" || params?.DeptAccountType === "ACCOUNTING" || (params?.EmpAuthLevel === 'C' || params?.EmpAuthLevel === 'D') ? <></> :
                        <div className="w-full col-span-2 border-2 border-gray-300 rounded-md mt-5">
                            {params?.DeptAccountType !== "NIBIN" && isNJSP() && !(user.AuthLevel == "A" || user.AuthLevel == "P" || user.AuthLevel == "I" || user.AuthLevel == "C" || user.AuthLevel == "D" || user.AuthLevel == "H" || user.AuthLevel == "N") && !(params?.EmpAuthLevel === 'H') ?
                                <span className="ml-5 "><FormControlLabel
                                    control={<Checkbox checked={params?.ShootingModify} onChange={e => setParams({ ...params, ShootingModify: !params?.ShootingModify })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                    label="Add/Edit Shooting" />
                                </span>
                                :
                                <span className="ml-5 "><FormControlLabel
                                    control={<Checkbox disabled checked={params?.ShootingModify} onChange={e => setParams({ ...params, ShootingModify: !params?.ShootingModify })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                    label="Add/Edit Shooting" /></span>
                            }
                            {params?.DeptAccountType !== "NIBIN" && (user.AuthLevel === 'M' || user.AuthLevel === 'G' || user.AuthLevel === 'S') && (params?.EmpAuthLevel === 'H' || params?.EmpAuthLevel === 'I' || params?.EmpAuthLevel === 'A' || params?.EmpAuthLevel === 'M' || params?.EmpAuthLevel === 'G') ?
                                <span className="ml-5 "><FormControlLabel
                                    control={<Checkbox checked={params?.HTModify} onChange={e => setParams({ ...params, HTModify: !params?.HTModify })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                    label="Add/Edit Human Traffic" />
                                </span>                                            
                                :
                                <span className="ml-5 "><FormControlLabel
                                    control={<Checkbox disabled checked={params?.HTModify} onChange={e => setParams({ ...params, HTModify: !params?.HTModify })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                    label="Add/Edit Human Traffic" />
                                </span>
                            }
                            {params?.DeptAccountType !== "NIBIN" && (user.AuthLevel === 'M' || user.AuthLevel === 'G' || user.AuthLevel === 'S') && (params?.EmpAuthLevel === 'A' || params?.EmpAuthLevel === 'G')?
                                <span className="ml-5 "><FormControlLabel
                                    control={<Checkbox checked={params?.ConfidentialPOI} onChange={e => setParams({ ...params, ConfidentialPOI: !params?.ConfidentialPOI })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                    label="View/Edit Confidential POI" />
                                </span>
                                :
                                <span className="ml-5 "><FormControlLabel
                                    control={<Checkbox disabled checked={params?.ConfidentialPOI} onChange={e => setParams({ ...params, ConfidentialPOI: !params?.ConfidentialPOI })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                    label="View/Edit Confidential POI" />
                                </span>
                            }
                            {(user.AuthLevel === 'S') && !(params?.EmpAuthLevel === 'P' || params?.EmpAuthLevel === 'H' || params?.EmpAuthLevel === 'S') ?
                                <span className="ml-5 ">
                                    <FormControlLabel
                                        control={<Checkbox checked={params?.SearchAnySource} onChange={e => setParams({ ...params, SearchAnySource: !params?.SearchAnySource })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                        label="Any Source Search" />
                                </span>
                                :
                                <span className="ml-5 ">
                                    <FormControlLabel
                                        control={<Checkbox disabled checked={params?.SearchAnySource} onChange={e => setParams({ ...params, SearchAnySource: !params?.SearchAnySource })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                        label="Any Source Search" />
                                </span>
                            }
                           {/* Only Manager/Superadmin can set TFO for other users: BAC-252 */}
                            {params?.DeptAccountType !== "NIBIN" && (user.AuthLevel == "A" || user.AuthLevel == "P" || user.AuthLevel == "I" || user.AuthLevel == "C" || user.AuthLevel == "D" || user.AuthLevel == "H" || user.AuthLevel == "N") || (params?.EmpAuthLevel === 'H')?
                                <span className="ml-5 ">
                                    <Tooltip title="Transferred From Other agency">
                                        <FormControlLabel
                                            control={<Checkbox disabled checked={params?.TFO} onChange={e => setParams({ ...params, TFO: !params?.TFO })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                            label="TFO" />
                                    </Tooltip>
                                </span> :
                                <span className="ml-5 ">
                                    <Tooltip title="Transferred From Other agency">
                                        <FormControlLabel
                                            control={<Checkbox checked={params?.TFO} onChange={e => handleTFOChange(e)} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                            label="TFO" />
                                    </Tooltip>
                                </span>
                            }
                            {params?.DeptAccountType !== "NIBIN" && (user.AuthLevel == "S") ?
                                <span className="ml-5 ">
                                    <Tooltip title="Access to Threat Assessment Site">
                                        <FormControlLabel
                                            control={<Checkbox checked={params?.ThreatAssessment} onChange={e => setParams({ ...params, ThreatAssessment: !params?.ThreatAssessment })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                            label="View/Edit Threat Assessment" />
                                    </Tooltip>
                                </span> 
                                : (params?.ThreatAssessment) ?
                                <span className="ml-5 ">
                                    <Tooltip title="Transferred From Other agency">
                                        <FormControlLabel
                                            control={<Checkbox disabled checked={params?.ThreatAssessment} onChange={e => setParams({ ...params, ThreatAssessment: !params?.ThreatAssessment })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                            label="View/Edit Threat Assessment" />
                                    </Tooltip>
                                </span>
                                : <></>
                            }
                            {/* Only Manager/Superadmin can set CDL for other users: BAC-408 */}
                            {params?.DeptAccountType !== "NIBIN" && (user.AuthLevel == "A" || user.AuthLevel == "P" || user.AuthLevel == "I" || user.AuthLevel == "C" || user.AuthLevel == "D" || user.AuthLevel == "H" || user.AuthLevel == "N") || (params?.EmpAuthLevel === 'H') ?
                                <span className="ml-5 ">
                                    <Tooltip title="Include in CODIS Distribution List">
                                        <FormControlLabel
                                            control={<Checkbox disabled checked={params?.CodisDistribution} onChange={e => setParams({ ...params, CodisDistribution: !params?.CodisDistribution })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                            label="CDL" />
                                    </Tooltip>
                                </span> :
                                <span className="ml-5 ">
                                    <Tooltip title="Include in CODIS Distribution List">
                                        <FormControlLabel
                                            control={<Checkbox checked={params?.CodisDistribution} onChange={e => setParams({ ...params, CodisDistribution: !params?.CodisDistribution })} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />}
                                            label="CDL" />
                                    </Tooltip>
                                </span>
                            }
                        </div>
                    }
                    </> : <></>}
                    <div className = "grid grid-cols-3 mt-5">
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label={fnError.length > 0 ? fnError:"User First Name"}
                                value={params?.FirstName}
                                onChange={e => setParams({ ...params, FirstName: e.target.value })}
                                color = {fnError.length > 0 ? "error" : "primary"}
                                focused = {fnError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full pr-10 pl-10">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label="User Middle Initial"
                                value={params?.MiddleName}
                                onChange={e => setParams({ ...params, MiddleName: e.target.value })}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label={lnError.length > 0 ? lnError:"User Last Name"}
                                value={params?.LastName}
                                onChange={e => setParams({ ...params, LastName: e.target.value })}
                                color = {lnError.length > 0 ? "error" : "primary"}
                                focused = {lnError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className = "grid grid-cols-1 mt-5">
                        <div className="w-full">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                className="w-full"
                                margin='none'
                                label={emailError.length > 0 ? emailError:"EMail Address"}
                                value={params?.EmailID}
                                onChange={e => setParams({ ...params, EmailID: e.target.value })}
                                color = {emailError.length > 0 ? "error" : "primary"}
                                focused = {emailError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className = "grid grid-cols-2 mt-5 gap-y-5">
                        <div className="w-full pr-5">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label={phoneError.length > 0 ? phoneError:"Cell Phone #"}
                                value={params?.CellPhone}
                                onChange={e => setParams({ ...params, CellPhone: e.target.value })}
                                color = {phoneError.length > 0 ? "error" : "primary"}
                                focused = {phoneError.length > 0 ? true : undefined}
                            />
                        </div>
                        {EditStatus === "Add" ?
                            <>
                        <div className="w-full pl-5">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label="Deactivation Date"
                                type="date"
                                value={params?.DateTerminated?.substring(0,10)}
                                onChange={e => setParams({ ...params, DateTerminated: e.target.value })}
                            />
                        </div>
                        </>
                            : <>
                                <div className="w-full pl-5">
                                    <TextField
                                        size = "small"
                                        type={"date"}
                                        InputLabelProps={{'shrink':true}}
                                        margin='none'
                                        className="w-full"
                                        label="DOB"
                                        value={params?.DOB?.substring(0,10)}
                                        onChange={e => setParams({ ...params, DOB: e.target.value })}
                                    />
                                </div>
                            </> }
                        {EditStatus === "Add" ?
                            <>
                        <div className="w-full pr-5">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                disabled
                                margin='none'
                                className="w-full"
                                variant="filled"
                                label="Created User"
                                value={params?.CreateUID}
                                onChange={e => setParams({ ...params, CreateUID: e.target.value })}
                            />
                        </div>
                        <div className="w-full pl-5">
                            <TextField
                                size = "small"
                                InputLabelProps={{'shrink':true}}
                                disabled
                                margin='none'
                                className="w-full"
                                variant="filled"
                                label="Created Date"
                                value={formatDate(params?.CreateDate)}
                                onChange={e => setParams({ ...params, CreateDate: e.target.value })}
                            />
                        </div>
                            </>
                            : <>
                                {EditStatus !== 'PartialEdit' ? 
                                <div className="w-full pr-5">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        margin='none'
                                        className="w-full"
                                        label="Deactivation Date"
                                        type="date"
                                        value={params?.DateTerminated?.substring(0,10)}
                                        onChange={e => setParams({ ...params, DateTerminated: e.target.value })}
                                    />
                                </div>
                                :
                                <div className="w-full pr-5">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Created User"
                                        value={params?.CreateUID}
                                    />
                                </div>
                                }
                                <div className="w-full pl-5">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Password Date"
                                        value={params?.PasswordDate}
                                    />
                                </div>
                                <div className="w-full pr-5">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Updated User"
                                        value={params?.UpdUID}
                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Updated Date"
                                        value={formatDate(params?.UpdDate)}
                                    />
                                </div>
                            </> }
                    </div>
                    <div className = "grid grid-cols-1 mt-5">
                    {EditStatus !== "PartialEdit" ?
                        <>
                        <div className="w-full">
                            <TextField
                                size = "small"
                                multiline minRows={2}
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label={notesError.length > 0 ? notesError:"Notes"}
                                value={params?.Notes}
                                onChange={e => setParams({ ...params, Notes: e.target.value })}
                                color = {notesError.length > 0 ? "error" : "primary"}
                                focused = {notesError.length > 0 ? true : undefined}
                            />
                        </div>
                        {EditStatus !== "PartialEdit" ? 
                        <div className="w-full mt-5">
                            <TextField
                                size = "small"
                                disabled
                                multiline
                                minRows={4}
                                InputLabelProps={{'shrink':true}}
                                margin='none'
                                className="w-full"
                                label="Previous Notes"
                                value={params?.PreviousNotes}
                                onChange={e => setParams({ ...params, PreviousNotes: e.target.value })}
                            />
                        </div> : <></>}
                            {EditStatus === "Add" ?    
                            <div className="w-full mt-5 mb-3">
                                <TextField
                                    size = "small"
                                    InputLabelProps={{'shrink':true}}
                                    disabled
                                    margin='none'
                                    className="w-full"
                                    variant="filled"
                                    label="Updated User"
                                    value={params?.UpdUID}
                                    onChange={e => setParams({ ...params, UpdUID: e.target.value })}
                                />
                            </div>
                                :
                                <div className="w-full mt-5 mb-3">
                                    <TextField
                                        size = "small"
                                        InputLabelProps={{'shrink':true}}
                                        disabled
                                        margin='none'
                                        className="w-full"
                                        variant="filled"
                                        label="Created User"
                                        value={params?.CreateUID}
                                    />
                                </div>
                            }
                       </> : <></> }
                    </div>
                    {submitError?.length > 0 ?
                        <span style={{ color: "red", fontSize:14}}>
                           {submitError.split(",").map((error)=> <div> <ErrorIcon style={{color:"red"}} fontSize = 'small' />{error}</div>)}
                        </span> 
                    : <></>}
                    <div className="mt-8 flex justify-center">
                        <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                        <Button onClick={submitUser} >Save</Button>
                        <Button onClick = {CloseBox}>CANCEL</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
                : <div className = "text-blue-400 text-6xl font-bold"></div>)}  
        </div>
    );
}

export default UpdateUser;