import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Card, CardContent, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { JAOS } from "../../services/JAOS.service";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { getAuditLogAction } from "../../services/getDropdown.service";
import { CheckBoxSelection, Inject, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";

interface AuditLogSearchProps {
    SubmitSearch: any
}

const AuditLogSearch: React.FC<AuditLogSearchProps> = ({ SubmitSearch }) => {
    let today: Date = new Date();
    const [params, setParams] = useState({ FromDate: today, ToDate: today, Action: [], EventType: "", Message: "" })
    const [clear, setClear] = useState<number>(0);
    const [initialRun, setInitialRun] = useState<boolean>(false)
    const [actionsList, setActionsList] = useState<any>([])

    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            getAuditLogAction().then((res) => { setActionsList(res) })
            handleSearch()
        }
    });    

    const handleClear = () => {
        setParams({ ...params, FromDate: today, ToDate: today, Action: [], EventType: "", Message: "" })
        setClear(clear + 1)
    }

    //const handleDropDownChange = (e: any) => {
    //    let p = { ...params }        
    //    switch (e.target.value) {
    //        case ('ERROR'):
    //            p.EventType = "ERROR"
    //            break;
    //        case ('INFO'):
    //            p.EventType = "INFO"
    //            break;
    //        case ('WARN'):
    //            p.EventType = "WARN"
    //            break;
    //    }
    //    setParams(p)
    //}

    const handleSearch = () => {
        SubmitSearch(params)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2" >
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            value={params.FromDate}
                            onChange={(e: any) => {
                                setParams({ ...params, FromDate: e })
                            }}
                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="From Date" InputLabelProps={{ shrink: true }} {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="To Date"
                            value={params.ToDate}
                            onChange={(e: any) => {
                                setParams({ ...params, ToDate: e })
                            }}
                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="To Date" InputLabelProps={{ shrink: true }} {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div className="inline">
                    <FormControl sx={{minWidth: 200, maxWidth: 200 }}>
                        <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={params.Action} change={(e) => { setParams({ ...params, Action: e.value }) }} fields={{ text: 'Value', value: 'Key' }} dataSource={actionsList} placeholder="Action" mode="CheckBox">
                            <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                    </FormControl>
                    {/*<FormControl sx={{ minWidth: 210, maxWidth: 210, minHeight: 80 }}>*/}
                    {/*    <InputLabel id="label">Actions</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        size="small"*/}
                    {/*        labelId="Action"*/}
                    {/*        value={params.Action}*/}
                    {/*        label="Action"*/}
                    {/*        onChange={(e) => { setParams({ ...params, Action: e.target.value }) }}*/}
                    {/*    >*/}
                    {/*        {actionsList.map((item: any) => (*/}
                    {/*            <MenuItem value={item.Key}>{item.Value}</MenuItem>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</FormControl>*/}
                </div>
                {/*<div className="inline mr-5">*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <InputLabel id="eventType">EventType</InputLabel>*/}
                {/*        <Select*/}
                {/*            labelId="EventType"*/}
                {/*            value={params.EventType}*/}
                {/*            label="Event Type"*/}
                {/*            onChange={handleDropDownChange}*/}
                {/*            size={'small'}*/}
                {/*        >*/}
                {/*            <MenuItem value={"ERROR"}>ERROR</MenuItem>*/}
                {/*            <MenuItem value={"INFO"}>INFO</MenuItem>*/}
                {/*            <MenuItem value={"WARN"}>WARN</MenuItem>*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</div>*/}
                {/*<div className="inline">*/}
                {/*    <TextField*/}
                {/*        margin='none'*/}
                {/*        size={"small"}*/}
                {/*        label="Message"*/}
                {/*        value={params.Message}*/}
                {/*        onChange={(e: any) => setParams({ ...params, Message: e.target.value })}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>            
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                <ButtonGroup size="large" color="inherit" >
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default AuditLogSearch;