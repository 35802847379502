import React, {useState} from "react";
import {GetDarInfo_Query} from "../../interfaces/getDarInfo_interface";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";
import {RunDarQuery} from "../../services/getDar.service";
import ArrestSearch from "../../components/tools/arrest/_arrestSearch";
import ProfessionalLicenseSearchInfo from "../../components/tools/professional_license/_professionalLicenseSearch";
import SearchResults from "../../components/tools/professional_license/_searchResultsProfessionalLicense";

function ProfessionalLicense() {
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params:any, dateType:string, andOrType:string, dropDowns:any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "C",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": params.showDate,
            "DateTypeValue": (dateType === "Expiry Date") ? 0 : 1,
            "FromDate": params.fromDate.toLocaleString(),
            "ToDate": params.toDate.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": [],
            "SEX": dropDowns.Profession ? dropDowns.Profession : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "PoliceStopDept": dropDowns.PoliceStopDept ? dropDowns.PoliceStopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "FTSIDs": [],
        }
        let qRes:any = await RunDarQuery(search_query)
        setDarTbl([])
        //console.log(qRes)
        setDarTbl(qRes.AnyTable)

        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    return (
        <div>
            {isArrestShow()?
                <>
                    <ProfessionalLicenseSearchInfo SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default ProfessionalLicense;