import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NIBINInfo from "../../../interfaces/NIBINInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import NibinIcon from "../../../assets/Images/nibin1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {OpenNIBINPDF} from "../../../services/detail.service";

interface PanelNIBINProps {
    person: PersonInfo,
    NIBINs: NIBINInfo[],
    isLoading:boolean
}

const  PanelNIBIN: React.FC<PanelNIBINProps> = ({person,NIBINs,isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...NIBINs]
        for (let p of tempP) {
            if (p.OffenseDate) {
                p.OffenseDate = new Date(p.OffenseDate)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [NIBINs])

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                       <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>NIBIN Notification <img src={NibinIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {NIBINs?.length}</span>
                            </span>
                       </Disclosure.Button>
                    
                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='OffenseDate' headerText='Incident Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='Offense' headerText='Incident'/>
                                    <ColumnDirective field='CaseNumber' headerText='Case #'/>
                                    <ColumnDirective field='Agency' headerText='Agency'/>
                                    <ColumnDirective field='HitNumber' headerText='Role'/>
                                    <ColumnDirective field='Profile' headerText='Ballistic/Weapon Details'/>
                                    <ColumnDirective template={(item: any) => (<>{item?.IncidentAddress} {item?.City} {item?.ST}</>)} headerText='Incident Address'/>
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { OpenNIBINPDF(item?.NIBINNo) }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                            
                           
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
        
    )
};

export default PanelNIBIN;