import React, { useState } from "react";
import VinSearch from "../../components/tools/alpr_vin_search/_vinSearch";
import SearchResults from "../../components/tools/alpr_vin_search/_searchResultsVin";
import PlateSearch from "../../interfaces/PlateSearch.interface";
import { getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunVINQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import { getUser, isArrestShow, isCODISShow } from "../../services/auth.service";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ALPRVINSearch() {
    let [vinData, setVinData] = useState<any[]>([])
    let [errorData, setErrorData] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())

    let query = useQuery();

    const SubmitSearch = async (params: any, dropDowns: any, vinType:string) => {
        //console.log(dropDowns);
        let search_query: PlateSearch = {
            PlateNo: params.PlateNo ? params.PlateNo.split(',') : [], 
            PlateState: params.PlateState ? params.PlateState.split(',') : [],
            //PerLicenseNo: params.PerLicenseNo,
            //PerFirstName: params.PerFirstName,
            //PerMiddleName: params.PerMiddleName,
            //PerLastName: params.PerLastName,            
            VehVINNo: params.VehVINNo ? params.VehVINNo.split(',') : [],
            /*VehMake: dropDowns?.DD?.VehicleMake ? dropDowns?.DD?.VehicleMake : [],*/
            VehMake: dropDowns?.DD?.VehicleMake ? dropDowns?.DD?.VehicleMake : [],
            VehModel: dropDowns?.DD?.VehicleModel ? dropDowns?.DD?.VehicleModel : [],
            VehTrim: dropDowns?.DD?.VehicleTrim ? dropDowns?.DD?.VehicleTrim : [],
            VehYear: dropDowns?.DD?.VehicleYear ? dropDowns?.DD?.VehicleYear : [],
            VehColor: dropDowns?.DD?.VehicleColor ? dropDowns?.DD?.VehicleColor : [],
            VehBody: dropDowns?.DD?.VehicleBody ? dropDowns?.DD?.VehicleBody : [],
            //VINEngineType: params.VINEngineType,
            //VINTransmission_short: params.VINTransmission_short,
            //VINTransmission_long: params.VINTransmission_long,
            //VINDriveline: params.VINDriveline,
            //VINAnti_BrakeSystem: params.VINAnti_BrakeSystem,
            //VINTires: params.VINTires,
            //VINInteriorTrim: params.VINInteriorTrim,
            //VINTrackFront: params.VINTrackFront,
            //VINTrackRear: params.VINTrackRear,
            //VINStandardSeating: params.VINStandardSeating,
            //VINVehicleAntiTheft: params.VINVehicleAntiTheft,
            //VIN4WD_AWD: params.VIN4WD_AWD,
            //VINTractionControl: params.VINTractionControl,
            //VINGenuineWoodTrim: params.VINGenuineWoodTrim,
            //VINFrontSplitBenchSeat: params.VINFrontSplitBenchSeat,
            //VINLeatherSeat: params.VINLeatherSeat,
            //VINAlloyWheels: params.VINAlloyWheels,
            //VINChromeWheels: params.VINChromeWheels,
            //VINSteelWheels: params.VINSteelWheels,
            //VINRearWiper: params.VINRearWiper,
            ALPRSearch: params.ALPR,
            ALPRStartDate: params.fromDate?.toLocaleString(),
            ALPREndDate: params.toDate?.toLocaleString(),
            ALPRServer: dropDowns?.ADD?.ALPRServer ? dropDowns?.ADD?.ALPRServer : [],
            ALPRCamera: dropDowns?.ADD?.ALPRCamera ? dropDowns?.ADD?.ALPRCamera : [],
            ALPRReason: dropDowns?.ADD?.ALPRReason ? dropDowns?.ADD?.ALPRReason : [],
            ALPRCaseNumber: params.ALPRCaseNumber ? params.ALPRCaseNumber.split(',') : [],
            ETicket: params.ETicket,
            ETicketAgency: dropDowns?.EDD?.ETicketAgency ? dropDowns?.EDD?.ETicketAgency : [],
            ParkMobile: params.ParkMobile,
            ParkMobileAgency: dropDowns?.PDD?.ParkMobileAgency ? dropDowns?.PDD?.ParkMobileAgency : [],
            ShowVIN: (vinType === "VIN") ? true : false,
        }
        //console.log(params);
        //console.log(search_query);
        try {
            let qRes: getDarInfoResponse = await RunVINQuery(search_query)
            setVinData([])
            setVinData(JSON.parse(qRes?.JsonObject))
            setErrorData([])
            setErrorData(qRes?.AnyTable)
        }
        catch(e)
        {
            setVinData([])
            setErrorData([])
            setErrorData([{ERROR:"Timed Out!", Record:"An ERROR has Occured while fetching the data please limit your search criteria."}])
        }
    }

    return (
        <div id="ToolsVIN">
            {isCODISShow()?
                <>
                    {query.get("PlateList") ?
                        <VinSearch SubmitSearch={SubmitSearch} data={query.get("PlateList")}/>
                        :
                        <VinSearch SubmitSearch={SubmitSearch} />
                    }
                    <SearchResults VINInfos={vinData} Errors = {errorData} />
                </>
            :<></>}
        </div>
    );
}

export default ALPRVINSearch;