import React, {useState, useEffect} from "react";
import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup, Tooltip} from "@mui/material";
import ArrestDropDowns from "./_arrestDropDowns";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import {JAOS} from "../../../services/JAOS.service";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface ArrestSearchProps {
    SubmitSearch: any
    passedData?: any|null
}

const ArrestSearch: React.FC<ArrestSearchProps> = ({SubmitSearch, passedData = null}) => {
    let today:Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date("2022-10-01");
    //if (toDate.getDay() === 1) {
    //    prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
    //    fromday = prevDate //Default - Previous weekend
    //}     
    //else 
    //    fromday = toDate //toDate.toISOString().slice(0, 10);
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", DL: "", Case: "", Plate: "", SSN: "", AgeRangeStart: "", AgeRangeEnd: "", FTSID: "", toDate:today, fromDate:fromday, showDate: true})
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [showALPR, setShowALPR] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(passedData) {
            let l = new JAOS()
            let data = l.objFromStack(passedData)
            let p = params
            if(data.FBI)
            {
                p.FBI = data.FBI
            }
            if(data.SBI)
            {
                p.SBI = data.SBI
            }
            if(data.DOB)
            {
                p.FirstName = data.FName
                p.MI = data.MI
                p.LastName = data.LName
                p.DOB = data.DOB
            }
            else if(data.Plates)
            {
                p.Plate = data.Plates
            }
            p.showDate = false
            setParams(p)
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
        else
        {
            handleSearch()
        }
    }, [passedData])
    

    React.useEffect(() => {
        let p = params
        if(params.FirstName?.length !== 0)
            p.showDate = false
        if(params.MI?.length !== 0)
            p.showDate = false
        if(params.LastName?.length !== 0)
            p.showDate = false
        if(params.DOB?.length !== 0)
            p.showDate = false
        if(params.FBI?.length !== 0)
            p.showDate = false
        if(params.SBI?.length !== 0)
            p.showDate = false
        if(params.DL?.length !== 0)
            p.showDate = false
        if(params.Case?.length !== 0)
            p.showDate = false
        if (params.Plate?.length !== 0)
        {
            p.showDate = false
            params?.Plate?.includes(",") ? setShowALPR(true) : setShowALPR(false)
             
        }
        if (params.SSN?.length !== 0)
            p.showDate = false
        if (params.FTSID?.length !== 0)
            p.showDate = false
        setParams(p)
    }, [params.FirstName, params.MI, params.LastName, params.DOB, params.FBI, params.SBI, params.DL, params.Case, params.Plate, params.SSN, params.AgeRangeStart, params.AgeRangeEnd, params.FTSID])
    

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if(dateType){
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns:any) => {
        setDropDownParams(dropDowns)
    }

    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", DL: "", Case: "", Plate: "", SSN: "", AgeRangeStart: "", AgeRangeEnd: "", FTSID: "", toDate:today, fromDate:today, showDate:true})
        setDateType("Entry Date")
        setAndOrType("AND")
        setShowALPR(false)
        setClear(clear + 1)
    }

    const handleDate = (e:any) =>
    {
        let show = params.showDate
        setParams({...params, showDate:!show})
    }   

    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    const handleALPRSearch = () => {
        let tempParams = { ...params }
        let l = new JAOS()
        let hashID = l.objToStack(tempParams.Plate)
        window.open('/tools/alpr_vin_search?PlateList=' + hashID, '_blank')
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{mr: 2 }}
                >
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <Checkbox checked = {params.showDate} icon={<EventBusyOutlinedIcon/>} checkedIcon={<EventAvailableOutlinedIcon />} onChange = {handleDate} />

                {(params.showDate) ? 
                    <>                        
                        <div >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From Date"
                                    value={params.fromDate}
                                    onChange={(e:any) => {
                                        setParams({...params, fromDate: e})
                                    }}
                                    renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="To Date"
                                        value={params.toDate}
                                        onChange={(e:any) => {
                                            setParams({...params, toDate: e})
                                        }}
                                        renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                    />
                            </LocalizationProvider>
                        </div>
                    </>
                    : <></> 
                }
                <div><TextField margin='none' size={"small"} label="First Name"   value = {params.FirstName} onChange={(e:any) => setParams({...params, FirstName: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="Middle Initial"  value = {params.MI} onChange={(e:any) => setParams({...params, MI: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="Last Name"  value = {params.LastName} onChange={(e:any) => setParams({...params, LastName: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} value = {params.DOB} onChange={(e:any) => setParams({...params, DOB: e.target.value})}  /></div>
                {/*<div><TextField margin='none' size={"small"} label="FBI #"  value = {params.FBI} onChange={(e:any) => setParams({...params, FBI: e.target.value})}   /></div>*/}
                {/*<div><TextField margin='none' size={"small"} label="SBI#"   value = {params.SBI} onChange={(e:any) => setParams({...params, SBI: e.target.value})} /></div>*/}
                <div><TextField margin='none' size={"small"} label="DL #" value={params.DL} onChange={(e: any) => setParams({ ...params, DL: e.target.value })} /></div>
                {/*<div><TextField margin='none' size={"small"} label="SSN #" value={params.SSN} onChange={(e: any) => setParams({ ...params, SSN: e.target.value })} /></div>*/}
                <div><TextField margin='none' size={"small"} label="Age Lower Range" type="number" value={params.AgeRangeStart} onChange={(e: any) => setParams({ ...params, AgeRangeStart: e.target.value })} title="Age of offender at the time of Arrest. (Lower Limit)" /></div>
                <div><TextField margin='none' size={"small"} label="Age Upper Range" type="number" value={params.AgeRangeEnd} onChange={(e: any) => setParams({ ...params, AgeRangeEnd: e.target.value })} title="Age of offender at the time of Arrest. (Upper Limit)"/></div>
                <div><TextField margin='none' size={"small"} label="Plate #"  value = {params.Plate} onChange={(e:any) => setParams({...params, Plate: e.target.value})} title="Search Sample: PL*E, PL%E, PL_TE, PL[A-E]TE, PL[^E-H]TE, 'PLATE1,PLATE2,PLATE3'" /></div>
                <div><TextField margin='none' size={"small"} label="Case #"  value = {params.Case} onChange={(e:any) => setParams({...params, Case: e.target.value})}  /></div>
                {/*<div><TextField margin='none' size={"small"} label="ATF #" value={params.FTSID} onChange={(e: any) => setParams({ ...params, FTSID: e.target.value })} title="ETrace ATF FTS_ID#" /></div>*/}
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{"marginTop":"25px"}}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"arrest"}/></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                    {showALPR ? <Button title="Search Make & Model of Plate List" onClick={handleALPRSearch}>Make and Model</Button> : <div ></div>}
                </ButtonGroup>
            </div>
        </div>
    );
}

export default ArrestSearch;