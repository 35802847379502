import React, {useState} from "react";
import MainLogo from "../../assets/Images/Main.png";
import PoliceStopLogo from "../../assets/Images/PoliceStop.png";
import Logo from "../../assets/Images/newArrest.png";
import {Link} from "react-router-dom";

import {Account, AccountTools} from "../../../interfaces/auth_interface";
import {getUser, getUserTools} from "../../../services/auth.service";
import BTlogo from "../../../assets/Images/Arrest-logo-transparent.png";
import {Disclosure, Menu} from "@headlessui/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TapAndPlay } from "@mui/icons-material";


function SideBar() {
    let [user, setUser] = useState<Account>(getUser());
    let [userTools, setUserTools] = useState<AccountTools[]>(getUserTools())
    
    return (
        <aside className="min-w-min	max-w-7xl w-80 sm:w-96 h-full bg-gray shadow-md sticky top-0">
            <div className="flex flex-col justify-between h-full min-h-screen items-stretch p-4 bg-gray-800">
                <div className="text-sm">
                    <img className="mx-auto" src={BTlogo} style={{height:"100px"}} alt={""}/>
                    <div className="text-white p-2 rounded mt-2 cursor-pointer">{user.Firstname} {user.Lastname}, {user.Dept}</div>
                    <div className="bg-gray-900 text-white p-2 rounded mt-1 cursor-pointer hover:bg-gray-700">
                        <Link to={"/"} style={{width:"100%"}}> Home</Link>
                    </div>
                    
                    <Disclosure defaultOpen={true}>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className={"w-full text-left"}>
                                    <div className="bg-gray-900 text-white p-2 rounded mt-1 cursor-pointer hover:bg-gray-700">
                                        <KeyboardArrowDownIcon className={`${open ? "transform rotate-180" : ""}`}/>
                                        Tools
                                    </div>
                                </Disclosure.Button>
                                <Disclosure.Panel className="text-gray-500 p-4 flex-grow">
                                    {(userTools)? userTools.map((tool)=> (
                                        
                                        <div className="bg-gray-700 text-white p-1 rounded mt-1 cursor-pointer hover:bg-gray-600">
                                            {tool.type && tool.type ==='A' ?
                                                <a href={tool.href} target="_blank">{tool.name}</a>
                                            : 
                                                <Link to={tool.href} style={{width:"100%"}}> {tool.name}</Link> 
                                            }
                                        </div>
                                        

                                    )) : <></>}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                    
                    <div className="bg-gray-900 text-white p-2 rounded mt-1 cursor-pointer hover:bg-gray-700"><Link to={"/poi"} style={{width:"100%"}}> POI</Link></div>
                    <div className="bg-gray-900 text-white p-2 rounded mt-1 cursor-pointer hover:bg-gray-700"><Link to={"/users"} style={{ width: "100%" }}> User Profile</Link></div>
                    <div className="bg-gray-900 text-white p-2 rounded mt-1 cursor-pointer hover:bg-gray-700"><Link to={"/AuditLog"} style={{ width: "100%" }}> Audit Log</Link></div>
                    <div className="bg-gray-900 text-white p-2 rounded mt-1 cursor-pointer hover:bg-gray-700"><Link to={"/logoff"} style={{width:"100%"}}> Logoff</Link></div>

                </div>

                <div>
                    {/*<div className="mt-2 flex p-3 text-white bg-blue-500 rounded cursor-pointer text-center text-sm">
                        Contact Support
                    </div>*/}
                </div>

            </div>
        </aside>  
    );
}



export default SideBar;