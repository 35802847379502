import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    Input,
    InputLabel, ListItemText,
    MenuItem,
    OutlinedInput,
    Popper,
    Select,
    SelectChangeEvent,
    TextField, Tooltip
} from "@mui/material";
import DarDropdown from "../../../interfaces/DarDropDown.interface";
import {
    getDropDownBody,
    getDropDownALPRCamera,
    getDropDownColor,
    getDropDownMake, getDropDownModel,
    getDropDownQuery, getDropDownTrim, getDropDownYear
} from "../../../services/getDropdown.service";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    CheckBoxSelection,
    DropDownListComponent,
    FilteringEventArgs,
    Inject,
    MultiSelectComponent
} from "@syncfusion/ej2-react-dropdowns";
import { Query } from '@syncfusion/ej2-data';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface DropDownsProps {
    HandleDropDowns: any
    clear:number
    tool?: string;
    alprSelected?: boolean;
}

const  ArrestDropDowns: React.FC<DropDownsProps> = ({HandleDropDowns, clear, tool}) => {
    const [DDSex, setDDSex] = React.useState<any[]>([])
    const [DDCity, setDDCity] = React.useState<any[]>([])
    const [DDResidentCounty, setDDResidentCounty] = React.useState<any[]>([])
    const [DDState, setDDState] = React.useState<any[]>([])
    const [DDStatute, setDDStatute] = React.useState<any>({})
    const [DDAgencyArrested, setDDAgencyArrested] = React.useState<any[]>([])
    const [DDArrestedCounty, setDDArrestedCounty] = React.useState<any[]>([])
    const [DDInfoDept, setDDInfoDept] = React.useState<any[]>([])
    const [DDArrestType, setDDArrestType] = React.useState<any[]>([])
    const [DDIndicator, setDDIndicator] = React.useState<any[]>([])
    const [DDNibrs, setDDNibrs] = React.useState<any[]>([])
    const [DDPersonRole, setDDPersonRole] = React.useState<any[]>([])
    const [DDProfession, setDDProfession] = React.useState<any[]>([])
    const [DDPlaceOfBirth, setDDPlaceOfBirth] = React.useState<any[]>([])
    
    const [appliedDDSex, setAppliedDDSex] = React.useState<any[]>([])
    const [appliedDDCity, setAppliedDDCity] = React.useState<any[]>([])
    const [appliedDDResidentCounty, setAppliedDDResidentCounty] = React.useState<any[]>([])
    const [appliedDDState, setAppliedDDState] = React.useState<any[]>([])
    const [appliedDDStatute, setAppliedDDStatute] = React.useState<any[]>([])
    const [appliedDDAgencyArrested, setAppliedDDAgencyArrested] = React.useState<any[]>([])
    const [appliedDDArrestedCounty, setAppliedDDArrestedCounty] = React.useState<any[]>([])
    const [appliedDDInfoDept, setAppliedDDInfoDept] = React.useState<any[]>([])
    const [appliedDDArrestType, setAppliedDDArrestType] = React.useState<any[]>([])
    const [appliedDDIndicator, setAppliedDDIndicator] = React.useState<any[]>([])
    const [appliedDDNibrs, setAppliedDDNibrs] = React.useState<any[]>([])
    const [appliedDDPersonRole, setAppliedDDPersonRole] = React.useState<any[]>([])
    const [appliedDDProfession, setAppliedDDProfession] = React.useState<any[]>([])
    const [appliedDDPlaceOfBirth, setAppliedDDPlaceOfBirth] = React.useState<any[]>([])
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const parseStatute = (data:any):any => {
        let temp = [];
        for (const [key, options] of Object.entries(data)) {
            let options1:any = options;
            for(const item of options1){
                temp.push({
                    key: item.Key + ' ' + item.Value,
                    value: item.Key,
                    group: key
                })
            }
        }
        return temp;
    }
    const statuteFields: object = { groupBy: 'group', text: 'key', value: 'value' };

    const statuteFiltering = (args: FilteringEventArgs) =>{
        let query = new Query();
        query = (args.text !== "") ? query.where("key", "contains", args.text, true) : query;
        args.updateData(DDStatute, query);
    }
    
    const loadDropdowns = async () => {
        if(!DDLoaded){
            setDDLoaded(true)
            switch(tool){
                case("arrest"):
                    getDropDownQuery('Sex').then((res)=>{setDDSex(res)})
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('State').then((res)=>{setDDState(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('PoliceStopDept').then((res)=>{setDDInfoDept(res)})
                    getDropDownQuery('ArrestType').then((res) => { setDDArrestType(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    getDropDownQuery('PlaceOfBirth').then((res) => { setDDPlaceOfBirth(res) })
                break;
                case("frequent"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('State').then((res)=>{setDDState(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('PoliceStopDept').then((res)=>{setDDInfoDept(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("nearby"):
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("shooting"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                break;
                case("openCaseIncident"):
                    getDropDownQuery('CADRMSAgency').then((res)=>{setDDCity(res)})
                    getDropDownQuery('Nibrs').then((res) => { setDDNibrs(res) })
                    getDropDownQuery('PersonRole').then((res) => { setDDPersonRole(res) })
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("codis"):
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("analysis"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('State').then((res)=>{setDDState(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("parole"):
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("prisonerInformation"):
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("profession"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('State').then((res)=>{setDDState(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    getDropDownQuery('ProfessionType').then((res) => { setDDProfession(res) })
                break;
                case("statute"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('PoliceStopDept').then((res)=>{setDDInfoDept(res)})
                break;
                case("violent"):
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("crumb"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                    getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                    getDropDownQuery('State').then((res)=>{setDDState(res)})
                    getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                    getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                    getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case("shotSpotter"):
                    getDropDownQuery('City').then((res)=>{setDDCity(res)})
                break;
                case("StolenVehicle"):
                    getDropDownQuery('State').then((res)=>{setDDState(res); setAppliedDDState(["NJ"])})
                break;
                case("GVRTF"):
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                break;
                case ("towed"):
                    getDropDownQuery('PoliceStopDept').then((res) => { setDDInfoDept(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break; 
            }
            /*
                getDropDownQuery('Sex').then((res)=>{setDDSex(res)})
                getDropDownQuery('City').then((res)=>{setDDCity(res)})
                getDropDownQuery('ResidentCounty').then((res)=>{setDDResidentCounty(res)})
                getDropDownQuery('State').then((res)=>{setDDState(res)})
                getDropDownQuery('Statute').then((res)=>{setDDStatute(parseStatute(res))})
                getDropDownQuery('AgencyArrested').then((res)=>{setDDAgencyArrested(res)})
                getDropDownQuery('ArrestedCounty').then((res)=>{setDDArrestedCounty(res)})
                getDropDownQuery('PoliceStopDept').then((res)=>{setDDInfoDept(res)})
                getDropDownQuery('ArrestType').then((res)=>{setDDArrestType(res)})
                getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                getDropDownQuery('Nibrs').then((res) => { setDDNibrs(res) })
                getDropDownQuery('PersonRole').then((res) => { setDDPersonRole(res) })
                getDropDownQuery('Profession').then((res) => { setDDProfession(res) })
            */
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "Sex": DDSex?.length == appliedDDSex?.length ? [] : appliedDDSex,
            "City": DDCity?.length == appliedDDCity?.length ? [] : appliedDDCity,
            "ResidentCounty": DDResidentCounty?.length == appliedDDResidentCounty?.length ? [] : appliedDDResidentCounty,
            "State": DDState?.length == appliedDDState?.length ? [] : appliedDDState,
            "Statute": DDStatute?.length == appliedDDStatute?.length ? [] : appliedDDStatute,
            "AgencyArrested": DDAgencyArrested?.length == appliedDDAgencyArrested?.length ? [] : appliedDDAgencyArrested,
            "ArrestedCounty": DDArrestedCounty?.length == appliedDDArrestedCounty?.length ? [] : appliedDDArrestedCounty,
            "PoliceStopDept": DDInfoDept?.length == appliedDDInfoDept?.length ? [] : appliedDDInfoDept,
            "ArrestType": DDArrestType?.length == appliedDDArrestType?.length ? [] : appliedDDArrestType,
            "Indicator": DDIndicator?.length == appliedDDIndicator?.length ? [] : appliedDDIndicator,
            "Nibrs": DDNibrs?.length == appliedDDNibrs?.length ? [] : appliedDDNibrs,
            "PersonRole": DDPersonRole?.length == appliedDDPersonRole?.length ? [] : appliedDDPersonRole,
            "Profession": DDProfession?.length == appliedDDProfession?.length ? [] : appliedDDProfession,
            "PlaceOfBirth": DDPlaceOfBirth?.length == appliedDDPlaceOfBirth?.length ? [] : appliedDDPlaceOfBirth,
        })
    }
    
    React.useEffect(() => {
        submitDropDowns();
    },[ appliedDDSex,
        appliedDDCity, 
        appliedDDResidentCounty, 
        appliedDDState,
        appliedDDStatute,
        appliedDDAgencyArrested, 
        appliedDDArrestedCounty, 
        appliedDDInfoDept,
        appliedDDArrestType, 
        appliedDDIndicator,
        appliedDDNibrs,
        appliedDDPersonRole,
        appliedDDProfession,
        appliedDDPlaceOfBirth,
    ])

    React.useEffect(() => {
        setAppliedDDSex([])
        setAppliedDDCity([])
        setAppliedDDResidentCounty([]) 
        setAppliedDDState([])
        setAppliedDDStatute([])
        setAppliedDDAgencyArrested([])
        setAppliedDDArrestedCounty([])
        setAppliedDDInfoDept([])
        setAppliedDDArrestType([])
        setAppliedDDIndicator([])
        setAppliedDDNibrs([])
        setAppliedDDPersonRole([])
        setAppliedDDProfession([])
        setAppliedDDPlaceOfBirth([])
    },[clear])

    React.useEffect(() => {
        if(!DDLoaded){
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props:any) {
    return <Popper {...props} style={{"maxWidth":"fit-content"}} placement="bottom-start" />;
    };
    

    return (
       <div>
           {(tool === "arrest") ?
               <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                   <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDSex} change={(e)=> {setAppliedDDSex(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDSex} placeholder="Sex" mode="CheckBox">
                       <Inject services={[CheckBoxSelection]} />
                   </MultiSelectComponent>
               </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === "statute" || tool === 'shooting' || tool === 'openCaseIncident' || tool === 'profession' || tool === 'analysis' || tool === 'crumb' || tool === 'shotSpotter' ) ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDCity} change={(e)=> {setAppliedDDCity(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDCity} placeholder="City" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === 'profession' || tool === 'prisonerInformation' || tool === 'analysis' || tool === 'shooting' || tool === 'crumb') ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDResidentCounty} change={(e)=> {setAppliedDDResidentCounty(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDResidentCounty} placeholder="Resident County" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === 'profession' || tool === 'analysis' || tool === 'crumb' || tool === 'StolenVehicle') ?
                    <FormControl sx={tool == "StolenVehicle" ? {minWidth:510, maxWidth:510}:{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                        <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDState} change={(e)=> {setAppliedDDState(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDState} placeholder="State" mode="CheckBox">
                            <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                    </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === "statute" || tool === 'openCaseIncident' || tool === 'profession' || tool === 'nearby' || tool === 'analysis' || tool === "codis" || tool === 'crumb') ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDStatute} change={(e)=> {setAppliedDDStatute(e.value)}} fields={statuteFields} dataSource={DDStatute} placeholder="Statutes" mode="CheckBox" enableGroupCheckBox={true} filtering={statuteFiltering}  allowFiltering={true} filterBarPlaceholder="Search Statutes">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === "parole" || tool === "codis" || tool === "statute" || tool === 'shooting' || tool == 'profession' || tool === 'nearby' || tool === 'prisonerInformation' || tool === 'analysis' || tool === 'crumb') ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDAgencyArrested} change={(e)=> {setAppliedDDAgencyArrested(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDAgencyArrested} placeholder="Agency Arrested" mode="CheckBox" showSelectAll={true}>
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === "parole" || tool === "codis" || tool === "statute" || tool === 'profession' || tool === 'nearby' || tool === 'prisonerInformation' || tool === 'analysis' || tool === 'crumb') ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDArrestedCounty} change={(e)=> {setAppliedDDArrestedCounty(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDArrestedCounty} placeholder="Arrested County" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
                : <></>}
            {(tool === "arrest") ?
                <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDPlaceOfBirth} change={(e) => { setAppliedDDPlaceOfBirth(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDPlaceOfBirth} placeholder="Place Of Birth" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "statute" || tool === "towed") ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDInfoDept} change={(e)=> {setAppliedDDInfoDept(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDInfoDept} placeholder="PoliceStop Dept" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
            : <></> }
            {(tool === "arrest") ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDArrestType} change={(e)=> {setAppliedDDArrestType(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDArrestType} placeholder="Arrest Type" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
            : <></> }
            {(tool === "arrest" || tool === "frequent" || tool === "parole" || tool === "codis" || tool === 'openCaseIncident' || tool === 'profession' || tool === 'nearby' || tool === 'prisonerInformation' || tool === "towed" || tool === 'crumb' || tool === 'GVRTF') ?
                <FormControl sx={{ m: tool == 'GVRTF' ? 0 : 1 ,minWidth: tool == 'GVRTF' ? 510 : 170, maxWidth: tool == 'GVRTF' ? 510 : 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDIndicator} change={(e)=> {setAppliedDDIndicator(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDIndicator} placeholder="Indicator" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
                : <></>}
            {(tool === 'profession') ?
            <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDProfession} change={(e)=> {setAppliedDDProfession(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDProfession} placeholder="Profession" mode="CheckBox">
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            : <></>}
            {(tool === 'openCaseIncident') ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDNibrs} change={(e)=> {setAppliedDDNibrs(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDNibrs} placeholder="Nibrs" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
                : <></>}
            {(tool === 'openCaseIncident') ?
                <FormControl sx={{ m: 1 ,minWidth: 170, maxWidth: 170}}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto"  popupHeight='400px' popupWidth={"400px"} value={appliedDDPersonRole} change={(e)=> {setAppliedDDPersonRole(e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDPersonRole} placeholder="Person Role" mode="CheckBox">
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
                : <></>}
       </div>
    );
}

export const VinDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear, tool = 'vin'}) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDVehicleMake, setDDVehicleMake] = React.useState<any[]>([])
    const [DDVehicleBody, setDDVehicleBody] = React.useState<any[]>([])
    const [DDVehicleModel, setDDVehicleModel] = React.useState<any[]>([])
    const [DDVehicleTrim, setDDVehicleTrim] = React.useState<any[]>([])
    const [DDVehicleYear, setDDVehicleYear] = React.useState<any[]>([])
    const [DDVehicleColor, setDDVehicleColor] = React.useState<any[]>([])
    //const [DDVehicleEngineType, setDDVehicleEngineType] = React.useState<any[]>([])
    //const [DDVehicleTires, setDDVehicleTires] = React.useState<any[]>([])
    //const [DDVehicleTrackFront, setDDVehicleTrackFront] = React.useState<any[]>([])
    //const [DDVehicleTrackRear, setDDVehicleTrackRear] = React.useState<any[]>([])
    //const [DDVehicleStandardSeating, setDDVehicleStandardSeating] = React.useState<any[]>([])
    //const [DDVehicleAlloyWheels, setDDVehicleAlloyWheels] = React.useState<any[]>([])
    //const [DDVehicleChromeWheels, setDDVehicleChromeWheels] = React.useState<any[]>([])
    //const [DDVehicleLeatherSeat, setDDVehicleLeatherSeat] = React.useState<any[]>([])
    //const [DDVehicleRearWiper, setDDVehicleRearWiper] = React.useState<any[]>([])
    //const [DDVehicleGenuineWoodTrim, setDDVehicleGenuineWoodTrim] = React.useState<any[]>([])

    const [appliedDDVehicleMake, setAppliedDDVehicleMake] = React.useState<any[]>([])
    const [appliedDDVehicleBody, setAppliedDDVehicleBody] = React.useState<any[]>([])
    const [appliedDDVehicleModel, setAppliedDDVehicleModel] = React.useState<any[]>([])
    const [appliedDDVehicleTrim, setAppliedDDVehicleTrim] = React.useState<any[]>([])
    const [appliedDDVehicleYear, setAppliedDDVehicleYear] = React.useState<any[]>([])
    const [appliedDDVehicleColor, setAppliedDDVehicleColor] = React.useState<any[]>([])    
    //const [appliedDDVehicleEngineType, setAppliedDDVehicleEngineType] = React.useState<any[]>([])
    //const [appliedDDVehicleTires, setAppliedDDVehicleTires] = React.useState<any[]>([])
    //const [appliedDDVehicleTrackFront, setAppliedDDVehicleTrackFront] = React.useState<any[]>([])
    //const [appliedDDVehicleTrackRear, setAppliedDDVehicleTrackRear] = React.useState<any[]>([])
    //const [appliedDDVehicleStandardSeating, setAppliedDDVehicleStandardSeating] = React.useState<any[]>([])
    //const [appliedDDVehicleAlloyWheels, setAppliedDDVehicleAlloyWheels] = React.useState<any[]>([])
    //const [appliedDDVehicleChromeWheels, setAppliedDDVehicleChromeWheels] = React.useState<any[]>([])
    //const [appliedDDVehicleLeatherSeat, setAppliedDDVehicleLeatherSeat] = React.useState<any[]>([])
    //const [appliedDDVehicleRearWiper, setAppliedDDVehicleRearWiper] = React.useState<any[]>([])
    //const [appliedDDVehicleGenuineWoodTrim, setAppliedDDVehicleGenuineWoodTrim] = React.useState<any[]>([])

    React.useEffect(() => {
        let r:any = {
            makeList: [],
            bodyList: [],
            modelList: [],
            trimList: [],
            yearList: [],
            colorList: []
        }
        for(let i of appliedDDVehicleMake){
            let obj = {key:i, value:i}
           
            r.makeList.push(obj)
        }
        getDropDownModel(r).then((res) => { setDDVehicleModel(res) })
        getDropDownBody(r).then((res) => { setDDVehicleBody(res) })
    }, [appliedDDVehicleMake])

    React.useEffect(() => {
        let r:any = {
            makeList: [],
            bodyList: [],
            modelList: [],
            trimList: [],
            yearList: [],
            colorList: []
        }
        for(let i of appliedDDVehicleMake){
            let obj = {key:i, value:i}

            r.makeList.push(obj)
        }
        for(let i of appliedDDVehicleModel){
            let obj = {key:i, value:i}
            r.modelList.push(obj)
        }
        for (let i of appliedDDVehicleBody) {
            let obj = { key: i, value: i }
            r.bodyList.push(obj)
        }
        getDropDownYear(r).then((res) => { setDDVehicleYear(res) })
        getDropDownTrim(r).then((res) => { setDDVehicleTrim(res) })
    }, [appliedDDVehicleModel, appliedDDVehicleBody])
    
    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)

            getDropDownMake( {}).then((res) => { setDDVehicleMake(res) })
            //getDropDownQuery('VehicleBody').then((res) => { setDDVehicleBody(res) })
            //getDropDownQuery('VehicleModel').then((res) => { setDDVehicleModel(res) })
            //getDropDownQuery('VehicleTrim').then((res) => { setDDVehicleTrim(res) })
            //getDropDownQuery('VehicleYear').then((res) => { setDDVehicleYear(res) })
            getDropDownColor({}).then((res) => { setDDVehicleColor(res) }) 
            //getDropDownQuery('VehicleEngineType').then((res) => { setDDVehicleEngineType(res) })
            //getDropDownQuery('VehicleTires').then((res) => { setDDVehicleTires(res) })
            //getDropDownQuery('VehicleTrackFront').then((res) => { setDDVehicleTrackFront(res) })
            //getDropDownQuery('VehicleTrackRear').then((res) => { setDDVehicleTrackRear(res) })
            //getDropDownQuery('VehicleStandardSeating').then((res) => { setDDVehicleStandardSeating(res) })
            //getDropDownQuery('VehicleAlloyWheels').then((res) => { setDDVehicleAlloyWheels(res) })
            //getDropDownQuery('VehicleChromeWheels').then((res) => { setDDVehicleChromeWheels(res) })
            //getDropDownQuery('VehicleLeatherSeat').then((res) => { setDDVehicleLeatherSeat(res) })
            //getDropDownQuery('VehicleRearWiper').then((res) => { setDDVehicleRearWiper(res) })
            //getDropDownQuery('VehicleGenuineWoodTrim').then((res) => { setDDVehicleGenuineWoodTrim(res) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "VehicleMake": DDVehicleMake?.length == appliedDDVehicleMake?.length? []:appliedDDVehicleMake,
            "VehicleBody": DDVehicleBody?.length == appliedDDVehicleBody?.length? []:appliedDDVehicleBody,
            "VehicleModel": DDVehicleModel?.length == appliedDDVehicleModel?.length? []:appliedDDVehicleModel,
            "VehicleTrim": DDVehicleTrim?.length == appliedDDVehicleTrim?.length? []:appliedDDVehicleTrim,
            "VehicleYear": DDVehicleYear?.length == appliedDDVehicleYear?.length? []:appliedDDVehicleYear,
            "VehicleColor": DDVehicleColor?.length == appliedDDVehicleColor?.length? []: appliedDDVehicleColor,            
            //"VehicleEngineType": appliedDDVehicleEngineType,
            //"VehicleTires": appliedDDVehicleTires,
            //"VehicleTrackFront": appliedDDVehicleTrackFront,
            //"VehicleTrackRear": appliedDDVehicleTrackRear,
            //"VehicleStandardSeating": appliedDDVehicleStandardSeating,
            //"VehicleAlloyWheels": appliedDDVehicleAlloyWheels,
            //"VehicleChromeWheels": appliedDDVehicleChromeWheels,
            //"VehicleLeatherSeat": appliedDDVehicleLeatherSeat,
            //"VehicleRearWiper": appliedDDVehicleRearWiper,
            //"VehicleGenuineWoodTrim": appliedDDVehicleGenuineWoodTrim,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDVehicleColor,
        appliedDDVehicleModel,
        appliedDDVehicleTrim,
        appliedDDVehicleBody,
        appliedDDVehicleYear,
        appliedDDVehicleMake,
        //appliedDDVehicleEngineType,
        //appliedDDVehicleTires,
        //appliedDDVehicleTrackFront,
        //appliedDDVehicleTrackRear,
        //appliedDDVehicleStandardSeating,
        //appliedDDVehicleAlloyWheels,
        //appliedDDVehicleChromeWheels,
        //appliedDDVehicleLeatherSeat,
        //appliedDDVehicleRearWiper,
        //appliedDDVehicleGenuineWoodTrim,
    ])

    React.useEffect(() => { 
        setAppliedDDVehicleMake([])
        setAppliedDDVehicleBody([])
        setAppliedDDVehicleModel([])
        setAppliedDDVehicleTrim([])
        setAppliedDDVehicleYear([])
        setAppliedDDVehicleColor([])        
        //setAppliedDDVehicleEngineType([])
        //setAppliedDDVehicleTires([])
        //setAppliedDDVehicleTrackFront([])
        //setAppliedDDVehicleTrackRear([])
        //setAppliedDDVehicleStandardSeating([])
        //setAppliedDDVehicleAlloyWheels([])
        //setAppliedDDVehicleChromeWheels([])
        //setAppliedDDVehicleLeatherSeat([])
        //setAppliedDDVehicleRearWiper([])
        //setAppliedDDVehicleGenuineWoodTrim([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };


    return (
        <div>
            {(tool === "vin" || tool === 'towed' || tool == 'StolenVehicle' && DDVehicleMake && DDVehicleMake.length > 0) ?
                <FormControl sx={tool == 'StolenVehicle' ? {marginRight:2, minWidth:170, maxWidth: 170} : {m: 1, minWidth: 170, maxWidth: 170 }}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleMake} change={(e) => { setAppliedDDVehicleMake(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDVehicleMake} placeholder="Vehicle Make" mode="CheckBox" showSelectAll={true} allowCustomValue={true}>
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </FormControl>
                : <></>}

            {(tool === "vin") ?
            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>                             
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleBody} change={(e) => { setAppliedDDVehicleBody(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDVehicleBody.length > 0 ? DDVehicleBody : []} placeholder="Vehicle Body" mode="CheckBox" showSelectAll={true} allowCustomValue={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            : <></>}            

            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle') ?
            <FormControl sx={tool == 'StolenVehicle' ? {marginRight:2, minWidth: 170, maxWidth: 170} : {m: 1, minWidth: 170, maxWidth: 170 }}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleModel} change={(e) => { setAppliedDDVehicleModel(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDVehicleModel} placeholder={appliedDDVehicleMake?.length == 0 ? "Requires Make" : "Vehicle Model"} mode="CheckBox" showSelectAll={true} allowCustomValue={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            : <></>}

            {(tool === "vin") ?
            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleTrim} change={(e) => { setAppliedDDVehicleTrim(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDVehicleTrim} placeholder="Vehicle Trim" mode="CheckBox" showSelectAll={true} allowCustomValue={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            : <></>}

            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle') ?    
            <FormControl sx={tool == 'StolenVehicle' ? {marginRight:2, minWidth: 170, maxWidth: 170} : {m: 1, minWidth: 170, maxWidth: 170 }}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleYear} change={(e) => { setAppliedDDVehicleYear(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDVehicleYear} placeholder={appliedDDVehicleModel?.length == 0 ? "Requires Model" : "Vehicle Year"} mode="CheckBox" showSelectAll={true} allowCustomValue={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            : <></>}

            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle') ?
            <FormControl sx={tool == 'StolenVehicle' ? {minWidth: 170, maxWidth: 170} : {m: 1, minWidth: 170, maxWidth: 170 }}>
                    <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleColor} change={(e) => { setAppliedDDVehicleColor(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDVehicleColor} placeholder="Vehicle Color" mode="CheckBox" showSelectAll={true} allowCustomValue={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            : <></>}
                
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleEngineType	        } change={(e)=> { setAppliedDDVehicleEngineType	        (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleEngineType	            } placeholder="VehicleEngineType" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
                
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleTires			    } change={(e)=> { setAppliedDDVehicleTires			    (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleTires			        } placeholder="VehicleTires" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
                
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleTrackFront           } change={(e)=> { setAppliedDDVehicleTrackFront           (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleTrackFront            } placeholder="VehicleTrackFront" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
                
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleTrackRear		    } change={(e)=> { setAppliedDDVehicleTrackRear		    (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleTrackRear		        } placeholder="VehicleTrackRear" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
              
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleStandardSeating      } change={(e)=> { setAppliedDDVehicleStandardSeating      (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleStandardSeating       } placeholder="VehicleStandardSeating" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
               
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleAlloyWheels	        } change={(e)=> { setAppliedDDVehicleAlloyWheels	        (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleAlloyWheels	        } placeholder="VehicleAlloyWheels" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
             
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleChromeWheels	        } change={(e)=> { setAppliedDDVehicleChromeWheels	        (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleChromeWheels	    } placeholder="VehicleChromeWheels" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
                
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleLeatherSeat	        } change={(e)=> { setAppliedDDVehicleLeatherSeat	        (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleLeatherSeat	        } placeholder="VehicleLeatherSeat" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
               
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleRearWiper		    } change={(e)=> { setAppliedDDVehicleRearWiper		        (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleRearWiper		    } placeholder="VehicleRearWiper" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
             
            {/*<FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>*/}
            {/*    <MultiSelectComponent  popupHeight='400px' popupWidth={"400px"} value={appliedDDVehicleGenuineWoodTrim      } change={(e)=> { setAppliedDDVehicleGenuineWoodTrim        (e.value)}} fields={{text: 'Value', value: 'Key'}} dataSource={DDVehicleGenuineWoodTrim     } placeholder="VehicleGenuineWoodTrim" mode="CheckBox">*/}
            {/*        <Inject services={[CheckBoxSelection]} />*/}
            {/*    </MultiSelectComponent>*/}
            {/*</FormControl>*/}
        </div>
    );
}

export const ALPRDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear}) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)
    const [DDALPRServer, setDDALPRServer] = React.useState<any[]>([])
    const [DDALPRCamera, setDDALPRCamera] = React.useState<any[]>([])
    const [DDALPRCameraLatLong, setDDALPRCameraLatLong] = React.useState<any[]>([])
    const [DDALPRReason, setDDALPRReason] = React.useState<any[]>([])

    const [appliedDDALPRServer, setAppliedDDALPRServer] = React.useState<any[]>([])
    const [appliedDDALPRCamera, setAppliedDDALPRCamera] = React.useState<any[]>([])
    const [appliedDDALPRReason, setAppliedDDALPRReason] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('ALPRServer').then((res) => { setDDALPRServer(res) })
            getDropDownQuery('ALPRReason').then((res) => { setDDALPRReason(res) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "ALPRServer": DDALPRServer?.length == appliedDDALPRServer?.length ? [] : appliedDDALPRServer,
            "ALPRCamera": DDALPRCamera?.length == appliedDDALPRCamera?.length ? [] : appliedDDALPRCamera,
            "ALPRReason": DDALPRReason?.length == appliedDDALPRReason?.length ? [] : appliedDDALPRReason,
            "ALPRCameraLatLong": DDALPRCameraLatLong
        })
    }

    React.useEffect(() => {
        let r: any = {
            ServerList: [],
        }
        for (let i of appliedDDALPRServer) {
            let obj = { key: i, value: i }

            r.ServerList.push(obj)
        }
        getDropDownALPRCamera(r).then((qRes) => { setDDALPRCamera(JSON.parse(qRes.JsonObject)); setDDALPRCameraLatLong(qRes.AnyTable)})  //getDropDownALPRCamera returns 2 sets of data Distinct CameraList, Camera with Lat&Long (used in GeoCode)
        setAppliedDDALPRCamera([])
        
    }, [appliedDDALPRServer])

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDALPRServer,
        appliedDDALPRCamera,
        appliedDDALPRReason,
        DDALPRCameraLatLong,
    ])

    React.useEffect(() => {
        setAppliedDDALPRServer([])
        setAppliedDDALPRCamera([])
        setAppliedDDALPRReason([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };


    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDALPRServer} change={(e) => { setAppliedDDALPRServer(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDALPRServer} placeholder="ALPR Server" mode="CheckBox" showSelectAll={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                {/*<DropDownListComponent id="ddlelement" value={appliedDDALPRReason[0]} dataSource={DDALPRReason} placeholder="ALPRReason" />*/}
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDALPRReason} change={(e) => { setAppliedDDALPRReason(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDALPRReason} placeholder="ALPR Reason" mode="CheckBox">
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
            
            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" title="Do not select camera option" popupHeight='400px' popupWidth={"400px"} value={appliedDDALPRCamera} change={(e) => { setAppliedDDALPRCamera(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDALPRCamera} placeholder={appliedDDALPRCamera?.length > 0 ? "ALPR Camera" : "All Cameras Selected"} mode="CheckBox">
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
        </div>
    );
}

export const ETicketDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDETicketAgency, setDDETicketAgency] = React.useState<any[]>([])
    const [appliedDDETicketAgency, setAppliedDDETicketAgency] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('ETicketAgency').then((res) => { setDDETicketAgency(res) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "ETicketAgency": DDETicketAgency?.length == appliedDDETicketAgency?.length ? [] : appliedDDETicketAgency,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDETicketAgency,
    ])

    React.useEffect(() => {
        setAppliedDDETicketAgency([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };


    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDETicketAgency} change={(e) => { setAppliedDDETicketAgency(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDETicketAgency} placeholder="ETicket Agency" mode="CheckBox" showSelectAll={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
        </div>
    );
}

export const ParkMobileDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear, alprSelected }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDParkMobileAgency, setDDParkMobileAgency] = React.useState<any[]>([])
    /*const [dropdownLength, setParkMobileAgencyLength] = React.useState<any>([])*/
    const [appliedDDParkMobileAgency, setAppliedDDParkMobileAgency] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        console.log(alprSelected)
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('ParkMobileAgency').then((res) => { setDDParkMobileAgency(res) /*; if(alprSelected){setAppliedDDParkMobileAgency(["American Dream Mall"])} */ })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "ParkMobileAgency": DDParkMobileAgency?.length == appliedDDParkMobileAgency?.length ? [] : appliedDDParkMobileAgency,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDParkMobileAgency,
    ])
    
    React.useEffect(() => {
        setAppliedDDParkMobileAgency([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true);
            loadDropdowns();
            /*console.log('ParkMobileAgency Len: ' +dropdownLength);*/
        }
    });
    
    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 170, maxWidth: 170 }}>
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDParkMobileAgency} change={(e) => { setAppliedDDParkMobileAgency(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDParkMobileAgency} placeholder="Parking Area" mode="CheckBox" showSelectAll={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
        </div>
    );
}

export const StolenStateDropdown: React.FC<DropDownsProps> = ({ HandleDropDowns, clear }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDStolenState, setDDStolenState] = React.useState<any[]>([])
    const [appliedDDStolenState, setAppliedDDStolenState] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('State').then((res) => { setDDStolenState(res); setAppliedDDStolenState(["NJ"]) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "StolenState": DDStolenState?.length == appliedDDStolenState?.length ? [] : appliedDDStolenState,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDStolenState,
    ])

    React.useEffect(() => {
        setAppliedDDStolenState([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };


    return (
        <div>
            <FormControl sx={{ minWidth: 510, maxWidth: 510 }}>
                <MultiSelectComponent cssClass="e-outline" floatLabelType="Auto" popupHeight='400px' popupWidth={"400px"} value={appliedDDStolenState} change={(e) => { setAppliedDDStolenState(e.value) }} fields={{ text: 'Value', value: 'Key' }} dataSource={DDStolenState} placeholder="State" mode="CheckBox" showSelectAll={true}>
                    <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
            </FormControl>
        </div>
    );
}


export default ArrestDropDowns