import React from 'react'
import {
    Button, ButtonGroup, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip
} from "@mui/material";
import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListRounded  from '@mui/icons-material/FilterListRounded';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import MapIcon from "@mui/icons-material/Map";
import { showCrumbScore } from '../../../services/config.service';
import ReactECharts from "echarts-for-react";


interface SVGraphInfo{
    IncidentDate:string,
    Type:string,
    PlateSt:string,
    TotalRecs:number
}

interface SearchResultsProps {
    Persons: PersonInfo[]
    Graph: SVGraphInfo[]
}

const SearchResults: React.FC<SearchResultsProps> = ({Persons, Graph})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const handleDataTyping = () =>
    {
        if(Persons) {
            let tempP: any = [...Persons]

            for (let p of tempP) {
                if (p.StolenDate) {
                    p.StolenDate = new Date(p.StolenDate)
                }
                if (p.RecoveryDate) {
                    p.RecoveryDate = new Date(p.RecoveryDate)
                }
                if (p.UpdateDate) {
                    p.UpdateDate = new Date(p.UpdateDate)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    },[Persons])

    let grid: Grid | null;
    
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'StolenVehicle.xlsx';

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'StolenDate', direction: 'Descending' }
        ] };

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length>0? <>
                <StolenVehicleGraph Data={Graph}/>
                <span  style ={{display:"inline-block"}} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Records Found</h4>
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    //detailTemplate={getPersonSummary} this is the bt person summary
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting = {(event:any) => {console.log(event)}}
                    //rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='StolenType' headerText='Stolen Type' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='StolenDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Stolen Date' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='RecoveryDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Recovery Date' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='PlateNumber' headerText='Plate Number' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='PlateSt' headerText='Plate State' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Make'  headerText='Make'  width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Model' headerText='Model' width={140} minWidth={140} maxWidth={140}  customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Color' headerText='Color' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Trim' headerText='Trim' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Year' headerText='Year' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='CaseNo' headerText='ORI Number' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='UpdateDate' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Entry Date' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default SearchResults;

interface StolenVehicleGraphProps {
    Data: any
}

const StolenVehicleGraph: React.FC<StolenVehicleGraphProps> = ({Data})=> {
    var option;

    const handleXAxis = () => {
         let xAxis:any = new Set()
         if(Data)
         {
             for(let x of Data)
             {
                 let date = new Date(x.IncidentDate)
                 let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString()
                 xAxis.add(fullDate)
             }
         }
         return Array.from(xAxis)
    }
    
    const handleSeries = () => {
        let series: any = []
        const states = new Set();

        if(Data)
        {
            for(let x of Data) {
                states.add(x.PlateSt)
            }

            for(let x of Array.from(states))
            {
                let st = Data.filter((e:any)=>(e.PlateSt == x))
                let stolen = []
                let recovered = []

                for (let i of st)
                {
                    stolen.push(i?.Stolen)
                    recovered.push(i?.Recovered)
                }

                series.push({
                    name: x + " Stolen",
                    type: 'bar',
                    barGap: 0,
                    stack:x,
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: '{c}  {a}',
                    },
                    //color: getColor(x,'S'),
                    emphasis: {
                        focus: 'series'
                    },
                    data: stolen
                },)

                series.push({
                    name: x + " Recovered",
                    type: 'bar',
                    barGap: 0.5,
                    stack:x,
                    label: {
                        show: true,
                        position: 'left',
                        formatter: function (params:any) {
                            return Math.abs(params.value) + '  ' + params.seriesName;
                        },
                    },
                    //color: getColor(x,'R'),
                    emphasis: {
                        focus: 'series'
                    },
                    data: recovered
                },)
            }
        }
        return series
    }
    
    const getColor = (st:any, type:any) => {
        switch(st){
            case("NJ"):
                return (type == 'S' ? '#21412a' : '#2657da' )
            break;
            
        }
    }
    
    option = {
        tooltip: {
        },
        height:'95%',
        grid: {
            left: '3%',
            right: '3%',
            bottom: '3%',
            top: '3%',
            containLabel: true
        },
        legend: {
            data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        yAxis: [
            {
                type: 'category',
                axisTick: {show: false},
                data: handleXAxis()
            }
        ],
        xAxis: [
            {
                type: 'value'
            }
        ],
        series: handleSeries()
    };
    
    
    return(
        <div className = "border-gray-900 border -2">
            <ReactECharts option={option} />
        </div>
    )
}

/*
series: [
    {
        name: 'Forest',
        type: 'bar',
        barGap: 0,
        emphasis: {
            focus: 'series'
        },
        data: [320, 332, 301, 334, 390]
    },
    {
        name: 'Steppe',
        type: 'bar',
        emphasis: {
            focus: 'series'
        },
        data: [220, 182, 191, 234, 290]
    },
    {
        name: 'Desert',
        type: 'bar',
        emphasis: {
            focus: 'series'
        },
        data: [150, 232, 201, 154, 190]
    },
    {
        name: 'Wetland',
        type: 'bar',
        emphasis: {
            focus: 'series'
        },
        data: [98, 77, 101, 99, 40]
    }
]*/

/* let xAxis:any = new Set()
 if(Data)
 {
     for(let x of Data)
     {
         let date = new Date(x.IncidentDate)
         let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString()
         xAxis.add(fullDate)
     }
 }
 console.log(Array.from(xAxis))
 return Array.from(xAxis)*/

/*let stolen = st.filter((e:any)=>(e.Type == 'S'))
let recovered = st.filter((e:any)=>(e.Type == 'R'))
let stolenData = new Array(range).fill(0)
let recoveredData = new Array(range).fill(0)

for(let i of stolen)
{
    let date = new Date(i.IncidentDate)
    let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString()
    stolenData[XAxis.indexOf(fullDate)] = i.TotalRecs
}

for(let i of recovered)
{
    let date = new Date(i.IncidentDate)
    let fullDate = (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString()
    recoveredData[XAxis.indexOf(fullDate)] = i.TotalRecs
}

series.push({
    name: x + " Stolen",
    type: 'bar',
    barGap: 0,
    stack:x,
    label: {
        show: true,
        position: 'inside',
        formatter: '{c}  {a}',
    },
    //color: getColor(x,'S'),
    emphasis: {
        focus: 'series'
    },
    data: stolenData
},)

series.push({
    name: x + " Recovered",
    type: 'bar',
    barGap: 0.5,
    stack:x,
    label: {
        show: true,
        position: 'left',
        formatter: function (params:any) {
            return Math.abs(params.value) + '  ' + params.seriesName;
        },
    },
    //color: getColor(x,'R'),
    emphasis: {
        focus: 'series'
    },
    data: recoveredData
},)*/
/*                if(minD == null)
                {
                    minD = new Date(x.IncidentDate)
                    maxD = new Date(x.IncidentDate)
                }
                else if (minD > new Date(x.IncidentDate))
                {
                    minD = new Date(x.IncidentDate)
                }
                if (maxD && maxD < new Date(x.IncidentDate))
                {
                    maxD = new Date(x.IncidentDate)
                }
                */
/*if(minD && maxD) {
    range = (maxD.getTime() - minD.getTime());
    range = Math.ceil(range / (1000 * 3600 * 24));
    if(range != 1)
        range ++
}*/