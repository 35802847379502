import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import ETicketDetail from "../../interfaces/Detail/ETicketDetail.interface"
import { RunETicketDetailQuery } from "../../services/detail.service";
import { formatDate, formatDT} from "../../services/formatDate.service"

import './Detail.css'
import DetailMap from "./_DetailMap";

interface DetailProps {
    ID: string | null
    department: string | null
}


const ETicketDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<ETicketDetail>({})
    const [pin, setPin] = React.useState<any>({})

    const loadData = async () => {
        //console.log(ID, " ", department)
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunETicketDetailQuery(ID, department);
            setData(res)
            createPin(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data: any) => {
        let tPin: any = {}
        if (Data && Data?.Latitude && Data?.Longitude) {
            tPin = ({
                lat: Data?.Latitude,
                lng: Data?.Longitude,
                date: Data?.Entereddate,
                dept: Data?.Municipalityname.trim(),
                plate: Data?.Licenseplatenumber + " " + Data?.Licenseplatestatecode ? Data?.Licenseplatestatecode : "",
                detail:"eticket"
            })
        }
        setPin(tPin)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Statute Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data.ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Ticket Type</span><span className="DetailTableData">{Data.Tickettype}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Ticket Number</span><span className="DetailTableData">{Data.Ticketnumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Case Status Code</span><span className="DetailTableData">{Data.Casestatuscode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Case Type Code</span><span className="DetailTableData">{Data.Casetypecode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Entered Date</span><span className="DetailTableData">{formatDT(Data.Entereddate)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Charge Date</span><span className="DetailTableData">{formatDT(Data.Chargedate)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Charge Time</span><span className="DetailTableData">{Data.Chargetime}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Statute Name</span><span className="DetailTableData">{Data.Statutename}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Statute Detail</span><span className="DetailTableData">{Data.Statutedetail}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Violation description</span><span className="DetailTableData">{Data.Violationdescription}</span></div>
                    {/*<div className="DetailTableRow" ><span className="DetailTableLabel">Statute ID</span><span className="DetailTableData">{Data.Statuteid}</span></div>*/}
                    {/*<div className="DetailTableRow" ><span className="DetailTableLabel">Violation ID</span><span className="DetailTableData">{Data.Violationid}</span></div>*/}
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Court Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court Code</span><span className="DetailTableData">{Data.Ctcode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court CD</span><span className="DetailTableData">{Data.Courtcd}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court Name</span><span className="DetailTableData">{Data.Ctname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court State</span><span className="DetailTableData">{Data.Ctstatecode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court City</span><span className="DetailTableData">{Data.Ctcity}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court ZipFirst</span><span className="DetailTableData">{Data.Ctzipfirst}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court ZipLast</span><span className="DetailTableData">{Data.Ctziplast}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court Phone2</span><span className="DetailTableData">{Data.Ctphone2}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court Phone</span><span className="DetailTableData">{Data.Ctphone1}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Payable</span><span className="DetailTableData">{Data.Payable}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Pay Court Date</span><span className="DetailTableData">{formatDate(Data.Paycourtdate)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">County Name</span><span className="DetailTableData">{Data.Countyname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Hours Of Operation</span><span className="DetailTableData">{Data.Hoursofoperation}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Jurisdiction Code</span><span className="DetailTableData">{Data.Jurisdictioncode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Municipality Of Offense Code</span><span className="DetailTableData">{Data.Municipalityofoffensecode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Penalty</span><span className="DetailTableData">{Data.Penalty}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Payby Date</span><span className="DetailTableData">{formatDate(Data.Paybydate)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court Date</span><span className="DetailTableData">{formatDate(Data.Ctdate)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Court Time</span><span className="DetailTableData">{Data.Cttime}</span></div>
                </CardContent>
            </Card>

            {Data.Corpname || Data.Corpname !== "" ?
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-5 font-bold font text-xl text-blue-400">Corporation Information</h3>                        
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corpid</span><span className="DetailTableData">{Data.Corpid}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corp idstatecode</span><span className="DetailTableData">{Data.Corpidstatecode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corp name</span><span className="DetailTableData">{Data.Corpname}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corp addresscity</span><span className="DetailTableData">{Data.Corpaddresscity}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corp addressstatecode</span><span className="DetailTableData">{Data.Corpaddressstatecode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Corp addresszipfirst</span><span className="DetailTableData">{Data.Corpaddresszipfirst}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corp addressziplast</span><span className="DetailTableData">{Data.Corpaddressziplast}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Corp addressstreet1</span><span className="DetailTableData">{Data.Corpaddressstreet1}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Corp addressstreet2</span><span className="DetailTableData">{Data.Corpaddressstreet2}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">corp phone</span><span className="DetailTableData">{Data.Corpphone}</span></div>                        
                    </CardContent>
                </Card>
                :
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>                        
                        <h3 className="mb-5 font-bold font text-xl text-blue-400">Person Information</h3>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant First Name</span><span className="DetailTableData">{Data.Deffirstname}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Middle Name</span><span className="DetailTableData">{Data.Defmiddlename}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Last Name</span><span className="DetailTableData">{Data.Deflastname}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Sex</span><span className="DetailTableData">{Data.Defsexcode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Eye Colorcode</span><span className="DetailTableData">{Data.Defeyecolorcode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Eye Colordesc</span><span className="DetailTableData">{Data.Defeyecolordesc}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Birthdate</span><span className="DetailTableData">{formatDate(Data.Defbirthdate)}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Height</span><span className="DetailTableData">{Data.Defheight}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Weight</span><span className="DetailTableData">{Data.Defweight}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL Number</span><span className="DetailTableData">{Data.Dlnumber}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL State</span><span className="DetailTableData">{Data.Dlstatecode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL Expiration Date</span><span className="DetailTableData">{Data.Dlexpirationdate}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL Restrictionclasscode</span><span className="DetailTableData">{Data.Dlrestrictionclasscode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL Restrictionclassdesc</span><span className="DetailTableData">{Data.Dlrestrictionclassdesc}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL Restrictiontypecode</span><span className="DetailTableData">{Data.Dlrestrictiontypecode}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">DL Restrictiontypedesc</span><span className="DetailTableData">{Data.Dlrestrictiontypedesc}</span></div>
                    </CardContent>
                </Card>
            }
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Address</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Street1</span><span className="DetailTableData">{Data.Defaddressstreet1}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Street2</span><span className="DetailTableData">{Data.Defaddressstreet2}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant City</span><span className="DetailTableData">{Data.Defaddresscity}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant State</span><span className="DetailTableData">{Data.Defaddressstatecode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Zip First</span><span className="DetailTableData">{Data.Defaddresszipfirst}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Zip Last</span><span className="DetailTableData">{Data.Defaddressziplast}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Defendant Phone</span><span className="DetailTableData">{Data.Defphone}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Model Year</span><span className="DetailTableData">{Data.Modelyear}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Color Code</span><span className="DetailTableData">{Data.Vehiclecolorcode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Color Code Desc</span><span className="DetailTableData">{Data.Colorcodedesc}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Vin</span><span className="DetailTableData">{Data.Vehiclevin}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">License PlateNumber</span><span className="DetailTableData">{Data.Licenseplatenumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">License Plate Expiration Date</span><span className="DetailTableData">{Data.Licenseplateexpirationdate}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">License Plate State Code</span><span className="DetailTableData">{Data.Licenseplatestatecode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">MakeDesc</span><span className="DetailTableData">{Data.Makedesc}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Bodydesc</span><span className="DetailTableData">{Data.Vehiclebodydesc}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">parkingnotespeedmph</span><span className="DetailTableData">{Data.Parkingnotespeedmph}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Agency Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Agency ID</span><span className="DetailTableData">{Data.Agencyid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer ID</span><span className="DetailTableData">{Data.Officerid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer CD</span><span className="DetailTableData">{Data.Officercd}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer First Name</span><span className="DetailTableData">{Data.Officerfirstname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer MI</span><span className="DetailTableData">{Data.Officermiddlename}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Officer Last Name</span><span className="DetailTableData">{Data.Officerlastname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Municipality ID</span><span className="DetailTableData">{Data.Municipalityid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Municipality Name</span><span className="DetailTableData">{Data.Municipalityname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Case Number</span><span className="DetailTableData">{Data.Casenumber}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Incident Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Offense streetname</span><span className="DetailTableData">{Data.Offensestreetname}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Meter Number</span><span className="DetailTableData">{Data.Meternumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Latitude</span><span className="DetailTableData">{Data.Latitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Longitude</span><span className="DetailTableData">{Data.Longitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">mnsm zonenumber</span><span className="DetailTableData">{Data.Mnsmzonenumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">sd methodcode</span><span className="DetailTableData">{Data.Sdmethodcode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">sd methoddesc</span><span className="DetailTableData">{Data.Sdmethoddesc}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is Commercial License</span><span className="DetailTableData">{Data.Iscommerciallicense}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is commercialvehicle</span><span className="DetailTableData">{Data.Iscommercialvehicle}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is breathtest</span><span className="DetailTableData">{Data.Isbreathtest}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is constructionzone</span><span className="DetailTableData">{Data.Isconstructionzone}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is deathseriousbodyinjury</span><span className="DetailTableData">{Data.Isdeathseriousbodyinjury}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is personalinjury</span><span className="DetailTableData">{Data.Ispersonalinjury}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is hazardmaterial</span><span className="DetailTableData">{Data.Ishazardmaterial}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is omnibus</span><span className="DetailTableData">{Data.Isomnibus}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is outofservice</span><span className="DetailTableData">{Data.Isoutofservice}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is safecorridor</span><span className="DetailTableData">{Data.Issafecorridor}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Is sixtyfivemph</span><span className="DetailTableData">{Data.Issixtyfivemph}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Area</span><span className="DetailTableData">{Data.Area}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Road</span><span className="DetailTableData">{Data.Road}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Traffic</span><span className="DetailTableData">{Data.Traffic}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Visibility</span><span className="DetailTableData">{Data.Visibility}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Equipment</span><span className="DetailTableData">{Data.Equipment}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Equipment Operatorname</span><span className="DetailTableData">{Data.Equipmentoperatorname}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="eticket" /> </div> : <></>}
                </CardContent>
            </Card>
            {Data.Casetypecode === "C" ?
                <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                    <CardContent>
                        <h3 className="mb-4 font-bold font text-xl text-blue-400">Special Complaint</h3>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">OffenseDegree</span><span className="DetailTableData">{Data.Offensedegree}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">PhoneType</span><span className="DetailTableData">{Data.Phonetype}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">PhoneNumber</span><span className="DetailTableData">{Data.Phonenumber}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Email</span><span className="DetailTableData">{Data.Email}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Military</span><span className="DetailTableData">{Data.Military}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Police CaseNo</span><span className="DetailTableData">{Data.Policecaseno}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Court Room</span><span className="DetailTableData">{Data.Courtroom}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Desc Of Offense</span><span className="DetailTableData">{Data.Descofoffense}</span></div>
                        <div className="DetailTableRow" ><span className="DetailTableLabel">Appearance Required</span><span className="DetailTableData">{Data.Appearancerequired}</span></div>
                    </CardContent>
                </Card>
                :
                <></>
            }

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Other Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Deleted</span><span className="DetailTableData">{Data.Deleted}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Modified By</span><span className="DetailTableData">{Data.Modifiedby}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Modified On</span><span className="DetailTableData">{formatDT(Data.Modifiedon)}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Offense Street ID</span><span className="DetailTableData">{Data.Offensestreetid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Make Code</span><span className="DetailTableData">{Data.Makecode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Body Code</span><span className="DetailTableData">{Data.Vehiclebodycode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Shift ID</span><span className="DetailTableData">{Data.Shiftid}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Police Unit ID</span><span className="DetailTableData">{Data.Policeunitid}</span></div>                    
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Operator Idnumber</span><span className="DetailTableData">{Data.Operatoridnumber}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Unit Code</span><span className="DetailTableData">{Data.Unitcode}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Hour24</span><span className="DetailTableData">{Data.Hour24}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">officer</span><span className="DetailTableData">{Data.Officer}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Shift</span><span className="DetailTableData">{Data.Shift}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Period Filter</span><span className="DetailTableData">{Data.Periodfilter}</span></div>
                </CardContent>
            </Card>
        </div>
    );
}

export default ETicketDetailInfo;