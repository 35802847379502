import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import CadRmsDetail from "../../interfaces/Detail/CadRmsDetail.interface"
import { RunCadRmsDetailQuery } from "../../services/detail.service";
import { formatDate } from "../../services/formatDate.service"

import './Detail.css'

interface DetailProps {
    ID: string | null
    department: string | null
}


const CadRmsDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<CadRmsDetail>({})

    const loadData = async () => {
        //console.log(ID, " ", department)
        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunCadRmsDetailQuery(ID, department);
            setData(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Statute Information</h3>                    
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Person Information</h3>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Person Address</h3>
                    </CardContent>
            </Card>
        </div>
    );
}

export default CadRmsDetailInfo;