import React from 'react'
import {
    Button, ButtonGroup, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip
} from "@mui/material";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListRounded from '@mui/icons-material/FilterListRounded';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { showCrumbScore } from '../../../services/config.service';


interface SearchResultsProps {
    Persons: PersonInfo[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ Persons }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])


    const handleDataTyping = () => {
        if(Persons) {
            let tempP: any = [...Persons]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
                if (p.End_Date) {
                    p.End_Date = new Date(p.End_Date)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Persons])

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }


    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };
    

    let grid: Grid | null;
    const gridTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{ "marginRight": "3px" }}> <img src={CODISIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundCODIS} </span> : <span />}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Parole.xlsx';

            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("SSN1") as Column).visible = true;
            (grid.getColumnByField("FoundPhoto") as Column).visible = true;
            (grid.getColumnByField("FoundArrest") as Column).visible = true;
            (grid.getColumnByField("FoundETicket") as Column).visible = true;
            (grid.getColumnByField("FoundParkMobile") as Column).visible = true;
            (grid.getColumnByField("FoundALPR") as Column).visible = true;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = true;
            (grid.getColumnByField("FoundCODIS") as Column).visible = true;
            (grid.getColumnByField("FoundPrisonRelease") as Column).visible = true;
            (grid.getColumnByField("FoundGunTrace") as Column).visible = true;
            (grid.getColumnByField("FoundShooting") as Column).visible = true;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = true;
            (grid.getColumnByField("FoundNJGunTrace") as Column).visible = true;
            (grid.getColumnByField("FoundPrisonParole") as Column).visible = true;
            (grid.getColumnByField("FoundArrest") as Column).visible = true;
            (grid.getColumnByField("FoundViolent") as Column).visible = true;
            (grid.getColumnByField("FoundHumanTraffic") as Column).visible = true;
            (grid.getColumnByField("FoundProfession") as Column).visible = true;
            (grid.getColumnByField("FoundReferral") as Column).visible = true;
            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("SSN1") as Column).visible = false;
            (grid.getColumnByField("FoundPhoto") as Column).visible = false;
            (grid.getColumnByField("FoundArrest") as Column).visible = false;
            (grid.getColumnByField("FoundETicket") as Column).visible = false;
            (grid.getColumnByField("FoundParkMobile") as Column).visible = false;
            (grid.getColumnByField("FoundALPR") as Column).visible = false;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = false;
            (grid.getColumnByField("FoundCODIS") as Column).visible = false;
            (grid.getColumnByField("FoundPrisonRelease") as Column).visible = false;
            (grid.getColumnByField("FoundGunTrace") as Column).visible = false;
            (grid.getColumnByField("FoundShooting") as Column).visible = false;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = false;
            (grid.getColumnByField("FoundNJGunTrace") as Column).visible = false;
            (grid.getColumnByField("FoundPrisonParole") as Column).visible = false;
            (grid.getColumnByField("FoundArrest") as Column).visible = false;
            (grid.getColumnByField("FoundViolent") as Column).visible = false;
            (grid.getColumnByField("FoundHumanTraffic") as Column).visible = false;
            (grid.getColumnByField("FoundProfession") as Column).visible = false;
            (grid.getColumnByField("FoundReferral") as Column).visible = false;
        }
    }

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length > 0 ? <>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Records Found</h4>
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound }
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    rowSelecting={(event: any) => { console.log(event) }}
                    rowSelected={(event: any) => { expandRow() }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                <ColumnsDirective>
                        <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText='SBINo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN1' width={110} minWidth={110} maxWidth={110} headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SEX' headerText='Sex' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText='SCOR Ranking' customAttributes={{ class: ['e-attr'] }}/>:<></>}
                        <ColumnDirective field='City' headerText='City' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='State' headerText='State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={gridTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTE' headerText='Statute' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description' width={240} minWidth={240} maxWidth={240} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Start_Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Start Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='End_Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='End Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUS' headerText='Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DTOFARREST' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Date of Arrest' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Dept' headerText='PoliceStop Dept' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='FoundPhoto' width={110} minWidth={110} maxWidth={110} headerText='Found Photo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundArrest' width={110} minWidth={110} maxWidth={110} headerText='Found Arrest' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundETicket' width={110} minWidth={110} maxWidth={110} headerText='Found ETicket' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundParkMobile' width={110} minWidth={110} maxWidth={110} headerText='Found ParkMobile' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundALPR' width={110} minWidth={110} maxWidth={110} headerText='Found ALPR' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundCADRMS' width={110} minWidth={110} maxWidth={110} headerText='Found CADRMS' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundCODIS' width={110} minWidth={110} maxWidth={110} headerText='Found CODIS' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundPrisonRelease' width={110} minWidth={110} maxWidth={110} headerText='Found Prison Release' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Found Gun Trace' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundShooting' width={110} minWidth={110} maxWidth={110} headerText='Found Shooting' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundNIBIN' width={110} minWidth={110} maxWidth={110} headerText='Found NIBIN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundNJGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Found NJGunTrace' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundPrisonParole' width={110} minWidth={110} maxWidth={110} headerText='Found Prison/Parole' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundViolent' width={110} minWidth={110} maxWidth={110} headerText='Found Violent' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundHumanTraffic' width={110} minWidth={110} maxWidth={110} headerText='Found Human Traffic' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundProfession' width={110} minWidth={110} maxWidth={110} headerText='Found Profession' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundReferral' width={110} minWidth={110} maxWidth={110} headerText='Found Referral' customAttributes={{ class: ['e-attr'] }} visible={false} />
                </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                </GridComponent>

            </> : <></>}
        </div>
    );
};

export default SearchResults;