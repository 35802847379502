import React from "react";
import { Button, ButtonGroup, TextField} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from '@mui/icons-material/Info';
import { useHistory } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

// @ts-ignore
interface PeopleSearchProps {
    SubmitSearch: any,
    DataCleared: any,
}
const  PeopleSearch: React.FC<PeopleSearchProps> = ({SubmitSearch, DataCleared})=> {
    const [params, setParams] = React.useState({FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", DL: ""})
    const [submitError, setSubmitError] = React.useState<string>("")
    const history = useHistory()
    
    const handleSearch = () => {
        if(handleValidation())
            SubmitSearch(params)
    }
    
    const handleValidation = () => {
        setSubmitError("")
        var val = true

        if (params === null || (
            params.FirstName.length === 0 &&
            params.MI.length === 0 &&
            params.LastName.length === 0 &&
            params.DOB.length === 0 &&
            params.FBI.length === 0 &&
            params.SBI.length === 0 &&
            params.DL.length === 0 )
        ) {
            setSubmitError("Please enter a search criteria");
            val = false
        }
        return val
    }
    
    //<div className="flex bg-blue-100 rounded-lg p-4 text-sm text-blue-700 m-3" role="alert">
    //    <div>
    //        <InfoIcon /><Link variant="body2" underline="none" href='/tools/arrest'><b>For an Advanced Person Search use "TOOLS - Arrest"</b></Link>
    //    </div>
    //</div>

    return (
        <>
            <div className="grid grid-cols-4 gap-1 pl-5 pb-5 pr-5 pr-10 mt-5">
                <div><TextField margin='none' size={"small"} label="First Name" variant="outlined" value = {params.FirstName} onChange={e => setParams({...params, FirstName: e.target.value})} /></div>
                <div><TextField margin='none' size={"small"} label="Middle Initial" variant="outlined" value = {params.MI} onChange={e => setParams({...params, MI: e.target.value})}  /></div>
                <div><TextField margin='none' size={"small"} label="Last Name" variant="outlined"  value = {params.LastName} onChange={e => setParams({...params, LastName: e.target.value})} /></div>
                <div><TextField margin='none' size={"small"} type="date" label="DOB" variant="outlined" InputLabelProps={{ shrink: true }} value = {params.DOB} onChange={e => setParams({...params, DOB: e.target.value})} /></div>
                <div className="mt-3"><TextField margin='none' size={"small"} label="FBI #" variant="outlined" value = {params.FBI} onChange={e => setParams({...params, FBI: e.target.value})}  /></div>
                <div className="mt-3"><TextField margin='none' size={"small"} label="SBI #" variant="outlined" value = {params.SBI} onChange={e => setParams({...params, SBI: e.target.value})}  /></div>
                <div className="mt-3"><TextField margin='none' size={"small"} label="DL #" variant="outlined" value = {params.DL} onChange={e => setParams({...params, DL: e.target.value})} /></div>
            </div>
            <div className="flex justify-center items-center mb-5">
                <ButtonGroup size="large" color="inherit" >
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={() => { setParams({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", DL: "" }); setSubmitError(""); DataCleared() }}>CLEAR</Button>
                    <Button title="Advanced Person Search 'Tools-Arrest'" onClick={() => { history.push("/tools/arrest") }}><span className="text-blue-600">Adv. Search</span></Button>
                </ButtonGroup>
            </div>
            <div className={submitError?.length > 0 ? "flex justify-center items-center mb-5 font-bold" : ""}>
                <span>{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>
            </div>
        </>
    );
}

export default PeopleSearch;