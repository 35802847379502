import React, { useState } from "react";
import UnitLevelGroup from "../../components/Admin/UnitLevelGroupSearch";
import SearchResults from "../../components/Admin/_searchResultsUnitLevelGroup";
import { PersonInfo } from "../../interfaces/getDarInfo_interface";
import { RunDeptUnitList } from "../../services/account.service";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function ManageUnitLevelGroup() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [dptTbl, setDptTbl] = useState<any[]>([])
    let [storedParams, setStoredParams] = useState<any>({})

    const SubmitSearch = async (params: any) => {
        if (!params) {
            params = storedParams
        }
        else {
            setStoredParams(params)
        }

        let search_query: any = {
            "PlateNumber": "",
            "PlateSt": "",
            "FName": "",
            "MName": "",
            "LName": "",
            "DOB": "",
            "SEX": "",
            "City": "",
            "DLNo": "",
            "DLSt": "",
            "VIN": "",
            "FBI": "",
            "SSN": "",
            "DTOFARREST": "",
            "STATUTE": "",
            "STATUTEDESCRIPTION": "",
            "PoliceStopIPAddress": "",
            "PoliceStopReqID": params.UserID ? params.UserID : "",
            "PoliceStopDeviceORI": params.DeptName ? params.DeptName : "",
            "PoliceStopDeptName": "",
            "DeptAccountType": params.AccountType ? params.AccountType : "",
            "DeptGroup": params.DeptGroup ? params.DeptGroup : ""
        }
        let qRes: any = await RunDeptUnitList(search_query)
        setDarTbl([])
        setDarTbl(qRes?.AnyData)

    }

    return (
        <div className="w-full">
            <UnitLevelGroup SubmitSearch={SubmitSearch} />
            <div className="pl-10 mt-3">
               <SearchResults Persons={darTbl} />
            </div>
        </div>
    );
}

export default ManageUnitLevelGroup;
