import React, {useState} from "react";

interface DashboardPriorGridProps {
    location:string,
    data:any,
    toDate:any,
    fromDate:any,
}

const DashboardPriorGrid: React.FC<DashboardPriorGridProps> = ({location, data, toDate, fromDate}) => {
    const [total, setTotal] = React.useState<any>(0)
    const [priorCriminal, setPriorCriminal] = React.useState<any>(0)
    const [priorGun, setPriorGun] = React.useState<any>(0)
    const [percentCriminal, setPercentCriminal] = React.useState<any>(0)
    const [percentGun, setPercentGun] = React.useState<any>(0)

    React.useEffect(() => {
        if(data.PriorChargeData)
        {
            setTotal(data.PriorChargeData[0].Total)
            setPriorGun(data.PriorChargeData[0].PriorGun)
            setPriorCriminal(data.PriorChargeData[0].PriorCriminal)

            let pc = data.PriorChargeData[0].PriorCriminal / data.PriorChargeData[0].Total
            pc = (pc*100) 
            setPercentCriminal(pc.toFixed(2))

            let pg = data.PriorChargeData[0].PriorGun / data.PriorChargeData[0].Total
            pg = (pg*100) 
            setPercentGun(pg.toFixed(2))
        }
    }, [data]);
    
    return (
        <div className = "border-gray-900 border-2">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center text-medium">Victims With Prior History <div className="font-semibold text-xs">{fromDate} - {toDate}</div> </div>
            <div className = "border-gray-900 border-t-2">
                {data.PriorChargeData ? 
                    <div className = "text-center font-bold text-sm">
                        <div>Total Victims: {total}</div>
                        <div>Prior Gun Charges: {priorGun} ({percentGun}%) </div>
                        <div>Prior Criminal History: {priorCriminal} ({percentCriminal}%) </div> 
                        
                        {/*<div>Piror Criminal History: {priorCriminal}</div> */}
                        {/* <div>Piror Gun Charges: {priorGun}</div> */}
                    </div >
                : <></>}
            </div>
            
        </div>

    );
}

export default DashboardPriorGrid;
