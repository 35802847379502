import axios, {AxiosResponse} from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { base64_to_blob } from "./detail.service";
import {SearchDL} from "../interfaces/searchDL.interface";
import {profileObject} from "../interfaces/PersonReport.interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";

export async function RunUserList(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/UserList', params)
    return (res.data)
}

export async function RunDepartmentList(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/DeptList', params)
    return (res.data)
}

export async function RunDeptUnitList(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/DeptUnitList', params)
    return (res.data)
}

export async function RunGetUser(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetUser', params)
    return (res.data)
}

export async function RunGetDeptUnit(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetDeptUnit', params)
    return (res.data)
}

export async function RunSetDeptUnit(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/DeptUnit', params)
    return (res.data)
}

export async function RunMangers(id:string, Default:any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/Managers?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunDeptUnits(id: string, Default: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/DeptUnits?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunDeptSearchList(id: string, Default: string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/Depts?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunEmpAuthLevels(id:string, Default:string): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Admin/EmpAuthLevels?id=' + id + '&Default=' + Default)
    return (res.data)
}

export async function RunSetUser(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/User', params)
    return (res.data)
}

export async function RunPOIList(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/POIList', params)
    return (res.data)
}

export async function RunGetPOI(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetPOI', params)
    return (res.data)
}

export async function RunSetPOI(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/POI', params)
    return (res.data)
}

export async function RunGetDept(params:any): Promise <any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetDept', params)
    return (res.data)
}

export async function RunSetDept(params:any): Promise <any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/Dept', params)
    return (res.data)
}

export async function RunGetNCIC(params:any): Promise <any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetNCIC', params)
    return (res.data)
}

export async function RunSetNCIC(params:any): Promise <any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/NCIC', params)
    return (res.data)
}

export async function RunUserExpiryDays(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Admin/GetUserExpiryDays', params)
    return (res.data)
}

export async function RunSocialMedia(params:any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/SocialMedia', params)
    return (res.data)
}

export async function RunCorrection(params:any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/Correction', params)
    return (res.data)
}

export async function RunPromisGavel(params:any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/PromisGavel', params)
    return (res.data)
}

export async function RunExpiryToken(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Account/ExpiryToken', params)
    return (res.data)
}

//REMOVE THIS LATER OR PUT IN PDF_SERVICE

export async function RunPersonSummaryPDF(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Export/PersonSummaryPDF', params, {responseType:'blob'})
    if (res != null) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type:'application/pdf'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target',params?.PersonInfo?.FName + ' ' + params?.PersonInfo?.LName + '.pdf');
        document.body.appendChild(link);
        link.click();
    }
}

//INSERT INTO DB
export async function RunProfileReport(profile: profileObject): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReport', profile)
    return (res.data)
}

export async function GetProfileReport(FBI:string, SBI:string, FName:string, LName:string, DOB:string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Report/GetProfileReportData?FBI=' + FBI + '&SBI=' + SBI + '&fName=' + FName + '&lName=' + LName + '&DOB=' + DOB)
    return (res.data)
}

export async function GetProfileReportPDF(ID:any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/Report/ProfileReport_PDF?ID=' + ID,{responseType:'blob'})
    if (res != null) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type:'application/pdf'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('_self','Output.pdf');
        document.body.appendChild(link);
        link.click();
    }
}

export async function HasProfileReport(FBI:string, SBI:string, FName:string, LName:string, DOB:string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Report/HasProfileReport?FBI=' + FBI + '&SBI=' + SBI + '&fName=' + FName + '&lName=' + LName + '&DOB=' + DOB)
    return (res.data)
}

export async function RunProfileReportFiles(file: FormData, ID: number): Promise<any> {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReportFiles/?ID=' + ID, file )
        return (res.data)
    }catch (e) {
        console.error(e)
    }
}

export async function ProfileReportFilesOld(files: any, NewID: number, OldID: number): Promise<any> {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/ProfileReportFilesOld/?NewID=' + NewID + '&OldID=' + OldID, files )
        return (res.data)
    }catch (e) {
        console.error(e)
    }
}

export async function GetProfileReportFiles(File: any, ID: number): Promise<any> {
    try{
        let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Report/GetProfileReportFiles/?ID=' + ID, File )
        if(res.data.AnyData != null){
            base64_to_blob(res.data.AnyData.FileContents, res.data.AnyData.ContentType).then(r=>{
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, "_target")
            })
        }
    }catch (e) {
        console.error(e)
    }
}



export async function RunSharedToken(id: any, dept:any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Account/SharedToken?ID=' + id + '&Dept=' + dept)
    return (res.data)
}