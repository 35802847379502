import React, { useState, useEffect } from "react";
import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface PrisonReleaseSearchProps {
    SubmitSearch: any
}

const PrisonReleaseSearch: React.FC<PrisonReleaseSearchProps> = ({ SubmitSearch }) => {
    let today = new Date();
    let monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    monthAgo.setDate(monthAgo.getDate() + 1);
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", toDate: today, fromDate: monthAgo })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleSearch()
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, FirstName: "", LastName: "", DOB: "", FBI: "", SBI: "", toDate: today, fromDate: monthAgo })
        setDateType("Entry Date")
        setAndOrType("AND")
        setClear(clear + 1)
    }

    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-0.5 gap-y-2" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{mr: 2 }}
                >
                    <ToggleButton value="Release Date" sx={{ pr: 2, pl: 2 }} >Release Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            value={params.fromDate}
                            onChange={(e:any) => {
                                setParams({...params, fromDate: e})
                            }}
                            renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To Date"
                                value={params.toDate}
                                onChange={(e:any) => {
                                    setParams({...params, toDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                            />
                    </LocalizationProvider>
                </div>
                <div><TextField margin='none' size={"small"} label="First Name"  value={params.FirstName} onChange={(e:any) => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="Last Name"  value={params.LastName} onChange={(e:any) => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e:any) => setParams({ ...params, DOB: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="FBI #"  value={params.FBI} onChange={(e:any) => setParams({ ...params, FBI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} label="SBI#"  value={params.SBI} onChange={(e:any) => setParams({ ...params, SBI: e.target.value })} /></div>
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"prisonerInformation"} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default PrisonReleaseSearch;