import React, {useState} from "react";
import {getUser, isArrestShow} from "../../services/auth.service";
import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ProfileReportPanelFour, ProfileReportPanelThree, ProfileReportPanelTwo } from "../../components/tools/profileReport/profileReport";
import ProfileReportPanelOne from "../../components/tools/profileReport/profileReport";
import ProfileReportInputForm from "../../components/tools/profileReport/_profileReportInputForm";
import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import {Account} from "../../interfaces/auth_interface";
import {useLocation} from "react-router-dom";
import ArrestSearch from "../../components/tools/arrest/_arrestSearch";
import ProfileReportSearch from "../../components/tools/profileReport/_profileReportSearch";
import SearchResults from "../../components/tools/profileReport/_searchResultsProfileReport";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ProfileReport() {
    let [darInfo, setDarInfo] = useState<PersonInfo[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [showPlate, setShowPlate] = useState<boolean>(false)
    let query = useQuery();
    let [noData, setNoData] = useState<boolean>(false)
    
    const SubmitSearch = (params:any) => {
        console.log(params)
    }
    
    return (
        <div>
            {isArrestShow() ?
               <div>
                   { query.get("profile") ?
                   <ProfileReportInputForm  person = {query.get("profile")}/>
                   :
                   /*<>
                       <ProfileReportSearch  SubmitSearch={SubmitSearch}/>
                       <SearchResults Persons={darInfo}/>
                   
                   </>*/
                   <ProfileReportInputForm/>
                   }
               </div>
            :<></>}
        </div>
    );
}

export default ProfileReport;


/* <Card sx={{ maxWidth: 1200, m: 'auto', mb: 1 }} >
                    <CardContent>
                        <h3 className="text-center font-bold font text-xl text-blue-400">Victim Profile</h3>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Tabs
                                value={active}
                                onChange={handleChange}
                                centered
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tab icon={(0 == active ? <EditIcon /> : <CheckCircleIcon />)} value={0} color={"success"} iconPosition="start" label="Person Info"/>
                                <Tab icon={(1 < active ? <CheckCircleIcon /> : 1 == active ? <EditIcon /> : <CircleIcon />)} value={1} iconPosition="start" label="Additional Info"/>
                                <Tab icon={(2 < active ? <CheckCircleIcon /> : 2 == active ? <EditIcon /> : <CircleIcon />)} value={2} iconPosition="start" label="Arrest Info"/>
                                <Tab icon={(3 < active ? <CheckCircleIcon /> : 3 == active ? <EditIcon /> : <CircleIcon />)} value={3} iconPosition="start" label="ALPR Info"/>
                            </Tabs>
                            <TabPanel value={active} index={0}>
                                <ProfileReportPanelOne handleNext={handleNext} handleBack={handleBack} />
                            </TabPanel>
                            <TabPanel value={active} index={1}>
                                <ProfileReportPanelTwo handleNext={handleNext} handleBack={handleBack} />
                            </TabPanel>
                            <TabPanel value={active} index={2}>
                                <ProfileReportPanelThree handleNext={handleNext} handleBack={handleBack} />
                            </TabPanel>
                            <TabPanel value={active} index={3}>
                                <ProfileReportPanelFour handleNext={handleNext} handleBack={handleBack} />
                            </TabPanel>
                        </Box>
                    </CardContent>
                </Card>*/