import {
    Alert,
    Autocomplete,
    Button,
    ButtonGroup,
    Dialog,
    FormControl, InputLabel, MenuItem,
    Select,
    Snackbar,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import UpdateUser from "./UpdateUser";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import {RunDeptSearchList, RunSetUser} from "../../services/account.service";
import UpdateUnitLevel from "./UpdateUnitLevel";



interface UnitLevelProps {
    SubmitSearch: any
}


const UnitLevelGroup: React.FC<UnitLevelProps> = ({SubmitSearch}) =>{
    let [userAuth, setUserAuth] = useState<Account>(getUser())
    let [creds, setCreds] = useState<Account>(getUser())
    const [params, setParams] = React.useState({UserID: "", DeptName: "", AccountType: "",DeptGroup: "", authLevel: "", status:""})
    const [user, setUser] = React.useState<boolean>(false)
    const [dept, setDept] = React.useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [deptList, setDeptList] = React.useState<any>([])


    useEffect(() => {
        if (!initialRun) {
            loadDeptList()
            setInitialRun(true)
            handleSearch()
        }
    });

    const loadDeptList = async () => {
        let response = await RunDeptSearchList("", "")
        setDeptList(response?.AnyData)
       
    }
    
    const handleSearch = () =>
    {
        SubmitSearch(params)
    }

    const createUser = () =>
    {
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=0")
    }

    const CloseBox = () => {
        setUser(false)
        setDept(false)
        SubmitSearch(params)
    }

    const changeUserActivation = async (person:any) => {
        let p = person
        if (window.confirm("Are You Sure Want To Resend The Login Link To Selected User?")){
            person.EditStatus = "Resend"
            let r = await RunSetUser(person)
            //console.log(r)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                setAlertOpen(true)
                setAlertText("Resend Successful")
            }
            else
            {
                alert(r.ErrorMessage)
            }
        }
    }
    
    const handleClear = () => {
        setParams({...params, UserID: "", DeptName: "", AccountType: "",DeptGroup: "", authLevel: "", status:""})
    }
    
    return (
        <div className = "ml-5 pl-5 pr-5 pt-5">
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={ () => { setAlertOpen(false)}} severity="success" sx={{ width: '100%' }}>
                    {setAlertText}
                </Alert>
            </Snackbar>
            <div >
                {creds.AuthLevel === 'S' ? 
                    <div className = "inline">
                        <div className = "inline mr-5">
                            <FormControl sx={{minWidth: 210, maxWidth: 210, minHeight:80}}>
                                <InputLabel id="label">Department Name</InputLabel>
                                <Select
                                    size = "small"
                                    labelId="label"
                                    value={params.DeptName}
                                    label="Department Name"
                                    onChange={(e) => { setParams({ ...params, DeptName: e.target.value})}}
                                >
                                    {deptList.map((item:any) => (
                                        <MenuItem value={item.Key}>{item.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                     </div>
                : <></>}
                <div className="inline mr-5"><TextField margin='none' size={"small"} label="Dept Unit Name" value={params.DeptGroup} onChange={e => setParams({ ...params, DeptGroup:e.target.value})}/></div>
            </div>
             <div className="mt-8 mb-4 flex justify-center">
                 <ButtonGroup variant="outlined" size={"large"} color="inherit">
                     <Button type="submit" onClick={handleSearch}>Search</Button>
                     <Button onClick={handleClear}>CLEAR</Button>
                 </ButtonGroup>
            </div>
            {userAuth.AuthLevel === 'S' || userAuth.AuthLevel === 'M' || userAuth.AuthLevel === 'G' ?
                <span className = {"mr-4"}><Button variant="outlined" size={"small"} type="submit" color="inherit" onClick={createUser}>Add Department Unit</Button></span>
                : <></>}
            <Dialog
                open={user}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdateUnitLevel ID={"0"} CloseBox={CloseBox}/>
            </Dialog>
        </div>
    );
}

export default UnitLevelGroup;