export const formatDate = (dateString:string | null | undefined) => {
    if(dateString && dateString.length > 9)
    {
        let date:any = new Date(dateString)
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = (date.getDate()).toString().padStart(2, '0');
  
        return month + '/' + day + '/' + year;
    }
    else{
        return ""
    }
}

export const formatDT = (dateTimeString:string | null | undefined) => {
    if(dateTimeString && dateTimeString.length > 9 )
    {
        let date:Date = new Date(dateTimeString)
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = (date.getDate()).toString().padStart(2, '0');
  
        return month + '/' + day + '/' + year + ' ' + dateTimeString.substring(11,19); 
    }
    else{
        return ""
    }
}

export const formatPhone = (phoneString:string | null | undefined) => {
    if(phoneString && phoneString?.length > 9)
    {
        return (phoneString.substring(0,3) + '-' + phoneString.substring(3,6) + '-' + phoneString.substring(6,10))
    }
    else
    {
        return phoneString
    }
}

export function DateToString(d:Date) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth()+1).toString();      
    var dd  = d.getDate().toString();
    var hh = d.getHours().toString();
    var mi = d.getMinutes().toString();
    return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]) + ' '+(hh[1]?hh:"0"+hh[0])+':'+(mi[1]?mi:"0"+mi[0]);
}


export const formatSSN = (ssnString: string | null | undefined) => {
    if (ssnString && ssnString?.length === 9) {
        return (ssnString.substring(0, 3) + '-' + ssnString.substring(3, 5) + '-' + ssnString.substring(5))
    }
    else {
        return ""
    }
}

export const hideSSN = (ssnString: string | null | undefined) => {
    if (ssnString && ssnString?.length === 9) {
        return "***-**-" + ssnString.substring(ssnString.length - 4)
    }
    else {
        return ""
    }
}