import React from 'react'
import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip,
    Switch,
    FormControlLabel,
    FormGroup,
    FormControl, ButtonGroup, Icon, Dialog, Alert, Snackbar, AlertColor
} from "@mui/material";
import { formatDT } from '../../services/formatDate.service';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateUser from "./UpdateUser";
import {RunSetUser} from "../../services/account.service";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import BTPersonReport from "../shared/_BTPersonReport";
import PersonIcon from "../../assets/Images/Person.png";
import VehicleIcon from "../../assets/Images/Vehicle.png";


interface SearchResultsProps {
    Persons: any[]
    GetUserList: any
}

const  SearchResults: React.FC<SearchResultsProps> = ({Persons, GetUserList})=> {
    const [personHolder, setPersonHolder] = React.useState(Persons);
    const [user, setUser] = React.useState<boolean>(false)
    const [userID, setUserID] = React.useState<string>("0")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [formatData, setFormatData] = React.useState<any>([])
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const handleDataTyping = () => {
        let tempP: any = [...Persons]
        for (let p of tempP) {
            if (p.LoginDateTime) {
                p.LoginDateTime = new Date(p.LoginDateTime)
            }
            if (p.EmpID) {
                p.EmpID = p.EmpID.toUpperCase()
            }
            if (p.DeptName) {
                p.DeptName = p.DeptName.toUpperCase()
            }
            if (p.DeptUnitName) {
                p.DeptUnitName = p.DeptUnitName.toUpperCase()
            }
            if (p.EmpAuthLevelDesc) {
                p.EmpAuthLevelDesc = p.EmpAuthLevelDesc.toUpperCase()
            }
            if (p.FirstName) {
                p.FirstName = p.FirstName.toUpperCase()
            }
            if (p.MiddleName) {
                p.MiddleName = p.MiddleName.toUpperCase()
            }
            if (p.LastName) {
                p.LastName = p.LastName.toUpperCase()
            }
            if (p.EmailID) {
                p.EmailID = p.EmailID.toUpperCase()
            }

        }
        setFormatData(tempP)
    }
    

    React.useEffect(() => {
        handleDataTyping()
    }, [Persons])

    const CloseBox = () => {
        setUser(false)
        GetUserList()
    }

    const editUser = (id:string) =>
    {
        setUserID(id)
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=" + id)
    }

    const deleteUser = async (person:any) => {
        let temp = [...formatData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))]
        if (window.confirm("Remove This User Permanently? '" + p.EmpID + "'")){
            p.EditStatus = "Delete"
            p.Notes = "Delete User"
            let r = await RunSetUser(p)
            //console.log(r)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                setAlertType("success")
                setAlertText("Delete Successful '" + p.EmpID + "'")
                setAlertOpen(true)
                temp.splice(temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID)),1)
                setFormatData([])
                setFormatData(temp)
                //GetUserList()
            }
            else
            {
                setAlertType("error")
                setAlertText(r?.AnyData?.ErrorMessage)
                setAlertOpen(true)
            }
        }
    }

    const resendUser = async (person:any) => {
        let temp = [...formatData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))] 
        if (window.confirm("Are You Sure Want To Resend The Login Link To Selected User?\n(This Will Reset The Account For '" + p.EmpID + "')")){
            p.EditStatus = "Resend"
            p.Notes = "Reset User"
            let r = await RunSetUser(p)
            //console.log(r)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                setAlertType("success")
                setAlertText("Reset Successful '" + p.EmpID + "'" )
                setAlertOpen(true)
            }
            else
            {
                setAlertType("error")
                setAlertText(r?.AnyData?.ErrorMessage)
                setAlertOpen(true)
            }
        }
    }

    const ActivateDeactivateUser = async (person: any) => {
        let temp = [...formatData]
        let p = temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))]
        p.EditStatus = p.Active === "Active" ? "Inactive" : "Active"
        p.Notes = p.Active === "Active" ? "Inactivate User" : "Activate User"
        let r = await RunSetUser(p)
        if (r.AnyData?.IsValid) {
            setAlertType("success")
            setAlertText((p.Active === "Active" ? "Inactivate User " : "Activate User ") + p.EmpID)
            setAlertOpen(true)
            p.Active = p.Active === "Active" ? "Inactive": "Active"
            temp[temp.indexOf(temp.find(element => element?.UniqueID === person?.UniqueID))] = p
            setFormatData([])
            setFormatData(temp)
        }
        else
        {
            setAlertType("error")
            setAlertText(r?.AnyData?.ErrorMessage)
            setAlertOpen(true)
        }
    }

    React.useEffect(() => {
        setPersonHolder(Persons)
    },[ Persons
    ])

    let grid: Grid | null;

    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'UserList.xlsx';

            (grid.columns[0] as Column).visible = false;    //View link
            (grid.columns[1] as Column).visible = false;    //Status (Active/Inactive Template)
            (grid.columns[7] as Column).visible = false;    //Combined name
            (grid.columns[8] as Column).visible = true;     //First name
            (grid.columns[9] as Column).visible = true;     //Middle Name
            (grid.columns[10] as Column).visible = true;    //Last Name
            (grid.columns[13] as Column).visible = true;    //Status (real field)

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;    //View link
            (grid.columns[1] as Column).visible = true;    //Status (Active/Inactive Template)
            (grid.columns[7] as Column).visible = true;    //Combined name
            (grid.columns[8] as Column).visible = false;   //First name
            (grid.columns[9] as Column).visible = false;   //Middle Name
            (grid.columns[10] as Column).visible = false;  //Last Name
            (grid.columns[13] as Column).visible = false;  //Status (real field)
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const statusTemplate = (person: any): any => {
        return (
            <span>
               <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch onChange={() => ActivateDeactivateUser(person)} checked = {person.Active === "Active"}/>
                            }
                            label= {person.Active === "Active" ? "Active" : "Inactive" }
                        />
                    </FormGroup>
                </FormControl>
            </span>);
    }

    const actionTemplate = (person: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Tooltip title="Edit" placement="top" arrow>
                        <IconButton onClick = {() => editUser(person.UniqueID)}><EditIcon/></IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Delete" placement="top" arrow>
                        <IconButton onClick = {() => deleteUser(person)}><DeleteIcon/></IconButton>
                    </Tooltip>*/}
                    <Tooltip title="Reset User" placement="top" arrow>
                        <IconButton onClick = {() => resendUser(person)}><EmailIcon/></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </span>);
    }

    return (
        <div className={" pr-10"}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            {formatData && formatData.length>0? <>
                <h4 className={"font-bold pl-5"}> {formatData?.length} Records Found</h4>
                <GridComponent
                    dataSource={formatData}
                    //dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    excelExportComplete={excelExportComplete}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={actionTemplate} headerText='Action' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={statusTemplate} field="Active" headerText='Status' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='EmpID' headerText='Emp ID' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='DeptName' headerText='Department' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DeptUnitName' headerText='Unit' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='EmpAuthLevelDesc' headerText='Auth Level' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={(person: any) => (<>{person?.TFO} {person?.TFO ? 'Y' : ''}</>)} field="TFO" width={100} minWidth={100} maxWidth={100} headerText='TFO' customAttributes={{ class: ['e-attr'] }} />                        
                        {/*<ColumnDirective template={(person: any) => (<>{person?.EmpAuthLevelDesc} {person?.TFO ? '(TFO)' : ''}</>)} field="EmpAuthLevelDesc" width={140} minWidth={140} maxWidth={140} headerText='Auth Level' customAttributes={{ class: ['e-attr'] }} />*/}
                        <ColumnDirective template={(person : any) => (<>{person?.FirstName?.toUpperCase()} {person?.MiddleName?.toUpperCase()} {person?.LastName?.toUpperCase()}</>)} field="FirstName" width={260} minWidth={260} maxWidth={260} headerText='Name (First Name, MI, Last Name)' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FirstName' headerText='First Name' visible={false} />
                        <ColumnDirective field='MiddleName' headerText='MI' visible={false} />
                        <ColumnDirective field='LastName' headerText='Last Name' visible={false} />
                        <ColumnDirective field='EmailID' headerText='Email Address' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LoginDateTime' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm ' }} headerText='Last Activity' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='Active' headerText='Status' visible={false} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>

            </>:<></>}
            <Dialog
                open={user}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdateUser ID={userID} CloseBox={CloseBox} EditStatus={"FullEdit"}/>
            </Dialog>
        </div>
    )
};
export default SearchResults