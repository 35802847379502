import React from 'react'
import {
    Button, Menu, MenuItem
} from "@mui/material";
import VINInfo from "../../../interfaces/PlateSearch.interface";
import {
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow, ExcelExport, ExcelExportProperties,
    Filter, Grid, GridComponent,
    Inject,
    Page, PdfExport, Reorder, Resize, Search,
    Sort, SortSettingsModel, Toolbar
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Marquee from "react-fast-marquee";


interface SearchResultsProps {
    VINInfos: VINInfo[]
    Errors: any[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ VINInfos , Errors}) => {
    const [FormatData, setFormatData] = React.useState(0);
    const [errorList, setErrorList] = React.useState<string>("");
    const [recordCount, setRecordCount] = React.useState<any>("");
    
    React.useEffect(() => {
        buildErrorList()
    },[Errors])

    const buildErrorList = async () => {
        setErrorList("")
        let p = ""
        if(Errors?.length > 0) {
            for (const [key, value] of Object.entries(Errors[0])) {
                if(key === "Record"){
                    setRecordCount(value)
                }
                else
                {
                    p = p + " | " + key + " : " + value
                }
               
            }
            setErrorList(p)
        }
    }
    
    let grid: Grid | null;
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            (grid.getColumnByField("LPRID") as Column).visible = false;

            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'ALPRVin.xlsx'
            grid.excelExport(excelProp);

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("LPRID") as Column).visible = true;
        }
    }

    const handleDataTyping = () => {
        if(VINInfos) {
            let tempP: any = [...VINInfos]
            for (let p of tempP) {
                if (p.LPRTimeStamp) {
                    p.LPRTimeStamp = new Date(p.LPRTimeStamp)
                }
            }
            setFormatData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [VINInfos])
    
    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const viewTemplate = (item: any): any => {

        return (
            <span>
                {(item?.LPRID && item?.LPRID != 0 ? <Button onClick={() => { window.open((item?.LPRServerName == "ETicketCentral" ? "/detail/ETicket" : item?.LPRServerName == "ParkMobileCentral" ? "/detail/ParkMobile" : "/detail/ALPR") + '?ID=' + item?.LPRID + '&Dept=' + (item?.LPRServerName == "ETicketCentral" ? item?.Trim_Level : item?.LPRCityName), '_blank') }} >View</Button> : <div></div>)}
            </span>
        );
    }

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {Errors.length > 0 && errorList.length > 0 ?
                <Marquee speed = {20} pauseOnHover={true}>
                   <span className = "font-bold text-red-700">{errorList?.length > 0 ? errorList : ""}</span>
                </Marquee>
            : <></>}
            {recordCount.length > 0 ? 
                <div className={"font-bold"}>
                    {recordCount}
                </div>
                : <></>}
            {VINInfos && VINInfos.length !== 0 ?
            <GridComponent
                dataSource={FormatData}
                dataBound={dataBound}
                allowPaging={true}
                pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClick}
                ref={(g) => { if (g) { grid = g } }}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"}}
                allowSelection={true}
                allowResizing={true}
                rowHeight={30}
                excelExportComplete={excelExportComplete}
            >
                <ColumnsDirective>
                    <ColumnDirective field='LPRID' template={viewTemplate} headerText='View' />
                    <ColumnDirective field='LPRTimeStamp'   headerText='Time Stamp'  format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}} />
                    <ColumnDirective field='LPRCameraName'  headerText='Zone/ Camera'    width={150} minWidth={150} maxWidth={150} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='PlateNum'       headerText='Plate #'        width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='PlateState'     headerText='Plate State'    width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr']}}  />
                    <ColumnDirective field='PlateYear'      headerText='Plate Year'     width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehMake'        headerText='Dmv Make'       width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehModel'       headerText='Dmv Model'      width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehBody'        headerText='Dmv Trim'       width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehColor'       headerText='Dmv Color'      width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VIN'            headerText='Vin'            width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Model_Year'     headerText='Vin Year'       width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Make'           headerText='Vin Make'       width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Model'          headerText='Vin Model'      width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Trim_Level'     headerText='Trim/ID'        width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}  />
                    <ColumnDirective field='Body_Style'     headerText='Body_Style'     width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Exterior_Color' headerText='Vin Color'      width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='LPRCityName'    headerText='City'           width={150} minWidth={150} maxWidth={150} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='LPRLocation' headerText='ALPR Location' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
            </GridComponent>
                :<></>}
        </div>
    );
    {/*<ColumnDirective template={(item: any) => (<>({item.LPRLocation})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/ }
    {/*<ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/ALPR?ID=' + item?.LPRID + '&Dept=' + item?.LPRServerName, '_blank') }} >View</Button>)} headerText='View' />*/ }

};

export default SearchResults