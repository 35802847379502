import {Button, ButtonGroup, Dialog, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import SystemNotice from "../authentication/login/_systemNotice";
import UpdateUser from "./UpdateUser";
import {Account} from "../../interfaces/auth_interface";
import {getUser} from "../../services/auth.service";
import UpdatePOI from "./UpdatePOI";


interface UserSearchProps {
    SubmitSearch: any
}


const POIs: React.FC<UserSearchProps> = ({SubmitSearch}) =>{
    const [params, setParams] = React.useState({Reason: "", FBI:"", DL:"", Plate:"", FirstName:"", MI: "", LastName: "", Email: "", UserID: ""})
    const [user, setUser] = React.useState<boolean>(false)
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            handleSearch()
        }
    });
    
    
    const handleSearch = () =>
    {
        SubmitSearch(params)
    }

    const createUser = () =>
    {
        setUser(true)
        //window.location.href = ("/admin/UpdateUser?ID=0")
    }

    const CloseBox = () => {
        setUser(false)
    }

    return (
        <div className = "ml-5 p-5">
            <div >
                <div className={"mb-3"}>
                    <Button variant="outlined" size={"small"} type="submit" color="inherit" onClick={createUser}>Add POI</Button>
                </div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="Reason" value ={params.Reason} onChange={e => setParams({...params, Reason:e.target.value})}/></div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="FBI #"  value ={params.FBI} onChange={e => setParams({...params, FBI:e.target.value})}/></div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="First Name"  value ={params.FirstName} onChange={e => setParams({...params, FirstName:e.target.value})}/></div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="Middle Initial" value ={params.MI} onChange={e => setParams({...params, MI:e.target.value})}/></div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="Last Name" value ={params.LastName} onChange={e => setParams({...params, LastName:e.target.value})}/></div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="DL #"  value ={params.DL} onChange={e => setParams({...params, DL:e.target.value})}/></div>
                <div className = "inline mr-5"><TextField margin='none' size={"small"} label="Plate #"  value ={params.Plate} onChange={e => setParams({...params, Plate:e.target.value})}/></div>
            </div>
            <div className="mt-8 flex justify-center">
                <ButtonGroup variant="outlined" size={"large"} color="inherit">
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={e => setParams({Reason: "", FBI:"", DL:"", Plate:"", FirstName:"", MI: "", LastName: "", Email: "", UserID: ""})}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <Dialog
                open={user}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdatePOI ID={"0"} CloseBox={CloseBox}/>
            </Dialog>
        </div>
    );
}

export default POIs;