import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React, {useState} from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {TablePagination, CircularProgress, Tooltip, Button} from "@mui/material";
import AssociatedIcon from "../../../assets/Images/associated.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter, SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import {JAOS} from "../../../services/JAOS.service";
import {Account} from "../../../interfaces/auth_interface";
import {getUser} from "../../../services/auth.service";

interface PanelArrestProps {
    person: PersonInfo,
    AssociatedPeople: any[],
    isLoading:boolean
}

const  PanelAssociatedPeople: React.FC<PanelArrestProps> = ({person,AssociatedPeople, isLoading})=> {
    const [FormatData, setFormatData] = React.useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [tempData, setTempData] = useState<any[]>([])
    
    const handleDataTyping = () => {
        let tempP: any = [...AssociatedPeople]
        for (let p of tempP) {
            if (p.DOB) {
                p.DOB = new Date(p.DOB)
            }
            if (p.DTOFARREST) {
                p.DTOFARREST = new Date(p.DTOFARREST)
            }
        }
        setFormatData(tempP)
    }
    
    const refreshData = () => {
        setTempData(AssociatedPeople)
    }

    React.useEffect(() => {
        if(!FormatData || FormatData.length === 0)
        {
            handleDataTyping()
        }
    }, [tempData])
    
    let grid: Grid | null;
    
    const personTemplate = (person: any):any => {
        return(<Button onClick={() => {openPersonView(person)}}>{person.FName}</Button>)
    }
    
    const gridTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{"marginRight":"3px"}}> <img src={CODISIcon} style={{"display":"inline"}} alt={""}/> {person.FoundCODIS} </span>: <span/>}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }
    
    const openPersonView = (p_view:any) => {
        if(user?.SharedToken == null) {
            let l = new JAOS()
            let hashPerson = l.objToStack(p_view)
            window.open('/btPDF?Person=' + hashPerson, '_blank')
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
    ] };
    
    return(
        <div className={"m-5 border"}>
            <Disclosure >
                {({ open }) => (
                    <>
                       <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }} onClick={()=>{refreshData()}}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                        <span className={"font-bold"}>Associated People <img src={AssociatedIcon} alt={''} style={{ display: 'inline' }} /></span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {AssociatedPeople?.length}</span>
                            </span>
                       </Disclosure.Button>
                    
                        <Disclosure.Panel className="text-gray-500 p-4">
    
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective template={personTemplate} field={'FName'} headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='MName' headerText='MI' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='LName' headerText='Last Name' customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='DOB' headerText='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='DLNo' headerText='DL#' customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='FBI' headerText='FBI' customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='SBINo' headerText='SBI' customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='DTOFARREST' headerText='Arrest Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective field='AType' headerText='Association' customAttributes={{ class: ['e-attr']}} width={150} minWidth={150} maxWidth={150}/>
                                    <ColumnDirective field='TypeDetail' headerText='Details' customAttributes={{ class: ['e-attr']}}/>
                                    <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' customAttributes={{ class: ['e-attr'] }}/>
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
    
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
};

export default PanelAssociatedPeople;