import React from "react";
import {Button, ButtonGroup, Link, TextField} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from "@mui/icons-material/Error";
import { useHistory } from "react-router-dom";

interface ThingSearchProps {
    SubmitSearch: any,
    DataCleared: any,
}
const  ThingSearch: React.FC<ThingSearchProps> = ({SubmitSearch, DataCleared})=> {
    
    const [params, setParams] = React.useState({Plate: "", VIN: "", Case: ""})
    const [submitError, setSubmitError] = React.useState<string>("")
    const history = useHistory()

    const handleSearch = () => {
        if(handleValidation())
            SubmitSearch(params)
    }

    const handleValidation = () => {
        setSubmitError("")
        var val = true

        if (params === null || (
            params.Plate.length === 0 &&
            params.VIN.length === 0 &&
            params.Case.length === 0 )
        ) {
            setSubmitError("Please enter a search criteria");
            val = false
        }
        return val
    }
    
    return (
        <>
            <div className="grid grid-cols-3 pl-5 pb-5 pr-5 pr-10 mt-5">
                <div><TextField margin='none' size={"small"} label="Plate #"  value = {params.Plate} onChange={(e:any) => setParams({...params, Plate: e.target.value})} title="Search Sample: PL*E, PL%E, PL_TE, PL[A-E]TE, PL[^E-H]TE, 'PLATE1,PLATE2,PLATE3'"/></div>
                <div><TextField margin='none' size={"small"} label="VIN #"   value = {params.VIN} onChange={(e:any) => setParams({...params, VIN: e.target.value})} /></div>
                <div><TextField margin='none' size={"small"} label="Case #"  value = {params.Case} onChange={(e:any) => setParams({...params, Case: e.target.value})} /></div>
            </div>
            <div className="flex justify-center items-center mb-5">
                <ButtonGroup size="large" color="inherit" >
                    <Button onClick={handleSearch}>Search</Button>
                    <Button onClick={() => { setParams({ Plate: "", VIN: "", Case: "" }); setSubmitError(""); DataCleared() }}>CLEAR</Button>
                    <Button title="Advanced Plate Search 'Tools-ALPR VIN Search'" onClick={() => { history.push("/tools/alpr_vin_search") }}><span className="text-blue-600">Adv. Search</span></Button>
                </ButtonGroup>
            </div>
            <div className={submitError?.length > 0 ? "flex justify-center items-center mb-5 font-bold" : ""}>
                <span>{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>
            </div>
        </>
    );
}

export default ThingSearch;