import React, {useState} from "react";

interface DashboardDataGridProps {
    location:string,
    data:any
}

const DashboardDataGrid: React.FC<DashboardDataGridProps> = ({location, data}) => {
    const [percentChange, setPercentChange] = React.useState<number>(0)
    let today = new Date()
    let thisYear = today.getFullYear()
    const [changeType, setChangeType] = React.useState<string>('')

    React.useEffect(() => {
        let change:any = 0
        if(data.YTDData)
        {
            let decrease = data.YTDData[1]?.YTDCount - data.YTDData[0]?.YTDCount 
            change = (decrease / data.YTDData[1]?.YTDCount) * 100
            if(Math.sign(change) === -1)
            {
                setChangeType('Increase')
                change = (change * -1)
                setPercentChange(change)
            }
            else
            {
                setChangeType('Decrease')
            }
            change = (Math.round(change * 100) / 100) 
        }
        setPercentChange(change)
    }, [data]);
    
    return (
        <div className = "border-gray-900 border-2">
            <div className="bg-blue-900 font-bold text-yellow-400 text-center text-medium">Total Year To Date Victims</div>
            <div className = "border-gray-900 border-t-2">
                <div className = "text-center font-bold text-xs" >{location}</div>
                {data.YTDData ? data.YTDData.map((data:any) => (
                    <div>
                        <div className = "text-center font-bold">{data.Year}:{data.YTDCount}</div>
                    </div>
                )) : <></>}
                {percentChange ?
                <div className = {changeType === 'Increase' ? "text-center font-bold text-sm text-red-600" : "text-center font-bold text-sm text-green-500"}>%{changeType} : {percentChange ? percentChange : 0}%</div>
                    :
                    <></>
                }
            </div>
            
        </div>

    );
}

export default DashboardDataGrid;


/*
const [newShootings, setNewShootings] = useState<number>(0)
    const [totalShootings, setTotalShootings] = useState<number>(0)
    const [newMurders, setNewMurders] = useState<number>(0)
    const [totalMurders, setTotalMurders] = useState<number>(0)

<div className="grid grid-cols-2 gap-x-1 mt-2">
    <div className = "font-bold text-center text-sm ">New Shootings</div>
    <div className = "font-bold text-center text-sm">Total Shootings</div>
    <div className = "font-bold text-center text-sm">{newShootings}</div>
    <div className = "font-bold text-center text-sm">{totalShootings}</div>
</div>
<div className="grid grid-cols-2 gap-x-1 mt-2">
    <div className = "font-bold text-center text-sm ">New Murders</div>
    <div className = "font-bold text-center text-sm">Total Deaths</div>
    <div className = "font-bold text-center text-sm">{newMurders}</div>
    <div className = "font-bold text-center text-sm">{totalMurders}</div>
</div>
*/