import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import React, {useEffect, useState} from "react";
import {SearchDL} from "../../interfaces/searchDL.interface";
import {
    RunPerson_ArrestQuery,
    RunPerson_ShootingQuery,
    RunPerson_ImageQuery,
    RunPerson_CodisDNAQuery,
    RunPerson_PoliceStopQuery,
    RunPerson_ETicketQuery,
    RunPerson_CADRMSQuery,
    RunPerson_ParkMobileQuery,
    RunPerson_ETraceQuery,
    RunPerson_NJTraceQuery,
    RunPerson_NIBINQuery,
    RunPerson_PrisonQuery,
    RunPerson_ParoleQuery,
    RunPerson_ViolentQuery,
    RunPerson_ProfessionQuery,
    RunPerson_DLQuery,
    RunPerson_AddressPhoneQuery,
    RunPerson_Plate,
    RunPerson_ALPRQuery,
    RunPerson_CrumbScore,
    RunGetGRAILReferral, RunPerson_Referral
} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import { getUser, isArrestShow, isLogoShow, isNCICShow, isPOIShow, isAnalysisShow } from "../../services/auth.service";
import {
    Alert,
    AlertColor,
    Box,
    Button,
    ButtonGroup, Dialog,
    IconButton,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import { ArrestInfo } from "../../interfaces/ArrestInfo.interface";
import PersonImageInfo from "../../interfaces/PersonImageInfo.interface";
import { ShootingInfo } from "../../interfaces/ShootingInfo.interface";
import ALPRInfo from "../../interfaces/ALPRInfo.interface";
import DNAInfo from "../../interfaces/DNAInfo.interface";
import AgencyInteractionInfo from "../../interfaces/AgencyInteractionInfo.interface";
import ETInfo from "../../interfaces/ETInfo.interface";
import CADRMSInfo from "../../interfaces/CADRMSInfo.interface";
import PDInfo from "../../interfaces/PDInfo.interface";
import WeaponTraceInfo from "../../interfaces/WeaponTraceInfo.interface";
import NIBINInfo from "../../interfaces/NIBINInfo.interface";
import { PrisonerReleaseInfo } from "../../interfaces/PrisonerReleaseInfo.interface";
import PrisonerParoleInfo from "../../interfaces/PrisonerParoleInfo.interface";
import ViolentInfo from "../../interfaces/ViolentInfo.interface";
import ProfessionInfo from "../../interfaces/ProfessionInfo.interface";
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import InsightsIcon from '@mui/icons-material/Insights';
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp';
import { Disclosure } from "@headlessui/react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {DateToString, formatDate, formatDT} from '../../services/formatDate.service'
import PrintIcon from '@mui/icons-material/Print';
import MapIcon from '@mui/icons-material/Map';
import POIIcon from '../../assets/Images/target.png';
import NCICIcon from '../../assets/Images/NCIC.png';
import SocialIcon from '../../assets/Images/SocialSearch.png';
import CorrectionIcon from '../../assets/Images/Jail.png';
import PromisGavelIcon from '../../assets/Images/court.png';
import RTCC from '../../assets/Images/RTCC.png';
import CorrStat from '../../assets/Images/CorrStat.png';
import ShareLinkIcon from '../../assets/Images/ShareLink.png';

import {JAOS} from "../../services/JAOS.service";
import {
    GetProfileReportPDF,
    HasProfileReport,
    RunCorrection,
    RunPromisGavel,
    RunSocialMedia,
    RunExpiryToken
} from "../../services/account.service";
import PanelArrest from "./BTPersonReportPanels/_PanelArrest";
import PanelShooting from "./BTPersonReportPanels/_PanelShooting";
import PanelEticket from "./BTPersonReportPanels/_PanelEticket";
import PanelAssociatedPeople from "./BTPersonReportPanels/_PanelAssociatedPeople";
import PanelCADRMS from "./BTPersonReportPanels/_PanelCADRMS";
import PanelCODIS from "./BTPersonReportPanels/_PanelCodis";
import PanelProfession from "./BTPersonReportPanels/_PanelProfession";
import PanelPrison from "./BTPersonReportPanels/_PanelPrison";
import PanelPoliceStop from "./BTPersonReportPanels/_PanelPoliceStop";
import PanelNIBIN from "./BTPersonReportPanels/_PanelNIBIN";
import PanelParkMobile from "./BTPersonReportPanels/_PanelParkMobile";
import PanelETrace from "./BTPersonReportPanels/_PanelETrace";
import PanelNJGunTrace from "./BTPersonReportPanels/_PanelNJGunTrace";
import PanelALPR from "./BTPersonReportPanels/_PanelALPR";
import PanelParole from "./BTPersonReportPanels/_PanelParole";
import PanelViolent from "./BTPersonReportPanels/_PanelViolent";
import GeoCode from "./GeoCodes/_GeoCode_Display";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {OpenSCORPDF} from "../../services/detail.service";
import {HelpOutline, Preview} from "@mui/icons-material";
import { RunPersonSummaryPDF } from "../../services/account.service"
import ContactPageIcon from '@mui/icons-material/ContactPage';
import {GRAILReferral} from "../../interfaces/GRAILReferral.interface";
import PanelReferral from "./BTPersonReportPanels/_PanelGVRTFReferral";
import {Main_URL} from "../../services/config.service";

interface BTPersonReportProps {
    person: PersonInfo,
    PersonSummaryInfos?:any,
    PrintMode?:boolean
}

interface PhoneAddressInterface {
    AdditionalData: any[],
    Association: any,
    Association1: any,
    Association2: any,
    Association3: any,
    Association4: any,
    AuthToken: any,
    LoggedUser: any,
    AnyData: any[]
}

const BTPersonReport: React.FC<BTPersonReportProps> = ({ person, PersonSummaryInfos, PrintMode=false}) => {
    let [user, setUser] = useState<Account>(getUser())
    let [crumbScoreInfo, setCrumbScoreInfo] = useState<any>([])
    let [arrestInfo, setArrestInfo] = useState<ArrestInfo[]>([])
    let [personImageInfo, setPersonImageInfo] = useState<PersonImageInfo[]>([])
    let [ShootingInfo, setShootingInfo] = useState<ShootingInfo[]>([])
    let [alprInfo, setALPRInfo] = useState<ALPRInfo[]>([])
    let [codisDNAInfo, setCodisDNAInfo] = useState<DNAInfo[]>([])
    let [PoliceStopInfo, setPoliceStopInfo] = useState<AgencyInteractionInfo[]>([])
    let [eTicketInfo, setETicketInfo] = useState<ETInfo[]>([])
    let [cadrmsInfo, setCADRMSInfo] = useState<CADRMSInfo[]>([])
    let [parkMobileInfo, setParkMobiletInfo] = useState<PDInfo[]>([])
    let [eTraceInfo, setETraceInfo] = useState<WeaponTraceInfo[]>([])
    let [njTraceInfo, setNJTraceInfo] = useState<WeaponTraceInfo[]>([])
    let [nibinInfo, setNIBINInfo] = useState<NIBINInfo[]>([])
    let [prisonInfo, setPrisonInfo] = useState<PrisonerReleaseInfo[]>([])
    let [paroleInfo, setParoleInfo] = useState<PrisonerParoleInfo[]>([])
    let [violentInfo, setViolentInfo] = useState<ViolentInfo[]>([])
    let [professionInfo, setProfessionInfo] = useState<ProfessionInfo[]>([])
    let [phoneAddressInfo, setPhoneAddressInfo] = useState<PhoneAddressInterface>()
    let [associatedPeopleInfo, setAssociatedPeopleInfo] = useState<any[]>([])
    let [referralInfo, setReferralInfo] = useState<any[]>([])
    let [DLNo, setDLNo] = useState<any[]>([])
    let [plateInfo, setPlateInfo] = useState<any[]>([])
    let [query, setQuery] = useState<any>({})

    let [arrestLoading, setArrestLoading] = useState<boolean>(false)
    let [ShootingLoading, setShootingLoading] = useState<boolean>(false)
    let [codisDNALoading, setCodisDNALoading] = useState<boolean>(false)
    let [PoliceStopLoading, setPoliceStopLoading] = useState<boolean>(false)
    let [eTicketLoading, setETicketLoading] = useState<boolean>(false)
    let [cadrmsLoading, setCADRMSLoading] = useState<boolean>(false)
    let [parkMobileLoading, setParkMobiletLoading] = useState<boolean>(false)
    let [eTraceLoading, setETraceLoading] = useState<boolean>(false)
    let [njTraceLoading, setNJTraceLoading] = useState<boolean>(false)
    let [nibinLoading, setNIBINLoading] = useState<boolean>(false)
    let [prisonLoading, setPrisonLoading] = useState<boolean>(false)
    let [paroleLoading, setParoleLoading] = useState<boolean>(false)
    let [violentLoading, setViolentLoading] = useState<boolean>(false)
    let [professionLoading, setProfessionLoading] = useState<boolean>(false)
    let [associatedPeopleLoading, setAssociatedPeopleLoading] = useState<boolean>(false)
    let [alprLoading, setALPRLoading] = useState<boolean>(false)
    let [referralLoading, setReferralLoading] = useState<boolean>(false)
    let [dPlate, setDPlate] = useState<any[]>([])
    let [ETicketPlate, setETicketPlate] = useState<any[]>([])
    let [allAddresses, setAllAddresses] = useState<any[]>([])
    let [dAddress, setDAddress] = useState<any[]>([])
    let [arrestAddress, setArrestAddress] = useState<any[]>([])
    let [profileReportNum, setProfileReportNum] = useState<number>(0)
    
    let associatedPeople:any[] = []
    let dmvPlates:any[] = []
    let eTicketPlates:any[]= []
    let dmvAddress: any[] = []
    let aAddress: any[] = []

    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    

    //For Image Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1);

    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")


    let [shareLink, setShareLink] = useState<string>()
    const [expiryOpen, setExpiryOpen] = React.useState<boolean>(false)

    useEffect( () => {
        if(PersonSummaryInfos != null){
            setArrestInfo(PersonSummaryInfos.ArrestInfo)
            setPersonImageInfo(PersonSummaryInfos.PersonImageInfo)
            setShootingInfo(PersonSummaryInfos.ShootingInfo)
            setALPRInfo(PersonSummaryInfos.ALPRInfo)
            setCodisDNAInfo(PersonSummaryInfos.CodisDNAInfo)
            setPoliceStopInfo(PersonSummaryInfos.PoliceStopInfo)
            setETicketInfo(PersonSummaryInfos.ETicketInfo)
            setCADRMSInfo(PersonSummaryInfos.CADRMSInfo)
            setParkMobiletInfo(PersonSummaryInfos.ParkMobileInfo)
            setETraceInfo(PersonSummaryInfos.ETraceInfo)
            setNJTraceInfo(PersonSummaryInfos.NJTraceInfo)
            setNIBINInfo(PersonSummaryInfos.NibinInfo)
            setPrisonInfo(PersonSummaryInfos.PrisonInfo)
            setParoleInfo(PersonSummaryInfos.ParoleInfo)
            setViolentInfo(PersonSummaryInfos.ViolentInfo)
            setProfessionInfo(PersonSummaryInfos.ProfessionInfo)
            setPhoneAddressInfo(PersonSummaryInfos.PhoneAddressInfo)
            setAssociatedPeopleInfo(PersonSummaryInfos.AssociatedPeopleInfo)
            setCrumbScoreInfo(PersonSummaryInfos.CrumbScoreInfo)
            setDLNo(PersonSummaryInfos.DLNosInfo)
            setPlateInfo(PersonSummaryInfos.PlateInfo)
            //setReferralInfo(PersonSummaryInfos.ReferralInfo)
        }
            
    }, [PersonSummaryInfos]);

    useEffect( () => {
        if(!PersonSummaryInfos){
            loadPersonSummary()
        }
    }, [person]);


    const loadPersonSummary = async () => {
        let today: string = new Date().toLocaleDateString();
        let query: SearchDL = {
            City: person?.City,
            DLNo: person?.DLNo,
            DLSt: person?.DLSt,
            DOB: person?.DOB ? (person?.DOB)?.toLocaleString() : "",
            DarID: person?.DarID ? person?.DarID.toString() : null,
            Dept: user.Dept,
            DeptORI: user.DeptORI,
            ETicket: null,
            EmpID: user.EmpID,
            FBI: person?.FBI,
            FirstName: person?.FName,
            FromDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today ,
            ToDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
            GANGRELATED: person?.GANGRELATED,
            LastName: person?.LName,
            LoggedDept: null,
            LoggedEmpID: null,
            MiddleName: person?.MName,
            PersonID: person?.PersonID,
            QueryType: null,
            CrumbScorePercentile: person?.CrumbScorePercentile,
            SBINo: person?.SBINo,
            SEX: person?.SEX
        }
        
        setQuery(query)

        //set loading circles
        setArrestLoading(true)
        setShootingLoading(true)
        setCodisDNALoading(true)
        setPoliceStopLoading(true)
        setETicketLoading(true)
        setCADRMSLoading(true)
        setParkMobiletLoading(true)
        setETraceLoading(true)
        setNJTraceLoading(true)
        setNIBINLoading(true)
        setPrisonLoading(true)
        setParoleLoading(true)
        setViolentLoading(true)
        setProfessionLoading(true)
        setAssociatedPeopleLoading(true)
        setALPRLoading(true)
        setReferralLoading(true)

        RunPerson_ImageQuery(query).then(response => { 
            if(response.AnyData)
                setPersonImageInfo(response.AnyData);
            handleAssociatedPeople(response);  
        })
        RunPerson_CrumbScore(query).then(response => {
            if(response.AnyData){
                setCrumbScoreInfo(response.AnyData[0]); 
            }
        })
        RunPerson_ArrestQuery(query).then(response => {
            if(response.AnyData)
                setArrestInfo(response.AnyData);
            handleAssociatedPeople(response); 
            setArrestLoading(false) 
        })
        RunPerson_ShootingQuery(query).then(response => {
            if(response.AnyData)
                setShootingInfo(response.AnyData);
            handleAssociatedPeople(response);  
            setShootingLoading(false) 
        })
        RunPerson_CodisDNAQuery(query).then(response => {
            if(response.AnyData)
                setCodisDNAInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setCodisDNALoading(false) 
        })  
        RunPerson_PoliceStopQuery(query).then(response => {
            if(response.AnyData)
                setPoliceStopInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setPoliceStopLoading(false)
        })
        RunPerson_ETicketQuery(query).then(response => {
            if(response.AnyData)
                setETicketInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setETicketLoading(false) 
        })
        RunPerson_CADRMSQuery(query).then(response => {
            if(response.AnyData)
                setCADRMSInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setCADRMSLoading(false) 
        })
        RunPerson_ParkMobileQuery(query).then(response => {
            if(response.AnyData)
                setParkMobiletInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setParkMobiletLoading(false) 
        })
        RunPerson_ETraceQuery(query).then(response => {
            if(response.AnyData)
                setETraceInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setETraceLoading(false) 
        })
        RunPerson_NJTraceQuery(query).then(response => {
            if(response.AnyData)
                setNJTraceInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setNJTraceLoading(false) 
        })
        RunPerson_NIBINQuery(query).then(response => {
            if(response.AnyData)
                setNIBINInfo(response.AnyData); 
            handleAssociatedPeople(response);
            setNIBINLoading(false)
        })
        RunPerson_PrisonQuery(query).then(response => {
            if(response.AnyData)
                setPrisonInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setPrisonLoading(false) 
        })
        RunPerson_ParoleQuery(query).then(response => {
            if(response.AnyData)
                setParoleInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setParoleLoading(false) 
        })
        RunPerson_ViolentQuery(query).then(response => {
            if(response.AnyData)
                setViolentInfo(response.AnyData); 
            handleAssociatedPeople(response); 
            setViolentLoading(false) 
        })
        RunPerson_ProfessionQuery(query).then(response => {
            if(response.AnyData)
                setProfessionInfo(response.AnyData); 
            handleAssociatedPeople(response);
            setProfessionLoading(false) 
        })
        RunPerson_Referral(query).then(response => {
            if(response.AnyData)
                setReferralInfo(response.AnyData);
            setReferralLoading(false)
        })

        let ALPRQuery = query;
        ALPRQuery.QueryType = '3';
        let date: any = new Date()
        date.setFullYear(date.getFullYear() - 1)
        ALPRQuery.FromDate = date.toISOString().slice(0, 10);  //Serach ALPR for 1 year
        ALPRQuery.ToDate = today;  
        RunPerson_ALPRQuery(ALPRQuery).then(response => { setALPRInfo(response.AnyData); setALPRLoading(false) })

        RunPerson_DLQuery(query).then(response => setDLNo(response.AnyData))
        RunPerson_AddressPhoneQuery(query).then(response => { setPhoneAddressInfo(response); handleAddressInfo(response.AnyData)})
        RunPerson_Plate(query).then((response => {setPlateInfo(response.AnyData); handlePlateInfo(response.AnyData)}))
        var reportNum = await HasProfileReport(person?.FBI ? person?.FBI : "", person?.SBINo ? person?.SBINo : "", person?.FName ? person?.FName : "", person?.LName ? person?.LName : "", person?.DOB ? (person?.DOB)?.toLocaleString() : "")
        setProfileReportNum(reportNum.ProfileID)
    }

    const handleAddressInfo = (address: any) => {
        for (let i = 0 ; i < address?.length; i++){
            if(address[i]?.Address.length > 0)
            {
                if(address[i].AddressType === 'DMV')
                {
                    dmvAddress.push(address[i].Address + ' ' + address[i].City + ' ' + address[i].State + ' ' + address[i].Zip)
                }
                else
                {
                    aAddress.push(address[i].Address + ' ' + address[i].City + ' ' + address[i].State + ' ' + address[i].Zip)
                }
            }
        }
        setAllAddresses(address)
        setDAddress(dmvAddress)
        setArrestAddress(aAddress)
    }

    const handlePlateInfo = (plates: any) => {
        for (let i = 0 ; i < plates?.length; i++){
            if(plates[i]?.PlateNo.length > 0)
            {
                if(plates[i].Source === 'DMV')
                {
                    dmvPlates.push(plates[i].PlateNo + " " + plates[i].PlateSt)
                }
                else
                {
                    eTicketPlates.push(plates[i].PlateNo + " " + plates[i].PlateSt)
                }
            }
        }
        setDPlate(dmvPlates);
        setETicketPlate(eTicketPlates);
    }

    const handleAssociatedPeople = (response: any) => {
        let temp: any[] = []

        if (response.Association) {
            associatedPeople.push(...response.Association)
        }
        if (response.Association1) {
            associatedPeople.push(...response.Association1)
        }
        if (response.Association2) {
            associatedPeople.push(...response.Association2)
        }
        if (response.Association3) {
            associatedPeople.push(...response.Association3)
        }
        if (response.Association4) {
            associatedPeople.push(...response.Association4)
        }
        setAssociatedPeopleInfo(associatedPeople)
    }

    const handlePrint = async () => {

        let pdfPerson : any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            SCORInfo: crumbScoreInfo,
            ArrestInfo: arrestInfo,
            ShootingInfo: ShootingInfo,
            DNAInfo: codisDNAInfo,
            PoliceStopInfo: PoliceStopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            ParkMobileInfo: parkMobileInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
        }

        //let pdfPersonInfo = {
        //    PersonInfo:person,
        //    CrumbScoreInfo:crumbScoreInfo,
        //    PersonImageInfo:personImageInfo,
        //    ArrestInfo:arrestInfo,
        //    ShootingInfo:ShootingInfo,
        //    ALPRInfo:alprInfo,
        //    CodisDNAInfo:codisDNAInfo,
        //    PoliceStopInfo:PoliceStopInfo,
        //    ETicketInfo:eTicketInfo,
        //    CADRMSInfo:cadrmsInfo,
        //    ParkMobileInfo:parkMobileInfo,
        //    ETraceInfo:eTraceInfo,
        //    NJTraceInfo:njTraceInfo,
        //    NibinInfo:nibinInfo,
        //    PrisonInfo:prisonInfo,
        //    ParoleInfo:paroleInfo,
        //    ViolentInfo:violentInfo,
        //    ProfessionInfo:professionInfo,
        //    PhoneAddressInfo:phoneAddressInfo,
        //    AssociatedPeopleInfo:associatedPeopleInfo,
        //    DLNosInfo:DLNo,
        //    PlateInfo:plateInfo,
        //}
        
        //_PDFPersonSummary(pdfPersonInfo) //<- pdfMake

        //RunPersonSummaryPDF(query) //<- export controller with tasks uncommented

        RunPersonSummaryPDF(pdfPerson)

    }

    const handleGRAIL = async () => {
       
        try {
            let checkGRAIL: GRAILReferral = {
                PersonID:person.PersonID,
                Adopted: null,
                Analytic_Notes: null,
                Arrest: null,
                CODIS: null,
                CaseExplorer: null,
                City: null,
                Comments: null,
                CreateDate: null,
                CreateUID: null,
                Custody_County: null,
                Custody_Status: null,
                DLNo: null,
                DLState: null,
                DOB: person?.DOB,
                EditStatus: null,
                FBI: person?.FBI,
                eCDR: null,
                FIRSTNAME: person?.FName,
                Felony_Convictions: null,
                Gang: null,
                Gun_Arrests: null,
                ID: null,
                InfoShare: null,
                Judge: null,
                LASTNAME: person.LName,
                MI: null,
                NIBIN: null,
                Shooting: null,
                Parole: null,
                Pending_Charges: null,
                Pending_Charges_Count: null,
                Pending_GunCharge: null,
                Pending_GunCharge_Count: null,
                Photos: null,
                Prosecution_Outcome: null,
                Prosecutor: null,
                RTCC: null,
                Referral_County: null,
                Resident_County: null,
                SBINo: person?.SBINo,
                SBIState: null,
                SSN: null,
                Shooting_Suspect: null,
                Shooting_Victim: null,
                Status: null,
                SubmitDate: null,
                Total_Arrests: null,
                UpdDate: null,
                UpdUID: null,
                isCODIS: null,
                isCaseExplorer: null,
                isGang: null,
                isInfoShare: null,
                isNIBIN: null,
                isShooting: null,
                isParole: null
            }

            let response = await RunGetGRAILReferral(checkGRAIL)
            let grail = response?.AnyTable
            if (grail == null || (grail && grail.length == 0)) {
                let personObj: any = {
                    PersonInfo: person,
                    ImageInfo: personImageInfo,
                    SCORInfo: crumbScoreInfo,
                    ArrestInfo: arrestInfo,
                    ShootingInfo: ShootingInfo,
                    DNAInfo: codisDNAInfo,
                    PoliceStopInfo: PoliceStopInfo,
                    ETicketInfo: eTicketInfo,
                    CADRMSInfo: cadrmsInfo,
                    ParkMobileInfo: parkMobileInfo,
                    ETraceInfo: eTraceInfo,
                    NJTraceInfo: njTraceInfo,
                    NIBINInfo: nibinInfo,
                    PrisonInfo: prisonInfo,
                    ParoleInfo: paroleInfo,
                    ViolentInfo: violentInfo,
                    ProfessionInfo: professionInfo,
                    ALPRInfo: alprInfo,
                    DLInfo: DLNo,
                    AddressInfo: phoneAddressInfo?.AnyData,
                    PhoneInfo: phoneAddressInfo?.AdditionalData,
                    PlateInfo: plateInfo,
                    AssociatedPeopleInfo: associatedPeopleInfo,
                }
                let l = new JAOS()
                let hashID = l.objToStack(personObj)
                window.open('/GRAIL?Hash=' + hashID, '_blank')
            }
            else
            {
                setAlertType("error")
                setAlertText(("An Incomplete Referral has already been created for ") + person?.FName + ' ' + person?.LName + " Please Edit through referral search.")
                setAlertOpen(true)
            }
        }
        catch(e)
        {
            console.log(e)
        }
    }

    const handleAnalysis = () => {

        let personObj : any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            SCORInfo: crumbScoreInfo,
            ArrestInfo: arrestInfo,
            ShootingInfo: ShootingInfo,
            DNAInfo: codisDNAInfo,
            PoliceStopInfo: PoliceStopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            ParkMobileInfo: parkMobileInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,
        }

        let l = new JAOS()
        let hashID = l.objToStack(personObj)
        window.open('/PersonAnalysis?Hash='+hashID,'_blank')
    }
    
    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const handleMap = () => {
        //todo make all pins. send to geocodeDisplay
        let pins:any[] = []
        //make address pins
        if(allAddresses) {
            for (let p of allAddresses ) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        address: p.Address,
                        city: p.City,
                        zip: p.Zip,
                        tool: "home"
                    })
                }
            }
        }
        //make arrest pins
        if(arrestInfo) {
            for (let p of arrestInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date:p.DTOFARREST,
                        statute:p.STATUTE,
                        arrestedBy:p.ORIGINATINGORINAME,
                        tool: "arrest"
                    })
                }
            }
        }
        //make Shooting pins
        if(ShootingInfo) {
            for (let p of ShootingInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999)) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.IncidentID,
                        weapon: p.W_Status + ' ' + p.W_Class + ' ' + p.W_Type,
                        tool: "Shooting"
                    })
                }
            }
        }
        //make PoliceStop pins
        if(PoliceStopInfo) {
            for (let p of PoliceStopInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date:p.DateTimeIN,
                        offense:p.ReqID,
                        arrestedBy:p.Dept,
                        tool: "PoliceStop"
                    })
                }
            }
        }
        //make eticket pins
        if(eTicketInfo) {
            for (let p of eTicketInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date:p.eCreated,
                        dept:p.eMunicipality,
                        plate: p.ePlateNbr,
                        tool: "eticket"
                    })
                }
            }
        }
        //make cadrms pins
        if(cadrmsInfo) {
            for (let p of cadrmsInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.AgencyCaseNo,
                        role: p.RoleDesc,
                        tool: "cadrms"
                    })
                }
            }
        }
        //make etrace pins
        if(eTraceInfo) {
            for (let p of eTraceInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIn,
                        offense: p.AgencyCaseNo,
                        weapon: p.W_TYPE_DESC,
                        tool: "etrace"
                    })
                }
            }
        }
        //make park data pins
        if(parkMobileInfo) {
            for (let p of parkMobileInfo) {
               if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        city: p.zoneid,
                        date: p.startdatetime,
                        tool: "parkmobile"
                    })
                }
            }
        }
        /*//make njguntrace data pins
        if(parkMobileInfo) {
            for (let p of parkMobileInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        city: p.zoneid,
                        date: p.startdatetime,
                        tool: "njtrace"
                    })
                }
            }
        }*/
        //make alpr data pins
        if(alprInfo) {
            for (let p of alprInfo) {
                if (p.Latitude && p.Longitude && (p.Latitude !== -1 && p.Longitude !== -1) && (p.Latitude !== -999 && p.Longitude !== -999) ){
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.red_TimeStamp,
                        name: p.src_Description,
                        plate: p.red_vrm,
                        tool: "alpr"
                    })
                }
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
        
    }

    const handlePOI = () => {
        var POI = {
            PlateNumber: '',
            PlateSt: '',
            PlateNumber2: '',
            PlateSt2: '',
            PlateNumber3: '',
            PlateSt3: '',
            VIN: '',
            FName: '',
            MName: '',
            LName: '',
            DOB: '',
            DLNo: '',
            DLSt: '',
            SSN: '',
            FBINo: '',
            SBINo: '',
            EmpID: user.EmpID,
            DeptCode: user.Dept,
            EditStatus: 'PartialEdit',
            UniqueID: 0,
        }
        if (DLNo) {
            //what does this even do very inefficent?
            for (var i = 0; i < DLNo?.length; i ++) {
                let data = DLNo[i];
                POI.DLNo = data.DLNo;
                POI.DLSt = data.DLSt;
                break;
            }
        }

        if (plateInfo) {
            for (i = 0; i < plateInfo?.length; i += 1) {
                var data = plateInfo[i];
                if (data.PlateNo) {
                    if (!POI.PlateNumber) {
                        POI.PlateNumber = data.PlateNo;
                        POI.PlateSt = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== POI.PlateNumber && !POI.PlateNumber2) {
                        POI.PlateNumber2 = data.PlateNo;
                        POI.PlateSt2 = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== POI.PlateNumber && data.PlateNo !== POI.PlateNumber2 && !POI.PlateNumber3) {
                        POI.PlateNumber3 = data.PlateNo;
                        POI.PlateSt3 = data.PlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        if (eTicketInfo) {
            for (i = 0; i < eTicketInfo?.length; i += 1) {
                data = eTicketInfo[i];
                if (data.ePlateNbr) {
                    if (!POI.PlateNumber) {
                        POI.PlateNumber = data.ePlateNbr;
                        POI.PlateSt = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== POI.PlateNumber && !POI.PlateNumber2) {
                        POI.PlateNumber2 = data.ePlateNbr;
                        POI.PlateSt2 = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== POI.PlateNumber && data.ePlateNbr !== POI.PlateNumber2 && !POI.PlateNumber3) {
                        POI.PlateNumber3 = data.ePlateNbr;
                        POI.PlateSt3 = data.ePlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        POI.FName = person?.FName ? person?.FName : "";
        POI.MName = person?.MName ? person?.MName : "";
        POI.LName = person?.LName ? person?.LName : "";
        POI.DOB = person?.DOB ? person?.DOB : '';
        if (person?.SSN1) POI.SSN = person?.SSN1;
        //if (person?.VIN) POI.VIN = person?.VIN;
        if (person?.FBI) POI.FBINo = person?.FBI;
        if (person?.SBINo) POI.SBINo = person?.SBINo;

        let l = new JAOS()
        let hashID = l.objToStack(POI)
        window.open('/addPOI?Hash='+hashID,'_blank')
    }
    const handleSocial = async () => {
        //Call API  Account / Social
        // retrieve RedirectLink1, 2
        //Call 2 new Tabs
        let r = await RunSocialMedia(query)
        window.open(r.RedirectLink, '_blank')
        window.open(r.RedirectLink_Additional, '_blank')
    }

    const handleCorrection = async () => {
        //todo
        let r = await RunCorrection(query)
        window.open(r.RedirectLink, '_blank')
    }

    const handlePromisGavel = async () => {
        //todo
        let r = await RunPromisGavel(query)
        window.open(r.RedirectLink, '_blank')
    }

    const handleShareLink = async () => {
        let r = await RunExpiryToken(query)
        let link = Main_URL + "/SharedPerson/" + r.AnyData;  //need to set demo/prod
        navigator.clipboard.writeText(link)
        setShareLink(link); 
        setExpiryOpen(true)
    }    
    
    const handleNCIC = () => {
        var NCIC = {
            UniqueID: 0,
            PlateNumber: '',
            PlateSt: '',
            PlateNumber2: '',
            PlateSt2: '',
            PlateNumber3: '',
            PlateSt3: '',
            PlateType: '',
            VehicleType: '',
            DLNo: '',
            DLSt: '',
            SSN: '',
            VIN: '',
            FBINo: '',
            SBINo: '',
            SBISt: '',
            FName: '',
            MName: '',
            LName: '',
            DOB: '',
            SEX: '',
            CaseNo: '',
            SearchNotes: '',
            Response: '',
            SearchType: '',
            EmpID: user.EmpID,
            DeptID: user.Dept,
            DeviceORI: '',
            SiteName: '',
            CreateUID: '',
            CreateDate: null,
            UpdUID: '',
            UpdDate: null,
            Active: '',
            EditStatus: 'PartialEdit',
        }
        if (DLNo) {
            //what does this even do very inefficent?
            for (var i = 0; i < DLNo?.length; i ++) {
                let data = DLNo[i];
                NCIC.DLNo = data.DLNo;
                NCIC.DLSt = data.DLSt;
                break;
            }
        }

        if (plateInfo) {
            for (i = 0; i < plateInfo?.length; i += 1) {
                var data = plateInfo[i];
                if (data.PlateNo) {
                    if (!NCIC.PlateNumber) {
                        NCIC.PlateNumber = data.PlateNo;
                        NCIC.PlateSt = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== NCIC.PlateNumber && !NCIC.PlateNumber2) {
                        NCIC.PlateNumber2 = data.PlateNo;
                        NCIC.PlateSt2 = data.PlateSt;
                        continue;
                    }
                    if (data.PlateNo !== NCIC.PlateNumber && data.PlateNo !== NCIC.PlateNumber2 && !NCIC.PlateNumber3) {
                        NCIC.PlateNumber3 = data.PlateNo;
                        NCIC.PlateSt3 = data.PlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        if (eTicketInfo) {
            for (i = 0; i < eTicketInfo?.length; i += 1) {
                data = eTicketInfo[i];
                if (data.ePlateNbr) {
                    if (!NCIC.PlateNumber) {
                        NCIC.PlateNumber = data.ePlateNbr;
                        NCIC.PlateSt = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== NCIC.PlateNumber && !NCIC.PlateNumber2) {
                        NCIC.PlateNumber2 = data.ePlateNbr;
                        NCIC.PlateSt2 = data.ePlateSt;
                        continue;
                    }
                    if (data.ePlateNbr !== NCIC.PlateNumber && data.ePlateNbr !== NCIC.PlateNumber2 && !NCIC.PlateNumber3) {
                        NCIC.PlateNumber3 = data.ePlateNbr;
                        NCIC.PlateSt3 = data.ePlateSt;
                        //once all 3 are poluated come out of the loop
                        break;
                    }
                }
            }
        }

        NCIC.FName = person?.FName ? person?.FName : "";
        NCIC.MName = person?.MName ? person?.MName : "";
        NCIC.LName = person?.LName ? person?.LName : "";
        NCIC.DOB = person?.DOB ? person?.DOB : '';
        NCIC.SEX = person?.SEX ? person?.SEX : "";
        if (person?.SSN1) NCIC.SSN = person?.SSN1;
        //if (person?.VIN) NCIC.VIN = person?.VIN;
        if (person?.FBI) NCIC.FBINo = person?.FBI;
        if (person?.SBINo) NCIC.SBINo = person?.SBINo;
    
        let l = new JAOS()
        let hashID = l.objToStack(NCIC)
        window.open('/ncic?Hash='+hashID,'_blank')
    }

    const handleProfileReport = async () => {
        
        let profileReport : any = {
            PersonInfo: person,
            ImageInfo: personImageInfo,
            ArrestInfo: arrestInfo,
            SCORInfo: crumbScoreInfo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            /*ShootingInfo: ShootingInfo,
            DNAInfo: codisDNAInfo,
            PoliceStopInfo: PoliceStopInfo,
            ETicketInfo: eTicketInfo,
            CADRMSInfo: cadrmsInfo,
            ParkMobileInfo: parkMobileInfo,
            ETraceInfo: eTraceInfo,
            NJTraceInfo: njTraceInfo,
            NIBINInfo: nibinInfo,
            PrisonInfo: prisonInfo,
            ParoleInfo: paroleInfo,
            ViolentInfo: violentInfo,
            ProfessionInfo: professionInfo,
            ALPRInfo: alprInfo,
            DLInfo: DLNo,
            AddressInfo: phoneAddressInfo?.AnyData,
            PhoneInfo: phoneAddressInfo?.AdditionalData,
            PlateInfo: plateInfo,
            AssociatedPeopleInfo: associatedPeopleInfo,*/
        }
        
        let l = new JAOS()
        let hashID = l.objToStack(profileReport)
        window.open('/tools/profile_report?profile='+hashID,'_blank')
    }
    
    const handleProfileReportPrint = async () => {
        if(profileReportNum) {
            GetProfileReportPDF(profileReportNum)
        }
    }
    
    let grid: Grid | null;

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }
    
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            grid.excelExport();
        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    return(
        <div className={"mt-5 mb-5 border border-dashed"} style={{maxWidth: "85vw"}}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <div className={"w-min-max bg-blue-100 p-2 rounded"}>
                <h4 style={{"fontWeight": "bold"}}>Arrest Report</h4>
                {isLogoShow()? <>
                    <div style={{display:'inline-block'}}>
                        <img src={RTCC} alt={''} style={{display:'inline'}} />
                        <img src={CorrStat} alt={''} style={{display:'inline', marginLeft:'5px'}} />
                    </div>
                </>:<></>}
            </div>
            <div className= "grid grid-cols-3 gap-x-0.5 gap-y-2">
                <div className = "mt-3">
                    <b>Name:</b> {person?.FName} {person?.MName} {person?.LName}
                    <br />
                    <b>DOB:</b> {person?.DOB ? formatDate(DateToString(new Date(person?.DOB)).toLocaleString()) : ""}
                    <br />
                    <b>SEX:</b> {person?.SEX}
                    <br />
                    <b>City:</b> {person?.City}
                    <br />
                    {crumbScoreInfo?.Status ? <><b>Status:</b> <span className = {crumbScoreInfo?.Status === 'Dead' ? "font-bold text-base bg-red-500" : "font-bold text-base bg-yellow-300"}>
                        {crumbScoreInfo?.Status === 'Dead' ? 'DECEASED' : crumbScoreInfo?.Status === 'Excercise' ? 'EXCERCISE' : <span> INCARCERATED till {formatDate(crumbScoreInfo?.FutureReleaseDate)}</span>}</span></> :<></>}
                </div>
                <div>
                    {(personImageInfo) ? personImageInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((image, idx) => (<span><img src={image.Image} height={180} width={150} /></span>)) : <></>}
                    <span>
                        {(personImageInfo && personImageInfo?.length > 1) ?
                            <span>
                                <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" onClick={() => setPage(0)}><DoubleArrowSharpIcon fontSize="small" className="transform rotate-180" /></Button>
                                <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} onClick={(page-1 >= 0)? () => setPage(page - 1): () => ('')}><KeyboardArrowLeftSharpIcon/></Button>
                                {personImageInfo.map((image, idx) => <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} onClick={() => setPage(idx)}>{idx + 1}</Button>)}
                                <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} onClick={(page + 1 < personImageInfo.length) ? () => setPage(page + 1) : () => ('')}><KeyboardArrowRightSharpIcon /></Button>
                                <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} size="small" onClick={() => setPage(personImageInfo.length - 1)}><DoubleArrowSharpIcon fontSize="small" /></Button>
                            </span>
                        :
                        <></>}
                    </span>
                </div>
                {!PrintMode? <>
                    <div>
                        <ButtonGroup color="inherit">
                            <Tooltip title="Print Arrest Person Activities" placement="top" arrow >
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handlePrint}><PrintIcon color ="primary" style ={{height:22, width:22, marginRight:4}}/>{/*<b>Print</b>*/}</IconButton>
                            </Tooltip>
                            <Tooltip title="Show Arrest Person Activities on Map" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handleMap}><MapIcon color="success" style ={{height:22, width:22, marginRight:4}}/>{/*<b>Map</b>*/}</IconButton>
                            </Tooltip>
                            {isPOIShow()?
                                <Tooltip title="Create Point of Interest" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handlePOI}><img src={POIIcon} style ={{height:22, width:22, marginRight:4}}/>{/*<b>POI</b>*/}</IconButton>
                                </Tooltip> :<></>}
                            {isNCICShow()?
                                <Tooltip title="NCIC Warrant Details" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handleNCIC}><img src={NCICIcon} style ={{height:22, width:22, marginRight:4}}/>{/*<b>NCIC</b>*/}</IconButton>
                                </Tooltip> :<></>}
                            {isNCICShow() ?
                                <Tooltip title="GVRTF Referral Form" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handleGRAIL}><img src={RTCC} style ={{height:22, width:22, marginRight:4}}/>{/*<b>GVRTF</b>*/}</IconButton>
                                </Tooltip>
                            :<></>}
                        </ButtonGroup>
                        <ButtonGroup color="inherit">
                            {isArrestShow() ?
                            <Tooltip title="Social Media Details" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handleSocial}><img src={SocialIcon} style ={{height:22, width:22, marginRight:4}}/>{/*<b>Social</b>*/}</IconButton>
                            </Tooltip>
                                :<></>}
                            {isArrestShow() ?
                            <Tooltip title="Dept Of Correction Details" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handleCorrection}><img src={CorrectionIcon} style ={{height:22, width:22, marginRight:4}}/>{/*<b>Correction</b>*/}</IconButton>
                            </Tooltip>
                                :<></>}
                            {/*{isArrestShow() ?
                            <Tooltip title="Criminal Cases: PROMIS/Gavel Public Access Details" placement="top" arrow>
                                <IconButton size={"small"} style={{ color: "gray" }} onClick={handlePromisGavel}><img src={PromisGavelIcon} style ={{height:22, width:22, marginRight:4}}/><b>PROMIS/Gavel</b></IconButton>
                            </Tooltip>
                                :<></>}*/}
                            {isAnalysisShow() ?
                                <Tooltip title="Person Analysis" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handleAnalysis}><InsightsIcon style ={{height:22, width:22, marginRight:4}}/>{/*<b>Analysis</b>*/}</IconButton>
                                </Tooltip>
                                :<></>}
                            {isArrestShow() && (user?.SharedToken == null) ?
                                <Tooltip title="Create Share Link for this Person" placement="top" arrow>
                                    <IconButton size={"small"} style={{ color: "gray" }} onClick={handleShareLink}><img src={ShareLinkIcon} style ={{height:22, width:22, marginRight:4}}/>{/*<b> Share Link</b>*/}</IconButton>
                                </Tooltip>
                                :<></>}
                        </ButtonGroup>
                        
                        { user.Dept === 'NJSP' || user.AuthLevel === 'S' || profileReportNum ?
                        <fieldset className = "border border-gray-500 font-bold mt-5 w-96"> 
                            <legend className ="ml-3 text-sm"> Files </legend>
                            <div className = " grid grid-cols-1 pb-5">
                                <div className = "mt-5 text-xs ml-auto mr-auto"> PROFILE REPORT
                                    <span className = "ml-2 mr-2">
                                        { user.Dept === 'NJSP' || user.AuthLevel === 'S' ?
                                            <Tooltip title="Add or Edit Profile Report" placement="top" arrow>
                                                <Button size={"small"} variant={"outlined"} color = "inherit" style={{ color: "gray" }} onClick={handleProfileReport}><ContactPageIcon /><b>Profile Report</b></Button>
                                            </Tooltip>
                                            : //if report exists show button for other users as print{}
                                            <></>
                                        }
                                    </span>
                                    {profileReportNum ?
                                            <Tooltip title="View Profile Report" placement="top" arrow>
                                                <Button  size={"small"} variant={"outlined"} color = "primary" onClick={handleProfileReportPrint}><b>View</b></Button>
                                            </Tooltip>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </fieldset>
                            : <></>}
                    </div>
                </>:<></>}
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" width="60px" className="bg-blue-400">
                                    <span className = "text-white">FBI#</span>
                                </TableCell>
                                <TableCell align="left">{person?.FBI}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">SBI#</span>
                                </TableCell>
                                <TableCell align="left">{person?.SBINo}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">SCOR Details</span>
                                </TableCell>
                                <TableCell align="left">
                                    <Disclosure>
                                    {({ open }) => (
                                        <>
                                                <Disclosure.Button>
                                                    <KeyboardArrowDownIcon className={`${open ? "transform rotate-180" : ""}`} /> <span className="text-base mr-2"><b>Position within NJ Offender Population: </b> {crumbScoreInfo?.CrumbScoreRank ? <>{crumbScoreInfo?.CrumbScoreRank} of {crumbScoreInfo?.TotalPeople}</> :<></> }</span>
                                                    <span className="text-base mr-2"><b>SCOR Date: </b> {formatDate(crumbScoreInfo?.CrumbScoreDate)}</span>
                                                    <span className="text-base mr-2"><b>SCOR Rank: </b> {crumbScoreInfo?.CrumbScoreDenseRank}</span>
                                                    <Tooltip title={"Show SCOR Calculations PDF"}><Button onClick={() => OpenSCORPDF(person.PersonID, person.Dept)}><HelpOutline /></Button></Tooltip>
                                                   {/* <span className="text-base"><b>SCOR Percentile: </b> {crumbScoreInfo?.CrumbScorePercentile} </span>*/}
                                            </Disclosure.Button>
                                            <Disclosure.Panel >
                                            {crumbScoreInfo && crumbScoreInfo.CrumbScore ? 
                                            
                                            <div className={"mt-5"}>
                                                    <GridComponent
                                                        dataSource={[crumbScoreInfo]}
                                                        dataBound={dataBound}
                                                        allowSorting={true}
                                                        allowMultiSorting={true}
                                                        allowTextWrap={true}
                                                        textWrapSettings={{ wrapMode: 'Both' }}
                                                        allowExcelExport={true}
                                                        allowPdfExport={true}
                                                        ref={g => grid = g}
                                                        selectionSettings={{
                                                            persistSelection: true,
                                                            type: "Multiple",
                                                            //checkboxOnly: true,
                                                            mode: "Row"
                                                        }}
                                                        allowSelection={true}
                                                        allowResizing={true}
                                                        rowHeight={24}
                                                    >
                                                        <ColumnsDirective>    
                                                            <ColumnDirective field='CrumbScore' headerText='SCOR' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                                                            <ColumnDirective field='Arrest' headerText='Arrest' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='Arrest_Assault' headerText='Agg Assault' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='Arrest_Gun' headerText='Gun Arrest' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='Arrest_Murder' headerText='Murder/Manslaughter' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='Codis' headerText='Codis Hit' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='NJTrace' headerText='NJ Trace' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='LEInteraction_MT_10' headerText='LE Interaction > 10' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='Parole' headerText='Active Parole' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='PrisonRelease' headerText='Prison Release' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='ShootingLE' headerText='Shooting LE' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='ShootingSuspect' headerText='Shooting Suspect' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='ShootingVictim' headerText='Shooting Victim' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='TriggerLock' headerText='Trigger Lock' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                                                            <ColumnDirective field='Violent' headerText='Violent Offender' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }}/>
                                                        </ColumnsDirective>
                                                        <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport, Resize, Reorder]}/>
                                                    </GridComponent>
                                            </div>
                                            :<div>No SCOR Data</div>}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                    </Disclosure>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Gang</span>
                                </TableCell>
                                <TableCell align="left">{person?.GANGRELATED}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">DL#</span>
                                </TableCell>
                                <TableCell align="left">{DLNo?.map((dl) =>dl.DLNo + ' ' + (dl.DLSt ? dl.DLSt : "")) + ' '}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Plates</span>
                                </TableCell>
                                <TableCell align="left">
                                    <b>DMV Agency:</b> {dPlate.map((plate) => plate + ' ')}
                                    <br/>
                                    <b>ETicket: </b> {ETicketPlate.map((plate) => plate + ' ')}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Addresses</span>
                                </TableCell>
                                <TableCell align="left">
                                <b>DMV Agency:</b> {dAddress.map((address) => address + ' ')}
                                <br/>
                                <b>Arrest: </b> {arrestAddress.map((address) => address + ' ')}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" className="bg-blue-400">
                                    <span className="text-white">Phone#</span>
                                </TableCell>
                                
                                <TableCell align="left">{phoneAddressInfo?.AdditionalData?.map((data) => data?.Phone ? data?.Phone : "" + ' ' + data?.PhoneType ? data?.PhoneType : "" + ' ')}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            { /*Display Panels Below */}
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-400 p-2 w-auto w-full text-left mt-5 rounded">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span className ="text-white">
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    Summary Info
                                </span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            <PanelArrest person={person} ArrestInfo={arrestInfo} isLoading={arrestLoading} crumbArrest = {crumbScoreInfo?.Arrest ? crumbScoreInfo?.Arrest : ""}/>
                            <PanelShooting person={person} ShootingInfos={ShootingInfo} isLoading={ShootingLoading}/>
                            <PanelCODIS person={person} DNAInfo={codisDNAInfo} isLoading={codisDNALoading}/>
                            <PanelPoliceStop person={person} AgencyInteractionInfos={PoliceStopInfo} isLoading={PoliceStopLoading} />
                            <PanelEticket person={person} ETInfos={eTicketInfo} isLoading={eTicketLoading}/>
                            <PanelCADRMS person={person} CADRMSCases={cadrmsInfo} isLoading={cadrmsLoading}/>
                            <PanelParkMobile person={person} PDInfos={parkMobileInfo} isLoading={parkMobileLoading}/>
                            <PanelETrace person={person} WeaponTraces={eTraceInfo} isLoading={eTraceLoading}/>
                            <PanelNJGunTrace person={person} NJWeaponTraces={njTraceInfo} isLoading={njTraceLoading}/>
                            <PanelNIBIN person={person} NIBINs={nibinInfo} isLoading={nibinLoading}/>
                            <PanelPrison person={person} PrisonerReleases={prisonInfo} isLoading={prisonLoading}/>
                            <PanelParole person={person} PrisonerParoles={paroleInfo} isLoading={paroleLoading}/>
                            <PanelViolent person={person} Violents={violentInfo} isLoading={violentLoading}/>
                            <PanelReferral person={person} Referrals={referralInfo} isLoading={referralLoading}/>
                            <PanelProfession person={person} Professions={professionInfo} isLoading={professionLoading}/>
                            <PanelAssociatedPeople person={person} AssociatedPeople={associatedPeopleInfo} isLoading={associatedPeopleLoading}/>
                            <PanelALPR person={person} ALPRInfo={alprInfo} isLoading={alprLoading} />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={'PersonSummary'}/></div>:<></>}
            
            <Dialog
                open={expiryOpen}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
               <div className = "px-20 pb-5 text-center">
                   <div className ="flex justify-end"><Button color = {"error"} onClick={() => {setShareLink(""); setExpiryOpen(false)}}><span className ="text-xl">X</span></Button></div>
                   <div className ="font-bold mb-8 ">Link has been Copied to Clipboard!</div>
                   <div className = "text-blue-400"><a href={shareLink}>{shareLink}</a></div>
                   <div className = " mt-8 text-gray-500 text-sm ">Information should only be shared with those that have the right to know and need to know.</div>
                   <div className = " mt-1 font-bold text-sm text-red-800">By sharing this link you take responsibility for the data shared.</div>
               </div>
            </Dialog>
        </div>
    )
};

export default BTPersonReport;