import React from 'react'
import {
    Button, Collapse, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Tooltip,
    Switch,
    FormControlLabel,
    FormGroup,
    FormControl, ButtonGroup, Icon, Dialog, Alert, Snackbar
} from "@mui/material";
import {formatDate, formatDT} from '../../services/formatDate.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {RunSetPOI, RunSetUser} from "../../services/account.service";
import UpdatePOI from "./UpdatePOI";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import EmailIcon from "@mui/icons-material/Email";

interface SearchResultsProps {
    POIs: any[]
}

const  SearchResults: React.FC<SearchResultsProps> = ({POIs})=> {
    //For Pagination
    const [poiHolder, setPOIHolder] = React.useState(POIs);
    const [poi, setPOI] = React.useState<boolean>(false)
    const [POIID, setPOIID] = React.useState<string>("0")
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [formatData, setFormatData] = React.useState<any>([])
    
    const CloseBox = () => {
        setPOI(false)
    }

    const editPOI = (id:string) =>
    {
        setPOIID(id)
        setPOI(true)
        //window.location.href = ("/admin/UpdateUser?ID=" + id)
    }

    const deletePOI = async (poi:any) => {
        let p = poi
        if (window.confirm("Remove This POI Record Permanently?")){
            poi.EditStatus = "Delete"
            let r = await RunSetPOI(poi)
            //console.log(r)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                setAlertOpen(true)
                setAlertText("Delete Successful")
            }
            else
            {
                alert(r.ErrorMessage)
            }
        }
    }
    
    const ActivateDeactivatePOI = async (poiT: any) => {
        let temp = [...formatData]
        let poi = temp[poiT.index]
        poi.EditStatus = poi.Active === "Active" ? "Inactive" : "Active"
        let r = await RunSetPOI(poi)
        if (r.AnyData?.IsValid) {
            setAlertOpen(true)
            poi.Active = poi.Active === "Active" ? "Inactive": "Active"
            temp[poiT.index] = poi
            setFormatData([])
            setFormatData(temp)
        }
    }

    const handleDataTyping = () => {
        //console.log(POIs)
        let tempP: any = [...POIs]
        for (let p of tempP) {
            if (p.StartDate) {
                p.StartDate = new Date(p.StartDate)
            }
            if (p.ExpiryDate) {
                p.ExpiryDate = new Date(p.ExpiryDate)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [POIs])
    
    let grid: Grid | null;

    const GridToolsClick = (args: ClickEventArgs) => {
        //console.log(args)
        if (grid && args.item.text === "Excel Export") {

            // let excelProp:ExcelExportProperties = {}
            // excelProp.fileName = 'ArrestExport.xlsx'
            //grid.excelExport(excelProp);
            (grid.columns[0] as Column).visible = false;
            (grid.columns[1] as Column).visible = false;
            
            grid.excelExport();

            //grid.excelExport();

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.columns[0] as Column).visible = true;
            (grid.columns[1] as Column).visible = true;
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const statusTemplate = (poi: any): any => {
        return (
            <span>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch onChange={() => ActivateDeactivatePOI(poi)} checked = {poi.Active === "Active"}/>
                            }
                            label= {poi.Active === "Active" ? "Active" : "Inactive" }
                        />
                    </FormGroup>
                </FormControl>
            </span>);
    }

    const actionTemplate = (poi: any): any => {
        return (
            <span>
                   <ButtonGroup>
                        <Tooltip title="Edit" placement="top" arrow>
                            <IconButton onClick = {() => editPOI(poi.UniqueID)}><EditIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top" arrow>
                            <IconButton onClick = {() => deletePOI(poi)}><DeleteIcon/></IconButton>
                        </Tooltip>
                   </ButtonGroup>
            </span>);
    }

    return (
        <div className={" pr-10"}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={ () => { setAlertOpen(false)}} severity="success" sx={{ width: '100%' }}>
                    {setAlertText}
                </Alert>
            </Snackbar>
            {formatData && formatData.length>0? <>
                <h4 className={"font-bold pl-5"}> {formatData?.length} Records Found</h4>
                <GridComponent
                    dataSource={formatData}
                    dataBound={dataBound}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    excelExportComplete={excelExportComplete}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={actionTemplate} headerText='Action'  width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={statusTemplate} headerText='Status'  width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='EmpID' headerText='Employee' customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='ReasonType' headerText='Reason Type' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Reason' headerText='Reason' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='StartDate' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm ' }} headerText='Start Date' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='ExpiryDate' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm ' }} headerText='Expiry Date' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='ConfidentialPOI' headerText='Confidential' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='NICNum' headerText='NIC#' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='FBINo' headerText='FBI#' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='SBINo' headerText='SBI#' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={(poi : any) => (<>{poi?.PlateNumber} {poi?.PlateSt} {poi?.PlateNumber2} {poi?.PlateSt2} {poi?.PlateNumber3} {poi?.PlateSt3} {poi.VIN}</>)} width={250} minWidth={250} maxWidth={250} headerText='Vehicle (Plate#, State, Vin#)' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={(poi : any) => (<>{poi?.FName} {poi?.MName} {poi?.LName}</>)} width={250} minWidth={250} maxWidth={250} headerText='POI (First Name, MI, Last Name)' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={(poi : any) => (<>{poi?.DLNo} {poi?.DLSt} {poi?.SSN}</>)} width={200} minWidth={200} maxWidth={200} headerText='Other (DL#, State, SSN)' customAttributes={{ class: ['e-attr'] }} />
                    </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search, Resize, Reorder]}/>
                </GridComponent>

            </>:<></>}
            <Dialog
                open={poi}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UpdatePOI ID={POIID} CloseBox={CloseBox}/>
            </Dialog>
        </div>

    )
};

export default SearchResults

/*<Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small" style={{"border":"1px solid black"}}>
                        <TableHead className={"bg-blue-100"}>
                            <TableRow>
                                <TableCell style={{"border":"1px solid gray"}}>Employee</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Reason Type</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Reason</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Start Date</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Expiry Date</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Confidential</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>NIC#</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>FBI#</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>SBI#</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Vehicle (Plate#, State, Vin#)</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>poi (First Name, MI, Last Name, DOB)</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Other (DL#, State, SSN)</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Status</TableCell>
                                <TableCell style={{"border":"1px solid gray"}}>Action</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                poiHolder?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((poi,idx)=>(
                                        <TableRow>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.EmpID}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.ReasonType}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.Reason}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{formatDate(poi.StartDate)}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{formatDate(poi.ExpiryDate)}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.ConfidentialPOI}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.NICNum}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.FBINo}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.SBINo}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.PlateNumber} {poi.PlateSt} {poi.PlateNumber2} {poi.PlateSt2} {poi.PlateNumber3} {poi.PlateSt3} {poi.VIN}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.FName} {poi.MName} {poi.LName} {formatDate(poi.DOB)}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>{poi.DLNo} {poi.DLSt} {poi.SSN}</TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>
                                                <FormControl component="fieldset" variant="standard">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch onChange={() => ActivateDeactivatePOI(poi)} checked = {poi.Active === "Active"}/>
                                                            }
                                                            label= {poi.Active === "Active" ? "Active" : "Inactive" }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell style={{"border":"1px solid gray"}}>
                                                <ButtonGroup>
                                                    <Tooltip title="Edit" placement="top" arrow>
                                                        <IconButton onClick = {() => editPOI(poi.UniqueID)}><EditIcon/></IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top" arrow>
                                                        <IconButton onClick = {() => deletePOI(poi)}><DeleteIcon/></IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {(poiHolder?.length>25)?
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={poiHolder.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={"bg-blue-100"}
                    />
                    : <></>}

            </Paper>*/