import React, {MouseEventHandler} from "react";
import {isUserLoggedIn, logoutUser, RefreshUserToken} from "../services/auth.service";
import {Redirect} from "react-router-dom";
import {OldUILayout} from "./oldUI/oldUI.layout";
import {TwoPanelLayout} from "./2PanelLayout/TwoPanel.layout";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import IdleTimer, { useIdleTimer } from 'react-idle-timer'

interface AppState {
    defaultLayout:boolean,
    idleDialogOpen:boolean,
    count:number
}
interface MainLayoutProps{
    
}

export class MainLayout extends React.Component<MainLayoutProps, AppState> {
    idleTimer:any = null
    timeoutLogoutInterval:any = null
    constructor(props:MainLayoutProps) {
        super(props);
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        
        this.state={
            defaultLayout:true,
            idleDialogOpen:false,
            count:0
        }
    }

    handleOnAction (event:any) {
        {this.setState({...this.state, count: (this.state.count + 1)})}
    }

    handleOnActive (event:any) {
        RefreshUserToken()
        this.setState({...this.state, idleDialogOpen:false})
        if(this.idleTimer)
            console.log('time remaining', this.idleTimer.getRemainingTime())
        try{
            clearInterval(this.timeoutLogoutInterval)
        }catch (e) {
            console.log(e);
        }
        
    }

    handleOnIdle (event:any) {
        RefreshUserToken(true);
        this.setState({...this.state, idleDialogOpen:true})
        if(this.idleTimer){
            console.log('last active', this.idleTimer?.getLastActiveTime())
        }
        this.timeoutLogoutInterval = setTimeout(()=>{
            clearInterval(this.timeoutLogoutInterval)
            logoutUser()
            window.location.href = '/login'
        }, (1000*60*3))
            
    }
    
    render () {
        const changeLayout = () => {
            this.setState({...this.state, defaultLayout:!this.state.defaultLayout})
        }

        return ( 
            (isUserLoggedIn()) ?
                <>
                    <IdleTimer
                        ref={(ref:any) => { this.idleTimer = ref }}
                        timeout={1000 * 60 * 30} 
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        onAction={this.handleOnAction}
                        crossTab={true}
                        debounce={500}
                    />
                    
                    <Dialog
                        open={this.state.idleDialogOpen}
                        onClose={()=>{this.setState({...this.state, idleDialogOpen:false})}}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Arrest Reports Inactivity Notification"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                We have detected that you are currently idle. We will proceed to logout if no activity is detected.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color={'success'} onClick={()=>{this.setState({...this.state, idleDialogOpen:false})}}>
                                <span style={{paddingLeft: '10px', paddingRight:'10px'}}>Continue using Arrest</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    
                    {(this.state.defaultLayout)? 
                        <OldUILayout count={this.state.count}>{this.props.children}</OldUILayout> 
                        : 
                        <TwoPanelLayout>{this.props.children}</TwoPanelLayout>
                    }
                    {/*<div className='fixed bottom-0 w-full'>
                        <IconButton aria-label="delete" className={'bottom-0 my-8 float-right'} onClick={()=>changeLayout()}>
                            <SwitchLeftIcon />
                        </IconButton>
                    </div>*/}
                </>
            : <Redirect to={'/login'} />
        );
    }
}
