import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, FormControl, ButtonGroup, Tooltip, IconButton } from "@mui/material";
import { VinDropDowns, ALPRDropDowns, ETicketDropDowns, ParkMobileDropDowns } from "../arrest/_arrestDropDowns";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { Inject, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { formatDT } from '../../../services/formatDate.service';
import { getDropDownQuery } from "../../../services/getDropdown.service";
import MapIcon from '@mui/icons-material/Map';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
/*import { DateTimeRangeContainer } from 'react-advanced-datetimerange-picker';*/
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import ErrorIcon from "@mui/icons-material/Error";
import { JAOS } from "../../../services/JAOS.service";

interface VINSearchProps {
    SubmitSearch: any
    data?: any | null
}

const VinSearch: React.FC<VINSearchProps> = ({ SubmitSearch, data = null }) => {
    let todayDate = new Date();
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    //let today: string = formatDT(todayDate.toISOString());
    //let start: string = formatDT(startDate.toISOString());
    /*const [params, setParams] = React.useState({ PlateNo: "", PlateState: "", VehVINNo: "", ALPRCaseNumber: "", ALPR: false, ETicket: false, ParkMobile: false, fromDate: start, toDate: today })*/
    const [params, setParams] = React.useState({ PlateNo: "", PlateState: "", VehVINNo: "", ALPRCaseNumber: "", ALPR: false, ETicket: false, ParkMobile: false, fromDate: startDate, toDate: todayDate })
    const [dropDownParams, setDropDownParams] = React.useState<any>({ DD: {}, ADD: {}, EDD: {}, PDD: {} });
    const [clear, setClear] = React.useState<number>(0);
    const [showGeoCode, setShowGeoCode] = React.useState<boolean>(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    const [mapVersion, setMapVersion] = useState<string>("??")
    const [submitError, setSubmitError] = React.useState<string>("")
    const [vinType, setVinType] = React.useState<string>("VIN");

    useEffect(() => {
        if (data) {
            handlePlateList()
        }
    }, [data]);

    const handlePlateList = async () => {
        let l = new JAOS()
        let plateList = l.objFromStack(data)
        let tParams = { ...params }
        tParams.PlateNo = plateList
        setParams({ ...params, PlateNo: plateList })
        SubmitSearch(tParams, dropDownParams, "VIN");
    }

    const HandleDropDowns = (dropDowns: any) => {
        let temp = dropDownParams
        temp.DD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownALPRs = (dropDowns: any) => {
        let temp = dropDownParams
        temp.ADD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownETickets = (dropDowns: any) => {
        let temp = dropDownParams
        temp.EDD = dropDowns
        setDropDownParams(temp)
    }

    const HandleDropDownParkMobiles = (dropDowns: any) => {
        let temp = dropDownParams
        temp.PDD = dropDowns
        setDropDownParams(temp)
    }

    const handleClear = () => {
        setSubmitError("");
        setParams({ ...params, PlateNo: "", PlateState: "", VehVINNo: "", ALPRCaseNumber: "", ALPR: false, ETicket: false, ParkMobile: false, fromDate: startDate, toDate: todayDate })
        setDropDownParams({ DD: {}, ADD: {}, EDD: {}, PDD: {} });
        setVinType("VIN")
        setClear(clear + 1)
    }

    const openGeoCode = () => {
        setMapVersion("alprCamera")
        setAllPins([])
        let pins: any[] = []
        if (dropDownParams.ADD.ALPRCameraLatLong && dropDownParams.ADD.ALPRCameraLatLong.length > 0) {
            for (let p of dropDownParams.ADD.ALPRCameraLatLong) {
                if (p.Latitude && p.Longitude) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        date: p.DateTimeIN,
                        alprSource: p.Source,
                        name: p.Name,
                        status: p.Status,
                        tool: "alprCamera"
                    })
                }
            }
        }
        setAllPins(pins);
        setShowGeoCode(true);
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const handleALPR = (e: any) => {
        let show = params.ALPR
        //let pm = params.ParkMobile
        //, ParkMobile: (!show) ? true : pm 
        setParams({ ...params, ALPR: !show})
    }

    const handleETicket = (e: any) => {
        let show = params.ETicket
        setParams({ ...params, ETicket: !show })
    }

    const handleParkMobile = (e: any) => {
        let show = params.ParkMobile
        setParams({ ...params, ParkMobile: !show })
    }

    const handleSearch = () => {
        if (handleValidation())
            SubmitSearch(params, dropDownParams, vinType)
    }

    const handleValidation = () => {
        setSubmitError("")
        var val = true
        if (dropDownParams.DD.VehMake?.length === 0 && params.PlateNo.length === 0 && params.VehVINNo.length === 0) {
            setSubmitError("Please enter atleast one search parameter");
            val = false;
        }
        else if (params.ALPR && (dropDownParams.ADD.ALPRReason.length === 0 || params.ALPRCaseNumber.length === 0)) {
            setSubmitError("Please enter ALPR Reason & Case#");
            val = false
        }
        if (params.ALPR && dropDownParams?.ADD?.ALPRCamera.length > 5) {
            setSubmitError("Please Limit The Number Of Selected Cameras To 5. Leave List Empty For All Cameras.")
            val = false
        }
        return val
    }

    const handleVINType = (event: React.MouseEvent<HTMLElement>, vinType: string) => {
        if(vinType){
            setVinType(vinType);
            if(vinType == 'NoVIN')
            {
                setClear(clear+1)
            }
        }
    };
    
    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <ToggleButtonGroup
                color="primary"
                value={vinType}
                exclusive
                size="small"
                onChange={handleVINType}
                sx={{ml: 1 }}
            >
                <ToggleButton value="NoVIN" sx={{ pr: 2, pl: 2 }} >NO VIN</ToggleButton>
                <ToggleButton value="VIN" sx={{ pr: 2, pl: 2 }} >Show VIN</ToggleButton>
            </ToggleButtonGroup>
            {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} /></div> : <></>}
            {vinType == "VIN" ? 
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "10px" }}>
                <VinDropDowns HandleDropDowns={HandleDropDowns} clear={clear} />
            </div>
            : <></>}
            <div className="grid grid-cols-7 gap-x-4 gap-y-2 ml-2 mt-3" >
                <TextField margin='none' size={"small"} label="Plate #" value={params.PlateNo} onChange={(e: any) => setParams({ ...params, PlateNo: e.target.value })} title="Search Sample: PL*E, PL%E, PL_TE, PL[A-E]TE, PL[^E-H]TE, 'PLATE1,PLATE2,PLATE3'" />
                <TextField margin='none' size={"small"} label="Plate State" value={params.PlateState} onChange={(e: any) => setParams({ ...params, PlateState: e.target.value })} />
                <TextField margin='none' size={"small"} label="VIN #" value={params.VehVINNo} onChange={(e: any) => setParams({ ...params, VehVINNo: e.target.value })} />
            </div>
            <div className="mb-2 ml-2 mt-5" style={{ display: "flex", justifyContent: "left" }} >
                <div className="font-medium">Date Range</div>
                <div className="border-gray-300 border-2 rounded ml-4 mr-2 w-48 pl-2 pr-2">
                    <DateTimePickerComponent
                        value={params.fromDate}
                        strictMode={true}
                        onChange={(e: { target: { value: any; }; }) => setParams({ ...params, fromDate: e.target.value })}
                        format='MM-dd-yyyy HH:mm'
                        floatLabelType="Auto"
                    />
                </div>
                <div className="border-gray-300 border-2 rounded ml-4 mr-2 w-48 pl-2 pr-2">
                    <DateTimePickerComponent
                        value={params.toDate}
                        strictMode={true}
                        onChange={(e: { target: { value: any; }; }) => setParams({ ...params, toDate: e.target.value })}
                        format='MM-dd-yyyy HH:mm'
                        floatLabelType="Auto"
                    />
                </div>
            </div>
            <div className={"ml-2"}>
                <CheckBoxComponent label="ALPR" cssClass="e-info" checked={params.ALPR} onChange={handleALPR} />
                {(params.ALPR) ?
                    <div className="mb-5" style={{ "marginTop": "15px", display: "flex", justifyContent: "left" }}>
                        <ALPRDropDowns HandleDropDowns={HandleDropDownALPRs} clear={clear} />
                        <div className="pt-2 mr-5"><TextField margin='none' size={"small"} label="Case #" value={params.ALPRCaseNumber} onChange={(e: any) => setParams({ ...params, ALPRCaseNumber: e.target.value })} /></div>
                        <div className={"pt-2 pb-2"} style={{ "display": "flex", "justifyContent": "end" }}>
                            <ButtonGroup size="medium" color="inherit" >
                                <Button variant="outlined" onClick={() => { openGeoCode() }} title="Maps ALPR Cameras for selected Area">
                                    <MapIcon color="success" className="mr-2" /><b>ALPR Camera</b>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    : <></>
                }
            </div>
            <div className="ml-2">
                <CheckBoxComponent label="ETicket" cssClass="e-info" checked={params.ETicket} onChange={handleETicket} />
                {(params.ETicket) ?
                    <div className="mb-5" style={{ "marginTop": "15px", display: "flex", justifyContent: "left" }}>
                        <ETicketDropDowns HandleDropDowns={HandleDropDownETickets} clear={clear} />
                    </div>
                    : <></>
                }
            </div>
            <div className="ml-2">
                <CheckBoxComponent label="Parking" cssClass="e-info" checked={params.ParkMobile} onChange={handleParkMobile} />
                {(params.ParkMobile) ?
                    <div className="mb-5" style={{ "marginTop": "15px", display: "flex", justifyContent: "left" }}>
                        <ParkMobileDropDowns HandleDropDowns={HandleDropDownParkMobiles} clear={clear} alprSelected={params.ALPR} />
                    </div>
                    : <></>
                }
            </div>
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                <ButtonGroup size="large" color="inherit" >
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <span style={{ "display": "flex", "justifyContent": "end" }} className="col-span-4">{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>
            <b>Note: Third Party vendors are included in ALPR search. Example: Bergen County will show reads from Vigilant, PIPs, Packetalk etc</b>
        </div>
    );
}

export default VinSearch;