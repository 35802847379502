import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Login from "./components/authentication/login/login";
import ForgotPassword from "./components/authentication/forgot-password/forgot-password";
import {MainLayout} from "./layouts/MainLayout";
import {Error404} from "./errors/404";
import MainDashboard from "./tools/main_dashboard/main_dashboard";
import Arrest from "./tools/arrest/arrest";
import FrequentOffender from "./tools/frequent_offender/frequent_offender";
import PersonNearBy from "./tools/person_nearby/person_nearby";
import ALPRMultipleOccurrence from "./tools/alpr_multiple_occurrence/alpr_multiple_occurrence";
import PhoneSearch from "./tools/phone_search/phone_search";
import TowedVehicle from "./tools/towed_vehicle/towed_vehicle";
import ShootingNotification from "./tools/shooting_notification/shooting_notification";
import OpenCaseIncidents from "./tools/open_case_incident/open_case_incident";
import CodisNotification from "./tools/codis_notification/codis_notification";
import Shooting from "./tools/Shooting/Shooting";
import ALPRVINSearch from "./tools/alpr_vin_search/alpr_vin_search";
import HumanTraffic from "./tools/human_traffic/human_traffic";
import ArrestAnalysis from "./tools/arrest_analysis/arrest_analysis";
import Parole from "./tools/parole/parole";
import PrisonerInformation from "./tools/prisoner_information/prisoner_information";
import ProfessionalLicense from "./tools/professional_license/professional_license";
import StatuteMapping from "./tools/statue_mapping/statue_mapping";
import ViolentOffender from "./tools/violent_offender/violent_offender";
import LogOff from "./components/authentication/logoff/logoff";
import ArrestDetail from './Views/Detail/ArrestDetail';
import ETicketDetail from './Views/Detail/ETicketDetail';
import PoliceStopDetail from './Views/Detail/PoliceStopDetail';
/*import PoliceStopImageDetail from './Views/Detail/PoliceStopImageDetail';*/
import ALPRDetail from './Views/Detail/ALPRDetail';
import CadRmsDetail from './Views/Detail/CadRmsDetail';
import CodisDNADetail from './Views/Detail/CodisDNADetail';
import ETraceDetail from './Views/Detail/ETraceDetail';
import NJTraceDetail from './Views/Detail/NJTraceDetail';
import ParkMobileDetail from './Views/Detail/ParkMobileDetail';
import PrisonParoleDetail from './Views/Detail/PrisonParoleDetail';
import PrisonReleaseDetail from './Views/Detail/PrisonReleaseDetail';
import ProfessionDetail from './Views/Detail/ProfessionDetail';
import ViolentDetail from './Views/Detail/ViolentDetail';

import ManageUser from './Views/Admin/ManageUser'
import ManagePOI from "./Views/Admin/ManagePOI";
import NCIC from "./components/Admin/NCIC";
import ManageNCIC from "./Views/Admin/ManageNCIC";
import ModifyUser from "./Views/Admin/ModifyUser";
import AddPOI from "./Views/Admin/AddPOI";
import PersonSummaryPDF from "./tools/pdf/BTPersonSummaryPDF";
import ManageDept from "./Views/Admin/ManageDept";
import ShootingMultipleOccurrence from "./tools/Shooting/ShootingMultipleOccurrence";
import DashboardLayout from "./components/Dashboard/_dashboardLayout";
import Dashboard from "./tools/dashboard/dashboard";
import ShotSpotter from './tools/shot_spotter/shot_spotter';
import SCOR from './tools/scor/scor';
import LinkAnalysis from "./tools/link_analysis/link_analysis";
import ManageUnitLevelGroup from './Views/Admin/UnitLevelGroup';
import AuditLog from './Views/Admin/AuditLog';
import ManagePassword from './Views/Admin/ManagePassword';
import ProfileReport from './tools/profile_report/profile_report';
import StolenVehicle from "./tools/stolen_vehicle/stolen_vehicle";
import ShootingPerson from "./tools/Shooting/ShootingPerson";
import DuplicatePerson from "./tools/duplicate_person/duplicate_person";
import GVRTFInputForm from "./components/GRAIL/GVRTF_InputForm";
import GVRTF from "./tools/grail/GVRTF";
import GRAILReferral from "./tools/grail/GRAILReferral";
import PersonAnalysis from "./tools/link_analysis/PersonAnalysis";
import SecuredView from './components/SharedPerson/SharedPerson';
import GVRTFReferralDetail from "./Views/Detail/GVRTFReferralDetail";
import UserManual from "./Views/Support/UserManual";
import FAQs from "./Views/Support/FAQs";

function App() {
  return (
    <div className="App">
        <Router>
            <Switch>
                <Route exact path='/logoff'><LogOff /></Route>
                <Route exact path='/login'><Login /></Route>
                <Route exact path='/forgot-password'><ForgotPassword /></Route>
                <Route exact path='/'><MainLayout><MainDashboard /></MainLayout></Route>
                <Route path='/change_password'><MainLayout><ManagePassword /></MainLayout></Route>
                <Route path='/tools/arrest'><MainLayout><Arrest /></MainLayout></Route>
                <Route path='/tools/frequent_offender'><MainLayout><FrequentOffender /></MainLayout></Route>
                <Route path='/tools/person_nearby'><MainLayout><PersonNearBy /></MainLayout></Route>
                <Route path='/tools/alpr_multiple_occurrence'><MainLayout><ALPRMultipleOccurrence /></MainLayout></Route>
                <Route path='/tools/phone_search'><MainLayout><PhoneSearch /></MainLayout></Route>
                <Route path='/tools/towed_vehicle'><MainLayout><TowedVehicle /></MainLayout></Route>
                <Route path='/tools/shooting_notification'><MainLayout><ShootingNotification /></MainLayout></Route>
                <Route path='/tools/open_case_incident'><MainLayout><OpenCaseIncidents /></MainLayout></Route>
                <Route path='/tools/codis_notification'><MainLayout><CodisNotification /></MainLayout></Route>
                <Route path='/tools/Shooting'><MainLayout><Shooting /></MainLayout></Route>
                <Route path='/tools/alpr_vin_search'><MainLayout><ALPRVINSearch /></MainLayout></Route>
                <Route path='/tools/human_traffic'><MainLayout><HumanTraffic /></MainLayout></Route>
                <Route path='/tools/arrest_analysis'><MainLayout><ArrestAnalysis /></MainLayout></Route>
                <Route path='/tools/parole'><MainLayout><Parole /></MainLayout></Route>
                <Route path='/tools/prisoner_information'><MainLayout><PrisonerInformation /></MainLayout></Route>
                <Route path='/tools/professional_license'><MainLayout><ProfessionalLicense /></MainLayout></Route>
                <Route path='/tools/statute_mapping'><MainLayout><StatuteMapping /></MainLayout></Route>
                <Route path='/tools/violent_offender'><MainLayout><ViolentOffender /></MainLayout></Route>
                <Route path='/tools/scor'><MainLayout><SCOR /></MainLayout></Route>
                <Route path='/tools/shot_spotter'><MainLayout><ShotSpotter /></MainLayout></Route>
                <Route path='/tools/stolen_vehicle'><MainLayout><StolenVehicle /></MainLayout></Route>
                <Route path='/tools/profile_report'><MainLayout><ProfileReport /></MainLayout></Route>
                <Route path='/tools/duplicate_person'><MainLayout><DuplicatePerson /></MainLayout></Route>
                <Route path='/tools/GVRTF_Referral'><MainLayout><GRAILReferral /></MainLayout></Route>
                <Route path='/poi'><MainLayout><ManagePOI/></MainLayout></Route>
                <Route path='/users'><MainLayout><ManageUser/></MainLayout></Route>
                <Route path='/departments'><MainLayout><ManageDept /></MainLayout></Route>
                <Route path='/deptUnit'><MainLayout><ManageUnitLevelGroup /></MainLayout></Route>
                <Route path='/SearchHistory'><MainLayout><AuditLog /></MainLayout></Route>
                <Route path='/ncic'><MainLayout><ManageNCIC/></MainLayout></Route>
                <Route path='/addPOI'><MainLayout><AddPOI/></MainLayout></Route>
                <Route path='/btPDF'><PersonSummaryPDF /></Route>
                <Route path='/SharedPerson'><SecuredView /></Route>
                <Route path='/Admin/User'><ModifyUser/></Route>
                <Route path='/detail/arrest'><MainLayout><ArrestDetail /></MainLayout></Route>
                <Route path='/detail/ETicket'><MainLayout><ETicketDetail /></MainLayout></Route>
                <Route path='/detail/PoliceStop'><MainLayout><PoliceStopDetail /></MainLayout></Route>
                {/*<Route path='/detail/PoliceStop_Image'><MainLayout><PoliceStopImageDetail /></MainLayout></Route>*/}
                <Route path='/detail/ALPR'><MainLayout><ALPRDetail /></MainLayout></Route>
                <Route path='/detail/CodisDNA'><MainLayout><CodisDNADetail /></MainLayout></Route>
                <Route path='/detail/CadRms'><MainLayout><CadRmsDetail /></MainLayout></Route>
                <Route path='/detail/ETrace'><MainLayout><ETraceDetail /></MainLayout></Route>
                <Route path='/detail/NJTrace'><MainLayout><NJTraceDetail /></MainLayout></Route>
                <Route path='/detail/ParkMobile'><MainLayout><ParkMobileDetail /></MainLayout></Route>
                <Route path='/detail/PrisonParole'><MainLayout><PrisonParoleDetail /></MainLayout></Route>
                <Route path='/detail/PrisonRelease'><MainLayout><PrisonReleaseDetail /></MainLayout></Route>
                <Route path='/detail/Profession'><MainLayout><ProfessionDetail /></MainLayout></Route>
                <Route path='/detail/ViolentDetail'><MainLayout><ViolentDetail /></MainLayout></Route>
                <Route path='/detail/ReferralDetail'><MainLayout><GVRTFReferralDetail /></MainLayout></Route>
                {/*<Route path='/admin/UpdateUser'><MainLayout><ModifyUser /></MainLayout></Route>*/}
                <Route path='/support/FAQ'><MainLayout><FAQs /></MainLayout></Route>
                <Route path='/support/UserManual'><MainLayout><UserManual /></MainLayout></Route>
                {/*<Route path='/support/Contact'><MainLayout><GVRTFReferralDetail /></MainLayout></Route>*/}
                <Route path='/Shooting/mo_connection'><ShootingMultipleOccurrence /></Route>
                <Route path='/Shooting/person'><ShootingPerson /></Route>
                <Route path='/dashboard'><Dashboard /></Route>
                <Route path='/link_analysis'><LinkAnalysis /></Route>
                <Route path='/GRAIL'><MainLayout><GVRTF /></MainLayout></Route>
                <Route path='/PersonAnalysis'><PersonAnalysis /></Route>
                <Route path='/'><Error404></Error404></Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
