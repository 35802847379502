import * as React from "react";
import { GoogleMap, useJsApiLoader, Marker, LoadScript, } from '@react-google-maps/api';
import Geocode from "react-geocode";
import {Google_API_Key} from "../../services/config.service";
import Pin from "../../interfaces/Pin.interface";
import {isPointInPolygon} from "../../services/dashboard.service";
import icon_gun from "../../../assets/Images/Gun.png"
import icon_gunRed from "../../../assets/Images/gun_red.png"
import icon_alpr from "../../assets/Images/FixedALPRCamera.png"
import icon_cctv from "../../../assets/Images/FixedCCTVCamera.png"
import icon_person from "../../../assets/Images/Person.png"
import icon_car from "../../assets/Images/Vehicle.png"
import icon_arrest from "../../assets/Images/ai1.png"
import icon_home from "../../../assets/Images/address.png"
import icon_Shooting from "../../assets/Images/Shooting.png"
import icon_PoliceStop from "../../assets/Images/ic1.png"
import icon_eticket from "../../assets/Images/et1.png"
import icon_cadrms from "../../assets/Images/Opencase.png"
import icon_etrace from "../../assets/Images/WeaponTrace.png"
import icon_parkmobile from "../../assets/Images/pm1.png"
import icon_alprIndicator from "../../assets/Images/alpr1.png"

interface DetailMapProps {
    data: any,
    detail:any,
}

const DetailMap: React.FC<DetailMapProps> = ({data,detail}) => {
    const [open, setOpen] = React.useState<boolean>(true)
    const [mapKey, setMapKey] = React.useState<number>(0)
    const [map, setMap] = React.useState(null)
    const [center, setCenter] = React.useState<any>({lat: 40.0352, lng: -74.5844})
    Geocode.setApiKey(Google_API_Key);
    const [zoom, setZoom] = React.useState<number>(17)

    const NJBoundry = [
        [41.362453762936575, -74.6918236783217],
        [40.990338834229426, -73.91728763616442],
        [40.6952910099279, -74.01067141429685],
        [40.486723153439925, -74.26885009501595],
        [40.49090088462017, -74.01067141429685],
        [40.06763774018179, -73.8568628385493],
        [39.48504098031486, -74.21391846082041],
        [38.89324697993387, -74.93901603220169],
        [39.47232109991295, -75.55425033519187],
        [39.62903700481567, -75.55974349861143],
        [39.852904834638665, -75.40593492286388],
        [40.23558358525728, -74.79619378329326],
        [40.557709209883015, -75.20818103975989],
        [40.98204561057492, -75.15324940556434]
    ]

    const handleCenter = (): any => {
        return { lat: (data?.lat ? data?.lat : 40.0352), lng:(data?.lng ? data?.lng : -74.5844)}
    }

    const getPinIcon = (pin: any): string => {
        if (pin.detail && pin.detail.length > 0) {
            switch (pin.detail) {
                case ("arrest"):
                    return icon_arrest
                case ("Shooting"):
                    return icon_Shooting
                case ("PoliceStop"):
                    return icon_PoliceStop
                case ("eticket"):
                    return icon_eticket
                case ("cadrms"):
                case ("case"):
                    return icon_cadrms
                case ("etrace"):
                    return icon_etrace
                case ("parkmobile"):
                    return icon_parkmobile
                case ("alprCamera"):
                    return icon_alpr
                case ("alpr"):
                    return icon_car
            }
        }
        return ""

    }

    const getMarkerTitle = (pin: any): string => {

        let title = ""

        if (pin.detail && pin.detail.length > 0) {
            switch (pin.detail) {
                case ('arrest'):
                    if (pin.date)
                        title += 'Arrest Date: ' + pin.date + '\n'
                    if (pin.statute)
                        title += 'Statute: ' + pin.statute + '\n'
                    if (pin.arrestedBy)
                        title += 'PD: ' + pin.arrestedBy + '\n'
                    break;
                case ('Shooting'):
                    if (pin.date)
                        title += 'Incident Date: ' + pin.date + '\n'
                    if (pin.offense)
                        title += 'Incident #: ' + pin.offense + '\n'
                    if (pin.weapon)
                        title += 'Weapon: ' + pin.weapon + '\n'
                    break;
                case ('PoliceStop'):
                    if (pin.date)
                        title += 'Interaction Date: ' + pin.date + '\n'
                    if (pin.offense)
                        title += 'Req ID: ' + pin.offense + '\n'
                    if (pin.arrestedBy)
                        title += 'PD: ' + pin.arrestedBy + '\n'
                    break;
                case ('eticket'):
                    if (pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if (pin.dept)
                        title += 'Source: ' + pin.dept + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case ('cadrms'):
                    if (pin.date)
                        title += 'Incident Date: ' + pin.date + '\n'
                    if (pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    if (pin.role)
                        title += 'Role: ' + pin.role + '\n'
                    break;
                case ('etrace'):
                    if (pin.date)
                        title += 'Recovery Date: ' + pin.date + '\n'
                    if (pin.offense)
                        title += 'ATF #: ' + pin.offense + '\n'
                    if (pin.weapon)
                        title += 'Weapon: ' + pin.weapon + '\n'
                    break;
                case ('case'):
                    if (pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if (pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    break;
                case ('openCase'):
                    if (pin.date)
                        title += 'Date: ' + pin.date + '\n'
                    if (pin.offense)
                        title += 'Case #: ' + pin.offense + '\n'
                    if (pin.inc_type)
                        title += 'Incident Type: ' + pin.inc_type + '\n'
                    break;
                case ('alprCamera'):
                    if (pin.name)
                        title += 'Name: ' + pin.name + '\n'
                    if (pin.status)
                        title += 'Status: ' + pin.status + '\n'
                    if (pin.alprSource)
                        title += 'Source: ' + pin.alprSource + '\n'
                    break;
                case ('alpr'):
                    if (pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if (pin.name)
                        title += 'Camera: ' + pin.name + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
                case ('parkmobile'):
                    if (pin.date)
                        title += 'Time Stamp: ' + pin.date + '\n'
                    if (pin.city)
                        title += 'Zone: ' + pin.city + '\n'
                    if (pin.plate)
                        title += 'Plate: ' + pin.plate + '\n'
                    break;
            }
        }

        return title
    }
    
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key,
        libraries: ["visualization"],
    })

    const containerStyle = {
        width: (detail === 'arrest' || detail === 'alpr' || detail === 'parkmobile' ? (window.innerWidth * 0.45) : (window.innerWidth * 0.722)) + 'px',
        height: '50vh',
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    
    return (
        <div>
            {(isLoaded) ? (
                <>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={handleCenter()}
                        zoom={zoom}
                        onUnmount={onUnmount}
                        key={mapKey}
                    >
                        { /* Child components, such as markers, info windows, etc. */
                            <Marker
                                position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
                                icon={getPinIcon(data)}
                                title={getMarkerTitle(data)}
                        />}
                    </GoogleMap>
                </>
            ) : <></>}
        </div>
    );
}
export default DetailMap