import React, { useState } from "react";
//import ArrestSearch from "../../components/tools/codis/_codisSearch";
import SearchResults from "../../components/tools/shooting/_searchResultsShooting";
import ShootingSearch from "../../components/tools/shooting/_shootingSearch";
import { ShootingInfo } from "../../interfaces/ShootingInfo.interface";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";

function ShootingNotification() {
    let [shootings, setShootings] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "6",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": params.fromDate.toLocaleString(),
            "ToDate": params.toDate.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": false,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "PoliceStopDept":  [],
            "Indicators": [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setShootings([]) 
        setShootings(qRes.AnyTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    return (
        <div id="ToolsShooting">
            {isArrestShow()?
                <>
                    <ShootingSearch SubmitSearch={SubmitSearch} /> 
                    {!noData ? <SearchResults ShootingInfo={shootings} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default ShootingNotification;
