import React, { useState } from "react";
import {PersonInfo } from "../../interfaces/getDarInfo_interface";
import {RunPOIList} from "../../services/account.service";

import SearchResults from "../../components/Admin/_searchResultsPOIs";
import POIs from "../../components/Admin/POIs";
import {isArrestShow, isPOIShow} from "../../services/auth.service";

function classNames(...classes:string[]) {
    return classes.filter(Boolean).join(' ')
}

function ManagePOI() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])

    const SubmitSearch = async (params: any) => {
        let search_query: any = {
            "PlateNumber": params.Plate ? params.Plate :"",
            "PlateSt":"",
            "FName": params.FirstName ? params.FirstName : "",
            "MName": params.MI ? params.MI :"",
            "LName": params.LastName ? params.LastName : "",
            "DOB":"",
            "SEX":"",
            "City":"",
            "DLNo": params.DL ? params.DL :"",
            "DLSt":"",
            "VIN": params.Reason ? params.Reason :"",
            "FBI": params.FBI ? params.FBI :"",
            "SSN":"",
            "DTOFARREST":"",
            "STATUTE":"",
            "STATUTEDESCRIPTION":"",
            "PoliceStopIPAddress":"",
            "PoliceStopReqID":"",
            "PoliceStopDeviceORI":"",
            "PoliceStopDeptName":"",
            "DeptAccountType":"",
            "DeptGroup":""
        }
        let qRes: any = await RunPOIList(search_query)
        setDarTbl([])
        setDarTbl(qRes?.AnyData)
    }

    return (
        <div className="w-full">
            {isPOIShow()?
                <>
                    <POIs SubmitSearch={SubmitSearch}/>
                    <div className = "ml-10 mt-3">
                        <SearchResults POIs = {darTbl}/>
                    </div>
                </>
            :<></>}
        </div>
    );
}

export default ManagePOI;