import {
    Button,
    ButtonGroup, Card,
    CardContent, Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { RunGetDept, RunGetDeptUnit, RunGetUser, RunMangers, RunSetDept, RunSetDeptUnit, RunSetUser } from "../../services/account.service";
import ErrorIcon from "@mui/icons-material/Error";
import { formatDate } from "../../services/formatDate.service";
import UnitLevelAttributes from "../../interfaces/UnitLevel.interface";


interface UpdateUnitLevelProps {
    ID: string | null
    CloseBox: any
}


const UpdateUnitLevel: React.FC<UpdateUnitLevelProps> = ({ ID, CloseBox }) => {
    const [params, setParams] = React.useState<UnitLevelAttributes>({})

    const [deptNameError, setDeptNameError] = React.useState<string>("")
    const [deptUnitError, setDeptUnitError] = React.useState<string>("")
    const [notesError, setNotesError] = React.useState<string>("")
    const [submitError, setSubmitError] = React.useState<string>("")

    const LoadDeptData = async (ID: string) => {
        let query = {
            "uniqueID": ID,
        }
        let r = await RunGetDeptUnit(query)
        await setParams(r.AnyData)
    }

    useEffect(() => {
        if (ID !== null)
            LoadDeptData(ID)
    }, [ID]);

    const submitDept = async () => {
        setSubmitError("")
        if (checkValidation()) {
            params.Status =  "Active"
            let r = await RunSetDeptUnit(params)
            if (r?.AnyData?.IsValid && r?.AnyData?.ErrorMessage?.length <= 0) {
                CloseBox()
            }
            else {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
            }
        }
    }

    const checkValidation = () => {
        setDeptNameError("")
        setNotesError("")

        var response = true
        if (params.DeptCode === null || (params.DeptCode && params.DeptCode.length <= 0)) {
            setDeptNameError("The Dept Name field is required.")
            response = false
        }
        if (params.DeptUnitName === null || (params.DeptUnitName && params.DeptUnitName.length <= 0)) {
            setDeptUnitError("The Dept Unit Name field is required.")
            response = false
        }
        if (params.Notes === null || (params.Notes && params.Notes.length <= 0)) {
            setNotesError("The Notes Field is required.")
            response = false
        }
        return response
    }

    return (
        <div className="mx-auto">
            {(params && params?.DeptList && params?.DeptList.length > 0 ?
                <Card sx={{ maxWidth: 900, minWidth: 900 }} className="mx-auto" >
                    <CardContent>
                        <span className="text-blue-400 font-bold text-xl " > 
                            {params?.UniqueID === 0 ? <span>Add New Unit Level</span> : <span>Edit Unit Level Details</span>}
                        </span>
                        <hr />
                        <div className="grid grid-cols-2 mt-5 gap-y-5">
                            <div className="w-full pr-5">
                                <FormControl fullWidth >
                                    <InputLabel id="DeptLabel" focused={deptNameError.length > 0 ? true : undefined} color={deptNameError.length > 0 ? "error" : "info"} >{deptNameError.length > 0 ? deptNameError : "Department"}</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="DeptLabel"
                                        label={deptNameError.length > 0 ? deptNameError : "Department"}
                                        value={params?.DeptCode}
                                        onChange={e => setParams({ ...params, DeptCode: e.target.value })}
                                        color={deptNameError.length > 0 ? "error" : "primary"}
                                        inputProps={{ 'shrink': true }}
                                    >
                                        {params.DeptList && params?.DeptList.map((group:any) => (
                                            <MenuItem value={group.Key}>{group.Value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="w-full pl-5">
                                <TextField
                                    size="small"
                                    InputLabelProps={{ 'shrink': true }}
                                    className="w-full"
                                    label={deptUnitError.length > 0 ? deptUnitError : "Dept Unit Name"}
                                    value={params?.DeptUnitName}
                                    onChange={e => setParams({ ...params, DeptUnitName: e.target.value })}
                                    color={deptUnitError.length > 0 ? "error" : "primary"}
                                    focused={deptUnitError.length > 0 ? true : undefined}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 mt-5 gap-y-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    multiline minRows={2}
                                    InputLabelProps={{ 'shrink': true }}
                                    margin='none'
                                    className="w-full"
                                    label={notesError.length > 0 ? notesError : "Notes"}
                                    value={params?.Notes}
                                    onChange={e => setParams({ ...params, Notes: e.target.value })}
                                    color={notesError.length > 0 ? "error" : "primary"}
                                    focused={notesError.length > 0 ? true : undefined}
                                />
                            </div>
                            {params?.UniqueID !== 0 ?
                                <div className="w-full">
                                    <TextField
                                        size="small"
                                        disabled
                                        multiline
                                        minRows={4}
                                        InputLabelProps={{ 'shrink': true }}
                                        margin='none'
                                        className="w-full"
                                        label="Previous Notes"
                                        value={params?.PreviousNotes}
                                        onChange={e => setParams({ ...params, PreviousNotes: e.target.value })}
                                    />
                                </div>
                                : <></>}
                        </div>
                        <div className="grid grid-cols-2 mt-5 gap-y-5">
                            <div className="w-full pr-5">
                                <TextField
                                    size="small"
                                    InputLabelProps={{ 'shrink': true }}
                                    disabled
                                    margin='none'
                                    className="w-full"
                                    variant="filled"
                                    label="Created User"
                                    value={params?.CreateUID}
                                    onChange={e => setParams({ ...params, CreateUID: e.target.value })}
                                />
                            </div>
                            <div className="w-full pl-5">
                                <TextField
                                    size="small"
                                    InputLabelProps={{ 'shrink': true }}
                                    disabled
                                    margin='none'
                                    className="w-full"
                                    variant="filled"
                                    label="Created Date"
                                    value={formatDate(params?.CreateDate)}
                                    onChange={e => setParams({ ...params, CreateDate: e.target.value })}
                                />
                            </div>
                        </div>
                        {params?.UniqueID !== 0 ?
                            <div className="grid grid-cols-2 mt-5 gap-y-5">
                                <div className="w-full pr-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        disabled
                                        variant="filled"
                                        label={"Updated User"}
                                        value={params?.UpdUID}

                                    />
                                </div>
                                <div className="w-full pl-5">
                                    <TextField
                                        size="small"
                                        InputLabelProps={{ 'shrink': true }}
                                        className="w-full"
                                        disabled
                                        variant="filled"
                                        label={"Updated Date"}
                                        value={params?.UpdDate}
                                    />
                                </div>
                            </div>
                            :
                            <span></span>}
                        {deptNameError?.length > 0 ?
                            <span style={{ color: "red", fontSize: 14 }}>
                                <div> <ErrorIcon style={{ color: "red" }} fontSize='small' />{deptNameError}</div>
                            </span>
                            : <></>}
                        {submitError?.length > 0 ?
                            <span style={{ color: "red", fontSize: 14 }}>
                                {submitError.split(",").map((error) => <div> <ErrorIcon style={{ color: "red" }} fontSize='small' />{error}</div>)}
                            </span>
                            : <></>}
                        <div className="mt-8 flex justify-center">
                            <ButtonGroup variant="outlined" size={"large"} color={"inherit"} >
                                <Button onClick={submitDept} >Save</Button>
                                <Button onClick={CloseBox}>CANCEL</Button>
                            </ButtonGroup>
                        </div>
                    </CardContent>
                </Card>
                : <div className="text-blue-400 text-6xl font-bold"></div>)}
        </div>
    );
}

export default UpdateUnitLevel;
