import React, { useState } from "react";
import {PersonInfo} from "../../interfaces/getDarInfo_interface";
import { RunUserList, RunDepartmentList }  from "../../services/account.service";
import Users from "../../components/Admin/Users";
import DepartmentsList from "../../components/Admin/Departments";
import SearchResults from "../../components/Admin/_searchResultsUsers";

function classNames(...classes:string[]) {
    return classes.filter(Boolean).join(' ')
}

function ManageUser() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [dptTbl, setDptTbl] = useState<any[]>([])
    let [storedParams, setStoredParams] = useState<any>({})

    const SubmitSearch = async (params: any) => {
        if(!params)
        {
            params = storedParams
        }
        else
        {
            setStoredParams(params)
        }
        
        let search_query: any = {
            "PlateNumber":"",
            "PlateSt":"",
            "FName": params.FirstName ? params.FirstName : "",
            "MName": params.MI ? params.MI :"",
            "LName": params.LastName ? params.LastName : "",
            "DOB":"",
            "SEX":"",
            "City":"",
            "DLNo": params.authLevel ? params.authLevel :"",
            "DLSt": params.status ? params.status :"",
            "VIN": params.Email ? params.Email :"",
            "FBI":"",
            "SSN":"",
            "DTOFARREST":"",
            "STATUTE":"",
            "STATUTEDESCRIPTION":"",
            "PoliceStopIPAddress":"",
            "PoliceStopReqID": params.UserID ? params.UserID :"",
            "PoliceStopDeviceORI": params.DeptName ? params.DeptName :"",
            "DeptAccountType": params.AccountType ? params.AccountType :"",
            "DeptGroup": params.DeptGroup ? params.DeptGroup :""
        }
        let qRes: any = await RunUserList(search_query)
        setDarTbl([])
        setDarTbl(qRes?.AnyData)
        
    }

    return (
        <div className="w-full">
            <Users SubmitSearch={SubmitSearch}/>
            <div className = "pl-10 mt-3">
                <SearchResults Persons = {darTbl} GetUserList = {SubmitSearch}/>
            </div>
        </div>
    );
}

export default ManageUser;