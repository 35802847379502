import React, {useState} from "react";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";
import DashboardLayout from "../../components/Dashboard/_dashboardLayout"
import {RunGetDailyStats} from "../../services/dashboard.service";
import {getDarInfoResponse} from "../../interfaces/getDarInfo_interface";
import {RunDarQuery} from "../../services/getDar.service";

function Dashboard() {
    let [user, setUser] = useState<Account>(getUser())
    const [data, setData] = useState<any>({})

    const GetStats = async ( params:any) => {
        //console.log(params)
        let qRes:getDarInfoResponse = await RunGetDailyStats(params)
        //console.log(qRes)
        setData(qRes)
       //console.log('Data Recieved Call This On A UseEffect Timer')
    }
    
    
    return (
        <div>
            {isArrestShow()?
                <>
                    <DashboardLayout GetStats={GetStats} data={data}/>
                </>
            :<></>}
        </div>
    );
}

export default Dashboard;