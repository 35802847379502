import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import axios, {AxiosResponse} from "axios";
import PersonPhotoIcon from "../assets/Images/photo1.png";
import ArrestIcon from "../assets/Images/ai1.png";
import PoliceStopIcon from "../assets/Images/ic1.png";
import ETicketIcon from "../assets/Images/et1.png";
import ParkMobileIcon from "../assets/Images/pm1.png";
import ALPRIcon from "../assets/Images/alpr1.png";
import CADRMSIcon from "../assets/Images/Opencase1.png";
import CODISIcon from "../assets/Images/DNA1.png";
import TowingIcon from "../assets/Images/towing1.png";
import PrisonReleaseIcon from "../assets/Images/jail1.png";
import GunTraceIcon from "../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../assets/Images/ShootingGreen.png";
import NibinIcon from "../assets/Images/nibin1.png";
import NJGunTraceIcon from "../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../assets/Images/parole1.png";
import ViolentIcon from "../assets/Images/Violent1.png";
import HumanTrafficIcon from "../assets/Images/HumanTraffic1.png";
import ProfessionIcon from "../assets/Images/profession1.png";
import PlateIcon from "../assets/Images/Vehicle.png";

import {JAOS} from "./JAOS.service";
import {formatDT} from "./formatDate.service";
import {SearchDL} from "../interfaces/searchDL.interface";
import {useState} from "react";
import {Account} from "../interfaces/auth_interface";
import {getUser} from "./auth.service";
import {
    RunDarQuery,
    RunPerson_ALPRQuery,
    RunPerson_ArrestQuery,
    RunPerson_CADRMSQuery,
    RunPerson_CodisDNAQuery,
    RunPerson_CrumbScore, RunPerson_DLQuery,
    RunPerson_ETicketQuery,
    RunPerson_ETraceQuery,
    RunPerson_ImageQuery,
    RunPerson_PoliceStopQuery,
    RunPerson_NIBINQuery,
    RunPerson_ShootingQuery,
    RunPerson_NJTraceQuery,
    RunPerson_ParkMobileQuery,
    RunPerson_ParoleQuery, RunPerson_Plate,
    RunPerson_PrisonQuery, RunPerson_ProfessionQuery, RunPerson_ViolentQuery
} from "./getDar.service";
import {GetDarInfo_Query, getDarInfoResponse} from "../interfaces/getDarInfo_interface";

export async function GetLinkAnalysisData(ID: string | null, APIKEY:string | null, AUTHOTP:string | null): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/LinkAnalysis/GetNibinJSON?ReportID=' + ID + '&APIKEY='+APIKEY + '&AUTHOTP='+AUTHOTP )
    return (res.data)
}

export async function GetChainNibinJSON(QType: string | null, ChainID:string | null): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/LinkAnalysis/GetChainNibinJSON?QType=' + QType + '&ChainID='+ChainID )
    return (res.data)
}

export async function GetPersonImage(params: any, APIKEY: string | null, AUTHOTP: string | null): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/LinkAnalysis/GetPersonImages' + '?APIKEY=' + APIKEY + '&AUTHOTP=' + AUTHOTP, params)
    return (res.data)
}

export async function GetAssociatedPeopleRun(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Person/Person_ALL_Association', params)
    return (res.data)
}

export async function GetPADetailsRun(QType:string, params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/LinkAnalysis/PADetails?QType='+QType, params)
    return (res.data)
}
export async function GetPersonAnalysis_PlateRegisteredOwner(plates : string[]){
    let user : Account = getUser()
    let search_query: GetDarInfo_Query = {
        "QueryType": "R",
        "Dept": user.Dept,
        "EmpID": user.EmpID,
        "DeptORI": user.DeptORI,
        "DateRange": true,
        "DateTypeValue": 1,
        "FromDate": new Date().toISOString().substring(0,10),
        "ToDate": new Date().toISOString().substring(0,10),
        "SearchType": "AND",
        "IndicatorOn": true,
        "TopArrestNumber": 0,
        "FNames": [],
        "MNames":  [],
        "LNames":  [],
        "DOBs": [],
        "SEX": [],
        "Address": "",
        "Cities": [],
        "States": [],
        "Zip": "",
        "Statutes": [],
        "Gang": [],
        "FBIs":  [],
        "SBIs": [],
        "DLNos": [],
        "PlateNos":  [...plates],
        "VINs": [],
        "AgencyArrested": [],
        "PoliceStopDept":  [],
        "Indicators": [],
        "Latitude": null,
        "Longitude": null,
        "Radius": 0.25,
        "ResidentCounty": [],
        "ArrestedCounty": [],
        "Arrest_Type":  [],
        "CaseNumbers": [],
        "FTSIDs": [],
    }
    return await RunDarQuery(search_query)
}
export async function GetPersonAnalysis_GetPersonInfo(person: any) : Promise<any> {
    let user: Account = getUser()
    let today: string = new Date().toISOString().slice(0, 10);
    let query: SearchDL = {
        City: person?.City,
        DLNo: person?.DLNo,
        DLSt: person?.DLSt,
        DOB: person?.DOB ? (person?.DOB)?.toLocaleString() : "",
        DarID: person?.DarID ? person?.DarID.toString() : null,
        Dept: user.Dept,
        DeptORI: user.DeptORI,
        ETicket: null,
        EmpID: user.EmpID,
        FBI: person?.FBI,
        FirstName: person?.FName,
        FromDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today ,
        ToDate: person?.PercentileDate ? person.PercentileDate.toLocaleString() : today,
        GANGRELATED: person?.GANGRELATED,
        LastName: person?.LName,
        LoggedDept: null,
        LoggedEmpID: null,
        MiddleName: person?.MName,
        PersonID: person?.PersonID,
        QueryType: null,
        CrumbScorePercentile: person?.CrumbScorePercentile,
        SBINo: person?.SBINo,
        SEX: person?.SEX
    }
    
    let ArrestInfoObj = await RunPerson_ArrestQuery(query);
    let ShootingInfoObj = await RunPerson_ShootingQuery(query);
    let DNAInfoObj = await RunPerson_CodisDNAQuery(query);
    let PoliceStopInfoObj = await RunPerson_PoliceStopQuery(query);
    let ETicketInfoObj = await RunPerson_ETicketQuery(query);
    let CADRMSInfoObj = await RunPerson_CADRMSQuery(query);
    let ParkMobileInfoObj = await RunPerson_ParkMobileQuery(query);
    let ETraceInfoObj = await RunPerson_ETraceQuery(query);
    let NJTraceInfoObj = await RunPerson_NJTraceQuery(query);
    let NIBINInfoObj = await RunPerson_NIBINQuery(query);
    let PrisonInfoObj = await RunPerson_PrisonQuery(query);
    let ParoleInfoObj = await RunPerson_ParoleQuery(query);
    let ViolentInfoObj = await RunPerson_ViolentQuery(query);
    let ProfessionInfoObj = await RunPerson_ProfessionQuery(query);
    let ALPRInfoObj = await RunPerson_ALPRQuery(query);
    let DLInfoObj = await RunPerson_DLQuery(query);
    let PlateInfoObj = await RunPerson_Plate(query);
    let AssocInfoObj : any= []
    
    AssocInfoObj.push(...MergeAssociatedPeople(ArrestInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ShootingInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(DNAInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(PoliceStopInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ETicketInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(CADRMSInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ParkMobileInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ETraceInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(NJTraceInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(NIBINInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(PrisonInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ParoleInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ViolentInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ProfessionInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(ALPRInfoObj))
    AssocInfoObj.push(...MergeAssociatedPeople(DLInfoObj))
    
    
    let personObj : any = {
        PersonInfo: person,
        ImageInfo: (await RunPerson_ImageQuery(query))?.AnyData,
        ArrestInfo: ArrestInfoObj?.AnyData,
        ShootingInfo: ShootingInfoObj?.AnyData,
        DNAInfo:DNAInfoObj?.AnyData,
        PoliceStopInfo: PoliceStopInfoObj?.AnyData,
        ETicketInfo: ETicketInfoObj?.AnyData,
        CADRMSInfo: CADRMSInfoObj?.AnyData,
        ParkMobileInfo: ParkMobileInfoObj?.AnyData,
        ETraceInfo: ETraceInfoObj?.AnyData,
        NJTraceInfo: NJTraceInfoObj?.AnyData,
        NIBINInfo: NIBINInfoObj?.AnyData,
        PrisonInfo: PrisonInfoObj?.AnyData,
        ParoleInfo: ParoleInfoObj?.AnyData,
        ViolentInfo: ViolentInfoObj?.AnyData,
        ProfessionInfo: ProfessionInfoObj?.AnyData,
        ALPRInfo: ALPRInfoObj?.AnyData,
        DLInfo: DLInfoObj?.AnyData,
        PlateInfo: PlateInfoObj?.AnyData,
        AssociatedPeopleInfo: AssocInfoObj,
    }
    
    return personObj;
}
export function GetPersonAnalysis(personInfoObj: any, exist:boolean = false, eid:string = ''): any {
    let returnObj : any = {items: []}
    
    let id:string = !exist ? `PERSON_NODE_ID${personInfoObj.PersonInfo.PersonID}`: eid
    if(!exist){
        returnObj.items.push({
            type: 'node',
            id: id,
            u: personInfoObj.ImageInfo && personInfoObj.ImageInfo.length>0? personInfoObj.ImageInfo[0].Image : PersonPhotoIcon,
            t:  `${personInfoObj.PersonInfo.FName? personInfoObj.PersonInfo.FName:''} ${personInfoObj.PersonInfo.MName? personInfoObj.PersonInfo.MName+' ':''}${personInfoObj.PersonInfo.LName?personInfoObj.PersonInfo.LName:''}`,
            d:{
                type:'PERSON_NODE',
                data: personInfoObj.PersonInfo
            },
            ha0: {
                c: (!exist? 'rgb(255, 0, 0)':null),
                r: 45,
                w: 5
            },
        })
    }
    
    
    if(personInfoObj.ArrestInfo && personInfoObj.ArrestInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ArrestInfo(personInfoObj.PersonInfo, personInfoObj.ArrestInfo, id))
    if(personInfoObj.ALPRInfo && personInfoObj.ALPRInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ALPRInfo(personInfoObj.PersonInfo, personInfoObj.ALPRInfo, id))
    if(personInfoObj.AssociatedPeopleInfo && personInfoObj.AssociatedPeopleInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_AssociatedPeopleInfo(personInfoObj.PersonInfo, personInfoObj.AssociatedPeopleInfo, id))
    if(personInfoObj.CADRMSInfo && personInfoObj.CADRMSInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_CADRMSInfo(personInfoObj.PersonInfo, personInfoObj.CADRMSInfo, id))
    if(personInfoObj.DNAInfo && personInfoObj.DNAInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_DNAInfo(personInfoObj.PersonInfo, personInfoObj.DNAInfo, id))
    if(personInfoObj.ETicketInfo && personInfoObj.ETicketInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ETicketInfo(personInfoObj.PersonInfo, personInfoObj.ETicketInfo, id))
    if(personInfoObj.ETraceInfo && personInfoObj.ETraceInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ETraceInfo(personInfoObj.PersonInfo, personInfoObj.ETraceInfo, id))
    if(personInfoObj.PoliceStopInfo && personInfoObj.PoliceStopInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_PoliceStopInfo(personInfoObj.PersonInfo, personInfoObj.PoliceStopInfo, id))
    if(personInfoObj.NIBINInfo && personInfoObj.NIBINInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_NIBINInfo(personInfoObj.PersonInfo, personInfoObj.NIBINInfo, id))
    if(personInfoObj.ShootingInfo && personInfoObj.ShootingInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ShootingInfo(personInfoObj.PersonInfo, personInfoObj.ShootingInfo, id))
    if(personInfoObj.NJTraceInfo && personInfoObj.NJTraceInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_NJTraceInfo(personInfoObj.PersonInfo, personInfoObj.NJTraceInfo, id))
    if(personInfoObj.ParkMobileInfo && personInfoObj.ParkMobileInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ParkMobileInfo(personInfoObj.PersonInfo, personInfoObj.ParkMobileInfo, id))
    if(personInfoObj.ParoleInfo && personInfoObj.ParoleInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ParoleInfo(personInfoObj.PersonInfo, personInfoObj.ParoleInfo, id))
    if(personInfoObj.PrisonInfo && personInfoObj.PrisonInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_PrisonInfo(personInfoObj.PersonInfo, personInfoObj.PrisonInfo, id))
    if(personInfoObj.ProfessionInfo && personInfoObj.ProfessionInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ProfessionInfo(personInfoObj.PersonInfo, personInfoObj.ProfessionInfo, id))
    if(personInfoObj.ViolentInfo && personInfoObj.ViolentInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_ViolentInfo(personInfoObj.PersonInfo, personInfoObj.ViolentInfo, id))
    if(personInfoObj.PlateInfo && personInfoObj.PlateInfo.length>0)
        returnObj.items.push(...GetPersonAnalysis_PlateInfo(personInfoObj.PersonInfo, personInfoObj.PlateInfo, id))
    return returnObj;
}
function GetPersonAnalysis_ArrestInfo(Person:any, ArrestInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];
    
    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_ARREST_CONTAINER`,
        u: ArrestIcon,
        t:  `ARRESTS`,
        d:{
            type:'PERSON_ARREST_CONTAINER',
            hidden: false,
            data: ArrestInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_ARREST_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_ARREST_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ArrestInfo){
        ArrestInfo.forEach((arrest:any) => {
                returnObj.push({
                    type: 'node',
                    id: `ARREST_NODE_${arrest.ID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_ARREST_CONTAINER`,
                    u: ArrestIcon,
                    t:  `${arrest.STATUTE}`,
                    d:{
                        type:'ARREST_NODE',
                        data: arrest
                    }
                })
                returnObj.push({
                    type: 'link',
                    id: `ARREST_NODE_${arrest.ID}_TO_PERSON_NODE_ID${Person.PersonID}_ARREST_CONTAINER`,
                    id1: `ARREST_NODE_${arrest.ID}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_ARREST_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                })
            }
        )
    }
    
    return returnObj;
}
function GetPersonAnalysis_ALPRInfo(Person:any, ALPRInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_ALPR_CONTAINER`,
        u: ALPRIcon,
        t:  `ALPR`,
        d:{
            type:'PERSON_ALPR_CONTAINER',
            hidden: false,
            data: ALPRInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_ALPR_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_ALPR_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ALPRInfo){
        ALPRInfo.forEach((alpr:any) => {
                returnObj.push({
                    type: 'node',
                    id: `ALPR_NODE_${alpr.LprID}`,
                    //parentId:`PERSON_NODE_ID${Person.PersonID}_ALPR_CONTAINER`,
                    u: ALPRIcon,
                    t:  `${alpr.red_vrm} \n ${formatDT(alpr.red_TimeStamp)} \n ${alpr.src_Description}`,
                    d:{
                        type:'ALPR_NODE',
                        data: alpr
                    }
                })
                returnObj.push({
                    type: 'link',
                    id: `ALPR_NODE_${alpr.LprID}_TO_PERSON_NODE_ID${Person.PersonID}_ALPR_CONTAINER`,
                    id1: `ALPR_NODE_${alpr.LprID}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_ALPR_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                })
            }
        )
    }
    return returnObj;
}
function GetPersonAnalysis_AssociatedPeopleInfo(Person:any, AssociatedPeopleInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_ASSOCPEOPLE_CONTAINER`,
        u: PersonPhotoIcon,
        t:  `Associated People (${Person.FName? Person.FName:''} ${Person.MName?Person.MName+' ':''}${Person.LName?Person.LName:''})`,
        d:{
            type:'PERSON_ASSOCPEOPLE_CONTAINER',
            hidden: false,
            data: AssociatedPeopleInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_ASSOCPEOPLE_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_ASSOCPEOPLE_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(AssociatedPeopleInfo){
        AssociatedPeopleInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `ASSOCPEOPLE_NODE_${node.PersonID}`,
                    u: PersonPhotoIcon,
                    t:  `${node.FName? node.FName:''} ${node.MName ? node.MName+' ':''}${node.LName? node.LName:''} \n ${node.AType? node.AType:''}`,
                    d:{
                        type:'ASSOCPEOPLE_NODE',
                        imageSet: false,
                        get_all_nodes_loaded:false,
                        data: node
                    }
                })

                returnObj.push({
                    type: 'link',
                    id: `PERSON_NODE_ID${Person.PersonID}_ASSOCPEOPLE_CONTAINER_TO_ASSOCPEOPLE_NODE_${node.PersonID}_CONTAINER`,
                    id1: `ASSOCPEOPLE_NODE_${node.PersonID}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_ASSOCPEOPLE_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                 })
            }
        )
    }

    return returnObj;
}
function GetPersonAnalysis_CADRMSInfo(Person:any, CADRMSInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_CADRMS_CONTAINER`,
        u: CADRMSIcon,
        t:  `CADRMS`,
        d:{
            type:'PERSON_CADRMS_CONTAINER',
            hidden: false,
            data: CADRMSInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_CADRMS_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_CADRMS_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(CADRMSInfo){
        CADRMSInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `CADRMS_NODE_${node.AgencyCaseNo}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_CADRMS_CONTAINER`,
                    u: CADRMSIcon,
                    t:  `${formatDT(formatDT(node.DateTimeIn))} \n ${node.CaseStatus} \n ${node.STATUTE}`,
                    d:{
                        type:'CADRMS_NODE',
                        data: node
                    }
                })
            returnObj.push({
                type: 'link',
                id: `CADRMS_NODE_${node.AgencyCaseNo}_TO_PERSON_NODE_ID${Person.PersonID}_CADRMS_CONTAINER`,
                id1: `CADRMS_NODE_${node.AgencyCaseNo}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_CADRMS_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }
    return returnObj;
}
function GetPersonAnalysis_DNAInfo(Person:any, DNAInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_DNA_CONTAINER`,
        u: CODISIcon,
        t:  `DNA`,
        d:{
            type:'PERSON_DNA_CONTAINER',
            hidden: false,
            data: DNAInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_DNA_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_DNA_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(DNAInfo){
        DNAInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `DNA_NODE_${node.ID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_DNA_CONTAINER`,
                    u: CODISIcon,
                    t:  `${formatDT(node.OffenseDate)}\n${node.DNANo}\n${node.Profile}`,
                    d:{
                        type:'DNA_NODE',
                        data: node
                    }
                })

            returnObj.push({
                type: 'link',
                id: `DNA_NODE_${node.ID}_TO_PERSON_NODE_ID${Person.PersonID}_DNA_CONTAINER`,
                id1: `DNA_NODE_${node.ID}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_DNA_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }
    return returnObj;
}
function GetPersonAnalysis_ETicketInfo(Person:any, ETicketInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_ETICKET_CONTAINER`,
        u: ETicketIcon,
        t:  `ETICKET`,
        d:{
            type:'PERSON_ETICKET_CONTAINER',
            hidden: false,
            data: ETicketInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_ETICKET_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_ETICKET_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ETicketInfo){
        ETicketInfo.forEach((node:any) => {
            returnObj.push({
                    type: 'node',
                    id: `ETICKET_NODE_${node.ID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_ETICKET_CONTAINER`,
                    u: ETicketIcon,
                    t:  `${formatDT(node.eCreated)}\n${node.ePlateNbr}\n${node.eOffenseStat}`,
                    d:{
                        type:'ETICKET_NODE',
                        data: node
                    }
                })

            returnObj.push({
                type: 'link',
                id: `ETICKET_NODE_${node.ID}_TO_PERSON_NODE_ID${Person.PersonID}_ETICKET_CONTAINER`,
                id1: `ETICKET_NODE_${node.ID}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_ETICKET_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }

    return returnObj;
}
function GetPersonAnalysis_ETraceInfo(Person:any, ETraceInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_ETRACE_CONTAINER`,
        u: GunTraceIcon,
        t:  `ETRACE`,
        d:{
            type:'PERSON_ETRACE_CONTAINER',
            hidden: false,
            data: ETraceInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_ETRACE_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_ETRACE_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ETraceInfo){
        ETraceInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `ETRACE_NODE_${node.ATFNo}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_ETRACE_CONTAINER`,
                    u: GunTraceIcon ,
                    t:  `${node.CALIBER} ${node.MODEL} \n ${node.MANUFACTURER_NAME}`,
                    d:{
                        type:'ETRACE_NODE',
                        data: node
                    }
                })
            returnObj.push({
                type: 'link',
                id: `ETRACE_NODE_${node.ATFNo}_TO_PERSON_NODE_ID${Person.PersonID}_ETRACE_CONTAINER`,
                id1: `ETRACE_NODE_${node.ATFNo}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_ETRACE_CONTAINER`,
                t: `${ node.RoleDesc }`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
            
        })
    }
    return returnObj;
}
function GetPersonAnalysis_NIBINInfo(Person:any, NIBINInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_NIBIN_CONTAINER`,
        u: NibinIcon,
        t:  `NIBIN`,
        d:{
            type:'PERSON_NIBIN_CONTAINER',
            hidden: false,
            data: NIBINInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_NIBIN_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_NIBIN_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(NIBINInfo){
        NIBINInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `NIBIN_NODE_${node.AgencyCaseNo}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_NIBIN_CONTAINER`,
                    u: NibinIcon,
                    t:  `${node.HitNumber} \n ${node.Offense} \n ${formatDT(node.OffenseDate)} \n ${node.Profile}`,
                    d:{
                        type:'NIBIN_NODE',
                        data: node
                    }
                })

            returnObj.push({
                type: 'link',
                id: `NIBIN_NODE_${node.AgencyCaseNo}_TO_PERSON_NODE_ID${Person.PersonID}_NIBIN_CONTAINER`,
                id1: `NIBIN_NODE_${node.AgencyCaseNo}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_NIBIN_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }

    return returnObj;
}
function GetPersonAnalysis_ShootingInfo(Person:any, ShootingInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_Shooting_CONTAINER`,
        u: ShootingIcon,
        t:  `Shooting`,
        d:{
            type:'PERSON_Shooting_CONTAINER',
            hidden: false,
            data: ShootingInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_Shooting_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_Shooting_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ShootingInfo){
        ShootingInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `Shooting_NODE_${node.AgencyCaseNo}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_Shooting_CONTAINER`,
                    u: ShootingIcon,
                    t:  `${node.IncidentType} \n ${node.RoleDesc} \n ${formatDT(node.DateTimeIn)}`,
                    d:{
                        type:'Shooting_NODE',
                        data: node
                    }
                })

            returnObj.push({
                type: 'link',
                id: `Shooting_NODE_${node.AgencyCaseNo}_TO_PERSON_NODE_ID${Person.PersonID}_Shooting_CONTAINER`,
                id1: `Shooting_NODE_${node.AgencyCaseNo}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_Shooting_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }

    return returnObj;
}
function GetPersonAnalysis_NJTraceInfo(Person:any, NJTraceInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_NJTRACE_CONTAINER`,
        u: NJGunTraceIcon,
        t:  `NJTRACE`,
        d:{
            type:'PERSON_NJTRACE_CONTAINER',
            hidden: false,
            data: NJTraceInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_NJTRACE_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_NJTRACE_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(NJTraceInfo){
        NJTraceInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `NJTRACE_NODE_${node.ATFNo}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_NJTRACE_CONTAINER`,
                    u: NJGunTraceIcon ,
                    t:  `${node.CALIBER} ${node.MODEL} \n ${node.MANUFACTURER_NAME}`,
                    d:{
                        type:'NJTRACE_NODE',
                        data: node
                    }
                })
            returnObj.push({
                type: 'link',
                id: `NJTRACE_NODE_${node.ATFNo}_TO_PERSON_NODE_ID${Person.PersonID}_NJTRACE_CONTAINER`,
                id1: `NJTRACE_NODE_${node.ATFNo}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_NJTRACE_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
                
        })
    }
    return returnObj;
}
function GetPersonAnalysis_ParkMobileInfo(Person:any, ParkMobileInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_PARKMOBILE_CONTAINER`,
        u: ParkMobileIcon,
        t:  `PARK MOBILE`,
        d:{
            type:'PERSON_PARKMOBILE_CONTAINER',
            hidden: false,
            data: ParkMobileInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_PARKMOBILE_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_PARKMOBILE_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ParkMobileInfo){
        ParkMobileInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `PARKMOBILE_NODE_${node.mobileparkid}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_PARKMOBILE_CONTAINER`,
                    u: ParkMobileIcon ,
                    t:  `${node.plate} \n ${node.zoneid} \n ${formatDT(node.startdatetime)}`,
                    d:{
                        type:'PARKMOBILE_NODE',
                        data: node
                    }
                })
            returnObj.push({
                type: 'link',
                id: `PARKMOBILE_NODE_${node.mobileparkid}_TO_PERSON_NODE_ID${Person.PersonID}_PARKMOBILE_CONTAINER`,
                id1: `PARKMOBILE_NODE_${node.mobileparkid}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_PARKMOBILE_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
            
        })
    }
    return returnObj;
}
function GetPersonAnalysis_ParoleInfo(Person:any, ParoleInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_PAROLE_CONTAINER`,
        u: PrisonParoleActiveIcon,
        t:  `PAROLE`,
        d:{
            type:'PERSON_PAROLE_CONTAINER',
            hidden: false,
            data: ParoleInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_PAROLE_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_PAROLE_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ParoleInfo){
        ParoleInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `PAROLE_NODE_${node.ID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_PAROLE_CONTAINER`,
                    u: ( node.End_Date && new Date(node.End_Date) < new Date() ) ? PrisonParoleInactiveIcon: PrisonParoleActiveIcon ,
                    t:  `${node.STATUTE} ${node.STATUTEDESCRIPTION} \n `,
                    d:{
                        type:'PAROLE_NODE',
                        data: node
                    }
                })

            returnObj.push({
                type: 'link',
                id: `PAROLE_NODE_${node.ID}_TO_PERSON_NODE_ID${Person.PersonID}_PAROLE_CONTAINER`,
                id1: `PAROLE_NODE_${node.ID}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_PAROLE_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }

    return returnObj;
}
function GetPersonAnalysis_PrisonInfo(Person:any, PrisonInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_PRISON_CONTAINER`,
        u: PrisonReleaseIcon,
        t:  `PRISON`,
        d:{
            type:'PERSON_PRISON_CONTAINER',
            hidden: false,
            data: PrisonInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_PRISON_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_PRISON_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(PrisonInfo){
        PrisonInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `PRISON_NODE_${node.ID}_${Person.PersonID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_PRISON_CONTAINER`,
                    u: PrisonReleaseIcon,
                    t:  `${node.STATUTE} ${node.STATUTEDESCRIPTION}\n ${node.STATUS} \n `,
                    d:{
                        type:'PRISON_NODE',
                        data: node
                    }
                })
            returnObj.push({
                type: 'link',
                id: `PRISON_NODE_${node.ID}_TO_PERSON_NODE_ID${Person.PersonID}_PRISON_CONTAINER`,
                id1: `PRISON_NODE_${node.ID}_${Person.PersonID}`,
                id2: `PERSON_NODE_ID${Person.PersonID}_PRISON_CONTAINER`,
                a1: true, w: 2, c: '#566573', t1: '', fb:true
            })
        })
    }
    return returnObj;
}
function GetPersonAnalysis_ProfessionInfo(Person:any, ProfessionInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_PROFESSION_CONTAINER`,
        u: ProfessionIcon,
        t:  `PROFESSION`,
        d:{
            type:'PERSON_PROFESSION_CONTAINER',
            hidden: false,
            data: ProfessionInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_PROFESSION_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_PROFESSION_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ProfessionInfo){
        ProfessionInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `PROFESSION_NODE_${node.ID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_PROFESSION_CONTAINER`,
                    u: ProfessionIcon,
                    t:  `${node.LicenseName} \n ${node.Status}`,
                    d:{
                        type:'PROFESSION_NODE',
                        data: node
                    }
                })

                returnObj.push({
                    type: 'link',
                    id: `PROFESSION_NODE_${node.ID}_TO_PERSON_NODE_ID${Person.PersonID}_PROFESSION_CONTAINER`,
                    id1: `PROFESSION_NODE_${node.ID}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_PROFESSION_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                })
            
        })
    }
    return returnObj;
}
function GetPersonAnalysis_ViolentInfo(Person:any, ViolentInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_VIOLENT_CONTAINER`,
        u: ViolentIcon,
        t:  `VIOLENT`,
        d:{
            type:'PERSON_VIOLENT_CONTAINER',
            hidden: false,
            data: ViolentInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_VIOLENT_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_VIOLENT_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(ViolentInfo){
        var fakeid=0;
        ViolentInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `VIOLENT_NODE_${node.PersonID}_${fakeid}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_VIOLENT_CONTAINER`,
                    u: ViolentIcon,
                    t:  `${node.Criteria}`,
                    d:{
                        type:'VIOLENT_NODE',
                        data: node
                    }
                })

                returnObj.push({
                    type: 'link',
                    id: `VIOLENT_NODE_${node.PersonID}_${fakeid}_TO_PERSON_NODE_ID${Person.PersonID}_VIOLENT_CONTAINER`,
                    id1: `VIOLENT_NODE_${node.PersonID}_${fakeid}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_VIOLENT_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                })
                fakeid++;
            }
        )
    }
    return returnObj;
}
function GetPersonAnalysis_PoliceStopInfo(Person:any, PoliceStopInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_PoliceStop_CONTAINER`,
        u: PoliceStopIcon,
        t:  `PoliceStop`,
        d:{
            type:'PERSON_PoliceStop_CONTAINER',
            hidden: false,
            data: PoliceStopInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_PoliceStop_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_PoliceStop_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(PoliceStopInfo){
     
        PoliceStopInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `PoliceStop_NODE_${node.ReqID}`,
                    //parentId: `PERSON_NODE_ID${Person.PersonID}_PoliceStop_CONTAINER`,
                    u: PoliceStopIcon,
                    t:  `${formatDT(node.DateTimeIN)}\n${node.PlateNumber}\n ${node.DLNo}`,
                    d:{
                        type:'PoliceStop_NODE',
                        data: node
                    }
                })

                returnObj.push({
                    type: 'link',
                    id: `PoliceStop_NODE_${node.ReqID}_TO_PERSON_NODE_ID${Person.PersonID}_PoliceStop_CONTAINER`,
                    id1: `PoliceStop_NODE_${node.ReqID}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_PoliceStop_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                })
            }
        )
    }

    return returnObj;
}
function GetPersonAnalysis_PlateInfo(Person:any, PlateInfo : any, parent_ID:any): any{
    let returnObj : any[] = [];

    returnObj.push({
        type: 'node',
        id: `PERSON_NODE_ID${Person.PersonID}_PLATE_CONTAINER`,
        u: PlateIcon,
        t:  `PLATE`,
        d:{
            type:'PERSON_Plate_CONTAINER',
            hidden: false,
            data: PlateInfo
        }
    })

    returnObj.push(
        {
            type: 'link',
            id: `PERSON_NODE_ID${Person.PersonID}_PLATE_CONTAINER_LINK`,
            id1: `PERSON_NODE_ID${Person.PersonID}_PLATE_CONTAINER`,
            id2: parent_ID,
            a1: true, w: 2, c: '#566573', t1: '', fb:true
        })

    if(PlateInfo){
        PlateInfo.forEach((node:any) => {
                returnObj.push({
                    type: 'node',
                    id: `PLATE_NODE_${node.PlateSt}${node.PlateNo}`,
                    u: PlateIcon,
                    t:  `${node.PlateSt} ${node.PlateNo}`,
                    d:{
                        type:'PLATE_NODE',
                        data: node
                    }
                })

                returnObj.push({
                    type: 'link',
                    id: `PLATE_NODE_${node.PlateSt}${node.PlateNo}_TO_PERSON_NODE_ID${Person.PersonID}_PLATE_CONTAINER`,
                    id1: `PLATE_NODE_${node.PlateSt}${node.PlateNo}`,
                    id2: `PERSON_NODE_ID${Person.PersonID}_PLATE_CONTAINER`,
                    a1: true, w: 2, c: '#566573', t1: '', fb:true
                })
            }
        )
    }

    return returnObj;
}
function MergeAssociatedPeople(response: any) : any[] {
    let temp: any[] = []

    if (response.Association) {
        temp.push(...response.Association)
    }
    if (response.Association1) {
        temp.push(...response.Association1)
    }
    if (response.Association2) {
        temp.push(...response.Association2)
    }
    if (response.Association3) {
        temp.push(...response.Association3)
    }
    if (response.Association4) {
        temp.push(...response.Association4)
    }
    return temp
}
export function GetPersonAnalysis_GenNIBINNodes(reports: any[], id:any){
    let returnObj : any[] = [];
    if(reports){
        for(let report of reports){
            //Make the Report Node
            returnObj.push({
                type: 'node',
                id: 'NIBIN_Report_' + report.ID,
                u: 'icons/weapon.png',
                t:  (report?.AssociatedEvidences?.AssociatedWeapon?.length > 0
                    ? report.Lab + " " +  report.ReferenceNumber + " (" + (report.NIBINHit ? "HIT" : "POTENTIAL") + ")" + '\n RECOVERED WEAPON'
                    : report.Lab + " " +  report.ReferenceNumber + " (" + (report.NIBINHit ? "HIT" : "POTENTIAL") + ")" + ' \n UNRECOVERED WEAPON'),
                d:{
                    type:'report',
                    data:report
                },
                ha0: {
                    c: ( report?.AssociatedEvidences?.AssociatedWeapon?.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)'),
                    r: 30,
                    w: 8
                }
            })
            
            //we have a parent node for the report node
            if(id){
                returnObj.push(
                    {
                        type: 'link',
                        id: 'NIBIN_Report_' + report.ID + '_TO_' + id,
                        id1: 'NIBIN_Report_' + report.ID,
                        id2: id,
                        a1:true,
                        w: 4,
                    })
            }

            for(var incident of report.Incidents) {
                returnObj.push(
                    {
                        type: 'node',
                        id: 'I' + incident.ID,
                        t:  incident.Dept.replace(/\s+/g, '').trim() + ' ' + incident.CaseNumber + '\n' + ( incident?.Shooting_DATETIME ?  formatDT(incident?.Shooting_DATETIME) : formatDT(incident.OccurrenceDate)) + '\n' + incident.Inc_Type,
                        u: 'icons/legal-docs.png',
                        d:{
                            type:'NIBIN_INCIDENT',
                            data:incident
                        }
                    })
                returnObj.push(
                    {
                        type: 'link',
                        id: 'L-I' + incident.ID,
                        id1: 'I' + incident.ID,
                        id2: 'NIBIN_Report_' + report.ID,
                        a1:true,
                        w: 4,
                    })
 
                if (incident.Persons && incident.Persons.length > 0)
                {
                    for(var person of incident.Persons) {

                        if(person?.Role?.toUpperCase() !== ('INVESTIGATOR')){

                            returnObj.push(
                                {
                                    type: 'node',
                                    id: 'ASSOCPEOPLE_NODE_' + person.ID,
                                    t: (person.FName ? person.FName : "") + ' ' + (person.LName ? person.LName : ""),
                                    u: 'icons/cbimage.png',
                                    d: {
                                        type: 'ASSOCPEOPLE_NODE',
                                        data: {"AType":"NIBIN Case",...person},
                                        imageSet: false
                                    }
                                })
                        }
                        returnObj.push(
                            {
                                type: 'link',
                                id: 'NIBIN_INCIDENT_TO_ASSOCPEOPLE_NODE_' + person.ID,
                                id1: 'ASSOCPEOPLE_NODE_' + person.ID,
                                id2: 'I' + incident.ID,
                                a1: true,
                                w: 4,
                                t1: person.Role,
                                fb:true
                            })
                    }
                }
                if (incident.Weapon && incident.Weapon.length > 0)
                {
                    for(var weapon of incident.Weapon) {

                        returnObj.push(
                            {
                                type: 'node',
                                id: 'W' + weapon.ID,
                                t: 'Recovered ' + (weapon.MAKE ? weapon.MAKE : "") + ' ' + (weapon.MODEL ? weapon.MODEL : ""),
                                u: 'icons/weapon.png',
                                d:{
                                    type:'NIBIN_WEAPON',
                                    data:weapon
                                },
                                ha0: {
                                    c: ( report.AssociatedEvidences.AssociatedWeapon.some((aWeapon:any) => aWeapon.ID === weapon.ID) ? 'rgb(0, 160, 0)' : 'rgb(255,255,255)'),
                                    r: 30,
                                    w: 8
                                },
                            })
                        returnObj.push(
                            {
                                type: 'link',
                                id: 'L-W' + weapon.ID,
                                id1: 'W' + weapon.ID,
                                id2: 'I' + incident.ID,
                                a1: true,
                                w: 4,
                                t1: weapon.SERIAL_NUMBER,
                                fb:true
                            })
                    }
                }
                
                if (incident.Ballistics && incident.Ballistics.length > 0)
                {
                    for(var ballistic of incident.Ballistics) {

                        returnObj.push(
                            {
                                type: 'node',
                                id: 'B' + ballistic.ID,
                                t:  (ballistic.Caliber_Type?.length > 0 ? ballistic.Caliber_Type + ' ' : "")  + (ballistic.VGID_CPart?.length > 0 ? ballistic.VGID_CPart : ""),
                                u: 'icons/shell.png',
                                d:{
                                    type:'NIBIN_BALLISTIC',
                                    data:ballistic
                                },
                                ha0: {
                                    c: ( report.AssociatedEvidences.AssociatedBallistic.some((aBallistic:any) => aBallistic.ID === ballistic.ID) ?
                                        (report.AssociatedEvidences.AssociatedWeapon.length > 0 ?'rgb(0, 160, 0)' : 'rgb(255,0,0)') : 'rgb(255,255,255)'),
                                    r: 30,
                                    w: 8
                                }
                            })
                        returnObj.push(
                            {
                                type: 'link',
                                id: 'L-B' + ballistic.ID,
                                id1: 'B' + ballistic.ID,
                                id2: 'I' + incident.ID,
                                a1: true,
                                w: 4,
                                t1: ballistic.Caliber_Type,
                                fb:true
                            })
                    }
                }
            }
        }
        return returnObj;
    }
}