import React from "react";

function Shooting() {
    return (
        <div>
            Shooting
        </div>
    );
}

export default Shooting;