import React, { useState } from "react";
import ArrestAnalysisSearch from "../../components/tools/ArrestAnalysis/_ArrestAnalysisSearch";
import SearchResults from "../../components/tools/ArrestAnalysis/_searchResultsArrestAnalysis";
import SearchResultGraph from "../../components/tools/ArrestAnalysis/_searchResultsArrestAnalysisGraph";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse, getGraphResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery, RunGraphQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";

function ArrestAnalysis() {
    let [analysis, setAnalysis] = useState<any[]>([])
    let [analysisColumn, setAnalysisColumn] = useState<any[]>([])
    let [analysisGraph, setAnalysisGraph] = useState<any>()
    let [user, setUser] = useState<Account>(getUser())

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any, top: number) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "4",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Arrest Date") ? 0 : 1,
            "FromDate": params.fromDate.toLocaleString(),
            "ToDate": params.toDate.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.header ? params.header.split(',') : [],  
            "MNames": params.detail ? params.detail.split(',') : [],
            "LNames": params.group ? params.group.split(',') : [], 
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "PoliceStopDept": [],
            "Indicators": [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        //console.log(qRes.JsonObject)
        setAnalysis([])
        setAnalysisColumn([])
        setAnalysisGraph([])
        setAnalysis(qRes.AnyTable)
        setAnalysisColumn(qRes.SecondaryTable)
        setAnalysisGraph(JSON.parse(qRes.JsonObject))

        //SubmitGraphSearch(params, dateType, andOrType, dropDowns, top);
    }

    //const SubmitGraphSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any, top: number) => {
    //    let search_query: GetDarInfo_Query = {
    //        "QueryType": "G",
    //        "Dept": user.Dept,
    //        "EmpID": user.EmpID,
    //        "DeptORI": user.DeptORI,
    //        "DateRange": true,
    //        "DateTypeValue": (dateType === "Arrest Date") ? 0 : 1,
    //        "FromDate": params.fromDate,
    //        "ToDate": params.toDate,
    //        "SearchType": andOrType,
    //        "IndicatorOn": true,
    //        "TopArrestNumber": 0,
    //        "FNames": params.header ? params.header.split(',') : [],
    //        "MNames": params.detail ? params.detail.split(',') : [],
    //        "LNames": params.group ? params.group.split(',') : [],
    //        "DOBs": [],
    //        "SEX": [],
    //        "Address": "",
    //        "Cities": dropDowns.City ? dropDowns.City : [],
    //        "States": dropDowns.State ? dropDowns.State : [],
    //        "Zip": "",
    //        "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
    //        "Gang": [],
    //        "FBIs": [],
    //        "SBIs": [],
    //        "DLNos": [],
    //        "PlateNos": [],
    //        "VINs": [],
    //        "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
    //        "PoliceStopDept": [],
    //        "Indicators": [],
    //        "Latitude": null,
    //        "Longitude": null,
    //        "Radius": 0,
    //        "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
    //        "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
    //        "Arrest_Type": [],
    //        "CaseNumbers": []
    //    }
    //    let qRes: getGraphResponse = await RunGraphQuery(search_query)
    //    console.log(qRes.Series)
    //    console.log(qRes.XAxis)
    //    setAnalysisGraph(qRes)
    //}

    return (
        <div>
            {isArrestShow()?
                <>
                    <ArrestAnalysisSearch SubmitSearch={SubmitSearch} />            
                    <SearchResults AnalysisData={analysis} AnalysisColumn={analysisColumn} AnalysisGraph={analysisGraph} />
                </>
            :<></>}
        </div>
    );
}

export default ArrestAnalysis;