import React, { useState } from "react";
//import ArrestSearch from "../../components/tools/codis/_codisSearch";
import SearchResults from "../../components/tools/shot_spotter/_searchResultsShotSpotter";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";
import ShotSpotterSearch from "../../components/tools/shot_spotter/_shootSpotterSearch";

function ShotSpotter() {
    let [shootings, setShootings] = useState<any[]>([])
    let [audioFiles, setAudioFiles] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "I",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": params.fromDate.toLocaleString(),
            "ToDate": params.toDate.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": false,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "PoliceStopDept":  [],
            "Indicators": [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        let shootings:any[] = []
        setShootings([])

        if(qRes.AnyTable){
            for(var i of qRes.AnyTable)
            {
                if(!shootings.some((shot:any) => shot.MainID === i.MainID)){
                    shootings.push(i)
                }
            }
        }

        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }

        setShootings(shootings)
        setAudioFiles(qRes.AnyTable)      
    }
    return (
        <div id="ToolsShooting">
            {isArrestShow()?
                <>
                    <ShotSpotterSearch SubmitSearch={SubmitSearch} /> 
                    {!noData ? <SearchResults ShootingInfo={shootings} AudioFiles={audioFiles} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default ShotSpotter;
