import {GetDarInfo_Query, getDarInfoResponse, getGraphResponse} from "../interfaces/getDarInfo_interface";
import axios, {AxiosResponse} from "axios";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import DarDropdown from "../interfaces/DarDropDown.interface";

export async function getDropDownQuery(dropdownName: string, controller: string ='Dropdown'): Promise<DarDropdown[]> {
    let res: AxiosResponse<DarDropdown[]> = await AuthenticatedAPIReq.get('/api/' + controller + '/' +dropdownName)
    return(res.data)
}

export async function getDropDownMake(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleMake', params)
    return (res.data)
}

export async function getDropDownModel(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleModel', params)
    return (res.data)
}

export async function getDropDownBody(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleBody', params)
    return (res.data)
}

export async function getDropDownYear(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleYear', params)
    return (res.data)
}

export async function getDropDownTrim(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleTrim', params)
    return (res.data)
}

export async function getDropDownColor(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/VehicleColor', params)
    return (res.data)
}

export async function RunGraphQuery(params: GetDarInfo_Query): Promise<getGraphResponse> {
    let res: AxiosResponse<getGraphResponse> = await AuthenticatedAPIReq.post('/api/Filter/GetDARInfo', params)
    return (res.data)
}

export async function getALPRCameras(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FixedALPRCamera')
    return(res.data)
}

export async function getCCTVCameras(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FixedCCTVCamera')
    return(res.data)
}

export async function getDropDownALPRCamera(params: any): Promise<any> {
    let res: AxiosResponse<any[]> = await AuthenticatedAPIReq.post('/api/Dropdown/ALPRCamera', params)
    return (res.data)
}

export async function getUserAuthLevel(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/UserAuthLevel')
    return(res.data)
}

export async function getDeptAccountType(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/DeptAccountType')
    return(res.data)
}

export async function getDeptGroup(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/DeptGroup')
    return(res.data)
}

export async function getAuditLogAction(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/AuditAction')
    return (res.data)
}

export async function getCustodyCounty(state:string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/CustodyCounty?State=' + state)
    return (res.data)
}

export async function getRTCCArea(state:string): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/RTCCArea?State=' + state)
    return (res.data)
}

export async function getFAQList(): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dropdown/FAQList')
    return (res.data)
}



export { }