import React, {useState} from "react";
import MainLogo from "../../assets/Images/Main.png";
import PoliceStopLogo from "../../assets/Images/PoliceStop.png";
import njspLogo from "../../assets/Images/njspLogo.png";
import uasiLogo from "../../assets/Images/uasiLogo.png";
import NJSnapLogo from "../../assets/Images/NJSnapLogo.png";
import cap5Logo from "../../assets/Images/cap5Logo.jpg";
import {Link} from "react-router-dom";
import { Menu, Transition } from '@headlessui/react'
import { Fragment} from 'react'
import {Account, AccountTools, DashboardList, Expiry, SupportList, UserProfile} from "../../interfaces/auth_interface";
import {
    getDashboards,
    getExpiry, getSupportTabs,
    getToken,
    getUser,
    getUserProfile,
    getUserTools,
    isArrestShow,
    isNIBINShow,
    isPOIShow, showTools
} from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import { Shooting } from "../../services/config.service";
import Marquee from "react-fast-marquee";
import {Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import { RunUserExpiryDays } from "../../services/account.service";

interface ToolsProps{
    isActive:number,
    ActiveTool:any,
}

const Tools: React.FC<ToolsProps> = ({isActive, ActiveTool}) => {
    const history = useHistory()
    const [activeTool, setActiveTool] = useState<string>("");
    
    let [userTools, setUserTools] = useState<AccountTools[]>(getUserTools())

    switch (history.location.pathname) {
        case ('/tools/arrest'):
            if(activeTool !== "Arrest")
                setActiveTool("Arrest")
            break;
        case ('/tools/frequent_offender'):
            if (activeTool !== "Frequent Offender")
                setActiveTool("Frequent Offender")
            break;
        case ('/tools/person_nearby'):
            if (activeTool !== "Person Nearby")
                setActiveTool("Person Nearby")
            break;
        case ('/tools/alpr_multiple_occurrence'):
            if (activeTool !== "LPR Multiple Occurrence")
                setActiveTool("LPR Multiple Occurrence")
            break;
        case ('/tools/phone_search'):
            if (activeTool !== "Phone Search")
                setActiveTool("Phone Search")
            break;
        case ('/tools/towed_vehicle'):
            if (activeTool !== "Towed Vehicle")
                setActiveTool("Towed Vehicle")
            break;
        case ('/tools/shooting_notification'):
            if (activeTool !== "Shooting Notification")
                setActiveTool("Shooting Notification")
            break;
        case ('/tools/open_case_incident'):
            if (activeTool !== "Open Case Incident")
                setActiveTool("Open Case Incident")
            break;
        case ('/tools/codis_notification'):
            if (activeTool !== "CODIS Notification")
                setActiveTool("CODIS Notification")
            break;
        case ('/tools/alpr_vin_search'):
            if (activeTool !== "LPR VIN Search")
                setActiveTool("LPR VIN Search")
            break;
        case ('/tools/arrest_analysis'):
            if (activeTool !== "Arrest Analysis")
                setActiveTool("Arrest Analysis")
            break;
        case ('/tools/parole'):
            if (activeTool !== "Parole")
                setActiveTool("Parole")
            break;
        case ('/tools/prisoner_information'):
            if (activeTool !== "Prisoner Information")
                setActiveTool("Prisoner Information")
            break;
        case ('/tools/professional_license'):
            if (activeTool !== "Professional License")
                setActiveTool("Professional License")
            break;
        case ('/tools/profile_report'):
            if (activeTool !== "Create Profile Report")
                setActiveTool("Create Profile Report")
            break;
        case ('/tools/statute_mapping'):
            if (activeTool !== "Statute Mapping")
                setActiveTool("Statute Mapping")
            break;
        case ('/tools/violent_offender'):
            if (activeTool !== "Violent Offender")
                setActiveTool("Violent Offender")
            break;
        case ('/tools/scor'):
            if (activeTool !== "SCOR Search")
                setActiveTool("SCOR Search")
            break;
        case ('/tools/shot_spotter'):
            if (activeTool !== "Shot Spotter")
                setActiveTool("Shot Spotter")
            break;
        case ('/ncic'):
            if (activeTool !== "NCIC Search")
                setActiveTool("NCIC Search")
            break;
        case ('/tools/stolen_vehicle'):
            if (activeTool !== "Stolen Vehicle")
                setActiveTool("Stolen Vehicle")
            break;
        case ('/tools/duplicate_person'):
            if (activeTool !== "Duplicate Person")
                setActiveTool("Duplicate Person")
            break;
        case ('/tools/GVRTF_Referral'):
            if (activeTool !== "GVRTF Referral")
                setActiveTool("GVRTF Referral")
            break;
        default:
            if(activeTool !== "")
                setActiveTool("")
            break;
    }

    
    React.useEffect(()=>{
        setActiveTool("")
    },[isActive])
    
    return (
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="mr-5 align-middle">
                        TOOLS <span style={{color:'gold'}}> {activeTool}</span>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{"zIndex":100}}>
                        <div className="px-1 py-1 ">
                            {(userTools)? userTools.map((tool,idx)=> (
                                <Menu.Item key={'NAVTLSDROPKEY'+idx}>
                                    {({ active }) => (
                                        <>
                                            {tool.type && tool.type ==='A' ?
                                                <a href={tool.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>{tool.name}</a>
                                            : 
                                                <Link to={tool.href} onClick={()=>{ActiveTool(isActive+1)}} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {tool.name}
                                                </Link>
                                            } 
                                        </>
                                    )}
                                </Menu.Item>
                            )) : <></>}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
    )
}

interface ProfileProps{
    isActive:number,
    ActiveProfile:any
}

const Profile: React.FC<ProfileProps> = ({ isActive, ActiveProfile }) => {
    let user: Account = getUser();
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");

    let [userProfiles, setUserProfiles] = useState<UserProfile[]>(getUserProfile())

    switch (history.location.pathname) {
        case ('/users'):
            if (activeProfile !== "" + user.Firstname )
                setActiveProfile("" + user.Firstname )
            break;
        case ('/departments'):
            if (activeProfile !== "Departments")
                setActiveProfile("Departments")
            break;
        case ('/deptUnit'):
            if (activeProfile !== "Dept Unit Level")
                setActiveProfile("Dept Unit Level")
            break;
        case ('/SearchHistory'):
            if (activeProfile !== "Search History")
                setActiveProfile("Search History")
            break;
        case ('/change_password'):
            if (activeProfile !== "Change Password")
                setActiveProfile("Change Password")
            break;
        default:
            if (activeProfile !== "" + user.Firstname )
                setActiveProfile("" + user.Firstname )
            break;
    }

    React.useEffect(()=>{
        setActiveProfile("")
    },[isActive])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                    PROFILE <span style={{color:'gold'}}> {activeProfile}</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{"zIndex":100}}>
                    <div className="px-1 py-1 ">
                        {(userProfiles)? userProfiles.map((profile, idx)=> (
                            <Menu.Item key={'NAVPFLEDROPKEY'+idx}>
                                {({ active }) => (
                                    <>
                                        {profile.type && profile.type ==='A' ?
                                            <a href={profile.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>{profile.name}</a>
                                            :
                                            <Link to={profile.href} onClick={()=>{setActiveProfile(profile.name); ActiveProfile(isActive+1)}} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {profile.name}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface DashboardProps{
    isActive:number,
    ActiveProfile:any
}
const Dashboard: React.FC<DashboardProps> = ({ isActive, ActiveProfile }) => {
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");

    let [dashboards, setDashboards] = useState<DashboardList[]>(getDashboards())

    React.useEffect(()=>{
        setActiveProfile("")
    },[isActive])
    
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                   DASHBOARDS
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{"zIndex":100}}>
                    <div className="px-1 py-1 ">
                        {(dashboards)? dashboards.map((dashboard, idx)=> (
                            <Menu.Item key={'NAVDBDROPKEY'+idx} >
                                {({ active }) => (
                                    <>
                                        {dashboard.type && dashboard.type ==='A' ?
                                            <a href={dashboard.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>{dashboard.name}</a>
                                            :
                                            <Link to={dashboard.href} onClick={()=>{setActiveProfile(dashboard.name); ActiveProfile(isActive+1)}} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {dashboard.name}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface SupportProps{
    isActive:number,
    ActiveProfile:any
}
const Support: React.FC<SupportProps> = ({ isActive, ActiveProfile }) => {
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");

    let [supportTabs, setSupportTabs] = useState<SupportList[]>(getSupportTabs())

    React.useEffect(()=>{
        setActiveProfile("")
    },[isActive])

    switch (history.location.pathname) {
        case ('/support/FAQ'):
            if (activeProfile !== "FAQs")
                setActiveProfile("FAQs")
            break;
        case ('/support/UserManual'):
            if (activeProfile !== "User Manual")
                setActiveProfile("User Manual")
            break;
        case ('/support/Contact'):
            if (activeProfile !== "Contact Main")
                setActiveProfile("Contact Main")
            break;
        default:
            if (activeProfile !== "")
                setActiveProfile("")
            break;
    }
    
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                    SUPPORT <span style={{color:'gold'}}> {activeProfile}</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{"zIndex":100}}>
                    <div className="px-1 py-1 ">
                        {(supportTabs)? supportTabs.map((support, idx)=> (
                            <Menu.Item key={'NAVDBDROPKEY'+idx} >
                                {({ active }) => (
                                    <>
                                        {support.type && support.type ==='A' ?
                                            <a href={support.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>{support.name}</a>
                                            :
                                            <Link to={support.href} onClick={()=>{setActiveProfile(support.name); ActiveProfile(isActive+1)}} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {support.name}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}


interface NavBarProps {
    count: number
}

const NavBar: React.FC<NavBarProps> = ({ count }) => {
    const history = useHistory()
    let [user, setUser] = useState<Account>(getUser());
    let [home, setHome] = useState<number>(0);
    let [userProfile, setUserProfile] = useState<number>(0);
    let [sessionExp, setSessionExp] = useState<any>(new Date(parseInt("" + getUser().exp) * 1000))
    let [expiry, setExpiry] = useState<string | null>(getExpiry());

    React.useEffect(() => {
        updateSessionTimeout()
    },[count])
    
    const updateSessionTimeout = async () => {
        if(!expiry || expiry.length === 0)
        {
            setExpiry(getExpiry())
        }
        
        setSessionExp(new Date(parseInt("" + getUser().exp) * 1000))
    }
    
    return (
        <nav className="bg-white mb-1">
            <Grid container spacing={0}>
                <Grid item xs={4}>
                    <div className = "flex justify-start items-center h-full ml-5">
                        {expiry && expiry?.length > 0 ?
                            <div className = "w-48 ml-auto">
                                <Marquee speed={60} pauseOnHover={true} gradientWidth={0}>
                                    <Card sx={{ minWidth: 200, maxWidth: 200, maxHeight: 40 }}>
                                        <CardContent className="text-center">
                                            <Typography sx={{ fontSize: 13 }} variant="h4" component="div"  color="red">
                                                {expiry}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Marquee>
                            </div>
                            : <></>}
                    </div>
                </Grid>
                <Grid item xs={4}> 
                    <div className = "flex justify-center items-center h-full">                    
                    </div>
                </Grid>

                <Grid item xs={4}>
                    <div className = "flex justify-end items-center h-full mr-5">                        
                    </div>
                </Grid>
            </Grid>

            <div className="w-screen ">
                <div className="bg-blue-900 rounded-5 w-auto text-white p-1 flow-root">
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className={"flex justify-start items-center h-full"}>
                                <Link to="/" className="ml-5 mr-5 align-middle" onClick={() => { setHome(home + 1); setUserProfile(userProfile + 1) }}><span style={history.location.pathname === '/' ? { color: 'gold' } : {} }>HOME</span></Link>
                                {showTools() ? <Tools isActive={home} ActiveTool={setUserProfile} /> : <></>}
                                {isArrestShow() ? <Dashboard isActive ={userProfile} ActiveProfile={setHome}/> : <></>}
                                {isPOIShow() ? <Link to="/poi" className="mr-5 align-middle" onClick={() => { setHome(home + 1); setUserProfile(userProfile + 1) }}><span style={history.location.pathname === '/poi' ? { color: 'gold' } : {}}>POI</span></Link> : <></>}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="text-xs text-yellow-300 flex justify-center items-center h-full"  style={{textAlign: 'center'}}>
                                {/*Session Expires: {sessionExp.toLocaleString()} or (30 Min Inactivity)*/}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div  className={"flex justify-end items-center h-full"}>
                                <a href="https://www.CanaraLabels.com/trainingcalendar" target={'_blank'} className="mr-5 align-middle">TRAINING</a>
                                <Support isActive={userProfile} ActiveProfile={setHome}/>
                                <Profile isActive={userProfile} ActiveProfile={setHome}/>
                                <Link to="/logoff" className="mr-5 align-middle">LOGOFF</Link>
                                
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
            
        </nav>

    );
}



export default NavBar;