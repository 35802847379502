import React, { useState } from "react";
import StatuteMappingSearch from "../../components/tools/statuteMapping/_statuteMappingSearch";
import SearchResults from "../../components/tools/statuteMapping/_searchResultsStatuteMapping";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isArrestShow} from "../../services/auth.service";

function StatuteMapping() {
    let [darTbl, setDarTbl] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "3",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Arrest Date") ? 0 : 1,
            "FromDate": params.fromDate.toLocaleString(),
            "ToDate": params.toDate.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs":[],
            "SBIs": [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "PoliceStopDept": dropDowns.PoliceStopDept ? dropDowns.PoliceStopDept : [],
            "Indicators": [],
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setDarTbl([])
        setDarTbl(qRes.PersonList)

        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    return (
        <div id="ToolsArrest">
            {isArrestShow()?
                <>
                    <StatuteMappingSearch SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default StatuteMapping;