import { CircularProgress } from "@mui/material";
import React from "react";
import { render } from "react-dom";

const LoadingSplash: React.FC = () => {
    let wordArray = [
        '"Evil is powerless if the good are unafraid." -President Ronald Reagan',
        '"Anyone can hold the helm when the sea is calm." -Publilius Syrus',
        '"When everything seems to be going against you, remember that the airplane takes off against the wind, not with it." -Henry Ford',
        '"This server is powered by a lemon and two electrodes" -Unknown',
        '"Real heroes dont wear capes. Real superheroes wear uniforms and badges and stethoscopes! Real superheroes are members of our military, law enforcement, and first responders. Pretend superheroes wear capes!" -Dean Cain',
        '"No place is unpoliceable; no crime is immune to better enforcement efforts." -William Bratton',
        '"Leadership is a potent combination of strategy and character. But if you must be without one, be without the strategy." -Norman Schwarzkopf',
        '"To be an effective leader, you have to have a manipulative streak - you have to figure out the people working for you and give each tasks that will take advantage of his strength." -Norman Schwarzkopf',
        '"You don’t need a title to be a leader." –Multiple Attributions',
        '"Society questions the police and their methods, and the police say, Do you want the criminals off the street or not?" -Kurt Russell',
        '“If it’s not important to the leadership – it’s not important to the people on the front line” -Carlos Canino',
        '"We know we can\'t stop every act of violence, every act of evil in the world, but maybe we could try to stop one act of evil, one act of violence." -Barack Obama',
    ]

    const [text, setText] = React.useState<string>(wordArray[wordArray.length * Math.random() | 0])
    const [title, setTitle] = React.useState<string>("Loading")
    const stage = 0
    

    React.useEffect(() => {
        const interval = setInterval(() => {
            setText(wordArray[wordArray.length * Math.random() | 0])
        }, 5000);
        return () => clearInterval(interval);
    })

    return (
        <>
            <div className="loading mb-3">Loading...</div>
            <div>{text}</div>
        </>
    );
    
}

export default LoadingSplash;
