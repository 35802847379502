import React, {useState} from 'react'
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import VehicleIcon from "../../../assets/Images/Vehicle.png";
import PersonIcon from "../../../assets/Images/Person.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import Checkbox from "@mui/material/Checkbox";
import {
    ALPRMultipleOccurenceQuery,
    GetDarInfo_Query,
    getDarInfoResponse
} from "../../../interfaces/getDarInfo_interface";
import {Account} from "../../../interfaces/auth_interface";
import {getUser} from "../../../services/auth.service";
import {RunDarQuery, RunMultipleOccurrence} from "../../../services/getDar.service";
import MapIcon from "@mui/icons-material/Map";
import {JAOS} from "../../../services/JAOS.service";
import PersonPhotoIcon from "../../../assets/Images/photo1.png";
import ArrestIcon from "../../../assets/Images/ai1.png";
import PoliceStopIcon from "../../../assets/Images/ic1.png";
import ETicketIcon from "../../../assets/Images/et1.png";
import ParkMobileIcon from "../../../assets/Images/pm1.png";
import ALPRIcon from "../../../assets/Images/alpr1.png";
import CADRMSIcon from "../../../assets/Images/Opencase1.png";
import CODISIcon from "../../../assets/Images/DNA1.png";
import TowingIcon from "../../../assets/Images/towing1.png";
import PrisonReleaseIcon from "../../../assets/Images/jail1.png";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import ShootingIcon from "../../../assets/Images/Shooting.png";
import ShootingSuspectIcon from "../../../assets/Images/ShootingRed.png";
import ShootingVictimIcon from "../../../assets/Images/ShootingGreen.png";
import NibinIcon from "../../../assets/Images/nibin1.png";
import NJGunTraceIcon from "../../../assets/Images/NjGunTrace1.png";
import PrisonParoleInactiveIcon from "../../../assets/Images/parole_Inactive.png";
import PrisonParoleActiveIcon from "../../../assets/Images/parole1.png";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import HumanTrafficIcon from "../../../assets/Images/HumanTraffic1.png";
import ProfesionIcon from "../../../assets/Images/profession1.png";
import ReferralIcon from "../../../assets/Images/Referral1.png";
import ReferralRearrestedIcon from "../../../assets/Images/ReferralRearrested1.png";
import { showCrumbScore } from '../../../services/config.service';


interface SearchResultsProps {
    CADRMSInfo: any[],
    Persons: any[],
}

const SearchResults: React.FC<SearchResultsProps> = ({CADRMSInfo,Persons})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    const [alprList, setALPRList] = useState<any[]>([])
    const [mapVersion, setMapVersion] = useState<string>("2")
    const [exportData, setExportData] = useState<any>({})

    const handleDataTyping = () =>
    {
        if(CADRMSInfo) {
            let tempP: any = [...CADRMSInfo]

            for (let p of tempP) {
                if (p.DateTimeIN) {
                    p.DateTimeIN = new Date(p.DateTimeIN)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    },[CADRMSInfo])

    let grid: Grid | null;
    const gridTemplate = (person: any): any => {
        return (<>
            <Tooltip title={"Photo"} arrow>{(person.FoundPhoto > 0) ? <img src={PersonPhotoIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Arrest"} arrow>{(person.FoundArrest > 0) ? <img src={ArrestIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"PoliceStop"} arrow>{(person.FoundPoliceStop > 0) ? <img src={PoliceStopIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ETicket"} arrow>{(person.FoundETicket > 0) ? <img src={ETicketIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ParkMobile"} arrow>{(person.FoundParkMobile > 0) ? <img src={ParkMobileIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"ALPR"} arrow>{(person.FoundALPR > 0) ? <img src={ALPRIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CAD/RMS"} arrow>{(person.FoundCADRMS > 0) ? <img src={CADRMSIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"CODIS"} arrow>{(person.FoundCODIS > 0) ? <span style={{"marginRight":"3px"}}> <img src={CODISIcon} style={{"display":"inline"}} alt={""}/> {person.FoundCODIS} </span>: <span/>}</Tooltip>
            <Tooltip title={"Towing"} arrow>{(person.FoundTowing > 0) ? <img src={TowingIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Prison Release"} arrow>{(person.FoundPrisonRelease > 0) ? <img src={PrisonReleaseIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"GunTrace"} arrow>{(person.FoundGunTrace > 0) ? <img src={GunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Shooting Suspect(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'S') ? <span style={{ "marginRight": "3px" }}><img src={ShootingSuspectIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting Victim(Last 1 year)"} arrow>{(person?.FoundShooting > 0) && (person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingVictimIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"Shooting"} arrow>{(person?.FoundShooting > 0) && !(person?.RecentShooting === 'S' || person?.RecentShooting === 'V') ? <span style={{ "marginRight": "3px" }}><img src={ShootingIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundShooting}</span> : <span />}</Tooltip>
            <Tooltip title={"NIBIN"} arrow>{(person.FoundNIBIN > 0) ? <img src={NibinIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"NJGunTrace"} arrow>{(person.FoundNJGunTrace > 0) ? <img src={NJGunTraceIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Inactive Parole"} arrow>{(person.FoundPrisonParole === 1) ? <img src={PrisonParoleInactiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Active Parole"} arrow>{(person.FoundPrisonParole === 2) ? <img src={PrisonParoleActiveIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Violent"} arrow>{(person.FoundViolent > 0) ? <img src={ViolentIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Human Traffic"} arrow>{(person.FoundHumanTraffic > 0) ? <img src={HumanTrafficIcon} style={{"display":"inline", "marginRight":"3px"}} alt={""}/> : <span/>}</Tooltip>
            <Tooltip title={"Profession"} arrow>{(person.FoundProfession > 0) ? <img src={ProfesionIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /> : <span />}</Tooltip>
            <Tooltip title={"Referral"} arrow>{(person?.FoundReferral === 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralIcon} style={{ "display": "inline" }} alt={""} /></span> : <span />}</Tooltip>
            <Tooltip title={"Referral (Rearrested)"} arrow>{(person?.FoundReferral > 1) ? <span style={{ "marginRight": "3px" }}><img src={ReferralRearrestedIcon} style={{ "display": "inline" }} alt={""} /> {person.FoundReferral}</span> : <span />}</Tooltip>
        </>);
    }

    const selectTemplate = (person: any): any => {
        return (
            <>
                <Checkbox onClick={ () => {buildALPRList(person)} }/>
            </>);
    }
    const NearByTemplate = (person: any): any => {
        return (
            <span>
                <Tooltip title={'Person: 0.25 Mile Range and Last 1 Year'} arrow><Button onClick ={() => {handlePersonGeo(person)}}><img src={PersonIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                <Tooltip title={'Vehicle: 16 minutes & 1 Mile Range'} arrow><Button onClick ={() => {handleCarGeo(person)}}><img src={VehicleIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
            </span>);
    }

    const handleCarGeo = async (caseInfo:any) => {
        setExportData({})
        let search_query:ALPRMultipleOccurenceQuery = {
            "incidents": [
                {
                    date: caseInfo.DateTimeIN.toLocaleString(),
                    threshold: 8,
                    radius: 1, //this needs to move to 1 on prod ***
                    location: {
                        address: caseInfo.Address2,
                        city: caseInfo.City2,
                        state: caseInfo.ST2,
                        zip: "",
                        latitude: caseInfo.Incident_Lat,
                        longitude: caseInfo.Incident_Lon,
                        addressType: ""
                    },
                    showEdit: true,
                    alprServerDB: "",
                    alprServerName: ""
                }
            ],
            minOccurrence: 0,
            lookFor: "Car",
            plate: {
                plateNumber: "",
                plateSt: ""
            },
            queryType: "M",
            alprReason: "Suspicious Incident",
            alprCaseNumber: "Automated",
            UTCDate:false,
        }
        let res:any = await RunMultipleOccurrence(search_query)
        let parsed = JSON.parse(res?.JsonObject)
        if(parsed.MultipleOccurrenceList.length > 0) {
            let obj = {
                caseInfo: [caseInfo],
                cars: parsed.MultipleOccurrenceList
            }
            setExportData(parsed.MultipleOccurrenceList)
            carGeoCode(obj)
        }
    }

    const handlePersonGeo = async (caseInfo:any) => {
        setExportData({})
        let fromDate = new Date(caseInfo.DateTimeIN)
        fromDate.setFullYear(fromDate.getFullYear() - 1)

        let search_query: GetDarInfo_Query = {
            "QueryType": "2",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 1,
            "FromDate": fromDate.toISOString().substring(0,10),
            "ToDate": caseInfo.DateTimeIN,
            "SearchType": "AND",
            "IndicatorOn": true, //why?
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": [],
            "PoliceStopDept":  [],
            "Indicators": [],
            "Latitude": caseInfo.Incident_Lat,
            "Longitude": caseInfo.Incident_Lon,
            "Radius": 0.25,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        if(qRes.PersonList?.length > 0) {
            let obj = {
                caseInfo: [caseInfo],
                people: qRes.PersonList
            }
            setExportData(qRes.PersonList)
            personGeoCode(obj)
        }
    }

    const buildALPRList = (caseInfo:any) => {
        let ALPRListTemp = alprList
        var result = ALPRListTemp.findIndex(x => x.AgencyCaseNo === caseInfo.AgencyCaseNo)

        if(result === -1) {
            ALPRListTemp.push(caseInfo)

        }
        else{
            ALPRListTemp.splice(result,1)
        }

        setALPRList(ALPRListTemp)
    }

    const handleALPRListSend = () => {
        if(alprList.length < 1)
        {
            window.alert("Please Select At Least One Incident To Compare (Check Lat & Long)")
        }
        else
        {
            let l = new JAOS()
            let hashID = l.objToStack(alprList)
            window.open('/tools/alpr_multiple_occurrence?ALPRList='+hashID,'_blank')

        }
    }

    const getPersonSummary = (p:any) =>
    {
        return (<BTPersonReport person={p}/>)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'OpenCase.xlsx'
            grid.excelExport(excelProp);
        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const personGeoCode = (peopleGeo:any) => {
        setMapVersion("person")
        let pins:any[] = []
        //console.log(peopleGeo)
        for (let p of peopleGeo.caseInfo)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, offense:p.AgencyCaseNo, tool:"case"})
            }
        }
        for (let p of peopleGeo.people)
        {
            if (p.Incident_Latitude && p.Incident_Longitude)
            {
                pins.push({ lat: p.Incident_Latitude, lng: p.Incident_Longitude, name:p.FName + ' ' + p.MName + ' ' + p.LName, date:p.DTOFARREST, statute:p.STATUTE ,tool:"person"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const carGeoCode = (carGeo:any) => {
        setMapVersion("car")
        let pins:any[] = []
        //console.log(carGeo)
        for (let p of carGeo.caseInfo)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, offense:p.AgencyCaseNo, tool:"case"})
            }
        }
        for (let p of carGeo.cars)
        {
            if(p.Latitude && p.Longitude)
            {
                if (p.POIReason?.toLowerCase() == 'stolen vehicle' || p.POIReason?.toLowerCase() == 'stolen plate')
                    pins.push({ lat: p.Latitude, lng: p.Longitude, plate: p.red_vrm, date: p.red_TimeStamp, alprSource: p.src_Name, tool: "stolenCar" })
                else
                    pins.push({lat:p.Latitude, lng:p.Longitude, plate:p.red_vrm, date:p.red_TimeStamp, alprSource:p.src_Name ,tool:"car"})
            }
        }
        //console.log(pins)
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const openGeoCode = () => {
        setMapVersion("openCase")
        setAllPins([])
        let pins:any[] = []
        for (let p of CADRMSInfo)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, offense:p.AgencyCaseNo, inc_type:p.NIBRSDesc, tool:"openCase"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }


    const handleDataTypingPerson = (persons:any) =>
    {
        if(Persons){
            let tempP:any = [...persons]

            for(let p of tempP)
            {
                if(p.DOB)
                {
                    p.DOB = new Date(p.DOB)
                }
                if(p.DTOFARREST)
                {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
            }
            return tempP
        }

    }
    
    //this can be put into a new component
    const casePersonInformation = (cadrms:any) => {
        
        let temp = handleDataTypingPerson(Persons?.filter((x)=> x.AgencyCaseNo === cadrms.AgencyCaseNo))
        
        return (
            <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                Open Case Person Information <img className={"inline ml-2"} src={PersonIcon} alt="ai" />
                {(temp && temp.length > 0) ? <h4 className={"font-bold pl-5"}> {temp.length} Records Found</h4> : <h4 className={"font-bold pl-5"}></h4>}
            </span>
            <GridComponent
                dataSource={temp}
                allowPaging={true}
                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                allowMultiSorting={true}
                detailTemplate={getPersonSummary}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"}}
                rowSelecting = {(event:any) => {console.log(event)}}
                rowSelected = {(event:any) => {expandRow()}}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
                ref={g=> grid = g}
            >
                <ColumnsDirective>
                    <ColumnDirective field='FName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr']}}/>
                    <ColumnDirective field='MName' width={110} minWidth={110} maxWidth={110} headerText='MI' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='LName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='DOB' width={115} minWidth={115} maxWidth={115} headerText='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }}
                                     customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SEX' width={110} minWidth={110} maxWidth={110} headerText='Sex' customAttributes={{ class: ['e-attr'] }}/>
                    { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText='SCOR Ranking' customAttributes={{ class: ['e-attr'] }}/>:<></>}
                    <ColumnDirective field='City' width={140} minWidth={140} maxWidth={140} headerText='City' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='State' width={110} minWidth={110} maxWidth={110} headerText='State' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='OpenCaseRole' width={110} minWidth={110} maxWidth={110} headerText='Role' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='STATUTE' width={140} minWidth={140} maxWidth={140} headerText='Statute' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='STATUTEDESCRIPTION' maxWidth={250} headerText='Description' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='DTOFARREST' width={140} minWidth={140} maxWidth={140} headerText='Arrest Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}
                                     customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='ORIGINATINGORINAME' maxWidth={200} headerText='Agency Arrested' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='Dept' maxWidth={200} headerText='PoliceStop Dept' customAttributes={{ class: ['e-attr'] }}/>
                </ColumnsDirective>
                <Inject services={[Sort, Page, Filter]}/>
            </GridComponent>
            </div>
        )
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
            {field: 'DateTimeIN', direction: 'Descending' }
        ] };
    
    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length>0? <>
                <span style ={{display:"inline-block"}} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Records Found</h4>
                    <ButtonGroup size="medium" color="inherit" >
                         <Button variant="outlined" onClick={openGeoCode}>
                            <MapIcon color="success" className ="mr-2"/><b>Map</b>
                        </Button>
                        <Button variant="outlined" onClick={handleALPRListSend}>
                           <img src={VehicleIcon} className={"mr-2"} alt={""} />OpenCase Connection
                        </Button>
                    </ButtonGroup>
                    {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} exportData={exportData}/></div>:<></>}
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={casePersonInformation}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting = {(event:any) => {console.log(event)}}
                    rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={selectTemplate} headerText='Select' width={100}/>
                        <ColumnDirective field='DateTimeIN' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm'}} headerText='Report Date' width={200}/>
                        <ColumnDirective field='AgencyCaseNo' headerText='Case #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Address2' headerText='Incident Address' width={150}/>
                        <ColumnDirective field='STATUTE' width={140} minWidth={140} maxWidth={140} headerText='Statute' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTEDESCRIPTION' maxWidth={250} headerText='Description' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={NearByTemplate} headerText='Near By Vehicle/Person' width={200}/>
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default SearchResults;
