import React, {useState, useEffect} from "react";

import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    ButtonGroup
} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {JAOS} from "../../../services/JAOS.service";
//import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
//import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
//import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

interface ArrestAnalysisSearchProps {
    SubmitSearch: any
}

const ArrestAnalysisSearch: React.FC<ArrestAnalysisSearchProps> = ({ SubmitSearch }) => {
    var toDate = new Date();
    var prevDate = new Date();
    let today: Date = toDate
    var fromday: Date = new Date();
    fromday = new Date(new Date().setFullYear(new Date().getFullYear() - 1))//Default - Month (-1Year)        

    const [params, setParams] = React.useState({ header: "MONTH", detail: "ARRESTAGENCY", group: "PERSON", fromDate: fromday, toDate: today, showDate: true})
    const [dateType, setDateType] = React.useState<string>("Arrest Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    
    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            handleSearch()
        }
    }, [])


    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleHeaderChange = (e: any) => {
        /*switch (e.target.value) {
            case "DAY":
                prevDate.setDate(toDate.getDate() - 30)
                break
            case "WEEK":
                prevDate.setDate(toDate.getDate() - (30 * 6))
                break
            case "MONTH":
                prevDate.setDate(toDate.getDate() - 365)
                break
            case "YEAR":
                prevDate.setDate(toDate.getDate() - (365 * 5))
                break
        }
        setParams({ ...params, fromDate: prevDate, header: e.target.value })
         */
        setParams({ ...params, header: e.target.value })
    }

    const handleDetailChange = (e: any) => {
        setParams({ ...params, detail: e.target.value })
    }

    const handleGroupChange = (e: any) => {
        setParams({ ...params, group: e.target.value })
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, header: "MONTH", detail: "ARRESTAGENCY", group: "PERSON", toDate: today, fromDate: fromday, showDate: true})
        setDateType("Arrest Date")
        setAndOrType("AND")       
        setClear(clear + 1)
    }

	const handleDate = (e:any) =>
    {
        let show = params.showDate
        setParams({...params, showDate:!show})
    }
   
    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">            
            <div className="grid grid-cols-7 gap-0.5 gap-y-2">                
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{mr: 2 }}
                >
                    <ToggleButton value="Offense Date" sx={{ pr: 2, pl: 2 }} >Offense Date</ToggleButton>
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                </ToggleButtonGroup>
                { /* Header Dropdown */}
                <div className="mr-5">
                    <FormControl fullWidth>
                        <InputLabel id="header">Header</InputLabel>
                        <Select
                            labelId="header"
                            value={params.header}
                            label="Header"
                            onChange={handleHeaderChange}
                        >
                            <MenuItem value={"DAY"}>DAY</MenuItem>
                            <MenuItem value={"WEEK"}>WEEK</MenuItem>
                            <MenuItem value={"MONTH"}>MONTH</MenuItem>
                            <MenuItem value={"YEAR"}>YEAR</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                < >
                    <div >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From Date"
                                value={params.fromDate}
                                onChange={(e:any) => {
                                    setParams({...params, fromDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To Date"
                                    value={params.toDate}
                                    onChange={(e:any) => {
                                        setParams({...params, toDate: e})
                                    }}
                                    renderInput={(params) => <TextField margin='none' type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                />
                        </LocalizationProvider>
                    </div>
                </>
                { /* Detail Dropdown */}
                <div className="mr-5">
                    <FormControl fullWidth>
                        <InputLabel id="detail">Detail</InputLabel>
                        <Select
                            labelId="detail"
                            value={params.detail}
                            label="Detail"
                            onChange={handleDetailChange}
                        >
                            <MenuItem value={"STATUTE"}>STATUTE</MenuItem>
                            <MenuItem value={"ARRESTAGENCY"}>ARRESTED AGENCY</MenuItem>
                            <MenuItem value={"ARRESTCOUNTY"}>ARRESTED COUNTY</MenuItem>
                            <MenuItem value={"RESIDENTCITY"}>RESIDENT CITY</MenuItem>
                            <MenuItem value={"RESIDENTCOUNTY"}>RESIDENT COUNTY</MenuItem>
                            <MenuItem value={"INCIDENTCITY"}>INCIDENT CITY</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                { /* GROUP Dropdown */}
                <div className="mr-5">
                    <FormControl fullWidth>
                        <InputLabel id="group">Group</InputLabel>
                        <Select
                            labelId="group"
                            value={params.group}
                            label="Group"
                            onChange={handleGroupChange}
                        >
                            <MenuItem value={"STATUTE"}>STATUTE</MenuItem>
                            <MenuItem value={"PERSON"}>PERSON</MenuItem>
                        </Select>
                    </FormControl>
                </div>              
            </div>
            <div>
                <ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"analysis"} />
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default ArrestAnalysisSearch;
