import "./styles.css"
import React from "react";
import {
    Alert,
    AlertColor,
    Autocomplete,
    Button,
    ButtonGroup,
    Card,
    CardContent, Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextareaAutosize,
    TextField
} from "@mui/material";
import noImage from "../../../assets/Images/cbimage.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import {
    additionalInfo,
    assocObject,
    deconObject, personImportObject,
    profileObject,
    reportObject
} from "../../../interfaces/PersonReport.interface";
import {JAOS} from "../../../services/JAOS.service";
import {
    GetProfileReport, GetProfileReportFiles,
    GetProfileReportPDF, ProfileReportFilesOld,
    RunProfileReport,
    RunProfileReportFiles
} from "../../../services/account.service";
import {formatDate} from "../../../services/formatDate.service";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

interface ProfileReportProps {
    person? : any
}

interface dropdown {
    value:number,
    key:string
}

interface docItem {
    name:string,
    size:number,
    type:string
}



const ProfileReportInputForm: React.FC<ProfileReportProps> = ({person = null}) => {
    const [initialBuild, setInitialBuild] = React.useState<boolean>(false)
    const [options, setOptions] = React.useState<number>(1)
    const [profileObject, setProfileObject] = React.useState<profileObject>(
{
        reportType:"Victim Profile Report",
        ID:0,
        personObject:{fName:"", mName:"", lName:"", LKA:"", DOB:"", SBI:"", FBI:"", alias:"", gang:"", phone:"", dna:"", height:0, weight:0, hair:"", eyes:"", race:"", gender:"", scars:"", pic:null},
        comments:"",
        ceNum:"",
        deconChecked:false,
        deconObject: [],
        crimActivityObject: {tArrests:"", convictions:"", priorCharges:"", arrestAgency:"", pendingCases:""},
        custodyHistoryObject: {stateCustody:"", countyCustody:""},
        mvcStatus:"",
        associateContacts:"",
        tripleI:"",
        ALPR:"",
        holds:"",
        probation:"",
        employmentHistory:"",
        domesticViolence:"",
        juvenileHistory:"",
        socialMedia:"",
        policeContactLocations: {arrests:"", fieldInterviews:"", other:""},
        additionalInfo:[{type:"Additional Addresses:",content:""},{type:"Additional Telephone Numbers:",content:""},{type:"Additional Aliases:",content:""}],
        assocObject: [],
        reportObject: {preparedBy:"", reviewedBy:"", date:"", reportNum:""},
        files:[],
    })
    const [heightDD, setHeightDD] = React.useState<dropdown[]>([])
    const [hairDD, setHairDD] = React.useState<string[]>(["Bald","Black","Blond", "Blue","Brown", "Gray", "Green", "Orange", "Pink", "Purple", "Red", "Sandy", "White", "Unknown"])
    const [eyesDD, setEyesDD] = React.useState<string[]>(["Black","Blue","Brown", "Gray", "Green", "Hazel", "Maroon", "Multicolored", "Pink", "Unknown"])
    const [raceDD, setRaceDD] = React.useState<string[]>(["American Indian","Alaskan Native", "Asian", "Black", "Pacific Islander", "White","Unknown"])
    const [genderDD, setGenderDD] = React.useState<string[]>(["Male","Female","Transgender", "Non-Binary","Unknown"])
    const [deconAgencyDD, setDeconAgencyDD] = React.useState<string[]>(["Unknown"])
    const [docList, setDocList] = React.useState<any[]>([])

    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")
    
    
    const createWeightDropDown = () => {
        let temp = heightDD
        for (var i = 20; i <= 108; i++ ){
            let h = "" + Math.floor(i/12) + "' " + i%12 + '"'
            temp.push({value:i, key:h})
        }
        setHeightDD(temp)
    }

    React.useEffect(() => {
        if(!initialBuild) {
            setInitialBuild(true)
            createWeightDropDown()
            GetProfileData().then(r => {
            if (r == false && person) {
                hadleDataFill()
            }})
        }
    }, [])
    
    const GetProfileData = async () => {
        let l = new JAOS()
        let data = l.objFromStack(person)
        
        let temp = await GetProfileReport(
            data?.PersonInfo?.FBI != null ? data?.PersonInfo?.FBI : "",
            data?.PersonInfo?.SBINo != null ? data?.PersonInfo?.SBINo : "",
            data?.PersonInfo?.FName != null ? data?.PersonInfo?.FName : "",
            data?.PersonInfo?.LName != null ? data?.PersonInfo?.LName : "",
            data?.PersonInfo?.DOB != null ? data?.PersonInfo?.DOB : ""
            )
        
        if(temp && temp.personObject)
        {
            temp.personObject.pic = "data:image/jpeg;base64,"+ temp?.personObject?.pic
            
            if(temp.assocObject && temp.assocObject.length > 0 ) {
                for (var i = 0; i < temp.assocObject.length; i++) {
                    if (temp.assocObject[i].pic)
                        temp.assocObject[i].pic = "data:image/jpeg;base64," + temp?.assocObject[i]?.pic
                }
            }
            
            if(temp.files == null)
                temp.files = []
            
            setProfileObject(temp)
            return true
        }
        return false
    }
    
    const hadleDataFill = () => {
        let l = new JAOS()
        let data = l.objFromStack(person)
        let temp = profileObject
        
        if (data?.ImageInfo && data?.ImageInfo[0] != null)
        {
            temp.personObject.pic = data?.ImageInfo[0].Image
        }
        if (data?.PersonInfo != null)
        {
            temp.personObject.fName = data?.PersonInfo?.FName != null ? data?.PersonInfo?.FName : "";
            temp.personObject.mName = data?.PersonInfo?.MName != null ? data?.PersonInfo?.MName : "";
            temp.personObject.lName = data?.PersonInfo?.LName != null ? data?.PersonInfo?.LName : "";
            
            temp.personObject.DOB = data?.PersonInfo?.DOB != null ? formatDate(data?.PersonInfo?.DOB) : "";
            temp.personObject.SBI = data?.PersonInfo?.SBINo != null ? data?.PersonInfo?.SBINo : "";
            temp.personObject.FBI = data?.PersonInfo?.FBI != null ? data?.PersonInfo?.FBI : "";

            temp.personObject.gang = data?.PersonInfo?.GANGRELATED != null ? (data?.PersonInfo?.GANGRELATED === "N" ? "Negative" : "" ): "";
            temp.personObject.phone = data?.PersonInfo?.Phone != null ? data?.PersonInfo?.Phone : "";
            temp.personObject.gender = data?.PersonInfo?.SEX != null ? (data?.PersonInfo?.SEX === "M" ? "Male" : data?.Person?.SEX === "F" ? "Female" : "") : "";
        }
        if(data.ArrestInfo != null && data.ArrestInfo.length > 0)
        {
            let agency:Set<String> = new Set([]) 
            for(var a of data.ArrestInfo)
            {
                if(a.ORIGINATINGORINAME != null)
                { 
                    agency.add(a.ORIGINATINGORINAME.trim())
                }
            }
            let agencyList = ""
            for (var x of Array.from(agency))
            {
                agencyList = agencyList + " " + x + ','
            }
            temp.crimActivityObject.arrestAgency = agencyList
        }
        if(data.SCORInfo != null)
        {
            temp.crimActivityObject.tArrests = data.SCORInfo.Arrest + "-NJ"
        }
        /*if(data.AddressInfo != null && data.AddressInfo.length > 0)
        {
            if(data.AddressInfo[data.AddressInfo.length-1] != null && data.AddressInfo[data.AddressInfo.length-1].Address.trim().length > 0)
            {
                temp.personObject.LKA = data.AddressInfo[data.AddressInfo.length-1].Address + ' ' + data.AddressInfo[data.AddressInfo.length-1].City + ' ' + data.AddressInfo[data.AddressInfo.length-1].State
            }
        }*/
        
        setProfileObject(temp)
        setOptions(options+1)
    }
    
   const handleValueChange = (obj:string, field:string, value:any, index:any = null) => {
       let o:string = obj
       let t:string = field
       let temp = profileObject
       switch(o.trim()){
           case("personObject"):
               if(temp.personObject) {
                   switch (t.trim()) {
                       case("fName"):
                           temp.personObject.fName = value
                           break
                       case("mName"):
                           temp.personObject.mName = value
                           break
                       case("lName"):
                           temp.personObject.lName = value
                           break
                       case("LKA"):
                           temp.personObject.LKA = value
                           break
                       case("DOB"):
                           temp.personObject.DOB = value
                           break
                       case("SBI"):
                           temp.personObject.SBI = value
                           break
                       case("FBI"):
                           temp.personObject.FBI = value
                           break
                       case("alias"):
                           temp.personObject.alias = value
                           break
                       case("gang"):
                           temp.personObject.gang = value
                           break
                       case("phone"):
                           temp.personObject.phone = value
                           break
                       case("dna"):
                           temp.personObject.dna = value
                           break
                       case("height"):
                           temp.personObject.height = value
                           break
                       case("weight"):
                           temp.personObject.weight = value
                           break
                       case("hair"):
                           temp.personObject.hair = value ? value : ""
                           break
                       case("eyes"):
                           temp.personObject.eyes = value
                           break
                       case("race"):
                           temp.personObject.race = value
                           break
                       case("gender"):
                           temp.personObject.gender = value
                           break
                       case("scars"):
                           temp.personObject.scars = value
                           break
                       case("pic"):
                           temp.personObject.pic = value
                           break
                   }
               }
               break
           case("deconObject"):
               if(temp.deconObject) {
                   switch (t.trim()) {
                       case("caseAgent"):
                           temp.deconObject[index].caseAgent = value
                           break
                       case("caseAgency"):
                           temp.deconObject[index].caseAgency = value
                           break
                       case("phone"):
                           temp.deconObject[index].phone = value
                           break
                       case("email"):
                           temp.deconObject[index].email = value
                           break
                       case("caseNum"):
                           temp.deconObject[index].caseNum = value
                           break
                   }
               }
               break
           case("crimActivityObject"):
               if(temp.crimActivityObject) {
                   switch (t.trim()) {
                       case("tArrests"):
                           temp.crimActivityObject.tArrests = value
                           break
                       case("convictions"):
                           temp.crimActivityObject.convictions = value
                           break
                       case("priorCharges"):
                           temp.crimActivityObject.priorCharges = value
                           break
                       case("arrestAgency"):
                           temp.crimActivityObject.arrestAgency = value
                           break
                       case("pendingCases"):
                           temp.crimActivityObject.pendingCases = value
                           break
                   }
               }
               break
           case("custodyHistoryObject"):
               if(temp.custodyHistoryObject) {
                   switch (t.trim()) {
                       case("stateCustody"):
                           temp.custodyHistoryObject.stateCustody = value
                           break
                       case("countyCustody"):
                           temp.custodyHistoryObject.countyCustody = value
                           break
                   }
               }
               break
           case("policeContactLocations"):
               if(temp.policeContactLocations) {
                   switch (t.trim()) {
                       case("arrests"):
                           temp.policeContactLocations.arrests = value
                           break
                       case("fieldInterviews"):
                           temp.policeContactLocations.fieldInterviews = value
                           break
                       case("other"):
                           temp.policeContactLocations.other = value
                           break
                   }
               }
               break
           case("additionalInfo"):
               if(temp.additionalInfo) {
                   switch(t.trim()){
                       case("content"):
                           temp.additionalInfo[index].content = value
                           break;
                       case("type"):
                           temp.additionalInfo[index].type = value
                   }
                  
               }
               break
           case("assocObject"):
               if(temp.assocObject) {
                   switch(t.trim()){
                       case("fName"):
                           temp.assocObject[index].fName = value
                           break
                       case("mName"):
                           temp.assocObject[index].mName = value
                           break
                       case("lName"):
                           temp.assocObject[index].lName = value
                           break
                       case("LKA"):
                           temp.assocObject[index].LKA = value
                           break
                       case("DOB"):
                           temp.assocObject[index].DOB = value
                           break
                       case("SBI"):
                           temp.assocObject[index].SBI = value
                           break
                       case("FBI"):
                           temp.assocObject[index].FBI = value
                           break
                       case("alias"):
                           temp.assocObject[index].alias = value
                           break
                       case("relationship"):
                           temp.assocObject[index].relationship = value
                           break
                       case("comments"):
                           temp.assocObject[index].comments = value
                           break
                   }
               }
               break
           case("reportObject"):
               if(temp.reportObject) {
                   switch (t.trim()) {
                       case("preparedBy"):
                           temp.reportObject.preparedBy = value
                           break
                       case("reviewedBy"):
                           temp.reportObject.reviewedBy = value
                           break
                       case("date"):
                           temp.reportObject.date = value
                           break
                       case("reportNum"):
                           temp.reportObject.reportNum = value
                           break
                   }
               }
               break
       }
       setProfileObject(temp)
       setOptions(options +1)
   }
    
    const handlePersonPhoto = (target: any) => {
        try {
            var file = target?.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                handleValueChange("personObject", "pic", reader.result)
            }

            reader.readAsDataURL(file);
        }
        catch (e) {
            console.log(e)
        }
    };
    
    const addDeconfliction = () => {
        let temp = profileObject
        
        if(temp.deconObject == null)
            temp.deconObject = []
        
        temp?.deconObject?.push({caseAgent:"", caseAgency:"", phone:"", email:"", caseNum:""})
        setProfileObject(temp)
        setOptions(options+1)
    }

    const removeDeconfliction = (indx:number) => {
        let temp = profileObject
        let del = temp?.deconObject?.splice(indx, 1)
        setProfileObject(temp)
        setOptions(options +1)
    }

    const removeKnownAssociate = (indx:number) => {
        let temp = profileObject
        let del = temp?.assocObject?.splice(indx, 1)
        setProfileObject(temp)
        setOptions(options +1)
    }
    
    const addKnownAssociate = () => {
        let temp = profileObject

        if(temp.assocObject == null)
            temp.assocObject = []
        
        temp?.assocObject?.push({fName:"", mName:"",lName:"", LKA:"", DOB:"", SBI:"", FBI:"", alias:"", relationship:"", comments:"", pic:""})
        setProfileObject(temp)
        setOptions(options+1)
    }
    
    const handleKnownAssociatePicture = (target: any, indx:any) => {
        try {
            var file = target?.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                let temp = profileObject
                if(temp && temp.assocObject[indx])
                    temp.assocObject[indx].pic = reader.result
                setProfileObject(temp)
                setOptions(options +1)
            }
            
            reader.readAsDataURL(file);
        }
        catch (e) {
            console.log(e)
        }
    }
    
    const addAdditionalInfo = () => {
        let temp = {...profileObject}
        if(temp?.additionalInfo == null)
        {
            temp.additionalInfo = []
        }
        temp.additionalInfo.push({type:"Additional Information:", content:""})
        setProfileObject(temp)
    }
    
    const removeAdditionalOption = (indx:number) => {
        let temp = {...profileObject}
        temp.additionalInfo.splice(indx,1)
        setProfileObject(temp)
    }

    const handleAdditionalFiles = (e : any) => {
        let list:any[] = []
        let name:any[] = []
        let obj = e.files

        if(obj != null) {
            Object.values(obj).forEach((val: any) => {
                list.push(val)
                name.push(val?.name)
            })
            
            setDocList(list)
            
        }
    };
    
    const handleDocDelete = (remove:docItem) => {
        let d = docList.filter(temp => temp !== remove)
        setDocList(d)
    }
    
    const handleFilesDelete = (remove:string) => {
        if(profileObject.files!=null) {
            let temp = profileObject
            temp.files = temp.files.filter(t => t !== remove)
            setProfileObject(temp)
        }
    }
    
    const handleViewDoc = (doc:any) => {
        if(profileObject.ID != null)
        {
            GetProfileReportFiles(doc, profileObject.ID)
        }
        
    }
    
    const handleSubmit = async () => {
        
        try {
            if (handleValidation()) {
                let res = await RunProfileReport(profileObject);

                if (res) {
                    for (var i of docList) {
                        const formData = new FormData();
                        formData.append("file", i)
                        let res1 = await RunProfileReportFiles(formData, res)

                    }

                    if (profileObject.files != null) {
                        let OldFiles = []
                        for (var i of profileObject.files) {
                            OldFiles.push(i)
                        }
                        console.log(profileObject.ID)
                        await ProfileReportFilesOld(OldFiles, res, profileObject.ID)
                    }
                    let pdf = await GetProfileReportPDF(res)
                }

            }
        }
        catch(e)
            {
                console.log(e)
            }
        
    }
    
    const handleValidation = () => {
        
        if(profileObject != null)
        {
            if(profileObject?.personObject != null &&
                (profileObject?.personObject?.SBI?.length > 0 ||
                 profileObject?.personObject?.FBI?.length > 0 ||
                (profileObject?.personObject?.fName?.length > 0 && profileObject?.personObject?.lName?.length > 0 && profileObject?.personObject?.DOB?.length > 0)) )
            {
                return true
            }
            else
            {
                setAlertType("error")
                setAlertText(("Please Enter at least one of the following (FBI, SBI, First & Last Name + DOB)"))
                setAlertOpen(true)
                return false
            }
        }
        setAlertType("error")
        setAlertText(("An Error has occurred while saving this report."))
        setAlertOpen(true)
        return false
    }
    
    return (
        <div className="">
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Card sx={{ maxWidth: 1600, m: 'auto', mb: 1 }} >
                <CardContent>    
                    <div className = "border-r-2 border-l-2 border-t-2 border-gray-700 bg-blue-300 h-11 font-bold" >
                        <FormControl size = "small" sx={{minWidth:"100%"}} style={{textAlign: 'center', height:8, verticalAlign:"text-top", justifyContent:"top", fontWeight:"bold"}}>
                            <Select
                                sx={{fontWeight:"bold"}}
                                className = "font-bold"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={profileObject?.reportType}
                                label="Report Type"
                                onChange={(e:any) => setProfileObject({...profileObject, reportType: e.target.value})}
                            >
                                <MenuItem value={"Person of Strategic Interest Report"}>Person of Strategic Interest Report</MenuItem>
                                <MenuItem value={"Victim Profile Report"}>Victim Profile Report</MenuItem>
                                <MenuItem value={"Suspect Profile Report"}>Suspect Profile Report</MenuItem>
                            </Select>
                        </FormControl>
{/*
                        <TextField fullWidth variant="standard" margin='none' size={"small"} label="Report Type" inputProps={{ shrink:true, style: {textAlign: 'center', height:12, verticalAlign:"text-top", justifyContent:"top", fontWeight:"bold"}}} value={profileObject?.reportType} onChange={(e:any) => setProfileObject({...profileObject, reportType: e.target.value})}/>
*/}
                    </div>
                    <div>
                        <div className = "flex border-2 border-gray-700">
                            <div className = "flex border-r-2 pr-4 border-gray-700">
                                <div className = "mt-3 ml-10 inline max-h-56" style={{ maxWidth: 200 }}>
                                    <img src={profileObject?.personObject?.pic ? profileObject?.personObject?.pic : noImage} style={{height:250, width:200}} />
                                </div>
                                <span className="inline mt-1">
                                    <input
                                        id="icon-button-photo"
                                        style={{display:"none"}}
                                        onChange={e => {handlePersonPhoto(e.target)}}
                                        type="file"
                                    />
                                    <label htmlFor="icon-button-photo" className = "">
                                        <IconButton color="primary" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </span>
                            </div>
                            <div className = "grid grid-cols-2 flex-1" style = {{clear:"both"}}>
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Name:</h1>
                                    <div style={{ "display": "flex", "justifyContent": "center" }}>
                                        <TextField  variant="standard" margin='none' size={"medium"} label="Firstname" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.fName} onChange = { (e) => handleValueChange("personObject", "fName", e.target.value )}/>
                                        <TextField  variant="standard" margin='none' size={"medium"} label="MI" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.mName} onChange = { (e) => handleValueChange("personObject", "mName", e.target.value )} />
                                        <TextField  variant="standard" margin='none' size={"medium"} label="Lastname" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.lName} onChange = { (e) => handleValueChange("personObject", "lName", e.target.value )}/>
                                    </div>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Last Known Address:</h1>
                                    <div className = ""><TextField fullWidth variant="standard" margin='none' size={"medium"} label="Last Known Address" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.LKA} onChange = { (e) => handleValueChange("personObject", "LKA", e.target.value )}/></div>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700 ml-5 pr-5">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >DOB:</h1>
                                            <div className="">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="DOB"
                                                        value={profileObject?.personObject?.DOB}
                                                        onChange={(e) => {handleValueChange("personObject", "DOB", e)}}
                                                        renderInput={(params) => <TextField margin='none' size={"small"} variant={"standard"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                                {/*<TextField variant="standard" margin='none' size={"small"} type="date" label="DOB" InputLabelProps={{ shrink: true }} value={profileObject?.personObject?.DOB} onChange = { (e) => handleValueChange("personObject", "DOB", e.target.value )}/></div>*/}
                                        </div>
                                        <div className = "border-r-2 border-gray-700 ml-5 pr-5">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>SBI:</h1>
                                            <TextField variant="standard" margin='none' size={"small"} label="SBI" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.SBI} onChange = { (e) => handleValueChange("personObject", "SBI", e.target.value )} />
                                        </div>
                                        <div className = "ml-5">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>FBI:</h1>
                                            <TextField variant="standard" margin='none' size={"small"} label="FBI" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.FBI} onChange = { (e) => handleValueChange("personObject", "FBI", e.target.value )}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-b-2 border-gray-700 ">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Alias/Aka/Street Names:</h1>
                                    <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} label="Alias/AKA/Street Names" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.alias} onChange = { (e) => handleValueChange("personObject", "alias", e.target.value )} /></div>
                                </div>
                                <div className="border-b-2 border-r-2 border-gray-700  ">
                                    <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Gang Indicia:</h1>
                                    <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} label="Gang Indicia" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.gang} onChange = { (e) => handleValueChange("personObject", "gang", e.target.value )}/></div>
                                </div>
                                <div className="border-b-2 border-gray-700 ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700" style={{width:312}}>
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >Tel#:</h1>
                                            <div className = ""><TextField  fullWidth variant="standard" margin='none' size={"small"} label="Tel#" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.phone} onChange = { (e) => handleValueChange("personObject", "phone", e.target.value )}/></div>
                                        </div>
                                        <div className = "" style={{width:310}}>
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>DNA on File:</h1>
                                            <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} label="DNA on File" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.dna} onChange = { (e) => handleValueChange("personObject", "dna", e.target.value )}/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-r-2 border-gray-700  ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700 ml-2 pr-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >Height:</h1>
                                            <FormControl variant="standard" sx={{minWidth: 140, textAlign:"center"}} size="small">
                                                <InputLabel id="search-option">Height</InputLabel>
                                                <Select
                                                    labelId="search-option"
                                                    value={profileObject?.personObject?.height}
                                                    label="Height"
                                                    onChange={(e) => handleValueChange("personObject", "height", e.target.value )}
                                                >
                                                    {heightDD.map((option) => (
                                                        <MenuItem value={option.value}>{option.key}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className = "border-r-2 border-gray-700 ml-2 pr-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Weight:</h1>
                                            <TextField variant="standard" type={"number"} margin='none' size={"small"} label="Weight" inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.personObject?.weight} onChange = { (e) => handleValueChange("personObject", "weight", e.target.value )} />
                                        </div>
                                        <div className = "border-r-2 border-gray-700 ml-2 pr-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Hair:</h1>
                                            <FormControl size="small" sx={{minWidth: 140, textAlign:"center"}}>
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:130, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={hairDD.map((option) => option)}
                                                    value = {profileObject.personObject.hair}
                                                    onChange = { (e, value) => handleValueChange("personObject", "hair", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("personObject", "hair", e.target.value )} label="Hair"/>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className = "ml-2">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Eyes:</h1>
                                            <FormControl size="small" sx={{minWidth: 140, textAlign:"center"}}>
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:130, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={eyesDD.map((option) => option)}
                                                    value = {profileObject.personObject.eyes}
                                                    onChange = { (e, value) => handleValueChange("personObject", "eyes", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("personObject", "eyes", e.target.value )} label="Eyes"/>}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-gray-700 mr-3 ">
                                    <div className = "flex">
                                        <div className = "border-r-2 border-gray-700">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >Race:</h1>
                                            <FormControl size="small">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:310, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={raceDD.map((option) => option)}
                                                    value = {profileObject.personObject.race}
                                                    onChange = { (e, value) => handleValueChange("personObject", "race", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("personObject", "race", e.target.value )} label="Race"/>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className = "">
                                            <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Gender:</h1>
                                            <FormControl size="small">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    size = "small"
                                                    sx={{width:310, textAlign:"center", justifyContent:"center"}}
                                                    options={genderDD.map((option) => option)}
                                                    value = {profileObject.personObject.gender}
                                                    onChange = { (e, value) => handleValueChange("personObject", "gender", value )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("personObject", "gender", e.target.value )} label="Gender"/>}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-l-2 border-r-2 border-b-2 border-gray-700 pl-4 mb-5">
                            <h1 className="text-blue-600 font-bold">Scars/Marks/Tattoos:</h1>
                            <TextField fullWidth variant="standard" margin='none' size={"small"} label="Scars/Marks/Tattoos" value = {profileObject?.personObject?.scars} onChange = { (e) => handleValueChange("personObject", "scars", e.target.value )} />
                        </div>
                    </div>
                    
                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Comments</div>
                            <TextareaAutosize style={{width:"100%"}} minRows={6} maxRows={6} placeholder="Comments" value = {profileObject?.comments} onChange={(e:any) => setProfileObject({...profileObject, comments: e.target.value})}/>
                        </div>
                    </div>
                    
                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Deconfliction</div>
                            <span>
                                <Checkbox
                                    value={profileObject?.deconChecked}
                                    onChange={() => {setProfileObject({...profileObject, deconChecked: !profileObject?.deconChecked})}}
                                    icon={<CancelPresentationIcon/>}
                                    checkedIcon={<CancelPresentationIcon/>}
                                />
                                <label className="font-bold font-large">{profileObject?.deconChecked ? ("Match") : ("No Match")} / CE#</label>
                                <span className ="ml-2"><TextField variant="standard" margin='none' size={"small"} value = {profileObject?.ceNum} onChange={(e:any) => setProfileObject({...profileObject, ceNum: e.target.value})}/></span>
                                {profileObject?.deconChecked ? <div><Button onClick = {(e) => {addDeconfliction()}}><AddCircleOutlineIcon/></Button></div> : <></>}
                                {profileObject && profileObject?.deconObject?.length > 0 ? profileObject?.deconObject.map((obj, indx)=>
                                    <div className = {indx == profileObject?.deconObject?.length - 1 ? "ml-2" : "ml-2 mb-4" }>
                                        <div><label className = "font-bold">Case Agent: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.caseAgent} onChange={(e) => handleValueChange("deconObject", "caseAgent", e.target.value, indx )}/></div>
                                        <div><label className = "font-bold">Case Agency: </label>
                                            <FormControl size="small" sx={{minWidth: 140, textAlign:"center"}}>
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    autoComplete = {true}
                                                    size = "small"
                                                    sx={{width:130, height:30, textAlign:"center", justifyContent:"center"}}
                                                    options={deconAgencyDD.map((option) => option)}
                                                    onChange = { (e, value) => handleValueChange("deconObject", "caseAgency", value, indx )}
                                                    renderInput={(params) => <TextField variant={"standard"} {...params} onChange = { (e) => handleValueChange("deconObject", "caseAgency", e.target.value, indx )}/>}
                                                />
                                            </FormControl>
                                        </div>
                                        <div><label className = "font-bold">Contact Phone Number: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.phone} onChange={(e) => handleValueChange("deconObject", "phone", e.target.value, indx )}/>
                                            <span className="ml-20 inline">
                                                <IconButton aria-label="delete" size="small" onClick={() => removeDeconfliction(indx)} color={"error"}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </span>
                                        </div>
                                        <div><label className = "font-bold">Contact Email Address: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.email} onChange={(e) => handleValueChange("deconObject", "email", e.target.value, indx )}/></div>
                                        <div><label className = "font-bold">Case Number: </label><TextField variant="standard" margin='none' size={"small"} value = {obj.caseNum} onChange={(e) => handleValueChange("deconObject", "caseNum", e.target.value, indx )}/></div>
                                    </div>
                                ) : <></> }
                            </span>
                        </div>
                    </div>
                    
                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Criminal Activity</div>
                            <span className = "border-b-2 border-gray-700" style={{ "display": "flex", "justifyContent": "center" }}>
                                <span className = "mr-20"><label className = "font-bold">Total Arrests: </label><TextField variant="standard" margin='none' size={"small"} value = {profileObject?.crimActivityObject?.tArrests} onChange={(e) => handleValueChange("crimActivityObject", "tArrests", e.target.value )} /></span>
                                <span><label className = "font-bold">Felony Convictions: </label><TextField variant="standard" margin='none' size={"small"} value = {profileObject?.crimActivityObject?.convictions} onChange={(e) => handleValueChange("crimActivityObject", "convictions", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2">
                                <label className = "font-bold">Prior Charges Include: </label>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Prior Charges Include:" value = {profileObject?.crimActivityObject?.priorCharges} onChange={(e) => handleValueChange("crimActivityObject", "priorCharges", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2">
                                <label className = "font-bold">Arresting Agencies: </label>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Arresting Agencies:" value = {profileObject?.crimActivityObject?.arrestAgency} onChange={(e) => handleValueChange("crimActivityObject", "arrestAgency", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2">
                                <label className = "font-bold">Pending Cases: </label>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Pending Cases:" value = {profileObject?.crimActivityObject?.pendingCases} onChange={(e) => handleValueChange("crimActivityObject", "pendingCases", e.target.value )}/></span>
                            </span>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Custody History</div>
                            <div className = "grid grid-cols-2 flex-1">
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>State Custody</h1>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>County Custody (Most Recent)</h1>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.custodyHistoryObject?.stateCustody} onChange = { (e) => handleValueChange("custodyHistoryObject", "stateCustody", e.target.value )} /></div>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.custodyHistoryObject?.countyCustody} onChange = { (e) => handleValueChange("custodyHistoryObject", "countyCustody", e.target.value )} /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>MVC Status / Registered Vehicles</div>
                            <TextareaAutosize style={{width:"100%", textAlign: 'center'}} minRows={2} maxRows={6} placeholder="MVC Status / Registered Vehicles" value={profileObject?.mvcStatus} onChange={(e:any) => setProfileObject({...profileObject, mvcStatus: e.target.value})} />
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Associate Contacts / Vehicles</div>
                            <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Associate Contacts / Vehicles" value={profileObject?.associateContacts} onChange={(e:any) => setProfileObject({...profileObject, associateContacts: e.target.value})}/>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Triple I</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>ALPR</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.tripleI} onChange={(e:any) => setProfileObject({...profileObject, tripleI: e.target.value})} /></div>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <div className = ""><TextField fullWidth  variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} value={profileObject?.ALPR} onChange={(e:any) => setProfileObject({...profileObject, ALPR: e.target.value})} /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>ACS / ATS / NCIC Holds</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Probation / Parole</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={1} maxRows={6} placeholder="ACS / ATS / NCIC Holds" value={profileObject?.holds} onChange={(e:any) => setProfileObject({...profileObject, holds: e.target.value})}/>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={1} maxRows={6} placeholder="Probation / Parole" value={profileObject?.probation} onChange={(e:any) => setProfileObject({...profileObject, probation: e.target.value})}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Employment History</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Domestic Violence</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Employment History" value={profileObject?.employmentHistory} onChange={(e:any) => setProfileObject({...profileObject, employmentHistory: e.target.value})}/>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Domestic Violence" value={profileObject?.domesticViolence} onChange={(e:any) => setProfileObject({...profileObject, domesticViolence: e.target.value})}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "grid grid-cols-2 flex-1">
                                <div className = "border-b-2 border-r-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Juvenile Registry</div>
                                <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Social Media</div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Juvenile Registry" value={profileObject?.juvenileHistory} onChange={(e:any) => setProfileObject({...profileObject, juvenileHistory: e.target.value})}/>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={6} placeholder="Social Media" value={profileObject?.socialMedia} onChange={(e:any) => setProfileObject({...profileObject, socialMedia: e.target.value})}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Police Contact Locations</div>
                            <div className = "grid grid-cols-3 flex-1">
                                <div className="border-r-2 border-b-2 border-gray-700 ">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Arrests</h1>
                                </div>
                                <div className="border-r-2  border-b-2 border-gray-700">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Field Interviews</h1>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <h1 className="font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Other Police Contacts (Last 2 Years)</h1>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700 " >
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={8} placeholder="Arrests" value={profileObject?.policeContactLocations?.arrests} onChange = { (e) => handleValueChange("policeContactLocations", "arrests", e.target.value )}/>
                                </div>
                                <div className="border-r-2 border-b-2 border-gray-700">
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={8} placeholder="Field Interviews" value={profileObject?.policeContactLocations?.fieldInterviews} onChange = { (e) => handleValueChange("policeContactLocations", "fieldInterviews", e.target.value )}/>
                                </div>
                                <div className="border-b-2 border-gray-700">
                                    <TextareaAutosize style={{width:"100%" , textAlign: 'center'}} minRows={2} maxRows={8} placeholder="Other Police Contacts (Last 2 Years)" value={profileObject?.policeContactLocations?.other} onChange = { (e) => handleValueChange("policeContactLocations", "other", e.target.value )}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="border-2 border-gray-700 mb-5">
                            <div className = "border-b-2 border-gray-700 bg-blue-300 h-6 font-bold mb-2" style={{ "display": "flex", "justifyContent": "center" }}>Additional Information</div>
                            {profileObject && profileObject?.additionalInfo?.length > 0 ? profileObject?.additionalInfo.map((item, indx) => (
                            <span className = "ml-2">
                                <span>
                                    <TextField variant="standard" style={{width:"14%"}} inputProps={{style: {fontSize: 15, padding:0, width:"100%"}}} value = {item?.type} onChange={(e) => handleValueChange("additionalInfo", "type", e.target.value, indx )} />
                                    <span className = "ml-4 inline "><IconButton size="small" onClick={() => removeAdditionalOption(indx)} color={"error"}><DeleteIcon/></IconButton></span> 
                                </span>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder={item?.type} value = {item.content} onChange={(e) => handleValueChange("additionalInfo", "content", e.target.value, indx )}/></span>
                                
                            </span>
                            )) : <></>}
                            
                            {/*<span className = "ml-2">
                                <label className = "underline">Additional Addresses: </label>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Additional Addresses:"  onChange = { (e) => handleValueChange("additionalInfo", "address", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2">
                                <label className = "underline">Additional Telephone Numbers: </label>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Additional Telephone Numbers:" onChange = { (e) => handleValueChange("additionalInfo", "phone", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2">
                                <label className = "underline">Additional Aliases: </label>
                                <span className = "ml-2"><TextareaAutosize style={{width:"99%"}} minRows={1} maxRows={4} placeholder="Additional Aliases:" onChange = { (e) => handleValueChange("additionalInfo", "alias", e.target.value )}/></span>
                            </span>*/}
                            <div>
                                <div><label className = "text-blue-600  font-bold ml-2"> Add Additional Information</label></div>
                                <Button onClick = {(e) => {addAdditionalInfo()}}><AddCircleOutlineIcon/></Button>
                            </div>
                        </div>
                    </div>
                    
                    <div className = "mb-5">
                        <label className = "text-blue-600  font-bold "> Add Known Associate</label>
                        <div><Button onClick = {(e) => {addKnownAssociate()}}><AddCircleOutlineIcon/></Button></div>

                        {profileObject && profileObject?.assocObject?.length > 0 ? profileObject?.assocObject.map((obj, idx) => 
                                <div className = "mb-10">
                                    <span className="inline"  style={{ "display": "flex", "justifyContent": "end" }}>
                                        <IconButton aria-label="delete" size="small" onClick={() => removeKnownAssociate(idx)} color={"error"}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </span>
                                    <div className = "flex border-2 border-gray-700">
                                        <div className = "flex border-r-2 pr-4 border-gray-700">
                                            <div className = "mt-5 ml-10 inline max-h-64" style={{ maxWidth: 200 }}>
                                                <img src={obj.pic ? obj.pic : noImage} style={{height:"180", width:200, maxWidth: 200, maxHeight: 256}} />
                                            </div>
                                            <span className="inline mt-1">
                                        <FormControl>
                                            <input
                                                id={"icon-button-assoc-photo" + idx }
                                                style={{display:"none"}}
                                                onChange={e => {handleKnownAssociatePicture(e.target,idx)}} 
                                                type="file"
                                            />
                                        </FormControl>
                                        <label htmlFor={"icon-button-assoc-photo" + idx } >
                                            <IconButton color="primary" component="span">
                                                <AttachFileIcon />
                                            </IconButton>
                                        </label>
                                    </span>
                                        </div>
                                        <div className = "grid grid-cols-2 flex-1" style = {{clear:"both"}}>
                                            <div className = " col-span-2 border-b-2 border-gray-700 bg-blue-300 h-6 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Known Associate</div>
                                            <div className="border-r-2 border-b-2 border-gray-700 ">
                                                <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Name:</h1>
                                                <div style={{ "display": "flex", "justifyContent": "center" }}>
                                                    <TextField  variant="standard" margin='none' size={"medium"} label="Firstname" value={obj.fName} 
                                                        onChange={(e) => {handleValueChange("assocObject", "fName", e.target.value, idx )}}/>
                                                    <TextField  variant="standard" margin='none' size={"medium"} label="MI" value={obj.mName}
                                                                onChange={(e) => {handleValueChange("assocObject", "mName", e.target.value, idx )}}/>
                                                    <TextField  variant="standard" margin='none' size={"medium"} label="Lastname" value={obj.lName}
                                                                onChange={(e) => {handleValueChange("assocObject", "lName", e.target.value, idx )}}/>
                                                </div>
                                            </div>
                                            <div className="border-b-2 border-gray-700">
                                                <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Last Known Address:</h1>
                                                <div className = ""><TextField fullWidth variant="standard" margin='none' size={"medium"} inputProps={{ style: {textAlign: 'center'} }} label="Last Known Address" value={obj.LKA}
                                                                                           onChange={(e) => {handleValueChange("assocObject", "LKA", e.target.value, idx )}}/></div>
                                            </div>
                                            <div className="border-r-2 border-b-2 border-gray-700 ">
                                                <div className = "flex">
                                                    <div className = "border-r-2 border-gray-700 ml-5 pr-5">
                                                        <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center"}} >DOB:</h1>
                                                        <div className="">
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="DOB"
                                                                    value={obj.DOB}
                                                                    onChange={(e) => {handleValueChange("assocObject", "DOB", e, idx )}}
                                                                    renderInput={(params) => <TextField margin='none' size={"small"} variant={"standard"} type="date" label="DOB"  InputLabelProps={{ shrink: true }} {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                            {/* <TextField variant="standard" margin='none' size={"small"} type="date" label="DOB" value={obj.DOB} onChange={(e) => {handleValueChange("assocObject", "DOB", e.target.value, idx )}} InputLabelProps={{ shrink: true }}/>*/}
                                                        </div>
                                                    </div>
                                                    <div className = "border-r-2 border-gray-700 ml-5 pr-5">
                                                        <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>SBI:</h1>
                                                        <TextField variant="standard" margin='none' size={"small"} label="SBI" value={obj.SBI}
                                                                   onChange={(e) => {handleValueChange("assocObject", "SBI", e.target.value, idx )}}/>
                                                    </div>
                                                    <div className = "ml-5">
                                                        <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>FBI:</h1>
                                                        <TextField variant="standard" margin='none' size={"small"} label="FBI" value={obj.FBI}
                                                                   onChange={(e) => {handleValueChange("assocObject", "FBI", e.target.value, idx )}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-b-2 border-gray-700 ">
                                                <h1 className="text-blue-600 font-bold" style={{ "display": "flex", "justifyContent": "center" }}>Alias/Aka/Street Names:</h1>
                                                <div className = ""><TextField fullWidth variant="standard" margin='none' size={"small"} inputProps={{ style: {textAlign: 'center'} }} label="Alias/AKA/Street Names" value={obj.alias}
                                                                                           onChange={(e) => {handleValueChange("assocObject", "alias", e.target.value, idx )}}/></div>
                                            </div>
                                            <div className="border-b-2 border-gray-700 col-span-2">
                                                <h1 className="ml-2 text-blue-600 font-bold">Relationship:</h1>
                                                <div className ="ml-2"><TextField fullWidth variant="standard" margin='none' size={"small"} label="Relationship" value={obj.relationship}
                                                                                  onChange={(e) => {handleValueChange("assocObject", "relationship", e.target.value, idx )}}/></div>
                                            </div>
                                            <div className="col-span-2">
                                                <h1 className="ml-2 text-blue-600 font-bold">Additional Comments:</h1>
                                                <div className ="ml-2"><TextField fullWidth variant="standard" margin='none' size={"small"} label="Comments" value={obj.comments}
                                                                                  onChange={(e) => {handleValueChange("assocObject", "comments", e.target.value, idx )}}/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <></>}
                    </div>

                    <div>
                        <span className="border-2 border-gray-700 mb-5 flex">
                            <span className = "ml-2 border-r-2 border-gray-700 pr-4" style={{width:400}}>
                                <label className = "text-blue-600 font-bold">Prepared by: </label>
                                <span className = ""><TextareaAutosize style={{width:"100%"}} minRows={1} maxRows={4} placeholder="Prepared by" value={profileObject?.reportObject?.preparedBy} onChange = { (e) => handleValueChange("reportObject", "preparedBy", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2 border-r-2 border-gray-700 pr-4" style={{width:400}}>
                                <label className = "text-blue-600 font-bold">Reviewed by: </label>
                                <span className = ""><TextareaAutosize style={{width:"100%"}} minRows={1} maxRows={4} placeholder="Reviewed by" value={profileObject?.reportObject?.reviewedBy} onChange = { (e) => handleValueChange("reportObject", "reviewedBy", e.target.value )}/></span>
                            </span>
                            <span className = "ml-2 border-r-2 border-gray-700 pr-4">
                                <label className = "text-blue-600 font-bold">Date: </label>
                                <span className = "">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date"
                                            value={profileObject?.reportObject?.date}
                                            onChange={(e) => {handleValueChange("reportObject", "date", e)}}
                                            renderInput={(params) => <TextField margin='none' size={"small"} variant={"standard"} type="date" label="Date"  InputLabelProps={{ shrink: true }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </span>
                            </span>
                            <span className = "ml-2 pr-4" style={{width:400}}>
                                <label className = "text-blue-600 font-bold">Report #: </label>
                                <span className = ""><TextareaAutosize style={{width:"100%"}} minRows={1} maxRows={4} placeholder="Report #" value={profileObject?.reportObject?.reportNum} onChange = { (e) => handleValueChange("reportObject", "reportNum", e.target.value )}/></span>
                            </span>
                        </span>
                    </div>
                    <div>
                        {profileObject.files.map(doc =>
                            <div className = "tag-item text-green-600">
                                <button
                                    type="button"
                                    style={{}}
                                    onClick={() => handleViewDoc(doc)}
                                >
                                    {doc.fileName}
                                </button>

                                <button
                                    type="button"
                                    className ="button text-black"
                                    style={{}}
                                    onClick={() => handleFilesDelete(doc)}
                                >
                                    &times;
                                </button>
                            </div>)}
                        {docList.map(doc =>
                        <div className = "tag-item text-yellow-600">
                            {doc.name}

                            <button
                                type="button"
                                className ="button text-black"
                                style={{}}
                                onClick={() => handleDocDelete(doc)}
                            >
                                &times;
                            </button>
                        </div>)}
                        <div className = "mt-2">
                            <label className =" border-2 border-gray-300 py-2 pl-2 text-xl rounded-xl text-gray-400" htmlFor="icon">
                                <input
                                    id="icon"
                                    hidden
                                    className = "input"
                                    multiple
                                    onChange={e => {handleAdditionalFiles(e.target)}}
                                    type="file"
                                    accept = ".pdf, .txt, .doc, .docx"
                                />
                                Attach Files to this Report
                                <IconButton color="primary" component="span">
                                        <AttachFileIcon />
                                </IconButton>
                            </label>
                        </div>
                    </div>
                    
                    
                    <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                        <ButtonGroup color="inherit" >
                            <Button type="submit" onClick={handleSubmit}>Submit</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default ProfileReportInputForm;