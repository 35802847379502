import React from 'react'
import {
    Button, ButtonGroup, 
} from "@mui/material";
import {ALPRMultipleOccurenceResult, PersonInfo} from "../../../interfaces/getDarInfo_interface";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {JAOS} from "../../../services/JAOS.service";
import icon_car from "../../../assets/Images/Vehicle.png"
import Marquee from "react-fast-marquee";

interface ALPRMultipleOccurrenceResultsProps {
    ALPRData: ALPRMultipleOccurenceResult
}

const ALPRMultipleOccurrenceResults: React.FC<ALPRMultipleOccurrenceResultsProps> = ({ALPRData})=> {
    const [typedData, setTypedData] = React.useState<any>({})
    let grid: Grid | null;


    const handleDataTyping = () =>
    {
        if(ALPRData && ALPRData.MultipleOccurrenceList) {
            let tempP: any = [...ALPRData.MultipleOccurrenceList]

            for (let p of tempP) {
                if (p.red_TimeStamp) {
                    p.red_TimeStamp = new Date(p.red_TimeStamp)
                }
                if (p.POIReason) {
                    //p.POIReason = p.POIReason.toUpperCase()
                }
            }
            setTypedData(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    },[ALPRData])
    
    const getALPRDetailsSummary = (alpr:any) =>
    {
        let temp = ALPRData.MultipleOccurrenceDetailList?.filter((x)=> x.red_vrm === alpr.red_vrm)
        if(temp) {
            let tempP: any = [...temp]

            for (let p of tempP) {
                if (p.red_TimeStamp) {
                    p.red_TimeStamp = new Date(p.red_TimeStamp)
                }
            }
            temp = tempP
        }
        return (
            <GridComponent
                dataSource={temp}
                allowPaging={true}
                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='red_vrm' headerText='Plate #'/>
                    <ColumnDirective field='red_TimeStamp' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm:ss'}} headerText='Time Stamp'/>
                    <ColumnDirective field='POIReasonType' headerText='POI Reason Type'/>
                    <ColumnDirective field='POIReason' headerText='POI Reason'/>
                    <ColumnDirective field='src_Name' headerText='Source Name'/>
                    <ColumnDirective field='src_Description' headerText='Source Description' />
                    <ColumnDirective field='Latitude' headerText='Latitude' />
                    <ColumnDirective field='Longitude' headerText='Longitude' />
                    {/*<ColumnDirective template={(item: any) => (<>({item.Latitude}, {item.Longitude})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/}
                    <ColumnDirective template={(item: any) => (<Button onClick={() => {window.open((item?.ste_Name == "ETicket" ? "/detail/ETicket" : item?.ste_Name == "ParkMobile" ? "/detail/ParkMobile" : "/detail/ALPR") + '?ID=' + item?.LprID + '&Dept=' + item?.src_Name, '_blank') }} >View</Button>)} headerText='View' />
                </ColumnsDirective>
                <Inject services={[Sort, Page, Filter]}/>
            </GridComponent>
        )
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'MultipleOccurrence.xlsx'
            grid.excelExport(excelProp);

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {
                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'red_vrm', direction: 'Ascending' }
        ] };

    const searchPlates = () =>  {
        if(typedData)
        {
            let obj = {Plates:""}
            if(typedData)
            {
                for (let p of typedData){
                    if(p.red_vrm)
                    {
                        obj.Plates += p.red_vrm + ",";
                    }
                }
            }

            if(obj.Plates.length !== 0) {
                let l = new JAOS()
                let hashID = l.objToStack(obj)
                window.open('/tools/arrest?personList=' + hashID, '_blank')
            }
        }
    }
    
    return (
        <div className={"pl-10 pr-10 mt-5 w-full"}>
            {ALPRData?.MultipleOccurrenceList && ALPRData?.MultipleOccurrenceList?.length>0? <>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {ALPRData.MultipleOccurrenceList?.length} Records Found</h4>
                    <ButtonGroup size="medium" color="inherit" >
                         <Button variant="outlined" onClick={searchPlates}>
                            <img src={icon_car} className ="mr-2"/><b>Vehicle Connection</b>
                        </Button>
                    </ButtonGroup>
                </span>
                <GridComponent
                    dataSource={typedData}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={getALPRDetailsSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    rowSelecting = {(event:any) => {console.log(event)}}
                    rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='red_vrm' headerText='Plate Number'/>
                        <ColumnDirective field='red_TimeStamp' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }} headerText='Time Stamp' />
                        <ColumnDirective field='POIReasonType' headerText='POI Reason Type' />
                        <ColumnDirective field='POIReason' headerText='POI Reason' />
                        <ColumnDirective field='src_Name' headerText='Source Name' />
                        <ColumnDirective field='Latitude' headerText='Latitude' />
                        <ColumnDirective field='Longitude' headerText='Longitude' />
                        <ColumnDirective field='Occurrence' headerText='Occurrence' />
                  
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default ALPRMultipleOccurrenceResults;