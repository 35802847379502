import * as React from "react";
import { GoogleMap, useJsApiLoader, Marker, HeatmapLayer, LoadScript, InfoWindow, } from '@react-google-maps/api';
import Box from "@mui/material/Box";
import Geocode from "react-geocode";
import {Google_API_Key} from "../../services/config.service";
import Pin from "../../interfaces/Pin.interface";
import {isPointInPolygon} from "../../services/dashboard.service";
import { useGoogleMap } from '@react-google-maps/api'
import hitPin from '../../assets/Images/HitPin.png'
import noHitPin from '../../assets/Images/NoHitPin.png'
import murderPin from '../../assets/Images/MurderPin.png'
import lawEnforcementPin from '../../assets/Images/LawEnforcementPin.png'
import { Button } from "@mui/material";
import { OpenShootingPDF } from "../../services/detail.service";
import { flexbox } from "@mui/system";
import { formatDT } from "../../services/formatDate.service";


interface HeatMapProps {
    data: any,
    location:any,
}

const HeatMap: React.FC<HeatMapProps> = ({data,location}) => {
    const [mapKey, setMapKey] = React.useState<number>(0)
    const [map, setMap] = React.useState<any>(null)
    const [heatMap, setHeatMap] = React.useState<any>(null)
    const [heatMapData, setHeatMapData] = React.useState<any[]>([])
    const [pinData, setPinData] = React.useState<any[]>([])
    const [center, setCenter] = React.useState<any>({lat: 40.0352, lng: -74.5844})
    const [radius, setRadius] = React.useState<any>(15);
    const [selectedCenter, setSelectedCenter] = React.useState<any>(null)
    Geocode.setApiKey(Google_API_Key);
    const [zoom, setZoom] = React.useState<number>(8)

    const NJBoundry = [
        [41.362453762936575, -74.6918236783217],
        [40.990338834229426, -73.91728763616442],
        [40.6952910099279, -74.01067141429685],
        [40.486723153439925, -74.26885009501595],
        [40.49090088462017, -74.01067141429685],
        [40.06763774018179, -73.8568628385493],
        [39.48504098031486, -74.21391846082041],
        [38.89324697993387, -74.93901603220169],
        [39.47232109991295, -75.55425033519187],
        [39.62903700481567, -75.55974349861143],
        [39.852904834638665, -75.40593492286388],
        [40.23558358525728, -74.79619378329326],
        [40.557709209883015, -75.20818103975989],
        [40.98204561057492, -75.15324940556434]
    ]
    
    React.useEffect(() => {
        if(data.HeatMap) {
            setPinData(data.HeatMap)
            let temp = []
            for(var x of data.HeatMap)
            {
                if(typeof x.Latitude !== 'number' || typeof x.Longitude !== 'number'){
                    console.log(typeof x.Latitude == 'number')
                }
                else if (isPointInPolygon(x.Latitude,x.Longitude, NJBoundry)){
                    temp.push(new google.maps.LatLng(x.Latitude, x.Longitude))
                
                }
            }
            setHeatMapData(temp)
            if(location === 'ALL NJ')
            {
                setCenter({lat: 40.0352, lng: -74.5844})
                setZoom(8)
            }
            else
            {
                setCenter({
                    lat: handleCenter().lat,
                    lng: handleCenter().lng
                })
                if(location === "WHOLE COUNTY")
                {
                    setZoom(10)
                }
                else
                {
                    setZoom(13)
                }
            }
            
        }
        
    }, [data]);

    const handleCenter = ():Pin => {
        var centerLat = 0
        var centerLng = 0
        var count = 0

        if(data.HeatMap && data.HeatMap.length > 0){
            for(var x of data.HeatMap)
            {
                if(typeof x.Latitude !== 'number' || typeof x.Longitude !== 'number'){
                    console.log(typeof x.Latitude == 'number')
                }
                else if(isPointInPolygon(x.Latitude,x.Longitude, NJBoundry))
                {
                        centerLat += parseFloat(x.Latitude)
                        centerLng += parseFloat(x.Longitude)
                        count++
                }
                
            }

            centerLat = centerLat/count
            centerLng = centerLng/count
        }

        return {lat:centerLat, lng:centerLng}
        
    }
    
    
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key,
        libraries: ["visualization"],
    })

    const containerStyle = {
        width: (window.innerWidth * 0.32) + 'px',
        height: '90vh',
        border:'solid 2px',
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
        setHeatMap(null)
    }, [])

    /* const handleZoomChange = () => {
        if(map && map.zoom < 11){
            console.log() 
        }
    } */ 

    /* const getNewRadius = () => {
        console.log(map.zoom)
        switch(map.zoom){
            case 22:
               return 0.00001
            case 21:
                return 0.00003
            case 20:
                return 0.00005
            case 19:
                return 0.00007
            case 18:
               return 0.00009
            case 17:
               return 0.0001
            case 16:
               return 0.0003
            case 15:
               return 0.0005
            case 14:
               return 0.0007
            case 13:
               return 0.0009
            case 12:
               return 0.002
            case 11:
               return 0.004
            case 10:
               return 0.006
            case 9:
                return 0.008
            case 8:
               return 0.01
            case 7:
               return 0.01
            case 6:
               return 0.01
            case 5:
               return 0.01
            case 4:
               return 0.01
            case 3:
               return 0.01
            case 2:
               return 0.01
            case 1:
               return 0.01


        }
    } */

    const onLoad = (mapInstance:any) => {
            setMap(mapInstance)
    }

    const onHeatMapLoad = (mapInstance:any) => {
        setHeatMap(mapInstance)
    }

    const getPinIcon = (pin:any):any  => { 
        let iconPin= {}
        if(pin.Inc_TYPE === 'Shooting Hit'){
            iconPin= {
                url:hitPin,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        else if (pin.Inc_TYPE === 'Shooting Murder'){
            iconPin= {
                url:murderPin,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        else if (pin.Inc_TYPE === 'Shooting No Hit'){
            iconPin= {
                url:noHitPin,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        else if (pin.Inc_TYPE === 'Law Enforcement'){
            iconPin= {
                url:lawEnforcementPin,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        return iconPin
        
    }

    return (
        <div>
            {(isLoaded) ? (
                <>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        key={mapKey}
                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        <>
                                {(data.HeatMap && data.HeatMap.length > 0 &&
                                    data.HeatMap.map((pin:any) =>
                                        <Marker
                                            position={{ lat: pin.Latitude, lng: pin.Longitude}}
                                            icon={getPinIcon(pin)}
                                            onClick={() => {
                                                setSelectedCenter(pin);
                                             }}
                                        >
                                            {selectedCenter && selectedCenter.Latitude === pin.Latitude && selectedCenter.Longitude === pin.Longitude &&
                                            <InfoWindow
                                                position={{ lat: pin.Latitude, lng: pin.Longitude}}
                                                onCloseClick={()=>{setSelectedCenter(null)}}
                                            >
                                                <div className="p-2">
                                                    <span className ="font-bold">Incident #</span> {pin.IncidentNum}
                                                    <hr/>
                                                    <span className ="font-bold">Type:</span> {pin.Inc_TYPE}
                                                    <br/>
                                                    <span className ="font-bold">When:</span> {(formatDT(pin.Inc_DateTime))}
                                                    <br/>
                                                    <span><Button size="small" style={{width:"100%"}} variant="outlined" onClick={() => { OpenShootingPDF(pin.IncidentNum) }} >View Record</Button></span>
                                                </div>
                                            </InfoWindow>
                                            }
                                        </Marker>
                                    )
                                )}
                              
                            <HeatmapLayer
                                onLoad={onHeatMapLoad}
                                options={{
                                    opacity:0.7,
                                    radius:radius,
                                    gradient :[
                                        "rgba(255,96,96,0)",
                                        "rgba(255,85,85,1)",
                                        "rgba(255,75,75,1)",
                                        "rgba(255,67,67,1)",
                                        "rgba(255,47,47,1)",
                                        "rgba(255,30,30,1)",
                                        "rgba(255,18,18,1)",
                                        "rgba(255,7,7,1)",
                                        "rgba(255,0,0,1)",
                                        "rgba(236,0,0,1)",
                                        "rgba(213,1,1,1)",
                                        "rgba(182,0,0,1)",
                                        "rgba(175,0,0,1)",
                                        "rgba(145,0,0,1)",
                                    ],
                                
                                }}
                                
                                // required
                                data={heatMapData}
                            />
                            
                        </>
                    </GoogleMap>
                </>
            ) : <></>}
        </div>
    );
}
export default HeatMap