import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViolentInfo from "../../../interfaces/ViolentInfo.interface";
import { TablePagination, CircularProgress } from "@mui/material";
import ViolentIcon from "../../../assets/Images/Violent1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {Link} from "react-router-dom";

interface PanelViolentProps {
    person: PersonInfo,
    Violents: ViolentInfo[],
    isLoading:boolean
}

const  PanelViolent: React.FC<PanelViolentProps> = ({person,Violents,isLoading})=> {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...Violents]
        for (let p of tempP) {
            if (p.Start_Date) {
                p.Start_Date = new Date(p.Start_Date)
            }
            if (p.ValidationDate) {
                p.ValidationDate = new Date(p.ValidationDate)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Violents])

    return(
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                       <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Violent <img src={ViolentIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{"marginLeft" : "5px"}} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {Violents?.length}</span>
                            </span>
                       </Disclosure.Button>
                    
                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{type: 'CheckBox'}}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='Start_Date' headerText='Entry Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='ValidationDate' headerText='Validation Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='CaseNo' headerText='Case #'/>
                                    <ColumnDirective field='DeptORI' headerText='PoliceStop Dept ORI'/>
                                    <ColumnDirective field='Criteria' headerText='Criteria'/>
                                    <ColumnDirective field='Notes' headerText='Notes'/>
                                    <ColumnDirective field='MedicalCondition' headerText='Medical Condition'/>
                                    <ColumnDirective field='CODIS_Notes' headerText='CODIS Notes'/>
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]}/>
                            </GridComponent>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
};

export default PanelViolent;