import React from "react";
import { useLocation } from "react-router-dom";
import ArrestDetailInfo  from "../../components/Detail/_ArrestDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

  
function ArrestDetail() {
    let query = useQuery();

    return (
        <div>
           <ArrestDetailInfo ID={query.get("ID")} department={query.get("Dept")}/>
        </div>
    );
}

export default ArrestDetail;